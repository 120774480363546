import { IconButton } from "@chakra-ui/button";
import { IoArrowBack } from "react-icons/io5";

export const OnboardingBackButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <IconButton
      aria-label="Atrás"
      color="gray.400"
      fontSize={{ base: "4xl", md: "5xl" }}
      icon={<IoArrowBack />}
      left={{ base: 4, md: 8, lg: 12 }}
      onClick={onClick}
      position="absolute"
      top={{ base: 4, md: 8, lg: 12 }}
      variant="link"
    />
  );
};
