import {
  Badge,
  Card,
  CardBody,
  Icon,
  IconButton,
  LinkBox,
  LinkOverlay,
  Spacer,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";

import { BulkPaymentSummary } from "modules/dashboard/routes/bulk-payments/bulk-payments.api";
import {
  bulkPaymentStatusColorScheme,
  bulkPaymentStatusLabel,
} from "modules/dashboard/routes/bulk-payments/bulk-payments.utils";
import { formatDate } from "utils/date.utils";

export const BulkPaymentsHistoryDraftItem = ({
  bulkPayment,
  onDelete,
  isDeleting,
}: {
  bulkPayment: BulkPaymentSummary;
  onDelete: () => void;
  isDeleting: boolean;
}) => {
  return (
    <Card
      _hover={{ shadow: "lg" }}
      as={LinkBox}
      lineHeight="short"
      rounded="xl"
      shadow="md"
      transition="box-shadow 0.2s"
      minH={44}
      data-group="bulk-payment-history-item"
      position="relative"
    >
      <CardBody p={6}>
        <Tooltip label="Eliminar borrador">
          <IconButton
            icon={<Icon as={FiTrash2} />}
            aria-label="Eliminar borrador"
            isRound
            size="sm"
            variant="subtle"
            colorScheme="red"
            opacity={0}
            _groupHover={{ opacity: 1 }}
            zIndex={1}
            position="absolute"
            top={4}
            right={4}
            onClick={onDelete}
            isLoading={isDeleting}
          />
        </Tooltip>

        <Stack align="start" h="full" justify="center">
          <Badge colorScheme={bulkPaymentStatusColorScheme(bulkPayment.status)}>
            {bulkPaymentStatusLabel(bulkPayment.status)}
          </Badge>

          <Spacer />

          <LinkOverlay
            as={Link}
            mt={0}
            to={`/pagos/${bulkPayment.id}`}
            state={{
              bulkPayment: {
                status: bulkPayment.status,
                paymentType: bulkPayment.paymentType,
              },
            }}
          >
            <Text fontSize="2xl" fontWeight="bold">
              {formatDate(bulkPayment.createdAt, "DD/MM/YYYY [@] HH:mm [h]")}
            </Text>
          </LinkOverlay>

          <Text color="gray.500" fontSize="sm" fontWeight="medium">
            por{" "}
            <Text as="span" color="black">
              {bulkPayment.operatedBy.name}
            </Text>
          </Text>
        </Stack>
      </CardBody>
    </Card>
  );
};
