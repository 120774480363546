import { GraphQLError } from "graphql";
import { CombinedError } from "urql";

export enum ErrorCode {
  Unauthorized = 111,
  SessionExpired = 74610001004,
}

export const getErrorCodes = (error: CombinedError | GraphQLError) => {
  const errors = error instanceof CombinedError ? error.graphQLErrors : [error];
  const errorCodes = errors.flatMap((error) => {
    const extensionError = error.extensions.error as Record<
      string,
      { code: number; message: string; extra: unknown }
    >;
    return Object.values(extensionError).map((error) => error.code);
  });
  return errorCodes;
};
