import { AppRoutes } from "app.routes";
import { AlertDialogProvider } from "contexts/alert-dialog.context";
import { ConfigProvider } from "contexts/config";
import { FeatureFlagsProvider } from "contexts/feature-flags";

export const CompaniesApp = () => {
  return (
    <AlertDialogProvider>
      <ConfigProvider>
        <FeatureFlagsProvider>
          <AppRoutes />
        </FeatureFlagsProvider>
      </ConfigProvider>
    </AlertDialogProvider>
  );
};
