import { Button } from "@chakra-ui/button";
import { Center, Icon } from "@chakra-ui/react";
import { Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/table";
import { useRef } from "react";
import { FiChevronsDown } from "react-icons/fi";

import { PaymentsListRow } from "./payments-list-row";

import { useBulkPaymentDetail } from "modules/dashboard/routes/bulk-payments/bulk-payment-detail/bulk-payment-detail.context";
import { PropsWithRest } from "types/react-utils";

export const PaymentsListBody = ({
  ...rest
}: PropsWithRest<{}, typeof TableContainer>) => {
  const {
    data: { payments },
    actions: { onFetchNextPage },
    state: { isFetchingAdditionalPage, areMorePagesAvailable },
  } = useBulkPaymentDetail();

  const rowAmountInputRefs = useRef<Record<string, HTMLInputElement>>({});

  const onFocusPreviousRow = (paymentId: string) => {
    const previousPaymentIndex =
      payments.findIndex(({ id }) => id === paymentId) - 1;

    if (previousPaymentIndex >= 0) {
      const previousPaymentId = payments[previousPaymentIndex].id;
      const previousPaymentInput =
        rowAmountInputRefs.current[previousPaymentId];
      previousPaymentInput.focus();
      setTimeout(() => previousPaymentInput.select());
    } else {
      rowAmountInputRefs.current[paymentId].blur();
    }
  };

  const onFocusNextRow = (paymentId: string) => {
    const nextPaymentIndex =
      payments.findIndex(({ id }) => id === paymentId) + 1;

    if (nextPaymentIndex < payments.length) {
      const nextPaymentId = payments[nextPaymentIndex].id;
      const nextPaymentInput = rowAmountInputRefs.current[nextPaymentId];
      nextPaymentInput.focus();
      setTimeout(() => nextPaymentInput.select());
    } else {
      rowAmountInputRefs.current[paymentId].blur();
    }
  };

  return (
    <>
      <TableContainer overflowX="auto" w="full" zIndex={0} {...rest}>
        <Table size="lg" overflow="visible">
          <Thead>
            <Tr>
              <Th>Proveedor</Th>
              <Th>CUIT</Th>
              <Th>CBU</Th>
              <Th>Descripción</Th>
              <Th
                isNumeric
                position="sticky"
                right={14}
                roundedTop="lg"
                zIndex={1}
                bg="gray.50"
                _before={{
                  content: '""',
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: "-1px",
                  left: 0,
                  shadow: "md",
                  roundedTop: "lg",
                }}
              >
                Pago neto
              </Th>
              <Th p={0} position="sticky" right={0} bg="white"></Th>
            </Tr>
          </Thead>
          <Tbody>
            {payments.map((payment) => (
              <PaymentsListRow
                key={payment.id}
                ref={(ref) => {
                  if (ref) {
                    rowAmountInputRefs.current[payment.id] = ref;
                  }
                }}
                onFocusNextRow={() => onFocusNextRow(payment.id)}
                onFocusPreviousRow={() => onFocusPreviousRow(payment.id)}
                payment={payment}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {areMorePagesAvailable && (
        <Center mt={3}>
          <Button
            color="gray.500"
            colorScheme="gray"
            isLoading={isFetchingAdditionalPage}
            leftIcon={<Icon as={FiChevronsDown} />}
            onClick={onFetchNextPage}
            rounded="full"
            variant="ghost"
          >
            Cargar más
          </Button>
        </Center>
      )}
    </>
  );
};
