import {
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  PropsOf,
  useColorModeValue,
} from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { FiSearch } from "react-icons/fi";

import { useAutocompleteInput } from "hooks/use-autocomplete-input";
import { PropsWithRest } from "types/react-utils";

type LabelPart = {
  str: string;
  isMatch: boolean;
};

type DisplayedOption<
  Option extends {
    toString(): string;
  }
> = [Option, LabelPart[]];

const normalizeString = (str: string) =>
  str
    .toLocaleLowerCase()
    .replace(/[áàãâ]/g, "a")
    .replace(/[éèê]/g, "e")
    .replace(/[íìî]/g, "i")
    .replace(/[óòõô]/g, "o")
    .replace(/[úùû]/g, "u")
    .replace(/[ç]/g, "c")
    .replace(/[ñ]/g, "n");

export function AutocompleteInput<
  Option extends {
    toString(): string;
  },
  Value extends string | number | null
>({
  options = [],
  optionLabel = (option) => option.toString(),
  optionValue,
  sortOptions,
  value,
  onChangeValue = () => {},
  placeholder,
  isDisabled,
  size,
  colorScheme = "brand",
  ...rest
}: PropsWithRest<
  {
    options?: Option[];
    optionLabel?: (option: Option) => string;
    optionValue: (option: Option) => Value;
    sortOptions?: (o1: Option, o2: Option) => number;
    value?: Value;
    onChangeValue?: (value: Value) => void;
    placeholder?: PropsOf<typeof Input>["placeholder"];
    isDisabled?: PropsOf<typeof Input>["isDisabled"];
    size?: PropsOf<typeof Input>["size"];
    colorScheme?: PropsOf<typeof Input>["colorScheme"];
  },
  typeof Box
>) {
  const sortedOptions = useMemo(() => {
    if (sortOptions) {
      return [...options].sort(sortOptions);
    } else {
      return options;
    }
  }, [options, sortOptions]);

  const inputDefaultValue = useMemo(() => {
    const option = options.find((o) => optionValue(o) === value);
    if (option) {
      return optionLabel(option);
    } else {
      return "";
    }
  }, [options, optionLabel, optionValue, value]);

  const {
    inputProps,
    listboxProps,
    options: displayedOptions,
    areOptionsShown,
  } = useAutocompleteInput<DisplayedOption<Option>>({
    onSelectOption: useCallback(
      ([option]: DisplayedOption<Option>, _method, { reset, hideOptions }) => {
        const value = optionValue(option);
        onChangeValue(value);
        reset();
        hideOptions();
      },
      [onChangeValue, optionValue]
    ),
    search: useCallback(
      (query) =>
        query
          ? sortedOptions
              .map((o: Option): DisplayedOption<Option> => {
                const label = optionLabel(o);
                const normalizedLabel = normalizeString(label);
                const normalizedSearch = normalizeString(query);
                const labelParts: LabelPart[] = [];
                let prevMatchEndIndex = 0;
                let matchIndex = normalizedLabel.indexOf(normalizedSearch);
                if (matchIndex === -1) {
                  return [o, [{ str: label, isMatch: false }]];
                }
                while (matchIndex !== -1) {
                  labelParts.push({
                    str: label.slice(prevMatchEndIndex, matchIndex),
                    isMatch: false,
                  });
                  labelParts.push({
                    str: label.slice(matchIndex, matchIndex + query.length),
                    isMatch: true,
                  });
                  prevMatchEndIndex = matchIndex + query.length;
                  matchIndex = normalizedLabel.indexOf(
                    normalizedSearch,
                    prevMatchEndIndex
                  );
                }
                if (prevMatchEndIndex < label.length) {
                  labelParts.push({
                    str: label.slice(prevMatchEndIndex),
                    isMatch: false,
                  });
                }
                return [o, labelParts];
              })
              .filter(([, labelParts]) =>
                labelParts.some(({ isMatch }) => isMatch)
              )
          : sortedOptions.map((o) => [
              o,
              [{ str: optionLabel(o), isMatch: false }],
            ]),
      [sortedOptions, optionLabel]
    ),
    inputDefaultValue,
  });

  const focusedOptionBg = useColorModeValue(
    `${colorScheme}.50`,
    `${colorScheme}.600`
  );

  return (
    <Box position="relative" {...rest}>
      <InputGroup size={size}>
        <Input
          colorScheme={focusedOptionBg}
          isDisabled={isDisabled}
          placeholder={placeholder}
          {...inputProps}
        />
        <InputRightElement h="full" pointerEvents="none">
          <Icon as={FiSearch} color="gray.400" />
        </InputRightElement>
      </InputGroup>
      {areOptionsShown && (
        <Flex
          _dark={{ bg: "gray.700" }}
          bg="white"
          borderWidth={1}
          flexDir="column"
          left={0}
          maxH={72}
          mt={1}
          overflow="hidden"
          overflowY="auto"
          position="absolute"
          right={0}
          rounded="md"
          shadow="md"
          top="full"
          w="full"
          zIndex={2}
        >
          <Flex direction="column" py={1} {...listboxProps}>
            {displayedOptions.map(
              ({ option: [option, labelParts], isFocused, optionProps }) => {
                const value = optionValue(option);
                return (
                  <Box
                    {...optionProps}
                    key={value}
                    bg={isFocused ? focusedOptionBg : undefined}
                    px={4}
                    py={1}
                    tabIndex={-1}
                    userSelect="none"
                  >
                    {labelParts.map(({ str, isMatch }, i) => (
                      <Box
                        key={i}
                        as="span"
                        fontWeight={isMatch ? "bold" : undefined}
                      >
                        {str}
                      </Box>
                    ))}
                  </Box>
                );
              }
            )}
            {displayedOptions.length === 0 && (
              <Box _dark={{ color: "gray.400" }} color="gray.600" px={4} py={2}>
                No hay opciones que coincidan con la búsqueda
              </Box>
            )}
          </Flex>
        </Flex>
      )}
    </Box>
  );
}
