import {
  Box,
  Flex,
  HStack,
  PinInput,
  PinInputField,
  Progress,
} from "@chakra-ui/react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { gql, useMutation } from "urql";

import { RetryCountdown } from "components/retry-countdown";
import { OnboardingBackButton } from "modules/onboarding/components/onboarding-back-button";
import { OnboardingHeader } from "modules/onboarding/components/onboarding-header";
import { OnboardingPage } from "modules/onboarding/components/onboarding-page";
import {
  OnboardingStep,
  getOnboardingPath,
} from "modules/onboarding/routes/onboarding.routes";

export const PhoneVerification = () => {
  const {
    state: { phone },
  } = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [attemptNumber, setAttemptNumber] = useState(0);

  const [, updatePhoneNumber] = useMutation<
    {
      setOnboardingPhoneNumber: null;
    },
    {
      phone: string;
    }
  >(gql`
    mutation UpdatePhoneNumber($phone: String!) {
      setOnboardingPhoneNumber(in: { phone: $phone })
    }
  `);

  const [, confirmPhoneNumber] = useMutation<
    {
      confirmOnboardingPhoneNumber: null;
    },
    {
      code: string;
    }
  >(gql`
    mutation ConfirmPhoneNumber($code: String!) {
      confirmOnboardingPhoneNumber(in: { code: $code })
    }
  `);

  const navigate = useNavigate();

  const handleOnComplete = async (code: string) => {
    setIsSubmitting(true);
    const result = await confirmPhoneNumber({ code });
    setIsSubmitting(false);

    if (!result.error) {
      navigate(getOnboardingPath(OnboardingStep.PhoneVerified), {
        state: { phone },
      });
    } else {
      setAttemptNumber(attemptNumber + 1);
    }
  };

  const handleOnRetry = async () => {
    const phoneDigits = phone.replace(/\D/g, "");
    const completePhone = "+54" + phoneDigits.slice(-10);

    setIsSubmitting(true);
    const result = await updatePhoneNumber({ phone: completePhone });
    setIsSubmitting(false);
    if (!result.error) {
      navigate(getOnboardingPath(OnboardingStep.PhoneVerification), {
        state: { phone: completePhone },
      });
    }
  };

  const handleNavigateBack = () => {
    navigate(getOnboardingPath(OnboardingStep.PhoneAdd));
  };

  return (
    <OnboardingPage>
      <OnboardingBackButton onClick={handleNavigateBack} />

      <OnboardingHeader
        mb={16}
        title="Ingresá el código que te enviamos por WhatsApp"
        subtitle="Si no recibiste el código de verificación por WhatsApp, por favor revisá si te llegó como mensaje de texto."
      />

      <Flex align="center" direction="column">
        <HStack>
          {isSubmitting ? (
            <Box mb={6} mt={5} w={36}>
              <Progress isIndeterminate rounded="full" size="xs" />
            </Box>
          ) : (
            <PinInput
              key={attemptNumber}
              autoFocus
              isDisabled={isSubmitting}
              onComplete={handleOnComplete}
              otp
              size="lg"
              variant="classic"
            >
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          )}
        </HStack>
        <RetryCountdown retry={handleOnRetry} isLoading={isSubmitting} />
      </Flex>
    </OnboardingPage>
  );
};
