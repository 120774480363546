import { Box } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

import { PropsWithRest } from "types/react-utils";

export const BulkPaymentConfirmationBox = ({
  children,
  ...rest
}: PropsWithChildren<PropsWithRest>) => {
  return (
    <Box bg="white" minW={160} pl={5} pr={10} py={3} rounded="lg" {...rest}>
      {children}
    </Box>
  );
};
