import { useEffect, useState } from "react";

export function useDebounce<T>(value: T, debounceBy?: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (debounceBy) {
      const timeout = setTimeout(() => {
        setDebouncedValue(value);
      }, debounceBy);
      return () => {
        clearTimeout(timeout);
      };
    } else {
      setDebouncedValue(value);
    }
  }, [value, debounceBy]);

  return debouncedValue;
}
