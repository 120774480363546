import {
  ChangeEvent,
  ComponentProps,
  ForwardedRef,
  forwardRef,
  useCallback,
  useMemo,
} from "react";

import { formatTaxId } from "utils/tax-id.utils";

export const CuitInput = forwardRef(
  (
    {
      value,
      onValueChange = () => {},
      ...rest
    }: ComponentProps<"input"> & {
      value: string;
      onValueChange?: (value: string) => void;
    },
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const formattedValue = useMemo(() => formatTaxId(value), [value]);

    const onChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        onValueChange(formatTaxId(value.replace(/\D/g, "")));
      },
      [onValueChange]
    );

    return (
      <input {...rest} ref={ref} onChange={onChange} value={formattedValue} />
    );
  }
);
