import { Button, HStack, Icon, Text } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";

export const ProviderSearchTrigger = ({
  onOpen,
  isDisabled = false,
}: {
  onOpen: () => void;
  isDisabled?: boolean;
}) => {
  return (
    <Button
      cursor="text"
      borderWidth={1}
      borderColor="gray.200"
      bg="white"
      rounded="full"
      w="32rem"
      size="lg"
      justifyContent="start"
      color="inherit"
      onClick={onOpen}
      isDisabled={isDisabled}
      _dark={{
        bg: "gray.700",
        borderColor: "gray.600",
      }}
      _hover={{
        borderColor: "gray.300",
        _dark: {
          borderColor: "gray.600",
        },
      }}
      _active={{
        bg: "auto",
      }}
    >
      <HStack spacing={3} align="center" w="full" pointerEvents="none">
        <Icon as={FiSearch} color="gray.700" />
        <Text color="gray.500" fontWeight="normal" isTruncated>
          Agregar por CBU/CVU, alias o proveedor agendado...
        </Text>
      </HStack>
    </Button>
  );
};
