import { PropsOf } from "@chakra-ui/react";

import { LineStepper } from "components/line-stepper";
import { useBulkPaymentDetail } from "modules/dashboard/routes/bulk-payments/bulk-payment-detail/bulk-payment-detail.context";

const steps = ["Formulario de pago", "Resumen", "Confirmación"];

export const BulkPaymentProvidersEditionStepper = ({
  activeStep,
  ...rest
}: Omit<PropsOf<typeof LineStepper>, "steps">) => {
  const {
    data: {
      confirmationResult: { successful },
    },
  } = useBulkPaymentDetail();

  return (
    <LineStepper
      activeStep={activeStep}
      steps={steps}
      activeStepColor={
        activeStep === 2 ? (successful ? "green.400" : "red.400") : undefined
      }
      {...rest}
    />
  );
};
