import {
  Card,
  CardBody,
  Icon,
  LinkBox,
  LinkOverlay,
  Stack,
} from "@chakra-ui/react";
import { FaSquarePlus } from "react-icons/fa6";
import { Link } from "react-router-dom";

export const BulkPaymentsHistoryNewSalaries = () => {
  return (
    <Card
      as={LinkBox}
      lineHeight="shorter"
      minH={44}
      rounded="xl"
      shadow="md"
      color="white"
      bgColor="brand.500"
      _hover={{ shadow: "lg" }}
      transition="box-shadow 0.2s"
    >
      <CardBody p={6}>
        <Stack align="center" h="full" justify="center" spacing={4}>
          <Icon as={FaSquarePlus} boxSize={12} />
          <LinkOverlay
            as={Link}
            fontSize="xl"
            fontWeight="semibold"
            maxW="14ch"
            textAlign="center"
            to="/pagos/sueldos/nuevo"
          >
            Iniciar nuevo pago de sueldos
          </LinkOverlay>
        </Stack>
      </CardBody>
    </Card>
  );
};
