import { PropsWithChildren, createContext } from "react";

import { FEATURE_FLAG_VALUES, FeatureFlagKey } from "./feature-flags.config";

import { useContextSafe } from "hooks/use-context-safe.hook";

export type FeatureFlagsContextValue = {
  isFeatureEnabled: (key: FeatureFlagKey) => boolean;
};

export const FeatureFlagsContext =
  createContext<FeatureFlagsContextValue | null>(null);

export const useFeatureFlags = () => useContextSafe(FeatureFlagsContext);

export const FeatureFlagsProvider = ({ children }: PropsWithChildren) => {
  const isFeatureEnabled = (key: FeatureFlagKey) =>
    FEATURE_FLAG_VALUES[key] ?? false;

  return (
    <FeatureFlagsContext.Provider
      value={{
        isFeatureEnabled,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};
