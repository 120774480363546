import { Button } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import {
  useCallback,
  useRef,
  useState,
  createContext,
  PropsWithChildren,
  RefObject,
  ReactNode,
} from "react";

import { useContextSafe } from "hooks/use-context-safe.hook";

type AlertDialogContextValue = {
  setOptions: (options: AlertDialogOptions) => void;
  setOnSubmit: (onSubmit: (value: boolean) => void) => void;
  onOpen: () => void;
  onClose: () => void;
};

interface AlertDialogButtonProps {
  show?: boolean;
  text?: ReactNode;
  colorScheme?: string;
  size?: string;
  variant?: string;
}

export interface AlertDialogOptions {
  title?: ReactNode;
  message: ReactNode;
  confirmButton?: AlertDialogButtonProps;
  cancelButton?: AlertDialogButtonProps;
  showCloseButton?: boolean;
  buttonsAlignment?: "start" | "center" | "end";
}

const AlertDialogContext = createContext<AlertDialogContextValue | null>(null);

export const useAlertDialog = () => {
  const { setOptions, setOnSubmit, onOpen } =
    useContextSafe(AlertDialogContext);

  const showAlertDialog = useCallback(
    (options: AlertDialogOptions): Promise<boolean> => {
      setOptions({
        title: "¡Atención!",
        ...options,
      });
      onOpen();

      return new Promise<boolean>((resolve) => {
        setOnSubmit((value) => resolve(value));
      });
    },
    [onOpen, setOnSubmit, setOptions]
  );

  return showAlertDialog;
};

export const AlertDialogProvider = ({ children }: PropsWithChildren) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [options, setOptions] = useState<AlertDialogOptions>({
    message: "",
  });
  const [onSubmit, setOnSubmit] = useState<((value: boolean) => void) | null>(
    null
  );
  const cancelRef: RefObject<HTMLButtonElement> =
    useRef() as RefObject<HTMLButtonElement>;

  const reset = useCallback(() => {
    setOptions({ message: "" });
    setOnSubmit(null);
  }, []);

  const onCloseWithValue = useCallback(
    (value: boolean) => {
      onClose();
      onSubmit?.(value);
      reset();
    },
    [onClose, onSubmit, reset]
  );

  return (
    <AlertDialogContext.Provider
      value={{
        setOptions,
        setOnSubmit: (onSubmit) => setOnSubmit(() => onSubmit),
        onOpen,
        onClose,
      }}
    >
      {children}

      <AlertDialog
        isCentered
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        motionPreset="slideInBottom"
        onClose={onClose}
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{options.title}</AlertDialogHeader>
          {options.showCloseButton !== false && <AlertDialogCloseButton />}
          <AlertDialogBody pb={4}>{options.message}</AlertDialogBody>
          <AlertDialogFooter justifyContent={options.buttonsAlignment}>
            {options.cancelButton?.show !== false && (
              <Button
                ref={cancelRef}
                colorScheme={options.cancelButton?.colorScheme ?? "gray"}
                onClick={() => onCloseWithValue(false)}
                size={options.cancelButton?.size}
                variant={options.cancelButton?.variant ?? "outline"}
              >
                {options.cancelButton?.text ?? "No"}
              </Button>
            )}
            {options.confirmButton?.show !== false && (
              <Button
                colorScheme={options.confirmButton?.colorScheme ?? "red"}
                ml={4}
                onClick={() => onCloseWithValue(true)}
                size={options.confirmButton?.size}
                variant={options.confirmButton?.variant ?? "solid"}
              >
                {options.confirmButton?.text ?? "Sí"}
              </Button>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </AlertDialogContext.Provider>
  );
};
