import {
  Flex,
  FormHelperText,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useBreakpointValue,
} from "@chakra-ui/react";
import { PropsWithChildren, ReactNode, useState } from "react";

import { PropsWithRest } from "types/react-utils";

export const OnboardingFieldHelp = ({
  text,
  children,
  ...rest
}: PropsWithRest<
  PropsWithChildren<{
    text: ReactNode;
  }>,
  typeof Popover
>) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const showMobileHelper = useBreakpointValue({ base: true, lg: false });

  return (
    <Popover
      arrowShadowColor="gray.900"
      arrowSize={10}
      gutter={20}
      isOpen={!!text && (isFocused || isHovered)}
      placement="right"
      trigger="hover"
      {...rest}
    >
      <Flex
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <PopoverTrigger>{children}</PopoverTrigger>
      </Flex>

      {showMobileHelper ? (
        <FormHelperText>{text}</FormHelperText>
      ) : (
        <PopoverContent
          bg="gray.700"
          borderColor="gray.900"
          color="white"
          fontSize="md"
        >
          <PopoverArrow bg="gray.700" />
          <PopoverBody fontSize="sm">{text}</PopoverBody>
        </PopoverContent>
      )}
    </Popover>
  );
};
