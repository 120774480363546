import {
  Button,
  IconButton,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ReactElement } from "react";

export type ActionButtonProps = {
  isDisabled: boolean;
  buttonLabel: string;
  buttonIcon: ReactElement;
  buttonTooltip?: string;
  onButtonClick: () => void;
};

export const ActionButton = ({
  isDisabled,
  buttonLabel,
  buttonIcon,
  buttonTooltip = "",
  onButtonClick,
}: ActionButtonProps) => (
  <Tooltip label={buttonTooltip} placement="top" textAlign="center">
    {useBreakpointValue({
      base: (
        <IconButton
          aria-label={buttonLabel}
          flexShrink={0}
          icon={buttonIcon}
          isDisabled={isDisabled}
          onClick={onButtonClick}
          rounded="full"
          variant="subtle"
        />
      ),
      sm: (
        <Button
          flexShrink={0}
          isDisabled={isDisabled}
          leftIcon={buttonIcon}
          onClick={onButtonClick}
          rounded="full"
          variant="subtle"
        >
          {buttonLabel}
        </Button>
      ),
    })}
  </Tooltip>
);
