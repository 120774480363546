import { cacheExchange } from "@urql/exchange-graphcache";
import { requestPolicyExchange } from "@urql/exchange-request-policy";
import { PropsWithChildren } from "react";
import {
  Client,
  Provider,
  fetchExchange,
  MapExchangeOpts,
  mapExchange,
  gql,
} from "urql";

import { useConfig } from "contexts/config";
// eslint-disable-next-line import/no-restricted-paths
import { appModules } from "modules";

export const ApiProvider = ({
  map,
  children,
}: PropsWithChildren<{
  map?: MapExchangeOpts;
}>) => {
  const config = useConfig();

  const envExchanges = config.isProduction
    ? []
    : [require("@urql/devtools").devtoolsExchange];

  const backendClient = new Client({
    exchanges: [
      ...envExchanges,
      // This exchange forces refetch of cached queries after a ttl (5m default).
      requestPolicyExchange({}),
      cacheExchange({
        // Hints for cache normalization (see https://formidable.com/open-source/urql/docs/graphcache/normalized-caching/)
        //   - Entities with null ids are non-cacheable (scalar values)
        //     Every instance is interpreted as unique.
        //   - Entities with id 'singleton' are singleton values.
        //     Every instance is interpreted as the same, global instance.
        keys: {
          // General
          Attachment: () => null,
          // Onboarding
          ...Object.fromEntries(
            appModules
              .map((module) =>
                module.cacheKeys ? Object.entries(module.cacheKeys) : []
              )
              .flat()
          ),
          // Salaries
        },
        updates: {
          Mutation: {
            deleteCompanyLogo(_result, _args, cache) {
              cache.writeFragment(
                gql`
                  fragment _ on CompanyOnboardingDescription {
                    logo {
                      publicURL
                    }
                  }
                `,
                { logo: null }
              );
            },
          },
        },
      }),
      ...(map ? [mapExchange(map)] : []),
      fetchExchange,
    ],
    fetchOptions: {
      credentials: "include",
    },
    url: `${config.baseUrl}/companies/web/graphql`,
    requestPolicy: "cache-and-network",
  });

  return <Provider value={backendClient}>{children}</Provider>;
};
