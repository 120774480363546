import {
  Box,
  Card,
  CardBody,
  Center,
  Circle,
  HStack,
  Heading,
  Icon,
  Image,
  Text,
  useTheme,
} from "@chakra-ui/react";
import chroma from "chroma-js";
import { ReactNode, useMemo } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";

import duckIllustration from "assets/illustrations/duck.svg";

export const BulkPaymentResult = ({
  status,
  title,
  body,
  actions,
}: {
  status: "success" | "error";
  title: ReactNode;
  body: ReactNode;
  actions: ReactNode;
}) => {
  const successBg = chroma(useTheme().colors.green[300]).alpha(0.25).hex();
  const errorBg = chroma(useTheme().colors.red[300]).alpha(0.25).hex();

  const bg = useMemo(
    () => (status === "success" ? successBg : errorBg),
    [status, successBg, errorBg]
  );

  return (
    <Card bg={bg} rounded="xl" shadow="inner" transition=".4s ease">
      <CardBody px={12} py={10}>
        <HStack minH={400} spacing={16}>
          <Box alignSelf="start" flexGrow={1} maxW="60ch" w="60%">
            <Circle
              bg={status === "success" ? "green.400" : "red.400"}
              mb={8}
              mt={1}
              size={24}
            >
              <Icon
                as={status === "success" ? FaCheck : FaTimes}
                color="white"
                fontSize="6xl"
              />
            </Circle>
            <Heading as="h2" fontSize="3xl" fontWeight="bold" mb={4}>
              {title}
            </Heading>
            <Text
              color="gray.600"
              css={{ strong: { color: "black" } }}
              fontSize="xl"
              mb={14}
            >
              {body}
            </Text>

            <HStack spacing={6}>{actions}</HStack>
          </Box>
          <Center
            flexGrow={1}
            h="full"
            opacity={status === "success" ? 1 : 0}
            transition=".4s ease"
            w="40%"
          >
            <Image maxW={400} src={duckIllustration} w="full" />
          </Center>
        </HStack>
      </CardBody>
    </Card>
  );
};
