import { useCallback, useEffect, useState } from "react";
import { FiXCircle } from "react-icons/fi";
import { useLocation } from "react-router-dom";

import { useRecaptcha, withRecaptcha } from "contexts/recaptcha.context";
import { OnboardingHeader } from "modules/onboarding/components/onboarding-header";
import { OnboardingPage } from "modules/onboarding/components/onboarding-page";
import { useOnboarding } from "modules/onboarding/onboarding.context";

export const OnboardingLogin = withRecaptcha(() => {
  const [errorOcurred, setErrorOcurred] = useState(false);
  const { search } = useLocation();

  const { getRecaptchaToken } = useRecaptcha();

  const {
    actions: { onCreateCompanyOnboarding },
  } = useOnboarding();

  const { email, cuit } = Object.fromEntries(
    new URLSearchParams(search).entries()
  ) as Record<string, string>;

  const onSubmit = useCallback(async () => {
    const successful = await onCreateCompanyOnboarding(
      email,
      cuit,
      getRecaptchaToken
    );
    if (!successful) {
      setErrorOcurred(true);
    }
  }, [onCreateCompanyOnboarding, email, cuit, getRecaptchaToken]);

  useEffect(() => {
    onSubmit();
  }, [onSubmit]);

  if (errorOcurred) {
    return (
      <OnboardingPage>
        <OnboardingHeader
          icon={FiXCircle}
          subtitle="No pudimos enviarte el mail para que verifiques tu cuenta. Por favor, intentá nuevamente más tarde."
          title="Ocurrió un error inesperado"
        />
      </OnboardingPage>
    );
  } else {
    return <OnboardingPage isLoading minH="100vh" />;
  }
});
