import {
  FormControl,
  Stack,
  Text,
  Textarea,
  forwardRef,
} from "@chakra-ui/react";

import { SectionHeader } from "./section-header";

export const Comment = forwardRef(function Comment(
  { onChange, onBlur, name },
  ref
) {
  return (
    <Stack direction="column" spacing={5}>
      <SectionHeader title="¿Querés decirnos algo?" />
      <Text color="gray.800" lineHeight={1.25}>
        Cada empresa es única, por eso, si querés contarnos algo especifico
        sobre tu empresa o sobre la documentación que subiste, sentite libre de
        hacerlo.
      </Text>
      <FormControl>
        <Textarea
          ref={ref}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          height="200px"
          placeholder="Tu comentario..."
          resize="none"
        />
      </FormControl>
    </Stack>
  );
});
