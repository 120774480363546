import { Skeleton } from "@chakra-ui/react";
import {
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
} from "@chakra-ui/table";

import { PropsWithRest } from "types/react-utils";

export const PaymentsListSkeleton = ({
  rowCount = 3,
  ...rest
}: PropsWithRest<{
  rowCount?: number;
}>) => {
  const rowIndices = Array.from({ length: rowCount }, (_, i) => i);

  return (
    <TableContainer overflowX="hidden" {...rest}>
      <Table size="lg">
        <Thead>
          <Tr>
            <Th w="20%">Proveedor</Th>
            <Th w="20%">CUIT</Th>
            <Th w="20%">CBU</Th>
            <Th w="20%">Descripción</Th>
            <Th
              position="sticky"
              right={14}
              zIndex={1}
              bg="gray.50"
              roundedTop="lg"
              w="20%"
              isNumeric
              _before={{
                content: '""',
                position: "absolute",
                top: 0,
                right: 0,
                bottom: "-1px",
                left: 0,
                shadow: "md",
                roundedTop: "lg",
              }}
            >
              Pago neto
            </Th>
            <Th py={0} position="sticky" right={0} bg="white"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {rowIndices.map((index) => (
            <Tr key={index}>
              <Td>
                <Skeleton height={6} rounded="md" width="full" />
              </Td>
              <Td>
                <Skeleton height={6} rounded="md" width="full" />
              </Td>
              <Td>
                <Skeleton height={6} rounded="md" width="full" />
              </Td>
              <Td>
                <Skeleton height={6} rounded="md" width="full" />
              </Td>
              <Td
                position="sticky"
                right={14}
                zIndex={1}
                bg="gray.50"
                _before={{
                  content: '""',
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: "-1px",
                  left: 0,
                  shadow: "md",
                }}
              >
                <Skeleton height={6} rounded="md" width="full" />
              </Td>
              <Td py={0} px={2} position="sticky" right={0} bg="white"></Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
