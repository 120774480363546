import {
  SocietyStructure,
  SocietyStructureNodeCompany,
  SocietyStructureNodeCompanyWithMembers,
  SocietyStructureNodeType,
  SocietyStructureNodeWithMembers,
} from "modules/onboarding/onboarding.types";

export function addMembersToCompany(
  company: SocietyStructureNodeCompany,
  members: SocietyStructureNodeWithMembers[]
): SocietyStructureNodeCompanyWithMembers {
  return {
    __typename: SocietyStructureNodeType.Company,
    id: company.id,
    parentCompanyId: company.parentCompanyId,
    name: company.name,
    taxId: company.taxId,
    sharesWithDecimals: company.sharesWithDecimals,
    members,
  };
}

export function getCompanyMembers(
  companyId: string | null,
  members: SocietyStructureNodeWithMembers[]
): SocietyStructureNodeWithMembers[] {
  const companyMembers = members.filter(
    (member) => member.parentCompanyId === companyId
  );

  return companyMembers.map((member) => {
    if (member.__typename === SocietyStructureNodeType.Company) {
      return addMembersToCompany(member, getCompanyMembers(member.id, members));
    } else {
      return member;
    }
  });
}

export function transformSourceToSocietyStructure(
  srcSocietyStructure: SocietyStructure
): SocietyStructureNodeWithMembers[] {
  const societyStructure: SocietyStructureNodeWithMembers[] = [];

  srcSocietyStructure.members.forEach((member) => {
    const rootMember = member.parentCompanyId === null;
    if (rootMember && member.__typename === SocietyStructureNodeType.Company) {
      const members = getCompanyMembers(
        member.id,
        srcSocietyStructure.members as SocietyStructureNodeWithMembers[]
      );

      const company = addMembersToCompany(member, members);

      societyStructure.push(company);
    } else if (
      rootMember &&
      member.__typename === SocietyStructureNodeType.Person
    ) {
      societyStructure.push(member);
    }
  });

  return societyStructure;
}
