import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  HStack,
  Icon,
  IconButton,
  SkeletonCircle,
  SkeletonText,
  Spinner,
  Text,
  Tooltip,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { FiCopy } from "react-icons/fi";
import { useClient } from "urql";

import { TransactionList } from "./transaction-list";

import { useInfinitePagination } from "hooks/pagination/use-infinite-pagination.hook";
import {
  CompanyAccountBalance,
  fetchTransactions,
} from "modules/dashboard/dashboard.api";
import { useDashboard } from "modules/dashboard/dashboard.context";
import { formatAmountWithCurrency } from "utils/money.utils";

type AccountBalanceCardProps = {
  accountBalance: CompanyAccountBalance & {
    icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  };
};

export const AccountBalanceSkeleton = () => (
  <Card w="100%">
    <CardBody p={8}>
      <HStack spacing={2} justifyContent="space-between">
        <HStack>
          <SkeletonCircle />
          <SkeletonText>Cargado cuentas...</SkeletonText>
        </HStack>
        <Text fontSize="2xl" fontWeight="bold">
          <SkeletonText>$....</SkeletonText>
        </Text>
      </HStack>
    </CardBody>
  </Card>
);

export const AccountBalanceCard = ({
  accountBalance,
}: AccountBalanceCardProps) => {
  const client = useClient();
  const toast = useToast();
  const {
    actions: { refetchCompanyAccountBalancesData },
  } = useDashboard();
  const [isTransactionsDetailOpen, setIsTransactionsDetailOpen] =
    useState(false);
  const [showFullTransactionsList, setShowFullTransactionsList] =
    useState(false);

  const {
    data: transactions,
    isLoadingFirstPage,
    isLoadingAdditionalPage,
    onLoadFirstPage,
    onLoadNextPage,
    areMorePagesAvailable,
  } = useInfinitePagination(
    useCallback(
      async (cursor: string | null) => {
        const result = await fetchTransactions(
          client,
          accountBalance.id,
          cursor
        );
        refetchCompanyAccountBalancesData();
        if (!result.error && result.data) {
          return {
            data: result.data.transactions.transactions,
            cursor: result.data.transactions.nextCursor,
          };
        } else {
          return null;
        }
      },
      [client, accountBalance.id, refetchCompanyAccountBalancesData]
    ),
    {
      initialCursor: null,
    }
  );

  const shownTransactions = useMemo(() => {
    if (transactions && showFullTransactionsList) {
      return transactions;
    } else if (!showFullTransactionsList) {
      const PREVIEW_AMOUNT = 3;
      return transactions.slice(0, PREVIEW_AMOUNT);
    } else {
      return [];
    }
  }, [showFullTransactionsList, transactions]);

  return (
    <Card key={accountBalance.id} w="100%">
      <CardBody>
        <HStack spacing={2} justifyContent="space-between">
          <HStack>
            <accountBalance.icon width={60} height={50} />
            <VStack align="flex-start">
              <Text fontSize="2xl" fontWeight="bold">
                {accountBalance.type}
              </Text>
              <Text fontSize="md" color="muted" mt="1 !important">
                CBU: {accountBalance.cbu}
                <Tooltip label="Copiar">
                  <IconButton
                    aria-label="Copiar"
                    colorScheme="gray"
                    icon={<Icon as={FiCopy} />}
                    isRound
                    ml={1}
                    mr={-1}
                    size="xs"
                    variant="ghost"
                    onClick={() => {
                      navigator.clipboard.writeText(accountBalance.cbu);
                      toast({
                        title: "¡Listo!",
                        description: "El CBU ha sido copiado su portapapeles.",
                        status: "success",
                        duration: 2000,
                      });
                    }}
                  />
                </Tooltip>
              </Text>
            </VStack>
          </HStack>
          <HStack>
            <Text fontSize="2xl" fontWeight="bold">
              {formatAmountWithCurrency(accountBalance.balance)}
            </Text>
            <Box
              cursor="pointer"
              pl={4}
              onClick={() => {
                if (!isTransactionsDetailOpen) {
                  onLoadFirstPage();
                }
                setIsTransactionsDetailOpen(!isTransactionsDetailOpen);
              }}
            >
              <Icon
                as={isTransactionsDetailOpen ? FaChevronUp : FaChevronDown}
                color="gray.500"
              />
            </Box>
          </HStack>
        </HStack>
      </CardBody>
      {isTransactionsDetailOpen && (
        <CardFooter justifyContent="center" pt={0} maxH="60vh" overflow="auto">
          {isLoadingFirstPage ? (
            <Spinner />
          ) : (
            <VStack w="100%">
              <TransactionList transactions={shownTransactions} />
              {areMorePagesAvailable && showFullTransactionsList && (
                <Button
                  pt={4}
                  variant="link"
                  isLoading={isLoadingAdditionalPage}
                  onClick={onLoadNextPage}
                >
                  Cargar más
                </Button>
              )}
              {transactions.length > shownTransactions.length && (
                <Button
                  pt={4}
                  variant="link"
                  isLoading={isLoadingAdditionalPage}
                  onClick={() => {
                    if (!showFullTransactionsList) {
                      setShowFullTransactionsList(true);
                    }
                  }}
                >
                  Ver más
                </Button>
              )}
            </VStack>
          )}
        </CardFooter>
      )}
    </Card>
  );
};
