import { Button } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { Heading, Text } from "@chakra-ui/layout";
import { Container } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { PropsWithRest } from "types/react-utils";

export const ErrorMessage = ({
  title = "¡Ups!",
  subtitle,
  body,
  showHomeLink,
  ...rest
}: PropsWithRest<{
  title?: string;
  subtitle: string;
  body: ReactNode;
  showHomeLink?: boolean;
}>) => {
  const bodyColor = useColorModeValue("gray.500", "gray.400");

  return (
    <Container maxW="md" textAlign="center" {...rest}>
      <Heading
        as="h2"
        backgroundClip="text"
        lineHeight={1.1}
        mb={4}
        size={title.length > 20 ? "lg" : "2xl"}
        bgGradient={useColorModeValue(
          "linear(to-r, brand.500, brand.700)",
          "linear(to-r, brand.100, brand.300)"
        )}
      >
        {title}
      </Heading>
      <Text fontSize="18px" mb={2}>
        {subtitle}
      </Text>

      {body && <Text color={bodyColor}>{body}</Text>}

      {showHomeLink !== false ? (
        <Button as={Link} mt={6} to="/" variant="solid">
          Ir al inicio
        </Button>
      ) : null}
    </Container>
  );
};
