import {
  Badge,
  Card,
  CardBody,
  Flex,
  HStack,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import {
  BulkPaymentSummary,
  BulkPaymentType,
} from "modules/dashboard/routes/bulk-payments/bulk-payments.api";
import {
  bulkPaymentStatusColorScheme,
  bulkPaymentStatusLabel,
} from "modules/dashboard/routes/bulk-payments/bulk-payments.utils";
import { Currency } from "types/api-global.types";
import { formatDate } from "utils/date.utils";
import { formatAmountWithCurrency } from "utils/money.utils";

export const BulkPaymentsHistoryItem = ({
  bulkPayment,
}: {
  bulkPayment: BulkPaymentSummary;
}) => {
  const paymentCount = useMemo(() => {
    const count = bulkPayment.paymentsMetadata?.total.count ?? 0;
    return `${count} ${
      bulkPayment.paymentType === BulkPaymentType.Providers
        ? count === 1
          ? "pago"
          : "pagos"
        : count === 1
        ? "persona"
        : "personas"
    }`;
  }, [bulkPayment.paymentType, bulkPayment.paymentsMetadata?.total.count]);

  const totalAmount = useMemo(() => {
    return formatAmountWithCurrency(
      bulkPayment.paymentsMetadata?.total.amount ?? {
        amountInCents: 0,
        currency: Currency.ARS,
      }
    );
  }, [bulkPayment.paymentsMetadata?.total.amount]);

  const hasErrors = useMemo(() => {
    return !!bulkPayment.paymentsMetadata?.failed?.count;
  }, [bulkPayment.paymentsMetadata?.failed?.count]);

  const hasSuccesess = useMemo(() => {
    return !!bulkPayment.paymentsMetadata?.successful?.count;
  }, [bulkPayment.paymentsMetadata?.successful?.count]);

  return (
    <Card
      _hover={{ shadow: "lg" }}
      as={LinkBox}
      lineHeight="short"
      rounded="xl"
      shadow="md"
      transition="box-shadow 0.2s"
      minH={44}
    >
      <CardBody p={6}>
        <Stack align="start" h="full" justify="center">
          <Flex direction="column" justify="space-between" w="full">
            <HStack fontSize="lg" justify="space-between" w="full">
              <Text color="gray.500" fontWeight="semibold">
                Total
              </Text>
              <Text color="brand.500" fontWeight="bold">
                {paymentCount}
              </Text>
            </HStack>
            <LinkOverlay
              as={Link}
              mt={0}
              to={`/pagos/${bulkPayment.id}`}
              state={{
                bulkPayment: {
                  status: bulkPayment.status,
                  paymentType: bulkPayment.paymentType,
                },
              }}
            >
              <Text
                fontSize="2xl"
                fontWeight="bold"
                color={hasErrors ? "red.500" : "blackAlpha"}
              >
                {totalAmount}
              </Text>
            </LinkOverlay>
          </Flex>
          <Badge
            colorScheme={bulkPaymentStatusColorScheme(
              bulkPayment.status,
              hasErrors
            )}
          >
            {bulkPaymentStatusLabel(
              bulkPayment.status,
              false,
              hasErrors,
              hasSuccesess
            )}
          </Badge>
          <Text color="gray.500" fontSize="sm" fontWeight="medium">
            Creado el{" "}
            {formatDate(bulkPayment.createdAt, "DD/MM/YYYY [a las] HH:mm [h]")}
            <br />
            por{" "}
            <Text as="span" color="black">
              {bulkPayment.operatedBy.name}
            </Text>
          </Text>
        </Stack>
      </CardBody>
    </Card>
  );
};
