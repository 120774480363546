import { onboardingRoutes } from "./routes/onboarding.routes";

import type { AppModule } from "modules/modules.types";

export const OnboardingModule: AppModule = {
  routes: onboardingRoutes,
  cacheKeys: {
    CompanyOnboardingPublicSummary: () => "singleton",
    CompanyOnboardingDescription: () => "singleton",
    CompanyAddress: () => "singleton",
    OnboardingStatus: () => "singleton",
  },
};
