import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { gql, useMutation } from "urql";

import { OnboardingButton } from "modules/onboarding/components/onboarding-button";
import { OnboardingHeader } from "modules/onboarding/components/onboarding-header";
import { OnboardingPage } from "modules/onboarding/components/onboarding-page";
import {
  OnboardingStep,
  getOnboardingPath,
} from "modules/onboarding/routes/onboarding.routes";

type PhoneAddFormFields = {
  phone: string;
};

export const PhoneAdd = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [, updatePhoneNumber] = useMutation<
    {
      setOnboardingPhoneNumber: null;
    },
    {
      phone: string;
    }
  >(gql`
    mutation UpdatePhoneNumber($phone: String!) {
      setOnboardingPhoneNumber(in: { phone: $phone })
    }
  `);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PhoneAddFormFields>({ mode: "onTouched" });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<PhoneAddFormFields> = async (data) => {
    const { phone } = data;
    const phoneDigits = phone.replace(/\D/g, "");
    const completePhone = "+54" + phoneDigits.slice(-10);

    setIsSubmitting(true);
    const result = await updatePhoneNumber({ phone: completePhone });
    setIsSubmitting(false);
    if (!result.error) {
      navigate(getOnboardingPath(OnboardingStep.PhoneVerification), {
        state: { phone: completePhone },
      });
    }
  };

  return (
    <OnboardingPage>
      <OnboardingHeader
        mb={16}
        subtitle="En el siguiente paso, vamos a pedirte que lo valides."
        title="Ingresá tu número de celular"
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex align="center" direction="column" w={80}>
          <FormControl isInvalid={errors?.phone !== undefined}>
            <InputGroup size="lg">
              <InputLeftAddon _dark={{ bg: "gray.700" }} bg="white">
                <Box>
                  <Text>🇦🇷 +54</Text>
                </Box>
              </InputLeftAddon>
              <Controller
                control={control}
                name="phone"
                render={({ field: { onChange, ...rest } }) => (
                  <Input
                    placeholder="11-5555-5555"
                    roundedLeft={0}
                    size="lg"
                    type="tel"
                    {...rest}
                    onChange={(e) => {
                      onChange(
                        e.target.value
                          .replace(/\s+/g, " ")
                          .replace(/[^\d\s-]/g, "")
                          .trimStart()
                      );
                    }}
                  />
                )}
                rules={{
                  required: "Ingresá tu número de teléfono.",
                  pattern: {
                    value: /^(?:9\s?)?(?:\d\s?(?:-\s?)?){9}\d\s*$/,
                    message: "Verificá el número de teléfono y reintentá.",
                  },
                }}
              />
            </InputGroup>

            {errors.phone && (
              <FormErrorMessage>
                {errors?.phone?.message?.toString()}
              </FormErrorMessage>
            )}
          </FormControl>

          <OnboardingButton isLoading={isSubmitting} mt={20} type="submit">
            Continuar
          </OnboardingButton>
        </Flex>
      </form>
    </OnboardingPage>
  );
};
