import { Center, Stack, Text } from "@chakra-ui/react";

import { AddEmployeeForm } from "./add-employee-form";
import { EmployeesTable } from "./employees-table";
import { EmployeesTableSkeleton } from "./employees-table/employees-table-skeleton";
import { useBulkPaymentsPayrollEditor } from "./payroll-editor.context";

import { useBulkPaymentsPayroll } from "modules/dashboard/routes/bulk-payments/bulk-payments-payroll/bulk-payments-payroll.context";

export const BulkPaymentsPayrollEditorBody = () => {
  const {
    data: { employeeCount },
    state: { isFetchingFirstPage, areMorePagesAvailable },
  } = useBulkPaymentsPayroll();

  const {
    addEmployeeForm: { isVisible: isAddEmployeeFormVisible },
  } = useBulkPaymentsPayrollEditor();

  return (
    <Stack spacing={6}>
      {!isFetchingFirstPage && isAddEmployeeFormVisible && (
        <AddEmployeeForm mx={3} />
      )}
      {isFetchingFirstPage ? (
        <EmployeesTableSkeleton />
      ) : employeeCount > 0 || areMorePagesAvailable ? (
        <EmployeesTable />
      ) : (
        <Center color="gray.500" py={20}>
          <Text>Todavía no agregaste a ninguna persona.</Text>
        </Center>
      )}
    </Stack>
  );
};
