import { Navigate } from "react-router-dom";

import { OnboardingPage } from "./components/onboarding-page";
import { useOnboarding } from "./onboarding.context";

export const OnboardingIndex = () => {
  const {
    state: { isLoading },
    data: { currentStepRoute },
  } = useOnboarding();

  if (isLoading || !currentStepRoute) {
    return <OnboardingPage isLoading minH="100vh" />;
  } else {
    return <Navigate replace to={currentStepRoute} />;
  }
};
