import { useDisclosure } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import { CombinedError, Operation } from "urql";

import { SessionExpiredDialog } from "./components/session-expired-dialog";
import { OnboardingProvider } from "./onboarding.context";

import { ApiProvider } from "contexts/api.context";
import { useGraphQLErrorToast } from "hooks/use-graphql-error-toast.hook";
import {
  OnboardingStep,
  getOnboardingPath,
  pathNeedsAuthentication,
} from "modules/onboarding/routes/onboarding.routes";
import { pushGqlErrorToFaro } from "utils/errors.utils";
import { ErrorCode, getErrorCodes } from "utils/graphql.utils";

export const OnboardingWrapper = () => {
  const errorToast = useGraphQLErrorToast();

  const {
    onOpen: _onSessionExpiredDialogOpen,
    onClose: _onSessionExpiredDialogClose,
    isOpen: isSessionExpiredDialogOpen,
  } = useDisclosure();
  const [wasSessionExpiredDialogClosed, setWasSessionExpiredDialogClosed] =
    useState(false);

  const onSessionExpiredDialogOpen = useCallback(() => {
    if (!wasSessionExpiredDialogClosed) {
      _onSessionExpiredDialogOpen();
    }
  }, [_onSessionExpiredDialogOpen, wasSessionExpiredDialogClosed]);

  const onSessionExpiredDialogClose = useCallback(() => {
    _onSessionExpiredDialogClose();
    setWasSessionExpiredDialogClosed(true);
  }, [_onSessionExpiredDialogClose]);

  const onError = useCallback(
    (error: CombinedError, operation: Operation) => {
      pushGqlErrorToFaro(error, operation);
      if (operation.context.noErrorToast !== true) {
        const errorCodes = getErrorCodes(error);
        if (errorCodes.includes(ErrorCode.Unauthorized)) {
          // Unauthorized - redirect to start (if not already there)
          const currentPath = window.location.pathname;
          if (pathNeedsAuthentication(currentPath)) {
            window.location.replace(getOnboardingPath(OnboardingStep.Start));
          }
        } else if (errorCodes.includes(ErrorCode.SessionExpired)) {
          // Session expired - show dialog and redirect to email step
          onSessionExpiredDialogOpen();
        } else {
          errorToast(error);
        }
      }
    },
    [onSessionExpiredDialogOpen, errorToast]
  );

  return (
    <ApiProvider map={{ onError }}>
      <OnboardingProvider>
        <Helmet
          titleTemplate="%s · Brubank Empresas · Onboarding"
          defaultTitle="Brubank Empresas · Onboarding"
        />
        {isSessionExpiredDialogOpen && (
          <SessionExpiredDialog onClose={onSessionExpiredDialogClose} />
        )}
        <Outlet />
      </OnboardingProvider>
    </ApiProvider>
  );
};
