import {
  Box,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  HStack,
  IconButton,
  Input,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import { useCallback } from "react";
import CurrencyInput from "react-currency-input-field";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FiCheck } from "react-icons/fi";

import { useBulkPaymentsPayrollEditor } from "./payroll-editor.context";

import { CuitInput } from "components/cuit-input";
import { useBulkPaymentsPayroll } from "modules/dashboard/routes/bulk-payments/bulk-payments-payroll/bulk-payments-payroll.context";
import { Currency } from "types/api-global.types";
import { PropsWithRest } from "types/react-utils";
import { isValidCbu, isValidCbuAlias } from "utils/cbu.utils";
import { parseNumber } from "utils/number.utils";
import { formatTaxId, isValidTaxId } from "utils/tax-id.utils";

type AddEmployeeFields = {
  firstName: string;
  lastName: string;
  taxId: string;
  cbuOrAlias: string;
  salary: string;
};

export const AddEmployeeForm = ({
  ...rest
}: PropsWithRest<{}, typeof Stack>) => {
  const {
    state: { isAdding },
    actions: { onAddEmployee },
  } = useBulkPaymentsPayroll();

  const {
    addEmployeeForm: { onToggle, onOpen, isOpenedByUser },
  } = useBulkPaymentsPayrollEditor();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    register,
    reset,
    setFocus,
  } = useForm<AddEmployeeFields>({
    mode: "onTouched",
    defaultValues: {
      firstName: "",
      lastName: "",
      taxId: "",
      cbuOrAlias: "",
      salary: "",
    },
  });

  const onSubmit: SubmitHandler<AddEmployeeFields> = useCallback(
    async (values) => {
      const cbuOrAlias = isValidCbu(values.cbuOrAlias)
        ? { cbu: values.cbuOrAlias }
        : { alias: values.cbuOrAlias };

      const successful = await onAddEmployee({
        firstName: values.firstName,
        lastName: values.lastName,
        taxId: formatTaxId(values.taxId, { withDashes: false }),
        ...cbuOrAlias,
        salary: {
          amountInCents: parseNumber(values.salary, { decimalScale: 2 }),
          currency: Currency.ARS,
        },
      });
      if (successful) {
        onOpen(); // Ensure the form keeps open to add more employees, even if it was not opened by the user
        reset(); // Reset the form to its initial state
        setTimeout(() => setFocus("firstName"), 0); // Focus the first input (the timeout is needed so the field is not disabled when the focus is set)
      }
    },
    [onAddEmployee, onOpen, reset, setFocus]
  );

  const templateColumns = "7fr 5fr 6fr 5fr";

  return (
    <Stack borderWidth={1} p={6} rounded="2xl" {...rest} position="relative">
      {isOpenedByUser && (
        <CloseButton onClick={onToggle} position="absolute" right={3} top={3} />
      )}
      <HStack px={6} spacing={8}>
        <Grid
          color="gray.600"
          flexGrow={1}
          fontSize="sm"
          fontWeight="bold"
          gap={6}
          letterSpacing="wider"
          templateColumns={templateColumns}
          textTransform="uppercase"
        >
          <Box px={4}>Persona empleada</Box>
          <Box px={4}>CUIT</Box>
          <Box px={4}>CBU / Alias</Box>
          <Box px={4} textAlign="right">
            Pago neto
          </Box>
        </Grid>
        <HStack flexShrink={0} spacing={2}>
          <Box flexGrow={0} flexShrink={0} w={10} />
        </HStack>
      </HStack>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <HStack bg="brand.50" p={6} rounded="xl" spacing={8}>
          <Grid flexGrow={1} gap={6} templateColumns={templateColumns}>
            <Flex position="relative">
              <FormControl
                isInvalid={errors?.firstName !== undefined}
                mr="-1px"
              >
                <Input
                  isDisabled={isAdding}
                  placeholder="Nombre"
                  rounded="full"
                  css={{
                    "&:focus": { zIndex: 2 },
                    "&[aria-invalid=true]": { zIndex: 1 },
                  }}
                  {...register("firstName", {
                    required: "Campo requerido",
                  })}
                  autoFocus
                  roundedRight={0}
                />
                <FormErrorMessage fontSize="xs" mb={-3} mt={1} pl={4}>
                  {errors.firstName?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.lastName !== undefined}>
                <Input
                  isDisabled={isAdding}
                  placeholder="Apellido"
                  rounded="full"
                  roundedLeft={0}
                  css={{
                    "&:focus": { zIndex: 2 },
                    "&[aria-invalid=true]": { zIndex: 1 },
                  }}
                  {...register("lastName", {
                    required: "Campo requerido",
                  })}
                />
                <FormErrorMessage fontSize="xs" mb={-3} mt={1} pl={4}>
                  {errors.lastName?.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>

            <FormControl isInvalid={errors?.taxId !== undefined}>
              <Controller
                control={control}
                name="taxId"
                render={({ field: { onChange, ...rest } }) => (
                  <Input
                    as={CuitInput}
                    isDisabled={isAdding}
                    rounded="full"
                    {...rest}
                    onValueChange={onChange}
                    placeholder="00-00000000-0"
                  />
                )}
                rules={{
                  required: "Campo requerido",
                  validate: (value) => isValidTaxId(value) || "CUIT incorrecto",
                }}
              />
              <FormErrorMessage fontSize="xs" mb={-3} mt={1} px={4}>
                {errors.taxId?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors?.cbuOrAlias !== undefined}>
              <Input
                isDisabled={isAdding}
                placeholder="Ingresá el Alias o CBU"
                rounded="full"
                {...register("cbuOrAlias", {
                  required: "Campo requerido",
                  validate: (value) =>
                    isValidCbu(value) ||
                    isValidCbuAlias(value) ||
                    "CBU o alias incorrecto",
                })}
              />
              <FormErrorMessage fontSize="xs" mb={-3} mt={1} px={4}>
                {errors.cbuOrAlias?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors?.salary !== undefined}>
              <Controller
                control={control}
                name="salary"
                render={({ field: { onChange, ...rest } }) => (
                  <Input
                    allowNegativeValue={false}
                    as={CurrencyInput}
                    decimalScale={2}
                    decimalsLimit={2}
                    fontWeight="semibold"
                    intlConfig={{ locale: "es-AR", currency: "ARS" }}
                    isDisabled={isAdding}
                    onValueChange={onChange}
                    placeholder="$ 0,00"
                    rounded="full"
                    textAlign="right"
                    css={{
                      fontVariantNumeric: "tabular-nums",
                    }}
                    {...rest}
                  />
                )}
                rules={{
                  required: "Campo requerido",
                }}
              />
              <FormErrorMessage
                fontSize="xs"
                justifyContent="flex-end"
                mb={-3}
                mt={1}
                px={4}
              >
                {errors.salary?.message}
              </FormErrorMessage>
            </FormControl>
          </Grid>
          <HStack flexShrink={0} spacing={2}>
            <Tooltip label="Confirmar">
              <IconButton
                aria-label="Confirmar"
                fontSize="xl"
                icon={<FiCheck />}
                isDisabled={!isValid}
                isLoading={isAdding}
                rounded="full"
                type="submit"
              />
            </Tooltip>
          </HStack>
        </HStack>
      </form>
    </Stack>
  );
};
