import { Container, Heading, Stack, Text } from "@chakra-ui/layout";
import { As, Icon } from "@chakra-ui/react";
import { ReactNode } from "react";

import { PropsWithRest } from "types/react-utils";

export const OnboardingHeader = ({
  title,
  subtitle,
  icon,
  maxW = "xl",
  ...rest
}: PropsWithRest<
  { title: ReactNode; subtitle?: ReactNode; icon?: As },
  typeof Stack
>) => {
  return (
    <Container maxW={maxW}>
      <Stack {...rest} align="center" spacing={3} textAlign="center">
        {icon && <Icon as={icon} boxSize={16} color="gray.400" />}

        <Heading size="lg">{title}</Heading>

        {subtitle && (
          <Text
            _dark={{ strong: { color: "white" } }}
            color="gray.500"
            fontSize="lg"
            fontWeight="semibold"
            css={{
              strong: {
                color: "black",
                fontWeight: "semibold",
              },
            }}
          >
            {subtitle}
          </Text>
        )}
      </Stack>
    </Container>
  );
};
