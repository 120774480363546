import {
  Card,
  CardBody,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Image,
  LinkBox,
  LinkOverlay,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ReactNode, useMemo } from "react";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";

import {
  AccountBalanceCard,
  AccountBalanceSkeleton,
} from "./account-balance-card";
import { mapCompanyAccountBalances } from "./account-balance-utils";

import providerPaymentsImage from "assets/illustrations/home-provider-payments.webp";
import salariesImage from "assets/illustrations/home-salaries.webp";
import { DashboardSection } from "modules/dashboard/components/dashboard-section";
import { useDashboard } from "modules/dashboard/dashboard.context";
import { PropsWithRest } from "types/react-utils";

const HomeCard = ({
  image,
  title,
  description,
  actions,
  ...rest
}: PropsWithRest<
  {
    image: string;
    title: ReactNode;
    description: string;
    actions: ReactNode;
  },
  typeof Card
>) => {
  return (
    <Card rounded="xl" shadow="md" {...rest}>
      <CardBody p={8}>
        <Stack
          spacing={4}
          alignItems="center"
          direction="column"
          textAlign="center"
          h="full"
        >
          <Image h={44} src={image} w={44} minH={44} minW={44} />

          <Stack spacing={2} flexGrow={1} justify="center">
            <Heading
              as="h2"
              fontSize="xl"
              fontWeight="bold"
              lineHeight="shorter"
            >
              {title}
            </Heading>
            <Text color="muted" maxW={64}>
              {description}
            </Text>
          </Stack>

          <HStack justifyContent="center">{actions}</HStack>
        </Stack>
      </CardBody>
    </Card>
  );
};

const HomeCardSkeleton = () => {
  return (
    <Card alignSelf="center" rounded="xl" shadow="md">
      <CardBody p={8}>
        <Flex alignItems="center" direction="column" textAlign="center">
          <Skeleton h={44} mb={4} rounded="lg" w={44} />
          <Skeleton fontSize="xl" mb={4} rounded="md">
            Lorem ipsum dolor
          </Skeleton>

          <SkeletonText
            alignItems="center"
            mb={4}
            noOfLines={2}
            rounded="md"
            skeletonHeight={3}
          >
            Lorem ipsum dolor sit amet.
          </SkeletonText>

          <Skeleton h={10} rounded="full" w={24} />
        </Flex>
      </CardBody>
    </Card>
  );
};

export const Home = () => {
  const {
    state: { isLoading },
    data: { company, companyAccountBalances },
  } = useDashboard();

  const skeletonWidths = useMemo(
    () => ({
      fantasyName: `${Math.random() * 12 + 16}ch`,
      legalName: `${Math.random() * 12 + 16}ch`,
    }),
    []
  );

  const accountBalances = mapCompanyAccountBalances(companyAccountBalances);

  return (
    <DashboardSection
      headerContent={
        <Stack align="flex-start" lineHeight="shorter">
          <Skeleton
            isLoaded={!isLoading}
            minW={skeletonWidths.fantasyName}
            rounded="md"
          >
            <Heading as="h1" fontSize="2xl" fontWeight="bold">
              {company?.fantasyName ?? "Mi empresa"}
            </Heading>
          </Skeleton>

          <Skeleton
            isLoaded={!isLoading}
            minW={skeletonWidths.legalName}
            rounded="md"
          >
            <Text color="muted" fontSize="xl" fontWeight="semibold">
              {company?.legalName ?? "Cargando..."}
            </Text>
          </Skeleton>
        </Stack>
      }
    >
      <Text fontWeight="bold" fontSize="lg" mb={4}>
        Mis cuentas
      </Text>
      <HStack alignItems="flex-start" gap={10}>
        <VStack spacing={4} minW="xl">
          {isLoading ? (
            <AccountBalanceSkeleton />
          ) : (
            accountBalances.map((cab) => (
              <AccountBalanceCard key={cab.id} accountBalance={cab} />
            ))
          )}
        </VStack>
        <HStack gap={10} alignItems="stretch">
          {!isLoading ? (
            <>
              <HomeCard
                as={LinkBox}
                description="Creá pagos de sueldos para las personas que trabajan en la empresa."
                image={salariesImage}
                title="Pago de sueldos"
                actions={
                  <LinkOverlay as={Link} to="/pagos/sueldos">
                    <IconButton
                      aria-label="Ver pagos de sueldos"
                      icon={<Icon as={FiArrowRight} boxSize={6} />}
                      px={8}
                      rounded="full"
                    />
                  </LinkOverlay>
                }
              />

              <HomeCard
                as={LinkBox}
                image={providerPaymentsImage}
                title="Pago a proveedores"
                description="Creá pagos para los proveedores de la empresa."
                actions={
                  <LinkOverlay as={Link} to="/pagos/proveedores">
                    <IconButton
                      aria-label="Ver pagos a proveedores"
                      icon={<Icon as={FiArrowRight} boxSize={6} />}
                      px={8}
                      rounded="full"
                    />
                  </LinkOverlay>
                }
              />
            </>
          ) : (
            <>
              {new Array(2).fill(null).map((_, index) => (
                <HomeCardSkeleton key={index} />
              ))}
            </>
          )}
        </HStack>
      </HStack>
    </DashboardSection>
  );
};
