import { useBreakpointValue } from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";

import { BulkPaymentPayrollStepper } from "./bulk-payments-payroll-stepper";
import { BulkPaymentsPayrollProvider } from "./bulk-payments-payroll.context";
import {
  BulkPaymentsPayrollConfirmation,
  BulkPaymentsPayrollConfirmationActionBar,
} from "./payroll-confirmation";
import {
  BulkPaymentsPayrollEditor,
  BulkPaymentsPayrollEditorActionBar,
} from "./payroll-editor";
import { BulkPaymentsPayrollResult } from "./payroll-result";

import { DashboardSection } from "modules/dashboard/components/dashboard-section";

export const BulkPaymentsPayroll = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const onNext = useCallback(
    () => setCurrentStep((prev) => (prev < 2 ? prev + 1 : prev)),
    []
  );
  const onPrevious = useCallback(
    () => setCurrentStep((prev) => (prev > 0 ? prev - 1 : prev)),
    []
  );

  const showStepper = useBreakpointValue({ base: false, lg: true });

  const sectionBody = useMemo(() => {
    switch (currentStep) {
      case 0:
        return <BulkPaymentsPayrollEditor />;
      case 1:
        return <BulkPaymentsPayrollConfirmation />;
      case 2:
        return <BulkPaymentsPayrollResult onPrevious={onPrevious} />;
    }
  }, [currentStep, onPrevious]);

  const sectionActionBar = useMemo(() => {
    switch (currentStep) {
      case 0:
        return <BulkPaymentsPayrollEditorActionBar onNext={onNext} />;
      case 1:
        return (
          <BulkPaymentsPayrollConfirmationActionBar
            onNext={onNext}
            onPrevious={onPrevious}
          />
        );
      case 2:
        return null;
    }
  }, [currentStep, onNext, onPrevious]);

  return (
    <BulkPaymentsPayrollProvider>
      <DashboardSection
        actionBarContent={sectionActionBar}
        backButton="/pagos/sueldos"
        title="Pago de sueldos"
        headerControls={
          showStepper && (
            <BulkPaymentPayrollStepper
              activeStep={currentStep}
              flexShrink={0}
            />
          )
        }
      >
        {sectionBody}
      </DashboardSection>
    </BulkPaymentsPayrollProvider>
  );
};
