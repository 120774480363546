import { HStack, Skeleton, Stack } from "@chakra-ui/react";
import { useMemo } from "react";

import { PropsWithRest } from "types/react-utils";

export const ProvidersListSkeleton = ({
  rowCount = 5,
  ...rest
}: PropsWithRest<
  {
    rowCount?: number;
  },
  typeof Stack
>) => {
  const skeletonRows = useMemo(
    () =>
      Array.from({ length: rowCount }, () => ({
        name: `${14 + Math.random() * 28}ch`,
        data: ["9ch", "20ch"],
      })),
    [rowCount]
  );

  return (
    <Stack {...rest}>
      {skeletonRows.map((row, index) => (
        <HStack key={index} _notLast={{ borderBottomWidth: 1 }} px={6} py={4}>
          <Stack spacing={2}>
            <Skeleton height={6} rounded="md" width={row.name} />
            <HStack>
              {row.data.map((width, index) => (
                <Skeleton key={index} height={5} rounded="md" width={width} />
              ))}
            </HStack>
          </Stack>
        </HStack>
      ))}
    </Stack>
  );
};
