import { Box, Card, CardBody, HStack, Heading } from "@chakra-ui/react";
import { PropsWithChildren, ReactNode } from "react";

export const BulkPaymentConfirmationCard = ({
  title,
  children,
}: PropsWithChildren<{ title: ReactNode }>) => (
  <Card
    backdropBlur="md"
    backdropFilter="auto"
    bg="whiteAlpha.500"
    borderColor="blackAlpha.50"
    borderWidth={1}
    rounded="xl"
    shadow="none"
  >
    <CardBody px={8} py={6}>
      <HStack align="start" spacing={6}>
        <Heading
          as="h2"
          flexShrink={0}
          fontSize="2xl"
          fontWeight="bold"
          maxW={300}
          mb={4}
          minW={200}
          my={1.5}
          w="30%"
        >
          {title}
        </Heading>
        <Box flexGrow={1} minW={0}>
          {children}
        </Box>
      </HStack>
    </CardBody>
  </Card>
);
