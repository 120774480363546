import { Center } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

import { ErrorMessage } from "components/error-message";
import { faro } from "faro";

export const ErrorBoundary = () => {
  const error = useRouteError();

  const isNotFoundError = useMemo(
    () => isRouteErrorResponse(error) && error.status === 404,
    [error]
  );

  const errorTitle = useMemo(
    () => (isNotFoundError ? "Tu equipo no anda por acá" : undefined),
    [isNotFoundError]
  );

  const errorSubtitle = useMemo(
    () =>
      isNotFoundError
        ? "No encontramos lo que estás buscando pero no te preocupes. Todavía queda mucho por recorrer."
        : "Algo salió mal",
    [isNotFoundError]
  );

  const errorMessage = useMemo(
    () => (isNotFoundError ? undefined : "Por favor, intentá nuevamente."),
    [isNotFoundError]
  );

  useEffect(() => {
    faro.api.pushError(
      error instanceof Error ? error : new Error(errorMessage),
      {
        type: isNotFoundError ? "not-found" : "application",
      }
    );
  }, [error, errorMessage, isNotFoundError]);

  return (
    <Center minH="100vh">
      <Helmet>
        <title>{errorTitle || errorSubtitle}</title>
      </Helmet>
      <ErrorMessage
        title={errorTitle}
        body={errorMessage}
        px={6}
        py={10}
        subtitle={errorSubtitle}
      />
    </Center>
  );
};
