import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { useDebounce } from "hooks/use-debounce";

export function useAutocompleteInput<Option>({
  search,
  onSelectOption,
  inputDefaultValue,
  hideOptions = () => false,
  hideOptionsOnBlur = true,
  debounceBy,
}: {
  search: (query: string) => Promise<Option[]> | Option[];
  onSelectOption: (
    option: Option,
    method: "click" | "keyboard",
    actions: {
      reset: () => void;
      hideOptions: () => void;
    }
  ) => void;
  inputDefaultValue?: string;
  hideOptions?: (query: string | null) => boolean;
  hideOptionsOnBlur?: boolean;
  debounceBy?: number | ((query: string) => number);
}) {
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [isSearching, setIsSearching] = useState(false);

  const [areOptionsShown, setAreOptionsShown] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState<number | null>(
    null
  );
  const listboxRef = useRef<HTMLElement | null>(null);
  const optionRefs = useRef<HTMLElement[]>([]);

  const reset = useCallback(() => {
    setSearchQuery(null);
    setFocusedOptionIndex(null);
  }, []);

  const onFocusOption = useCallback((index: SetStateAction<number | null>) => {
    setFocusedOptionIndex(index);
  }, []);

  const debouncedSearchQuery = useDebounce(
    searchQuery,
    searchQuery !== null
      ? typeof debounceBy === "function"
        ? debounceBy(searchQuery)
        : debounceBy
      : undefined
  );

  const onFocusNextOption = useCallback(() => {
    onFocusOption((curr) => {
      if (options.length === 0) {
        return null;
      } else if (curr === null) {
        return 0;
      } else {
        return (curr + 1) % options.length;
      }
    });
  }, [options, onFocusOption]);

  const onFocusPreviousOption = useCallback(() => {
    onFocusOption((curr) => {
      if (options.length === 0) {
        return null;
      } else if (curr === null) {
        return options.length - 1;
      } else {
        return (curr - 1 + options.length) % options.length;
      }
    });
  }, [options, onFocusOption]);

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (!areOptionsShown) {
        if (e.key === "ArrowDown" || e.key === "ArrowUp") {
          e.preventDefault();
          setAreOptionsShown(true);
        }
      }
      if (e.key === "ArrowDown") {
        e.preventDefault();
        onFocusNextOption();
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        onFocusPreviousOption();
      } else if (e.key === "Enter") {
        e.preventDefault();
        if (focusedOptionIndex !== null) {
          onSelectOption(options[focusedOptionIndex], "keyboard", {
            reset,
            hideOptions: () => setAreOptionsShown(false),
          });
        }
      }
    },
    [
      focusedOptionIndex,
      onSelectOption,
      onFocusNextOption,
      onFocusPreviousOption,
      options,
      reset,
      areOptionsShown,
    ]
  );

  const onFocus = useCallback(() => {
    setSearchQuery("");
    setAreOptionsShown(true);
  }, []);

  const onBlur = (ev: FocusEvent<HTMLInputElement>) => {
    if (!listboxRef.current?.contains(ev.relatedTarget as Node)) {
      setAreOptionsShown(false);
      reset();
    }
  };

  const performSearch = useCallback(async () => {
    if (debouncedSearchQuery !== null) {
      const options = await search(debouncedSearchQuery);
      setOptions(options);
      setIsSearching(false);
    }
  }, [search, debouncedSearchQuery]);

  useEffect(() => {
    setIsSearching(true);
  }, [searchQuery]);

  useEffect(() => {
    performSearch();
  }, [search, performSearch]);

  useEffect(() => {
    setFocusedOptionIndex(options.length > 0 ? 0 : null);
  }, [options]);

  useEffect(() => {
    if (focusedOptionIndex !== null) {
      optionRefs.current?.[focusedOptionIndex]?.scrollIntoView({
        block: "nearest",
      });
    }
  }, [focusedOptionIndex]);

  return {
    searchQuery,
    isSearching,
    inputProps: {
      autoComplete: "off",
      value: searchQuery ?? inputDefaultValue ?? "",
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
      },
      onKeyDown,
      onFocus,
      onBlur: hideOptionsOnBlur ? onBlur : undefined,
    },
    listboxProps: {
      ref: (el: HTMLElement | null) => {
        if (el) {
          listboxRef.current = el;
        }
      },
      role: "listbox",
    },
    areOptionsShown:
      areOptionsShown && (hideOptions ? !hideOptions(searchQuery) : true),
    options: options.map((option, index) => ({
      option,
      isFocused: focusedOptionIndex === index,
      optionProps: {
        onClick: (ev: MouseEvent) => {
          ev.preventDefault();
          onSelectOption(option, "click", {
            reset,
            hideOptions: () => setAreOptionsShown(false),
          });
        },
        onMouseEnter: () => onFocusOption(index),
        ref: (el: HTMLElement | null) => {
          if (el) {
            optionRefs.current[index] = el;
          }
        },
        role: "option",
      },
    })),
    reset,
  };
}
