import { Button, Icon, useToast } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { BulkPaymentResult } from "modules/dashboard/components/bulk-payment-result";
import { useDashboard } from "modules/dashboard/dashboard.context";
import { useBulkPaymentDetail } from "modules/dashboard/routes/bulk-payments/bulk-payment-detail/bulk-payment-detail.context";
import { buildErrorMessage } from "utils/errors.utils";
import { formatAmountWithCurrency } from "utils/money.utils";

export const BulkPaymentProvidersEditionResult = ({
  onPrevious,
}: {
  onPrevious: () => void;
}) => {
  const toast = useToast();
  const navigate = useNavigate();

  const {
    data: { confirmationResult, totalAmount },
    actions: { onCancelBulkPayment, onFetchFirstPage },
    state: { isFetchingFirstPage, isCancellingBulkPayment },
  } = useBulkPaymentDetail();

  const {
    data: { company },
  } = useDashboard();

  const onCancel = useCallback(async () => {
    const successful = await onCancelBulkPayment();
    if (successful) {
      toast({
        description: "El pago fue cancelado exitosamente.",
        status: "success",
        title: "Pago cancelado",
      });
      navigate("/pagos/proveedores");
    }
  }, [onCancelBulkPayment, toast, navigate]);

  const paymentConditions = (
    <>
      de manera <strong>inmediata</strong>
    </>
  );

  const wasSuccessful = useMemo(
    () => confirmationResult.successful === true,
    [confirmationResult]
  );

  const errorMessage = useMemo(
    () =>
      confirmationResult?.error
        ? buildErrorMessage(confirmationResult.error)
        : "Ocurrió un error al intentar confirmar el pago. Por favor, intentá nuevamente más tarde",
    [confirmationResult]
  );

  return (
    <BulkPaymentResult
      status={wasSuccessful ? "success" : "error"}
      title={
        wasSuccessful
          ? "¡Estás a un paso de pagar a proveedores!"
          : "Algo salió mal"
      }
      body={
        wasSuccessful ? (
          <>
            Las personas firmantes ya fueron notificados. Cuando se apruebe el
            pago, vamos a debitar{" "}
            <strong>{formatAmountWithCurrency(totalAmount)}</strong> de la caja
            de ahorro de <strong>{company?.legalName ?? "la empresa"}</strong>{" "}
            {paymentConditions}.
          </>
        ) : (
          errorMessage
        )
      }
      actions={
        wasSuccessful ? (
          <>
            <Button
              isLoading={isFetchingFirstPage}
              onClick={onFetchFirstPage}
              rounded="full"
              size="lg"
            >
              Ir al resumen
            </Button>

            <Button
              colorScheme="blackAlpha"
              isLoading={isCancellingBulkPayment}
              onClick={onCancel}
              rounded="full"
              size="lg"
              variant="outline"
            >
              Cancelar pago
            </Button>
          </>
        ) : (
          <Button
            colorScheme="blackAlpha"
            leftIcon={<Icon as={FiArrowLeft} />}
            onClick={onPrevious}
            rounded="full"
            size="lg"
            variant="outline"
          >
            Volver
          </Button>
        )
      }
    />
  );
};
