import {
  Button,
  Circle,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  PropsOf,
  Spacer,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { useCallback, useRef } from "react";
import CurrencyInput from "react-currency-input-field";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FiPlus } from "react-icons/fi";

import { useBulkPaymentDetail } from "modules/dashboard/routes/bulk-payments/bulk-payment-detail/bulk-payment-detail.context";
import { useBulkPaymentProvidersEditionEditor } from "modules/dashboard/routes/bulk-payments/bulk-payment-providers-edition/payment-editor/payment-editor.context";
import { Currency } from "types/api-global.types";
import { parseNumber } from "utils/number.utils";

type AddPaymentToProviderFields = {
  amount: string;
  description: string;
};

export const AddPaymentToProviderDrawer = ({
  finalFocusRef,
}: {
  finalFocusRef?: PropsOf<typeof Drawer>["finalFocusRef"];
}) => {
  const {
    state: { isAddingPayment },
    actions: { onAddPaymentToProvider },
  } = useBulkPaymentDetail();

  const {
    addPaymentToProviderDrawer: { isOpen, onClose, selectedContact },
  } = useBulkPaymentProvidersEditionEditor();

  const firstFieldRef = useRef<HTMLInputElement | null>(null);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<AddPaymentToProviderFields>({
    mode: "onTouched",
    defaultValues: {
      amount: "",
      description: "",
    },
  });

  const onSubmit: SubmitHandler<AddPaymentToProviderFields> = useCallback(
    async (values) => {
      const successful = await onAddPaymentToProvider(selectedContact!, {
        amount: {
          amountInCents: parseNumber(values.amount, { decimalScale: 2 }),
          currency: Currency.ARS,
        },

        description: values.description,
      });
      if (successful) {
        onClose();
        reset();
      }
    },
    [onAddPaymentToProvider, onClose, selectedContact, reset]
  );

  return (
    <Drawer
      finalFocusRef={finalFocusRef}
      isOpen={isOpen}
      onClose={onClose}
      placement="right"
      size="sm"
      initialFocusRef={firstFieldRef}
    >
      <DrawerOverlay />

      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody>
          <Stack
            as="form"
            h="full"
            onSubmit={handleSubmit(onSubmit)}
            spacing={6}
          >
            <Stack align="center" pt={10} spacing={4} textAlign="center">
              <Circle bg="brand.100" size={24}>
                <Icon as={FiPlus} color="brand.500" fontSize="6xl" />
              </Circle>
              <Heading fontSize="2xl">Creá un pago a proveedor</Heading>
            </Stack>

            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Proveedor</FormLabel>
                <Input readOnly value={selectedContact?.name ?? ""} />
              </FormControl>

              <FormControl isInvalid={errors?.amount !== undefined}>
                <FormLabel>Pago neto</FormLabel>
                <Controller
                  control={control}
                  name="amount"
                  render={({ field: { onChange, ref, ...rest } }) => (
                    <Input
                      ref={(e) => {
                        ref(e);
                        firstFieldRef.current = e;
                      }}
                      allowNegativeValue={false}
                      as={CurrencyInput}
                      decimalScale={2}
                      decimalsLimit={2}
                      intlConfig={{ locale: "es-AR", currency: "ARS" }}
                      isDisabled={isAddingPayment}
                      onValueChange={onChange}
                      placeholder="$ 0,00"
                      {...rest}
                    />
                  )}
                  rules={{
                    required: "Campo requerido",
                  }}
                />
                <FormErrorMessage>{errors.amount?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors?.description !== undefined}>
                <FormLabel>Descripción</FormLabel>
                <Textarea
                  isDisabled={isAddingPayment}
                  {...register("description")}
                />
                <FormErrorMessage>
                  {errors.description?.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>

            <Spacer />

            <Stack alignItems="stretch" pb={6} spacing={3}>
              <Button isLoading={isAddingPayment} rounded="full" type="submit">
                Crear pago
              </Button>
              <Button mr={3} onClick={onClose} rounded="full" variant="subtle">
                Cancelar
              </Button>
            </Stack>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
