import {
  Box,
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
} from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";
import Cropper, { Area } from "react-easy-crop";
import { FiZoomIn, FiZoomOut } from "react-icons/fi";

import { cropImage } from "utils/image.utils";

export const ImageCropperModal = ({
  file,
  isOpen,
  onClose: _onClose,
  onSubmit: _onSubmit,
  isSubmitting,
}: {
  file: File | null;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (image: Blob) => Promise<boolean>;
  isSubmitting: boolean;
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const imageUrl = useMemo(
    () => (file ? URL.createObjectURL(file) : ""),
    [file]
  );

  const onClose = useCallback(() => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(null);
    _onClose();
  }, [_onClose]);

  const onSubmit = useCallback(async () => {
    if (imageUrl !== "" && croppedAreaPixels) {
      const blob = await cropImage(imageUrl, croppedAreaPixels);
      if (blob) {
        const result = await _onSubmit(blob);
        if (result) {
          onClose();
        }
      }
    }
  }, [croppedAreaPixels, imageUrl, onClose, _onSubmit]);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Subir logo de la empresa</ModalHeader>
        <ModalBody>
          <Stack spacing={4}>
            <Box minH="400px" h="50vh" maxH="1000px" position="relative">
              <Cropper
                image={imageUrl}
                crop={crop}
                showGrid={false}
                zoom={zoom}
                aspect={1}
                cropShape="round"
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={(_, croppedAreaPixels) =>
                  setCroppedAreaPixels(croppedAreaPixels)
                }
                style={{
                  containerStyle: { height: "100%" },
                }}
              />
            </Box>
            <HStack spacing={4}>
              <Icon as={FiZoomOut} />
              <Slider
                aria-label="Zoom"
                min={1}
                max={3}
                value={zoom}
                onChange={setZoom}
                step={0.01}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
              <Icon as={FiZoomIn} />
            </HStack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onSubmit} isLoading={isSubmitting}>
            Aceptar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
