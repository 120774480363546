import { Avatar, Image, Flex, PropsOf, Stack, Text } from "@chakra-ui/react";

import { PropsWithRest } from "types/react-utils";
import { formatTaxId } from "utils/tax-id.utils";

const avatarSizes = {
  "2xs": 4,
  xs: 6,
  sm: 8,
  md: 12,
  lg: 16,
  xl: 24,
  "2xl": 32,
  "3xl": 40,
};

export const BusinessAvatar = ({
  name,
  photo,
  cuit,
  showNameAndCuit = true,
  size = "xl",
  ...rest
}: PropsWithRest<
  {
    name?: string;
    photo?: string;
    cuit?: string;
    showNameAndCuit?: boolean;
    size?: PropsOf<typeof Avatar>["size"];
  },
  typeof Stack
>) => {
  return (
    <Stack align="center" spacing={3} {...rest}>
      {photo ? (
        <Image
          boxSize={avatarSizes[size]}
          fit="cover"
          loading="eager"
          outline="2px solid black"
          outlineColor="blackAlpha.300"
          outlineOffset="-2px"
          rounded="full"
          shadow="lg"
          src={photo}
        />
      ) : (
        <Avatar
          borderColor="blackAlpha.300"
          borderWidth={2}
          name={name}
          rounded="lg"
          shadow="lg"
          size={size}
        />
      )}
      {showNameAndCuit && (
        <Flex direction="column" textAlign="center">
          <Text fontSize="2xl" fontWeight="semibold">
            {name}
          </Text>
          {cuit && <Text className="subtitle">{formatTaxId(cuit)}</Text>}
        </Flex>
      )}
    </Stack>
  );
};
