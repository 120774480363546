import { Button } from "@chakra-ui/button";
import { PropsOf } from "@chakra-ui/system";

export const OnboardingButton = ({ ...rest }: PropsOf<typeof Button>) => {
  return (
    <Button
      background="brand.500"
      borderRadius="lg"
      color="white"
      h={14}
      size="lg"
      w={64}
      _hover={{
        ":not(:disabled)": {
          color: "brand.500",
          background: "gray.200",
        },
      }}
      {...rest}
    />
  );
};
