import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Stack,
  Link as ChakraLink,
  Heading,
  Text,
  FormLabel,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FiArrowLeft } from "react-icons/fi";
import { Link, Navigate } from "react-router-dom";

import { CuitInput } from "components/cuit-input";
import { RetryCountdown } from "components/retry-countdown";
import { useRecaptcha, withRecaptcha } from "contexts/recaptcha.context";
import { useLocalStorage } from "hooks/use-local-storage.hook";
import { useDashboard } from "modules/dashboard/dashboard.context";
import { getOnboardingPath } from "modules/onboarding/onboarding.exports";
import { isValidCompanyTaxId, isValidTaxId } from "utils/tax-id.utils";

type LoginFormFields = {
  email: string;
  taxId: string;
};

export const RequestLogin = withRecaptcha(() => {
  const [savedCountdown, _, clearSavedCountdown] = useLocalStorage(
    "retryCountdown",
    0
  );
  const [wasSubmitted, setWasSubmitted] = useState(savedCountdown > 0 || false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormFields>({ mode: "onTouched" });

  const {
    state: { isRequestingExternalOperatorLogin, isAuthenticated },
    actions: { onRequestExternalOperatorLogin },
  } = useDashboard();

  const { getRecaptchaToken } = useRecaptcha();

  const onSubmit: SubmitHandler<LoginFormFields> = useCallback(
    async (data) => {
      const successful = await onRequestExternalOperatorLogin(
        data.email,
        data.taxId,
        getRecaptchaToken
      );
      if (successful) {
        setWasSubmitted(true);
      }
    },
    [onRequestExternalOperatorLogin, getRecaptchaToken]
  );

  const onRetry = useCallback(() => {
    handleSubmit(onSubmit);
  }, [handleSubmit, onSubmit]);

  if (isAuthenticated) {
    return <Navigate replace to="/" />;
  } else if (wasSubmitted) {
    return (
      <>
        <Text textAlign="center">
          <Heading color="gray.700" mb={4} size="md">
            Revisá tu correo electrónico
          </Heading>
          Te enviamos un correo para que valides tu identidad. Si no lo recibís
          es porque no tenés una cuenta empresa creada.
        </Text>
        <VStack marginBlock={4} w="100%">
          <RetryCountdown
            retry={onRetry}
            isLoading={isRequestingExternalOperatorLogin}
            buttonProps={{
              size: "lg",
              w: "full",
            }}
          />
          <Button
            size="lg"
            w="full"
            marginTop="4!"
            variant="outline"
            leftIcon={<FiArrowLeft />}
            onClick={() => {
              setWasSubmitted(false);
              clearSavedCountdown();
            }}
          >
            Volver
          </Button>
        </VStack>
      </>
    );
  } else {
    return (
      <Stack
        align="center"
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        spacing={12}
        w="full"
      >
        <Stack align="center" spacing={4} w="full">
          <FormControl
            isDisabled={isRequestingExternalOperatorLogin}
            isInvalid={errors?.email !== undefined}
          >
            <FormLabel fontWeight="bold" mb={2}>
              Correo electrónico
            </FormLabel>
            <Input
              placeholder="correo@ejemplo.com"
              size="lg"
              {...register("email", {
                required: "El email es requerido",
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: "La dirección de email no es válida",
                },
                maxLength: {
                  value: 128,
                  message: "El email no puede tener más de 128 caracteres",
                },
              })}
            />
            {errors.email && (
              <FormErrorMessage>
                {errors?.email?.message?.toString()}
              </FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            isDisabled={isRequestingExternalOperatorLogin}
            isInvalid={errors?.taxId !== undefined}
          >
            <FormLabel fontWeight="bold" mb={2}>
              CUIT de empresa
            </FormLabel>
            <Controller
              control={control}
              name="taxId"
              render={({ field: { onChange, ...rest } }) => (
                <Input
                  as={CuitInput}
                  size="lg"
                  {...rest}
                  onValueChange={onChange}
                  placeholder="30-00000000-00"
                />
              )}
              rules={{
                required: "El CUIT es requerido",
                validate: (value) => {
                  if (!isValidTaxId(value)) {
                    return "El CUIT ingresado es incorrecto";
                  } else if (!isValidCompanyTaxId(value)) {
                    return "El CUIT ingresado no corresponde a una empresa";
                  }
                },
              }}
            />
            <FormErrorMessage>
              {errors?.taxId?.message?.toString()}
            </FormErrorMessage>
          </FormControl>
        </Stack>

        <Stack align="center" spacing={4} w="full">
          <Button
            isLoading={isRequestingExternalOperatorLogin}
            size="lg"
            type="submit"
            w="full"
          >
            Ingresar
          </Button>

          <Text fontSize="sm" textAlign="center">
            ¿No tenés una cuenta empresa?{" "}
            <ChakraLink as={Link} color="brand.500" to={getOnboardingPath()}>
              Creala gratis
            </ChakraLink>
            .
          </Text>
        </Stack>
      </Stack>
    );
  }
});
