import { useToast } from "@chakra-ui/react";
import { useCallback } from "react";
import { CombinedError } from "urql";

import { buildErrorMessage } from "utils/errors.utils";
import { hashCode } from "utils/string.utils";

export const useGraphQLErrorToast = () => {
  const toast = useToast();

  const handleGraphQLError = useCallback(
    (error: CombinedError) => {
      const errorMessage = buildErrorMessage(error);
      // We use a hash code to avoid showing the same error message multiple times
      const toastId = `graphql-error-${hashCode(errorMessage.toString())}`;

      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          title: "Algo salió mal",
          description: errorMessage,
          status: "error",
        });
      }
    },
    [toast]
  );

  return handleGraphQLError;
};
