/* eslint-disable no-constant-condition */
import {
  Avatar,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image as ChakraImage,
  PropsOf,
  Skeleton,
  Spinner,
  Square,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { BsShopWindow } from "react-icons/bs";
import { FaCamera } from "react-icons/fa";
import { FiTrash2 } from "react-icons/fi";
import { gql, useMutation } from "urql";

import { ImageCropperModal } from "components/image-cropper-modal";
import { CompanyDescription } from "modules/onboarding/onboarding.types";
import { PropsWithRest } from "types/react-utils";

export const UploadAvatar = ({
  logo,
  ...rest
}: PropsWithRest<
  { logo?: string; size?: PropsOf<typeof Avatar>["size"] },
  typeof Flex
>) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rawLogo, setRawLogo] = useState<File | null>(null);
  const [localLogo, setLocalLogo] = useState("");
  const {
    isOpen: isCropperOpen,
    onOpen: _onOpenCropper,
    onClose: onCloseCropper,
  } = useDisclosure();

  const [_, updateCompanyLogo] = useMutation<
    {
      updateCompanyLogo: {
        companyDescription: Pick<CompanyDescription, "logo">;
      };
    },
    {
      content: Blob;
    }
  >(gql`
    mutation UpdateCompanyLogo($content: Upload!) {
      updateCompanyLogo(in: $content) {
        companyDescription {
          logo {
            publicURL
          }
        }
      }
    }
  `);

  const [deleteCompanyLogoResult, deleteCompanyLogo] = useMutation<
    {
      deleteCompanyLogo: null;
    },
    {}
  >(gql`
    mutation DeleteCompanyLogo {
      deleteCompanyLogo
    }
  `);

  const toast = useToast();

  const onOpenCropper = useCallback(
    (file: File) => {
      setRawLogo(file);
      _onOpenCropper();
    },
    [_onOpenCropper]
  );

  const onSubmit = useCallback(
    async (file: Blob) => {
      setIsSubmitting(true);
      const result = await updateCompanyLogo({ content: file });
      if (!result.error) {
        const { data } = result;
        setLocalLogo(
          data?.updateCompanyLogo?.companyDescription?.logo?.publicURL ?? ""
        );
      }
      setIsSubmitting(false);
      return result.error === undefined;
    },
    [updateCompanyLogo]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "image/webp": [".webp"],
    },
    maxFiles: 1,
    maxSize: 5 * 1024 * 1024,
    onDropAccepted: useCallback(
      async (files: File[]) => {
        if (files.length > 0) {
          onOpenCropper(files[0]);
        }
      },
      [onOpenCropper]
    ),
    onDropRejected: useCallback(
      (fileRejections) => {
        let errorMessage = "El archivo no es compatible.";
        if (fileRejections.length > 1) {
          errorMessage = "Subí un solo archivo.";
        } else {
          const errorCode = fileRejections[0]?.errors[0]?.code;
          switch (errorCode) {
            case "too-many-files":
              errorMessage = "Subí un solo archivo.";
              break;
            case "file-too-large":
              errorMessage = "Subí un logo que pese menos de 5MB.";
              break;
            case "file-invalid-type":
              errorMessage = "Subí un logo en formato JPG, PNG o WEBP.";
              break;
          }
        }
        toast({
          description: errorMessage,
          status: "error",
        });
      },
      [toast]
    ),
  });

  useEffect(() => {
    setLocalLogo(logo || "");
  }, [logo]);

  const uploadAvatarContent = localLogo ? (
    <Flex position="relative" {...rest}>
      {isSubmitting ? (
        <Skeleton h={36} rounded="lg" w={36} />
      ) : (
        <>
          <ChakraImage
            boxSize={36}
            fit="cover"
            mt={3}
            mx={3}
            outline="2px solid black"
            outlineColor="blackAlpha.300"
            outlineOffset="-2px"
            rounded="full"
            shadow="lg"
            src={localLogo}
          />
          <IconButton
            aria-label="Eliminar logo"
            colorScheme="red"
            icon={<Icon as={FiTrash2} />}
            isLoading={deleteCompanyLogoResult.fetching}
            onClick={() => deleteCompanyLogo({})}
            position="absolute"
            right={1}
            rounded="full"
            shadow="md"
            top={1}
          />
        </>
      )}
    </Flex>
  ) : (
    <Flex
      align="center"
      bg={isDragActive ? "brand.100" : "gray.100"}
      borderColor={isDragActive ? "brand.500" : "gray.300"}
      borderStyle="dashed"
      borderWidth={3}
      cursor="pointer"
      direction="column"
      rounded="lg"
      transition="all 0.2s"
      w="full"
      _dark={{
        bg: isDragActive ? "brand.800" : "gray.700",
        borderColor: isDragActive ? "brand.500" : "gray.600",
      }}
      {...rest}
      {...getRootProps()}
      lineHeight={1.35}
      px={4}
      py={6}
    >
      <input {...getInputProps()} hidden />
      <Square
        bg="blackAlpha.200"
        mb={4}
        rounded="lg"
        size={16}
        _dark={{
          bg: "whiteAlpha.200",
        }}
      >
        <Icon
          _dark={{ color: "whiteAlpha.500" }}
          as={BsShopWindow}
          boxSize={8}
          color="blackAlpha.500"
        />
      </Square>
      {isSubmitting ? (
        <HStack
          _dark={{ color: "brand.300" }}
          color="brand.500"
          fontSize="xl"
          spacing={3}
        >
          <Spinner size="sm" thickness="2px" />
          <Text fontWeight="semibold">Subiendo...</Text>
        </HStack>
      ) : (
        <>
          <Text color="gray.500" fontSize="sm" fontWeight="semibold">
            Opcional
          </Text>
          <HStack
            _dark={{ color: "brand.300" }}
            color="brand.500"
            fontSize="xl"
            spacing={3}
          >
            <Icon as={FaCamera} />
            <Text fontWeight="semibold">Subir logo empresa</Text>
          </HStack>
        </>
      )}
    </Flex>
  );

  return (
    <>
      <ImageCropperModal
        isOpen={isCropperOpen}
        onClose={onCloseCropper}
        file={rawLogo}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      />
      {uploadAvatarContent}
    </>
  );
};
