import { RouteObject } from "react-router";

import { BulkPaymentDetail } from "./bulk-payment-detail";
import { BulkPaymentsHistory } from "./bulk-payments-history";
import { BulkPaymentsPayroll } from "./bulk-payments-payroll";
import { BulkPaymentType } from "./bulk-payments.api";

import { DashboardLayout } from "modules/dashboard/components/dashboard-layout";
import { RouteNotFound } from "utils/router.utils";

export const bulkPaymentsRoutes: RouteObject[] = [
  {
    element: <DashboardLayout />,
    path: "pagos",
    children: [
      {
        index: true,
        loader: RouteNotFound,
      },
      {
        path: "sueldos",
        children: [
          {
            index: true,
            element: <BulkPaymentsHistory type={BulkPaymentType.Salaries} />,
          },
          {
            path: "nuevo",
            element: <BulkPaymentsPayroll />,
          },
        ],
      },
      {
        path: "proveedores",
        children: [
          {
            index: true,
            element: <BulkPaymentsHistory type={BulkPaymentType.Providers} />,
          },
        ],
      },
      {
        path: ":bulkPaymentId",
        element: <BulkPaymentDetail />,
      },
    ],
  },
];
