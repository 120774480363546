import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "urql";

import { ONBOARDING_STATUS } from "./onboarding.constants";
import { OnboardingSummary } from "./onboarding.fragments";
import {
  CompanyOnboarding,
  DocumentTypeBySocietyType,
  ItemWithNameAndDescription,
  TaxCondition,
} from "./onboarding.types";

import { RecaptchaTokenGetter } from "contexts/recaptcha.context";
import { useContextSafe } from "hooks/use-context-safe.hook";
import { useLocalStorage } from "hooks/use-local-storage.hook";
import {
  OnboardingStep,
  getOnboardingPath,
} from "modules/onboarding/routes/onboarding.routes";
import { mapSourceTags } from "utils/marketing.utils";
import { formatTaxId } from "utils/tax-id.utils";

type OnboardingContextValue = {
  state: {
    isLoading: boolean;
    isStale: boolean;
    isSavingData: boolean;
    isFetchingFormOptions: boolean;
    isCreatingCompanyOnboarding: boolean;
    requiresFormReSubmission: boolean;
    isAccepted: boolean;
  };
  data: {
    currentStepRoute: string | null;
    onboardingSummary?: CompanyOnboarding;
    userComment: CompanyOnboarding["userComment"];
    formOptions: {
      societyTypes: ItemWithNameAndDescription[];
      taxConditions: TaxCondition[];
      activityTypes: ItemWithNameAndDescription[];
      documentTypes: DocumentTypeBySocietyType[];
      roles: ItemWithNameAndDescription[];
    };
    isOnboardingFinished: boolean;
    lastCreatedOnboarding: { email: string; taxId: string } | null;
  };
  actions: {
    setPauseSummaryQuery: (pause: boolean) => void;
    setIsSavingData: (isSavingData: boolean) => void;
    onCreateCompanyOnboarding: (
      email: string,
      taxId: string,
      getRecaptchaToken: RecaptchaTokenGetter
    ) => Promise<boolean>;
    setRequiresFormReSubmission: (requiresFormReSubmission: boolean) => void;
    setUserComment: (userComment: string) => void;
  };
};

export const OnboardingContext = createContext<OnboardingContextValue | null>(
  null
);

export const useOnboarding = () => useContextSafe(OnboardingContext);

export const OnboardingProvider = ({ children }: PropsWithChildren<{}>) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const sourceTags = mapSourceTags(searchParams);
  const [pauseSummaryQuery, setPauseSummaryQuery] = useState(false);
  const [isCreatingCompanyOnboarding, setIsCreatingCompanyOnboarding] =
    useState(false);
  const [isSavingData, setIsSavingData] = useState(false);

  const [lastCreatedOnboarding, setLastCreatedOnboarding] = useLocalStorage<{
    email: string;
    taxId: string;
  } | null>("onboarding:lastCreated", null);

  const [_createCompanyOnboardingResult, createCompanyOnboarding] = useMutation<
    {
      createCompanyOnboarding: { message: string };
    },
    {
      email: string;
      taxId: string;
      sourceTags: Record<string, string>;
    }
  >(gql`
    mutation CreateCompanyOnboarding(
      $email: String!
      $taxId: String!
      $sourceTags: Map
    ) {
      createCompanyOnboarding(
        in: { email: $email, taxId: $taxId, sourceTags: $sourceTags }
      ) {
        message
      }
    }
  `);

  const [onboardingData] = useQuery<{
    companyOnboarding: CompanyOnboarding;
  }>({
    query: gql`
      query OnboardingData {
        companyOnboarding {
          ...OnboardingSummary
        }
      }
      ${OnboardingSummary}
    `,
    // Allow components to pause the query at will
    pause: pauseSummaryQuery,
  });

  const [formOptions] = useQuery<{
    societyTypes: ItemWithNameAndDescription[];
    taxConditions: TaxCondition[];
    activityTypes: ItemWithNameAndDescription[];
    companyRoles: ItemWithNameAndDescription[];
  }>({
    query: gql`
      query {
        societyTypes {
          id
          name
          description
        }
        taxConditions {
          id
          bankCoreId
          description
        }
        activityTypes {
          id
          name
          description
        }
        companyRoles {
          id
          name
          description
        }
      }
    `,
    pause: !onboardingData.data?.companyOnboarding,
    requestPolicy: "cache-first",
  });

  const [documentTypes] = useQuery<{
    documentTypesBySocietyType: DocumentTypeBySocietyType[];
  }>({
    query: gql`
      query GetDocumentTypes($societyTypeId: Int!) {
        documentTypesBySocietyType(societyTypeId: $societyTypeId) {
          documentTypeId
          documentName
          documentDescription
          mandatory
        }
      }
    `,
    variables: {
      societyTypeId:
        onboardingData.data?.companyOnboarding?.companyDescription?.societyType
          ?.id,
    },
    pause:
      !onboardingData.data?.companyOnboarding?.companyDescription?.societyType
        ?.id,
    requestPolicy: "cache-first",
  });

  const currentStepRoute = useMemo(() => {
    if (!onboardingData.data) {
      return getOnboardingPath(OnboardingStep.Start);
    } else {
      const { companyOnboarding: onboardingSummary } = onboardingData.data;

      if (!onboardingSummary?.email) {
        // Email is missing - redirect to start page
        return getOnboardingPath(OnboardingStep.Start);
      } else if (!onboardingSummary?.phoneNumber) {
        // Phone number is missing - redirect to phone number step
        return getOnboardingPath(OnboardingStep.PhoneAdd);
      } else if (
        !onboardingSummary.companyDescription.fantasyName ||
        !onboardingSummary.companyDescription.societyType ||
        !onboardingSummary.companyDescription.legalName ||
        !onboardingSummary.companyDescription.constitutionDay ||
        !onboardingSummary.companyDescription.taxCondition ||
        !onboardingSummary.companyDescription.address
      ) {
        // Some company description fields are missing - redirect to company step 1
        return getOnboardingPath(OnboardingStep.Step1);
      } else if (
        !onboardingSummary.companyDescription.activity ||
        !onboardingSummary.companyDescription.activityDescription ||
        !onboardingSummary.companyDescription.ageInBusiness ||
        !onboardingSummary.companyDescription.employeesQuantity
      ) {
        // Some company description fields are missing - redirect to company step 2
        return getOnboardingPath(OnboardingStep.Step2);
      } else if (!onboardingSummary.docs) {
        // No documents have been uploaded - redirect to documents step
        return getOnboardingPath(OnboardingStep.Documents);
      } else if (!documentTypes.data) {
        // Document types are still loading - wait until they are loaded,
        // because we need them to determine the next step
        return null;
      } else if (
        (documentTypes.data?.documentTypesBySocietyType ?? []).some(
          ({ documentTypeId }) =>
            onboardingSummary.docs!.every(
              ({ typeBySociety }) =>
                typeBySociety.documentTypeId !== documentTypeId
            )
        )
      ) {
        // At least one document type has no files uploaded - redirect to documents step
        return getOnboardingPath(OnboardingStep.Documents);
      } else if (
        !onboardingSummary.users ||
        onboardingSummary.users.length === 0
      ) {
        // No users have been added - redirect to team step
        return getOnboardingPath(OnboardingStep.TeamLanding);
      } else {
        // Redirect to summary
        return getOnboardingPath(OnboardingStep.Summary);
      }
    }
  }, [onboardingData.data, documentTypes.data]);

  const onboardingSummary = useMemo(() => {
    return onboardingData.data?.companyOnboarding;
  }, [onboardingData.data]);

  const [userComment, setUserComment] = useState("");
  useEffect(() => {
    setUserComment(onboardingSummary?.userComment ?? "");
  }, [onboardingSummary?.userComment]);

  const isOnboardingFinished = useMemo(() => {
    return (
      onboardingSummary?.status?.status !== undefined &&
      ["in_review", "succeeded", "rejected"].includes(
        onboardingSummary.status.status
      )
    );
  }, [onboardingSummary]);

  const onCreateCompanyOnboarding = useCallback(
    async (
      email: string,
      taxId: string,
      getRecaptchaToken: RecaptchaTokenGetter
    ): Promise<boolean> => {
      setIsCreatingCompanyOnboarding(true);
      const taxIdWithoutDashes = formatTaxId(taxId, { withDashes: false });
      const recaptchaToken = await getRecaptchaToken("onboarding");
      const result = await createCompanyOnboarding(
        {
          email,
          taxId: taxIdWithoutDashes,
          sourceTags: sourceTags,
        },
        {
          fetchOptions: {
            headers: recaptchaToken
              ? {
                  "x-captcha-token": recaptchaToken,
                }
              : {},
          },
        }
      );
      setIsCreatingCompanyOnboarding(false);
      if (!result.error) {
        setLastCreatedOnboarding({ email, taxId: taxIdWithoutDashes });
        navigate(getOnboardingPath(OnboardingStep.EmailSent), {
          state: { email, taxId },
        });
        return true;
      } else {
        return false;
      }
    },
    [createCompanyOnboarding, sourceTags, setLastCreatedOnboarding, navigate]
  );
  const [requiresFormReSubmission, setRequiresFormReSubmission] =
    useState<boolean>(false);
  const isAccepted = useMemo(
    () => onboardingSummary?.status.status === ONBOARDING_STATUS.SUCCEEDED,
    [onboardingSummary?.status.status]
  );

  const value: OnboardingContextValue = useMemo(
    () => ({
      state: {
        isLoading: onboardingData.fetching,
        isStale: onboardingData.stale,
        isFetchingFormOptions: formOptions.fetching || documentTypes.fetching,
        isCreatingCompanyOnboarding,
        isSavingData,
        requiresFormReSubmission,
        isAccepted,
      },
      data: {
        currentStepRoute,
        onboardingSummary,
        userComment,
        formOptions: {
          societyTypes: formOptions.data?.societyTypes ?? [],
          taxConditions: formOptions.data?.taxConditions ?? [],
          activityTypes: formOptions.data?.activityTypes ?? [],
          documentTypes: documentTypes.data?.documentTypesBySocietyType ?? [],
          roles: formOptions.data?.companyRoles ?? [],
        },
        isOnboardingFinished,
        lastCreatedOnboarding,
      },
      actions: {
        setPauseSummaryQuery,
        setIsSavingData,
        onCreateCompanyOnboarding,
        setRequiresFormReSubmission,
        setUserComment,
      },
    }),
    [
      onboardingData.fetching,
      onboardingData.stale,
      formOptions.fetching,
      formOptions.data?.societyTypes,
      formOptions.data?.taxConditions,
      formOptions.data?.activityTypes,
      formOptions.data?.companyRoles,
      documentTypes.fetching,
      documentTypes.data?.documentTypesBySocietyType,
      isCreatingCompanyOnboarding,
      isSavingData,
      requiresFormReSubmission,
      isAccepted,
      currentStepRoute,
      onboardingSummary,
      userComment,
      isOnboardingFinished,
      lastCreatedOnboarding,
      onCreateCompanyOnboarding,
    ]
  );

  return (
    <OnboardingContext.Provider value={value}>
      {children}
    </OnboardingContext.Provider>
  );
};
