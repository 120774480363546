import {
  Circle,
  Divider,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FaArrowRightArrowLeft } from "react-icons/fa6";

import { Transaction } from "modules/dashboard/dashboard.api";
import { formatDate } from "utils/date.utils";
import { formatAmountWithCurrency } from "utils/money.utils";

export const TransactionList = ({
  transactions,
}: {
  transactions: Transaction[];
}) => {
  const transactionFallbackIcon = (
    <Icon as={FaArrowRightArrowLeft} color="gray.500" w={4} h={4} />
  );
  return (
    <VStack spacing={4} w="100%">
      {transactions.length > 0 ? (
        transactions.map((transaction, index) => (
          <VStack key={transaction.id} w="100%">
            {index > 0 && <Divider color="gray.300" />}
            <HStack justifyContent="space-between" w="100%" pt={2}>
              <HStack align="flex-start" alignItems="center">
                <Circle overflow="hidden" size={8} bg="gray.100">
                  {transaction.imageURL ? (
                    <Image
                      src={transaction.imageURL}
                      w={8}
                      h={8}
                      fallback={transactionFallbackIcon}
                    />
                  ) : (
                    transactionFallbackIcon
                  )}
                </Circle>
                <VStack align="start" ps={2}>
                  <Text fontWeight="bold">{transaction.title}</Text>
                  <Text fontSize="sm" color="gray.500" marginTop="0 !important">
                    {formatDate(transaction.time, "DD/MM/YYYY")} •{" "}
                    {formatDate(transaction.time, "HH:mm")}
                  </Text>
                </VStack>
              </HStack>
              <Text fontSize="xl" fontWeight="bold">
                {formatAmountWithCurrency(transaction.amount)}
              </Text>
            </HStack>
          </VStack>
        ))
      ) : (
        <Text>
          Acá vas a ver los movimientos de tu cuenta, estamos trabajando en eso
        </Text>
      )}
    </VStack>
  );
};
