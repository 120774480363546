import {
  Badge,
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Skeleton,
  Stack,
  Wrap,
  useToast,
} from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { FiX } from "react-icons/fi";

import { useBulkPaymentDetail } from "./bulk-payment-detail.context";

import { ErrorMessage } from "components/error-message";
import {
  BulkPaymentConfirmationBox,
  BulkPaymentConfirmationCard,
  BulkPaymentConfirmationStatBox,
  BulkPaymentConfirmationTable,
} from "modules/dashboard/components/bulk-payment-confirmation";
import { DashboardSection } from "modules/dashboard/components/dashboard-section";
import { BulkPaymentStatusStepper } from "modules/dashboard/routes/bulk-payments/bulk-payment-detail/bulk-payment-status-stepper";
import {
  BulkPaymentStatus,
  BulkPaymentType,
} from "modules/dashboard/routes/bulk-payments/bulk-payments.api";
import {
  bulkPaymentStatusColorScheme,
  bulkPaymentStatusLabel,
  paymentStatusColorScheme,
  paymentStatusLabel,
} from "modules/dashboard/routes/bulk-payments/bulk-payments.utils";
import { formatAmountWithCurrency } from "utils/money.utils";
import { formatTaxId } from "utils/tax-id.utils";

export const BulkPaymentDetailSummary = ({
  backButtonPath = "/",
}: {
  backButtonPath: string;
}) => {
  const toast = useToast();

  const {
    data: { bulkPayment, payments, paymentCount, totalAmount },
    state: {
      isFetchingFirstPage,
      isFetchingAdditionalPage,
      areMorePagesAvailable,
      isCancellingBulkPayment,
    },
    actions: { onFetchFirstPage, onFetchNextPage, onCancelBulkPayment },
  } = useBulkPaymentDetail();

  const onCancel = useCallback(async () => {
    const successful = await onCancelBulkPayment();
    if (successful) {
      toast({
        description: "El pago fue cancelado exitosamente.",
        status: "success",
        title: "Pago cancelado",
      });
      onFetchFirstPage();
    }
  }, [onCancelBulkPayment, toast, onFetchFirstPage]);

  const stats = useMemo(
    () => [
      {
        title:
          bulkPayment?.paymentType === BulkPaymentType.Salaries
            ? "Personas"
            : "Pagos",
        value: paymentCount,
      },
      {
        title:
          bulkPayment?.paymentType === BulkPaymentType.Salaries
            ? "Total en sueldos"
            : "Pago total",
        value: formatAmountWithCurrency(totalAmount),
      },
      // { title: "Fecha de pago", value: "Hoy" },
      // { title: "Nuevos", value: "?" },
      // { title: "Removidos", value: "?" },
      // { title: "Mes pasado", value: "+?%" },
    ],
    [bulkPayment, paymentCount, totalAmount]
  );

  const hasErrors = useMemo(() => {
    return (bulkPayment?.paymentsMetadata?.failed?.count ?? 0) > 0;
  }, [bulkPayment?.paymentsMetadata?.failed]);

  const hasSuccesess = useMemo(() => {
    return (bulkPayment?.paymentsMetadata?.successful?.count ?? 0) > 0;
  }, [bulkPayment?.paymentsMetadata?.successful]);

  if (!isFetchingFirstPage && !bulkPayment) {
    return (
      <Center h="full">
        <ErrorMessage
          subtitle="No encontramos el pago"
          body="El pago que estás buscando no existe o fue eliminado."
        />
      </Center>
    );
  }

  return (
    <DashboardSection
      backButton={backButtonPath}
      isBackButtonDisabled={!bulkPayment}
      title="Resumen de pago"
      headerControls={
        bulkPayment?.status === BulkPaymentStatus.PendingApproval ? (
          <Box>
            <Button
              colorScheme="red"
              isLoading={isCancellingBulkPayment}
              leftIcon={<Icon as={FiX} />}
              my={-1}
              onClick={onCancel}
              rounded="full"
              variant="outline"
            >
              Cancelar pago
            </Button>
          </Box>
        ) : undefined
      }
    >
      <Stack spacing={8}>
        <BulkPaymentConfirmationCard title="Estado">
          <BulkPaymentConfirmationBox>
            <HStack
              align={isFetchingFirstPage ? "center" : "baseline"}
              spacing={10}
            >
              {!isFetchingFirstPage && bulkPayment ? (
                <>
                  <Badge
                    fontSize="md"
                    px={3}
                    py={1}
                    rounded="md"
                    colorScheme={bulkPaymentStatusColorScheme(
                      bulkPayment.status,
                      hasErrors
                    )}
                  >
                    {bulkPaymentStatusLabel(
                      bulkPayment.status,
                      false,
                      hasErrors,
                      hasSuccesess
                    )}
                  </Badge>
                  <BulkPaymentStatusStepper bulkPayment={bulkPayment} />
                </>
              ) : (
                <>
                  <Skeleton h={8} rounded="md" w="12ch" />
                  <Skeleton fontSize="lg" h={6} rounded="md" w="50ch" />
                </>
              )}
            </HStack>
          </BulkPaymentConfirmationBox>
        </BulkPaymentConfirmationCard>

        <BulkPaymentConfirmationCard title="Resumen">
          <Wrap overflow="visible" spacing={4}>
            {stats.map((stat, index) => (
              <BulkPaymentConfirmationStatBox
                {...stat}
                key={index}
                isLoading={isFetchingFirstPage}
              />
            ))}
          </Wrap>
        </BulkPaymentConfirmationCard>

        <BulkPaymentConfirmationCard
          title={
            !isFetchingFirstPage ? (
              `${paymentCount} ${
                bulkPayment?.paymentType === BulkPaymentType.Providers
                  ? paymentCount === 1
                    ? "pago"
                    : "pagos"
                  : paymentCount === 1
                  ? "sueldo"
                  : "sueldos"
              }`
            ) : (
              <Skeleton h={7} rounded="md" w="90%" />
            )
          }
        >
          <BulkPaymentConfirmationBox>
            <BulkPaymentConfirmationTable
              areMorePagesAvailable={areMorePagesAvailable}
              isFetchingAdditionalPage={isFetchingAdditionalPage}
              isFetchingFirstPage={isFetchingFirstPage}
              onFetchNextPage={onFetchNextPage}
              columns={{
                fullName: {
                  title:
                    bulkPayment?.paymentType === BulkPaymentType.Providers
                      ? "Proveedor"
                      : "Persona empleada",
                  isNumeric: false,
                },
                status: { title: "Estado", isNumeric: false },
                taxId: { title: "CUIT", isNumeric: false },
                cbu: { title: "CBU", isNumeric: false },
                salary: { title: "Pago neto", isNumeric: true },
              }}
              rows={payments.map((payment) => ({
                id: payment.id,
                fullName: payment.to.name,
                taxId: formatTaxId(payment.to.taxId),
                cbu: payment.toCBU,
                salary: formatAmountWithCurrency(payment.amount),
                status: (
                  <Badge colorScheme={paymentStatusColorScheme(payment.status)}>
                    {paymentStatusLabel(payment.status)}
                  </Badge>
                ),
              }))}
            />
          </BulkPaymentConfirmationBox>
        </BulkPaymentConfirmationCard>
      </Stack>
    </DashboardSection>
  );
};
