import { Icon } from "@chakra-ui/icon";
import { Center, Image } from "@chakra-ui/react";
import { IoBusinessOutline } from "react-icons/io5";

import { PropsWithRest } from "types/react-utils";

export const CompanyAvatar = ({
  src,
  size = 20,
  ...rest
}: PropsWithRest<
  {
    src?: string;
    size?: number;
  },
  typeof Image & typeof Center
>) => {
  return (
    <Image
      bg="white"
      borderColor="blackAlpha.200"
      borderStyle="solid"
      borderWidth={1}
      boxSize={size}
      fit="contain"
      rounded="lg"
      src={src}
      {...rest}
      fallback={
        <Center
          _dark={{ bg: "gray.700", color: "gray.400" }}
          bg="gray.100"
          color="gray.500"
          h={size}
          rounded="lg"
          w={size}
          {...rest}
        >
          <Icon as={IoBusinessOutline} boxSize={8} />
        </Center>
      }
    />
  );
};
