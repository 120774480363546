import { FiUsers } from "react-icons/fi";
import { useNavigate } from "react-router";

import { OnboardingBackButton } from "modules/onboarding/components/onboarding-back-button";
import { OnboardingButton } from "modules/onboarding/components/onboarding-button";
import { OnboardingHeader } from "modules/onboarding/components/onboarding-header";
import { OnboardingPage } from "modules/onboarding/components/onboarding-page";
import {
  OnboardingStep,
  getOnboardingPath,
} from "modules/onboarding/routes/onboarding.routes";

export const TeamLanding = () => {
  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate(getOnboardingPath(OnboardingStep.Documents));
  };

  const handleContinue = () => {
    navigate(getOnboardingPath(OnboardingStep.TeamList));
  };

  return (
    <OnboardingPage activeStep={2}>
      <OnboardingBackButton onClick={handleNavigateBack} />

      <OnboardingHeader
        icon={FiUsers}
        subtitle="Las personas que agregues van a tener habilitadas las funcionalidades de la cuenta empresa. Podés editar sus permisos cuando quieras."
        title="Vamos a dar de alta a las personas con acceso a la cuenta"
      />
      <OnboardingButton mt={12} onClick={handleContinue}>
        Continuar
      </OnboardingButton>
    </OnboardingPage>
  );
};
