import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Link,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import {
  FiExternalLink,
  FiFileText,
  FiPaperclip,
  FiTrash2,
  FiXCircle,
} from "react-icons/fi";
import { LuFileCheck } from "react-icons/lu";

import {
  CompanyOnboardingDoc,
  DocumentTypeBySocietyType,
} from "modules/onboarding/onboarding.types";

export const DocumentTypeCard = ({
  documentType,
  documents = [],
  isDisabled,
  onAddDocument,
  onOmitDocument,
  onRemoveDocument: _onRemoveDocument,
  ...rest
}: {
  documentType: DocumentTypeBySocietyType;
  documents?: CompanyOnboardingDoc[];
  isDisabled: boolean;
  onAddDocument: () => void;
  onOmitDocument: () => void;
  onRemoveDocument: (document: CompanyOnboardingDoc) => Promise<void>;
}) => {
  const [removingDocumentIds, setRemovingDocumentIds] = useState<string[]>([]);

  const { documentDescription, documentName } = documentType;

  const onRemoveDocument = async (document: CompanyOnboardingDoc) => {
    setRemovingDocumentIds([...removingDocumentIds, document.id]);
    await _onRemoveDocument(document);
    setRemovingDocumentIds(
      removingDocumentIds.filter((documentId) => documentId !== document.id)
    );
  };

  const canAddDocument = useMemo(() => {
    return documents.every((document) => !document.skipExplanation);
  }, [documents]);

  const canOmitDocument = useMemo(() => {
    return !documentType.mandatory && documents.length === 0;
  }, [documentType.mandatory, documents.length]);

  return (
    <Box borderWidth={1} {...rest} px={6} py={5} rounded="lg" shadow="sm">
      <HStack align="start" spacing={4}>
        <Icon as={FiFileText} boxSize={8} color="gray.300" />

        <Stack minW={0} spacing={4} w="full">
          <HStack align="start" spacing={4}>
            <Stack lineHeight="1.2">
              <Text fontSize="xl" fontWeight="semibold">
                {documentName}
              </Text>
              <Text
                _dark={{ color: "gray.500" }}
                color="gray.500"
                fontSize="md"
                lineHeight="1.2"
              >
                {documentDescription}
              </Text>
            </Stack>

            <Spacer />

            <Stack>
              {useBreakpointValue({
                base: canAddDocument && (
                  <IconButton
                    aria-label="Adjuntar documento"
                    flexShrink={0}
                    icon={<FiPaperclip />}
                    isDisabled={isDisabled}
                    onClick={onAddDocument}
                    rounded="full"
                    variant="subtle"
                  />
                ),
                sm: canAddDocument && (
                  <Button
                    flexShrink={0}
                    isDisabled={isDisabled}
                    leftIcon={<FiPaperclip />}
                    onClick={onAddDocument}
                    rounded="full"
                    variant="subtle"
                  >
                    Adjuntar
                  </Button>
                ),
              })}
              {useBreakpointValue({
                base: canOmitDocument && (
                  <IconButton
                    aria-label="No aplica"
                    flexShrink={0}
                    icon={<FiXCircle />}
                    isDisabled={isDisabled}
                    onClick={onOmitDocument}
                    rounded="full"
                    variant="subtle"
                    colorScheme="gray"
                  />
                ),
                sm: canOmitDocument && (
                  <Button
                    flexShrink={0}
                    isDisabled={isDisabled}
                    leftIcon={<FiXCircle />}
                    onClick={onOmitDocument}
                    rounded="full"
                    variant="subtle"
                    colorScheme="gray"
                  >
                    No aplica
                  </Button>
                ),
              })}
            </Stack>
          </HStack>

          {documents.length > 0 &&
            documents.map((document) => (
              <HStack key={document.id} align="center" spacing={4}>
                {document.attachment ? (
                  <Button
                    as={Link}
                    colorScheme="blue"
                    href={document.attachment.publicURL}
                    isExternal
                    leftIcon={<LuFileCheck />}
                    rightIcon={<Icon as={FiExternalLink} />}
                    rounded="full"
                    size="sm"
                    variant="subtle"
                  >
                    <Text isTruncated>{document.fileName} </Text>
                  </Button>
                ) : (
                  <Box
                    rounded="full"
                    bg="gray.50"
                    fontWeight="semibold"
                    px={3}
                    py={1.5}
                    fontSize="sm"
                    color="gray.500"
                    minW={0}
                  >
                    <HStack>
                      <Icon as={FiXCircle} />
                      <Text wordBreak="break-word">
                        Omitido: {document.skipExplanation}
                      </Text>
                    </HStack>
                  </Box>
                )}
                <Tooltip
                  label={
                    document.attachment
                      ? "Borrar documento"
                      : "Deshacer omisión"
                  }
                >
                  <IconButton
                    aria-label="Borrar documento"
                    colorScheme="red"
                    icon={<FiTrash2 />}
                    isDisabled={isDisabled}
                    isLoading={removingDocumentIds.includes(document.id)}
                    isRound
                    onClick={() => onRemoveDocument(document)}
                    size="sm"
                    variant="subtle"
                  />
                </Tooltip>
              </HStack>
            ))}
        </Stack>
      </HStack>
    </Box>
  );
};
