import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

import { BulkPaymentDetailSummary } from "./bulk-payment-detail-summary";
import {
  BulkPaymentDetailProvider,
  useBulkPaymentDetail,
} from "./bulk-payment-detail.context";

import { BulkPaymentProvidersEdition } from "modules/dashboard/routes/bulk-payments/bulk-payment-providers-edition";
import {
  BulkPaymentStatus,
  BulkPaymentType,
} from "modules/dashboard/routes/bulk-payments/bulk-payments.api";

const BulkPaymentDetailContent = () => {
  const {
    data: { bulkPayment },
  } = useBulkPaymentDetail();

  const location = useLocation();

  const bulkPaymentData: {
    status: BulkPaymentStatus;
    paymentType: BulkPaymentType;
  } | null = useMemo(
    () =>
      bulkPayment
        ? {
            status: bulkPayment.status,
            paymentType: bulkPayment.paymentType,
          }
        : location.state?.bulkPayment ?? null,
    [bulkPayment, location.state]
  );

  const backButtonPath = useMemo(() => {
    if (location.state?.from) {
      return location.state.from;
    } else {
      return bulkPayment
        ? bulkPayment.paymentType === BulkPaymentType.Providers
          ? "/pagos/proveedores"
          : "/pagos/sueldos"
        : "/";
    }
  }, [bulkPayment, location.state]);

  if (
    bulkPaymentData &&
    bulkPaymentData.paymentType === BulkPaymentType.Providers &&
    bulkPaymentData.status === BulkPaymentStatus.Created
  ) {
    return <BulkPaymentProvidersEdition backButtonPath={backButtonPath} />;
  } else {
    return <BulkPaymentDetailSummary backButtonPath={backButtonPath} />;
  }
};

export const BulkPaymentDetail = ({ pageSize }: { pageSize?: number }) => {
  const { bulkPaymentId } = useParams<{ bulkPaymentId: string }>();

  return (
    <BulkPaymentDetailProvider
      bulkPaymentId={bulkPaymentId!}
      pageSize={pageSize}
    >
      <BulkPaymentDetailContent />
    </BulkPaymentDetailProvider>
  );
};
