import {
  ReactNode,
  Reducer,
  createContext,
  useCallback,
  useEffect,
} from "react";
import { useClient } from "urql";

import { useAlertDialog } from "contexts/alert-dialog.context";
import { useContextSafe } from "hooks/use-context-safe.hook";
import { useReducerAlt } from "hooks/use-reducer-alt.hook";
import { useBulkPaymentDetail } from "modules/dashboard/routes/bulk-payments/bulk-payment-detail/bulk-payment-detail.context";
import { parseProvidersQuery } from "modules/dashboard/routes/bulk-payments/bulk-payment-providers-edition/bulk-payment-providers-edition.utils";
import {
  Provider,
  ProviderEditInput,
  ProviderInput,
  ProvidersQuery,
  addProvider,
  deleteProvider,
  fetchProviders,
  editProvider,
} from "modules/dashboard/routes/bulk-payments/bulk-payments-providers.api";

export const BulkPaymentProvidersEditionContext = createContext<{
  actions: {
    onFetchProvidersFirstPage: () => Promise<void>;
    onFetchProvidersNextPage: () => Promise<void>;
    onAddProvider: (provider: ProviderInput) => Promise<Provider | null>;
    onDeleteProvider: (providerId: string) => Promise<void>;
    onChangeProvidersQuery: (query: string) => void;
    onEditProvider: (providerId: string, data: ProviderEditInput) => void;
  };
  data: {
    providers: Provider[];
    providersQuery: string;
  };
  state: {
    isFetchingProviders: boolean;
    isFetchingProvidersFirstPage: boolean;
    areMoreProvidersPagesAvailable: boolean;
    isAddingProvider: boolean;
    isUpdatingProviderIds: string[];
    isUpdatingProvider: (providerId: string) => boolean;
  };
} | null>(null);

export const useBulkPaymentProvidersEdition = () =>
  useContextSafe(BulkPaymentProvidersEditionContext);

type BulkPaymentProvidersEditionState = {
  providers: Provider[];
  providersCursor: string | null;
  providersQuery: {
    text: string;
    parsed: ProvidersQuery | null;
  };
  isFetchingProviders: boolean;
  isFetchingProvidersFirstPage: boolean;
  isAddingProvider: boolean;
  isUpdatingProviderIds: string[];
};

type BulkPaymentProvidersEditionAction =
  | {
      type: "ADD_PROVIDERS";
      payload: {
        providers: Provider[];
        placement?: "start" | "end" | "replace";
      };
    }
  | {
      type: "DELETE_PROVIDER";
      payload: string;
    }
  | {
      type: "UPDATE_PROVIDER";
      payload: Provider;
    }
  | {
      type: "SET_PROVIDERS_CURSOR";
      payload: string | null;
    }
  | {
      type: "SET_PROVIDERS_QUERY";
      payload: { text: string; parsed: ProvidersQuery | null };
    }
  | {
      type: "SET_IS_FETCHING_PROVIDERS";
      payload: { firstPage?: boolean; value: boolean };
    }
  | {
      type: "SET_IS_ADDING_PROVIDER";
      payload: boolean;
    }
  | {
      type: "SET_IS_UPDATING_PROVIDER";
      payload: { ids: string[]; value: boolean };
    };

const BulkPaymentProvidersEditionReducer: Reducer<
  BulkPaymentProvidersEditionState,
  BulkPaymentProvidersEditionAction
> = (state, { type, payload }): BulkPaymentProvidersEditionState => {
  switch (type) {
    case "ADD_PROVIDERS":
      if (payload.placement === "replace") {
        return { ...state, providers: payload.providers };
      } else if (payload.placement === "start") {
        return {
          ...state,
          providers: [...payload.providers, ...state.providers],
        };
      } else {
        return {
          ...state,
          providers: [...state.providers, ...payload.providers],
        };
      }
    case "DELETE_PROVIDER":
      return {
        ...state,
        providers: state.providers.filter(
          (provider) => provider.id !== payload
        ),
      };
    case "UPDATE_PROVIDER":
      return {
        ...state,
        providers: state.providers.map((provider) =>
          provider.id === payload.id ? payload : provider
        ),
      };
    case "SET_PROVIDERS_CURSOR":
      return { ...state, providersCursor: payload };
    case "SET_PROVIDERS_QUERY":
      return { ...state, providersQuery: payload };
    case "SET_IS_FETCHING_PROVIDERS":
      return {
        ...state,
        [payload.firstPage
          ? "isFetchingProvidersFirstPage"
          : "isFetchingProviders"]: payload.value,
      };
    case "SET_IS_ADDING_PROVIDER":
      return { ...state, isAddingProvider: payload };
    case "SET_IS_UPDATING_PROVIDER":
      return {
        ...state,
        isUpdatingProviderIds: payload.value
          ? [...state.isUpdatingProviderIds, ...payload.ids]
          : state.isUpdatingProviderIds.filter(
              (id) => !payload.ids.includes(id)
            ),
      };
  }
};

export const BulkPaymentProvidersEditionProvider = ({
  pageSize = 50,
  children,
}: {
  pageSize?: number;
  children: ReactNode;
}) => {
  const client = useClient();
  const alertDialog = useAlertDialog();
  const {
    actions: { onFetchFirstPage: onFetchFirstPaymentsPage },
  } = useBulkPaymentDetail();

  const [state, dispatch] = useReducerAlt(BulkPaymentProvidersEditionReducer, {
    providers: [],
    providersCursor: null,
    providersQuery: {
      text: "",
      parsed: null,
    },
    isFetchingProviders: false,
    isFetchingProvidersFirstPage: false,
    isAddingProvider: false,
    isUpdatingProviderIds: [],
  });

  /**
   * API requests
   */
  const onFetchProvidersPage = useCallback(
    async (cursor: string | null) => {
      dispatch("SET_IS_FETCHING_PROVIDERS", {
        firstPage: cursor === null,
        value: true,
      });
      const result = await fetchProviders(
        client,
        pageSize,
        cursor,
        state.providersQuery.parsed
      );
      if (!result.error && result.data) {
        dispatch("ADD_PROVIDERS", {
          providers: result.data.searchProviderInAgenda.providers,
          placement: cursor === null ? "replace" : "end",
        });
        dispatch(
          "SET_PROVIDERS_CURSOR",
          result.data.searchProviderInAgenda.cursor
        );
      }
      dispatch("SET_IS_FETCHING_PROVIDERS", {
        firstPage: cursor === null,
        value: false,
      });
    },
    [client, dispatch, pageSize, state.providersQuery.parsed]
  );

  const onFetchProvidersFirstPage = useCallback(async () => {
    await onFetchProvidersPage(null);
  }, [onFetchProvidersPage]);

  const onFetchProvidersNextPage = useCallback(async () => {
    await onFetchProvidersPage(state.providersCursor);
  }, [onFetchProvidersPage, state.providersCursor]);

  const onChangeProvidersQuery = useCallback(
    (query: string) => {
      const trimmedQuery = query.trim();
      dispatch("SET_PROVIDERS_QUERY", {
        text: trimmedQuery,
        parsed: parseProvidersQuery(trimmedQuery),
      });
    },
    [dispatch]
  );

  const onAddProvider = useCallback(
    async (provider: ProviderInput) => {
      dispatch("SET_IS_ADDING_PROVIDER", true);
      const result = await addProvider(client, provider);
      if (!result.error && result.data) {
        dispatch("ADD_PROVIDERS", {
          providers: [result.data.addProvider],
          placement: "start",
        });
        // We reset the query to avoid conflicts if the new provider
        // doesn't match the current query (there are better ways to do this)
        onChangeProvidersQuery("");
      }
      dispatch("SET_IS_ADDING_PROVIDER", false);
      return !result.error && result.data ? result.data.addProvider : null;
    },
    [client, dispatch, onChangeProvidersQuery]
  );

  const onDeleteProvider = useCallback(
    async (employeeId: string) => {
      const confirmed = await alertDialog({
        title: "¿Querés eliminar la información de este proveedor?",
        message:
          "Si te arrepentís, no te preocupes. Vas a poder volver a cargar sus datos cuando quieras.",
        cancelButton: { text: "No eliminar" },
        confirmButton: { text: "Eliminar" },
      });
      if (confirmed) {
        dispatch("SET_IS_UPDATING_PROVIDER", {
          ids: [employeeId],
          value: true,
        });
        const result = await deleteProvider(client, employeeId);
        if (!result.error) {
          dispatch("DELETE_PROVIDER", employeeId);
        }
        dispatch("SET_IS_UPDATING_PROVIDER", {
          ids: [employeeId],
          value: false,
        });
      }
    },
    [client, dispatch, alertDialog]
  );

  const onEditProvider = useCallback(
    async (providerId: string, data: ProviderEditInput) => {
      dispatch("SET_IS_UPDATING_PROVIDER", {
        ids: [providerId],
        value: true,
      });
      const result = await editProvider(client, providerId, data);
      if (!result.error) {
        const provider = state.providers.find(
          (provider) => provider.id === providerId
        );
        if (provider) {
          dispatch("UPDATE_PROVIDER", {
            ...provider,
            contact: { ...provider.contact, ...data },
          });
          onFetchFirstPaymentsPage();
        }
      }
      dispatch("SET_IS_UPDATING_PROVIDER", {
        ids: [providerId],
        value: false,
      });
    },
    [client, dispatch, onFetchFirstPaymentsPage, state.providers]
  );

  // Fetch the first providers page on mount
  useEffect(() => {
    onFetchProvidersFirstPage();
  }, [onFetchProvidersFirstPage]);

  return (
    <BulkPaymentProvidersEditionContext.Provider
      value={{
        actions: {
          onFetchProvidersFirstPage,
          onFetchProvidersNextPage,
          onAddProvider,
          onDeleteProvider,
          onChangeProvidersQuery,
          onEditProvider,
        },
        data: {
          providers: state.providers,
          providersQuery: state.providersQuery.text,
        },
        state: {
          isFetchingProviders: state.isFetchingProviders,
          isFetchingProvidersFirstPage: state.isFetchingProvidersFirstPage,
          areMoreProvidersPagesAvailable: state.providersCursor !== null,
          isAddingProvider: state.isAddingProvider,
          isUpdatingProviderIds: state.isUpdatingProviderIds,
          isUpdatingProvider: (providerId: string) =>
            state.isUpdatingProviderIds.includes(providerId),
        },
      }}
    >
      {children}
    </BulkPaymentProvidersEditionContext.Provider>
  );
};
