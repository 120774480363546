import { Card, CardBody, Icon, Spinner, Stack, Text } from "@chakra-ui/react";
import { FaSquarePlus } from "react-icons/fa6";

import { useBulkPaymentProvidersCreation } from "modules/dashboard/routes/bulk-payments/use-bulk-payment-providers-creation.hook";

export const BulkPaymentsHistoryNewProviders = () => {
  const {
    onCreateBulkPayment: onCreateBulkPaymentToProviders,
    isLoading: isCreatingBulkPaymentToProviders,
  } = useBulkPaymentProvidersCreation();

  return (
    <Card
      aria-disabled={isCreatingBulkPaymentToProviders}
      onClick={onCreateBulkPaymentToProviders}
      role="button"
      tabIndex={0}
      lineHeight="shorter"
      minH={44}
      rounded="xl"
      shadow="md"
      color="white"
      bgColor="brand.500"
      _hover={{ shadow: "lg" }}
      transition="box-shadow 0.2s"
    >
      <CardBody p={6}>
        <Stack align="center" h="full" justify="center" spacing={4}>
          {isCreatingBulkPaymentToProviders ? (
            <Spinner my={2} size="lg" thickness="4px" />
          ) : (
            <Icon as={FaSquarePlus} boxSize={12} />
          )}
          <Text
            fontSize="xl"
            fontWeight="semibold"
            maxW="14ch"
            textAlign="center"
          >
            Iniciar nuevo pago a proveedores
          </Text>
        </Stack>
      </CardBody>
    </Card>
  );
};
