export type ItemWithDescription = {
  id: number;
  description: string;
};

export enum SocietyStructureNodeType {
  Person = "SocietyStructureNodePerson",
  Company = "SocietyStructureNodeCompany",
}

export type SocietyStructureNodePerson = {
  __typename: SocietyStructureNodeType.Person;
  parentCompanyId: string | null;
  country: ItemWithDescription;
  memberType: ItemWithDescription;
  personDocumentType: ItemWithDescription;
  documentNumber: string;
  firstName: string;
  lastName: string;
  sharesWithDecimals: number | null;
};

export type SocietyStructureNodeCompany = {
  __typename: SocietyStructureNodeType.Company;
  id: string;
  parentCompanyId: string | null;
  name: string;
  taxId: string;
  sharesWithDecimals: number | null;
};

export type SocietyStructureNodeCompanyWithMembers =
  SocietyStructureNodeCompany & {
    members: SocietyStructureNodeWithMembers[];
  };

export type SocietyStructureNodeWithMembers =
  | SocietyStructureNodePerson
  | SocietyStructureNodeCompanyWithMembers;

export type SocietyStructureNode =
  | SocietyStructureNodePerson
  | SocietyStructureNodeCompany;

export type SocietyStructure = {
  members: SocietyStructureNode[];
};

export type CompanySocietyDetails = {
  registrationNumber: string | null;
  registrationDescription: string | null;
  structure: SocietyStructure;
  shareCapital: number | null;
};

export type AccountingBalance = {
  netWorthInCents: number;
  revenueInCents: number;
  fiscalYearEndDate: string;
};

export type CompanyOnboardingAccountingInformation = {
  balances: AccountingBalance[];
};

export type CompanyOnboarding = {
  email: string;
  phoneNumber: string | null;
  status: CompanyOnboardingStatus;
  comments: CompanyOnboardingComment[] | null;
  companyDescription: CompanyDescription;
  docs: CompanyOnboardingDoc[] | null;
  users: CompanyOnboardingUser[] | null;
  userComment: string;
  accountingInformation: CompanyOnboardingAccountingInformation;
  societyDetails: CompanySocietyDetails;
};

export type CompanyOnboardingStatus = {
  status: string;
  message: string | null;
};

export type CompanyOnboardingComment = {
  comment: string;
};

export type CompanyDescription = {
  taxId: string;
  fantasyName: string;
  logo: {
    publicURL: string;
  } | null;
  societyType: ItemWithNameAndDescription | null;
  legalName: string;
  constitutionDay: string | null;
  isReportingParty: boolean;
  address: CompanyAddress | null;
  activityDescription: string;
  ageInBusiness: string;
  hasOtherBanks: boolean;
  taxCondition: TaxCondition | null;
  employeesQuantity: string;
  activity: ItemWithNameAndDescription | null;
  referenceNumber: string;
};

export type CompanyAddress = {
  street: string;
  number: string;
  locality: string;
  provinceISOCode: string;
  zipCode: string;
  floor: string;
  apartment: string;
};

export type CompanyOnboardingDoc = {
  id: string;
  fileName: string;
  typeBySociety: DocumentTypeBySocietyType;
  attachment: {
    publicURL: string;
  } | null;
  skipExplanation: string | null;
};

export type DocumentTypeBySocietyType = {
  documentTypeId: number;
  documentName: string;
  documentDescription: string;
  mandatory: boolean;
};

export type CompanyOnboardingUser = {
  id: string;
  role: ItemWithNameAndDescription;
  name: string;
  lastName: string;
  taxId: string;
  email: string;
  canAcceptTermsAndConditions: boolean;
  powerOfLaw: {
    id: string;
    publicURL: string;
  };
};

export type TaxCondition = {
  id: string;
  bankCoreId: string;
  description: string;
};

export type ItemWithNameAndDescription = {
  id: number;
  name: string;
  description: string;
};
