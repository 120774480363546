import {
  Button,
  HStack,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
} from "@chakra-ui/react";
import { BiSelectMultiple } from "react-icons/bi";
import { FiChevronDown, FiPlus, FiTrash2 } from "react-icons/fi";

import { useBulkPaymentsPayrollEditor } from "./payroll-editor.context";

import { useBulkPaymentsPayroll } from "modules/dashboard/routes/bulk-payments/bulk-payments-payroll/bulk-payments-payroll.context";

export const BulkPaymentsPayrollEditorHeader = () => {
  const {
    data: { employeeCount },
    actions: { onBulkDeleteEmployees },
  } = useBulkPaymentsPayroll();
  const {
    selectedEmployees: { ids: selectedEmployeeIds },
    addEmployeeForm: {
      onToggle: onToggleAddEmployeeForm,
      isVisible: isAddEmployeeFormVisible,
    },
  } = useBulkPaymentsPayrollEditor();

  return (
    <HStack align="center" spacing={4}>
      <Heading as="h2" fontSize="2xl" my={1.5}>
        Nómina de personas empleadas
      </Heading>
      <Spacer />
      {selectedEmployeeIds.length > 0 && (
        <Menu>
          <MenuButton
            as={Button}
            leftIcon={<BiSelectMultiple />}
            rightIcon={<FiChevronDown />}
            rounded="full"
            variant="outline"
          >
            {selectedEmployeeIds.length}{" "}
            {selectedEmployeeIds.length > 1 ? "seleccionadas" : "seleccionada"}
          </MenuButton>
          <MenuList>
            <MenuItem
              icon={<FiTrash2 />}
              onClick={() => onBulkDeleteEmployees(selectedEmployeeIds)}
            >
              {selectedEmployeeIds.length > 1 ? "Eliminar todas" : "Eliminar"}
            </MenuItem>
          </MenuList>
        </Menu>
      )}
      {employeeCount > 0 && !isAddEmployeeFormVisible && (
        <Button
          leftIcon={<FiPlus />}
          onClick={onToggleAddEmployeeForm}
          rounded="full"
        >
          Agregar
        </Button>
      )}
    </HStack>
  );
};
