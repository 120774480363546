import {
  Checkbox,
  Flex,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Td,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import {
  KeyboardEvent,
  RefObject,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import CurrencyInput from "react-currency-input-field";
import { FiMoreVertical, FiTrash2 } from "react-icons/fi";

import { useBulkPaymentsPayroll } from "modules/dashboard/routes/bulk-payments/bulk-payments-payroll/bulk-payments-payroll.context";
import { Employee } from "modules/dashboard/routes/bulk-payments/bulk-payments-salaries.api";
import { PropsWithRest } from "types/react-utils";
import { applyDecimalScale, parseNumber } from "utils/number.utils";
import { formatTaxId } from "utils/tax-id.utils";

export const EmployeesTableRow = forwardRef(
  (
    {
      employee,
      isSelected,
      onToggleSelection,
      onFocusPreviousRow = () => {},
      onFocusNextRow = () => {},
      ...rest
    }: PropsWithRest<
      {
        employee: Employee;
        isSelected: boolean;
        onToggleSelection: () => void;
        onFocusPreviousRow?: () => void;
        onFocusNextRow?: () => void;
      },
      typeof Tr
    >,
    salaryInputRef: RefObject<HTMLInputElement>
  ) => {
    const {
      actions: { onUpdateEmployeeSalary, onDeleteEmployee },
      state: { isUpdating: isUpdatingById },
    } = useBulkPaymentsPayroll();

    const isUpdating = useMemo(
      () => isUpdatingById(employee.id),
      [isUpdatingById, employee.id]
    );

    const [localSalaryAmount, setLocalSalaryAmount] = useState("");
    const [shouldSubmit, setShouldSubmit] = useState(false);

    const reset = useCallback(() => {
      setLocalSalaryAmount(
        applyDecimalScale(employee.salary.amountInCents, 2).join(",")
      );
    }, [employee]);

    const onSalaryInputKeyDown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        switch (event.key) {
          case "ArrowUp":
            onFocusPreviousRow();
            break;
          case "ArrowDown":
          case "Enter":
            onFocusNextRow();
            break;
          case "Escape":
            reset();
            break;
        }
      },
      [onFocusNextRow, onFocusPreviousRow, reset]
    );

    const onSubmitChanges = useCallback(async () => {
      const amountInCents = parseNumber(localSalaryAmount, { decimalScale: 2 });
      if (amountInCents !== employee.salary.amountInCents) {
        const successful = await onUpdateEmployeeSalary(employee.id, {
          amountInCents,
          currency: employee.salary.currency,
        });
        if (!successful) {
          reset();
        }
      }
    }, [employee, localSalaryAmount, onUpdateEmployeeSalary, reset]);

    useEffect(() => {
      reset();
    }, [reset]);

    useEffect(() => {
      if (shouldSubmit) {
        onSubmitChanges();
        setShouldSubmit(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldSubmit]);

    return (
      <Tr
        {...rest}
        data-employee-id={employee.id}
        data-group="employees-table-row"
        position="relative"
      >
        <Td
          cursor="pointer"
          onClick={onToggleSelection}
          pl={4}
          _before={{
            bg: isSelected ? "brand.50" : "transparent",
            bottom: 1,
            content: '""',
            left: -2,
            top: 1,
            right: -2,
            position: "absolute",
            borderRadius: 10,
            transition: "background-color 0.2s",
            pointerEvents: "none",
          }}
        >
          <Checkbox
            borderColor="blackAlpha.300"
            isChecked={isSelected}
            onChange={onToggleSelection}
            verticalAlign="middle"
          />
        </Td>
        <Td>
          <Flex position="relative">
            {employee.firstName} {employee.lastName}
          </Flex>
        </Td>
        <Td>
          <Flex position="relative">{formatTaxId(employee.contact.taxId)}</Flex>
        </Td>
        <Td>
          <Flex position="relative">{employee.contact.cbu}</Flex>
        </Td>
        <Td isNumeric px={4} w={250}>
          <Input
            ref={salaryInputRef}
            allowNegativeValue={false}
            as={CurrencyInput}
            decimalScale={2}
            decimalsLimit={2}
            fontWeight="semibold"
            intlConfig={{ locale: "es-AR", currency: employee.salary.currency }}
            isDisabled={isUpdating}
            onBlur={() => setShouldSubmit(true)}
            onKeyDown={onSalaryInputKeyDown}
            placeholder="$ 0,00"
            rounded="full"
            textAlign="right"
            value={localSalaryAmount}
            w="full"
            css={{
              fontVariantNumeric: "tabular-nums",
            }}
            onValueChange={(value) => {
              setLocalSalaryAmount(value);
            }}
          />
        </Td>
        <Td p={0} px={4}>
          <HStack spacing={4}>
            <Menu>
              <Tooltip label="Acciones">
                <MenuButton
                  aria-label="Acciones"
                  as={IconButton}
                  icon={<FiMoreVertical />}
                  isLoading={isUpdating}
                  rounded="full"
                  variant="ghost"
                />
              </Tooltip>
              <Portal>
                <MenuList>
                  <MenuItem
                    icon={<FiTrash2 />}
                    onClick={() => onDeleteEmployee(employee.id)}
                  >
                    Eliminar
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          </HStack>
        </Td>
      </Tr>
    );
  }
);
