import {
  Box,
  Center,
  Flex,
  HStack,
  Link,
  PropsOf,
  Spacer,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { PropsWithChildren, ReactNode } from "react";

import { Logo } from "components/logo";
import { OnboardingStepper } from "modules/onboarding/components/onboarding-stepper";
import { useOnboarding } from "modules/onboarding/onboarding.context";
import { PropsWithRest } from "types/react-utils";

const OnboardingActionBar = ({
  children,
  ...rest
}: PropsWithRest<PropsWithChildren<{}>, typeof Box>) => {
  return (
    <Box
      backdropFilter="auto"
      bg="whiteAlpha.200"
      {...rest}
      backdropBlur="md"
      borderColor="blackAlpha.200"
      borderTopWidth={1}
      px={10}
      py={6}
      shadow="lg"
    >
      {children}
    </Box>
  );
};

export const OnboardingPage = ({
  isLoading,
  children,
  actionBarContent,
  activeStep,
  ...rest
}: {
  isLoading?: boolean;
  actionBarContent?: ReactNode;
  activeStep?: number;
} & PropsOf<typeof Flex>) => {
  const {
    state: { isLoading: isContextLoading, isSavingData, isAccepted },
  } = useOnboarding();

  let content: ReactNode;
  if (isContextLoading || isLoading) {
    content = (
      <Center flexGrow={1}>
        <Spinner color="brand.500" size="xl" thickness="5px" />
      </Center>
    );
  } else {
    content = (
      <>
        <Flex
          align="center"
          direction="column"
          flexGrow={1}
          h="full"
          justify="center"
          p={8}
          position="relative"
        >
          {isSavingData && (
            <Flex
              align="center"
              bg="brand.50"
              color="brand.500"
              fontWeight="semibold"
              position="fixed"
              px={4}
              py={2}
              right={4}
              rounded="md"
              top={4}
            >
              <Spinner color="brand.500" mr={3} size="sm" />
              Guardando...
            </Flex>
          )}
          {!isAccepted && activeStep !== undefined ? (
            <OnboardingStepper activeStep={activeStep} />
          ) : null}
          <Spacer />
          {children}
          <Spacer />
        </Flex>

        <HStack
          align="center"
          color="gray.500"
          flexShrink={0}
          p={8}
          spacing={4}
        >
          <Text fontSize={{ base: "xs", md: "sm" }} maxW="30ch">
            ¿Necesitás ayuda? Consultá nuestras{" "}
            <Link className="link" color="brand.500">
              preguntas frecuentes
            </Link>{" "}
            o{" "}
            <Link className="link" color="brand.500">
              video tutoriales
            </Link>
            .
          </Text>

          <Spacer />

          <Box>
            <Logo />
          </Box>
        </HStack>
      </>
    );
  }

  return (
    <Flex direction="column" flexGrow={1} minH="100vh" {...rest}>
      {content}

      {!isAccepted && actionBarContent && (
        <OnboardingActionBar bottom={0} flexShrink={0} position="sticky">
          {actionBarContent}
        </OnboardingActionBar>
      )}
    </Flex>
  );
};
