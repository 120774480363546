import { Box, Button, HStack, Input, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useRef, useState } from "react";

import { useBulkPaymentsPayroll } from "modules/dashboard/routes/bulk-payments/bulk-payments-payroll/bulk-payments-payroll.context";
import {
  getHumanReadableDateTextsForScheduledPayments,
  isImmediatePayment,
} from "modules/dashboard/routes/bulk-payments/bulk-payments.utils";

export const PayrollDateScheduler = () => {
  const {
    actions: { onUpdateScheduledFor },
    data,
  } = useBulkPaymentsPayroll();
  const dateInputRef = useRef<HTMLInputElement>(null);

  const [scheduledDate, setScheduleDate] = useState(
    data.scheduledFor ? dayjs.utc(data.scheduledFor).format() : ""
  );

  const minimumDate = dayjs.utc().add(1, "day").format("YYYY-MM-DD");
  const isImmediate = isImmediatePayment(scheduledDate);

  const { title, subtitle, editDateButton, todayButton } =
    getHumanReadableDateTextsForScheduledPayments(scheduledDate);

  return (
    <HStack justifyContent="space-between">
      <Box>
        <Text fontSize="3xl" fontWeight="bold">
          {title}
        </Text>
        <Text color="gray.500" fontSize="lg" fontWeight="semibold">
          {subtitle}
        </Text>
      </Box>
      <HStack flexWrap="wrap" gap={2}>
        {!isImmediate && (
          <Button
            _hover={{ bg: "brand.500", color: "white" }}
            // eslint-disable-next-line react/jsx-sort-props
            _active={{ bg: "brand.600", color: "white" }}
            bg="white"
            color="brand.500"
            border="1px"
            borderColor="brand.500"
            rounded="full"
            size="lg"
            mr={2}
            onClick={() => {
              onUpdateScheduledFor(null);
              setScheduleDate("");
            }}
          >
            {todayButton}
          </Button>
        )}
        <Button
          _hover={{ bg: "brand.500", color: "white" }}
          // eslint-disable-next-line react/jsx-sort-props
          _active={{ bg: "brand.600", color: "white" }}
          bg="brand.50"
          color="brand.500"
          rounded="full"
          size="lg"
          onClick={() => {
            dateInputRef.current?.showPicker();
          }}
        >
          <Input
            ref={dateInputRef}
            type="date"
            min={minimumDate}
            position="absolute"
            visibility="hidden"
            value={scheduledDate}
            onChange={(event) => {
              const newDate = dayjs.utc(event.target.value).format();
              setScheduleDate(newDate);
              onUpdateScheduledFor(newDate);
            }}
          />
          {editDateButton}
        </Button>
      </HStack>
    </HStack>
  );
};
