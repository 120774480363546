import {
  Button,
  Circle,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  PropsOf,
  Spacer,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { useCallback, useRef } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FaUsers } from "react-icons/fa";

import { CuitInput } from "components/cuit-input";
import { useBulkPaymentProvidersEdition } from "modules/dashboard/routes/bulk-payments/bulk-payment-providers-edition/bulk-payment-providers-edition.context";
import { isValidCbu, isValidCbuAlias } from "utils/cbu.utils";
import { formatTaxId, isValidTaxId } from "utils/tax-id.utils";

type AddProviderFields = {
  name: string;
  taxId: string;
  cbuOrAlias: string;
  note: string;
};

export const AddProviderDrawer = ({
  finalFocusRef,
  isOpen,
  onClose,
}: {
  finalFocusRef?: PropsOf<typeof Drawer>["finalFocusRef"];
  isOpen: boolean;
  onClose: () => void;
}) => {
  const {
    state: { isAddingProvider },
    actions: { onAddProvider },
  } = useBulkPaymentProvidersEdition();

  const firstFieldRef = useRef<HTMLInputElement | null>(null);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<AddProviderFields>({
    mode: "onTouched",
    defaultValues: {
      name: "",
      taxId: "",
      cbuOrAlias: "",
      note: "",
    },
  });

  const { ref: nameFieldRef, ...nameFieldProps } = register("name", {
    required: "Campo requerido",
  });

  const onSubmit: SubmitHandler<AddProviderFields> = useCallback(
    async (values) => {
      const cbuOrAlias = isValidCbu(values.cbuOrAlias)
        ? { cbu: values.cbuOrAlias }
        : { alias: values.cbuOrAlias };

      const addedProvider = await onAddProvider({
        name: values.name,
        taxId: formatTaxId(values.taxId, { withDashes: false }),
        ...cbuOrAlias,
        note: values.note || undefined,
      });
      if (addedProvider !== null) {
        onClose();
        reset();
      }
    },
    [onAddProvider, onClose, reset]
  );

  return (
    <Drawer
      finalFocusRef={finalFocusRef}
      isOpen={isOpen}
      onClose={onClose}
      placement="right"
      size="sm"
      initialFocusRef={firstFieldRef}
    >
      <DrawerOverlay />

      <DrawerContent>
        <DrawerCloseButton left={2} />
        <DrawerBody>
          <Stack
            as="form"
            h="full"
            onSubmit={handleSubmit(onSubmit)}
            spacing={6}
          >
            <Stack align="center" pt={4} spacing={8} textAlign="center">
              <Heading fontSize="2xl">Agendá a un proveedor</Heading>
              <Circle bg="brand.50" size={28}>
                <Icon as={FaUsers} color="brand.500" fontSize="6xl" />
              </Circle>
            </Stack>

            <Stack spacing={2}>
              <FormControl isInvalid={errors?.name !== undefined}>
                <FormLabel>Nombre</FormLabel>
                <Input
                  ref={(e) => {
                    nameFieldRef(e);
                    firstFieldRef.current = e;
                  }}
                  isDisabled={isAddingProvider}
                  {...nameFieldProps}
                />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors?.taxId !== undefined}>
                <FormLabel>CUIT / CUIL</FormLabel>
                <Controller
                  control={control}
                  name="taxId"
                  render={({ field: { onChange, ...rest } }) => (
                    <Input
                      as={CuitInput}
                      isDisabled={isAddingProvider}
                      {...rest}
                      onValueChange={onChange}
                      placeholder="00-00000000-0"
                    />
                  )}
                  rules={{
                    required: "Campo requerido",
                    validate: (value) =>
                      isValidTaxId(value) || "CUIT incorrecto",
                  }}
                />
                <FormErrorMessage>{errors.taxId?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors?.cbuOrAlias !== undefined}>
                <FormLabel>CBU / CVU / Alias</FormLabel>
                <Input
                  isDisabled={isAddingProvider}
                  {...register("cbuOrAlias", {
                    required: "Campo requerido",
                    validate: (value) =>
                      isValidCbu(value) ||
                      isValidCbuAlias(value) ||
                      "CBU, CVU o alias incorrecto",
                  })}
                />
                <FormErrorMessage>
                  {errors.cbuOrAlias?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors?.note !== undefined}>
                <FormLabel>Descripción</FormLabel>
                <Textarea
                  isDisabled={isAddingProvider}
                  placeholder="Opcional"
                  {...register("note")}
                />
                <FormErrorMessage>{errors.note?.message}</FormErrorMessage>
              </FormControl>
            </Stack>

            <Spacer />

            <Stack alignItems="stretch" pb={6} spacing={3}>
              <Button isLoading={isAddingProvider} rounded="full" type="submit">
                Agendar proveedor
              </Button>
              <Button mr={3} onClick={onClose} rounded="full" variant="subtle">
                Cancelar
              </Button>
            </Stack>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
