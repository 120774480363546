import {
  Box,
  Step,
  StepDescription,
  StepIndicator,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
} from "@chakra-ui/react";
import { ReactNode, useMemo } from "react";
import { IconType } from "react-icons";
import { FiCheck, FiClock, FiEdit3, FiSettings, FiX } from "react-icons/fi";
import { MdOutlineFrontHand } from "react-icons/md";

import {
  BulkPaymentStatus,
  BulkPaymentSummary,
} from "modules/dashboard/routes/bulk-payments/bulk-payments.api";
import { bulkPaymentStatusColorScheme } from "modules/dashboard/routes/bulk-payments/bulk-payments.utils";
import { formatDate } from "utils/date.utils";

const FormattedDate = ({ date }: { date: string }) => (
  <>
    el{" "}
    <Text as="span" fontWeight="bold">
      {formatDate(date, "D [de] MMMM [de] YYYY")}
    </Text>{" "}
    a las{" "}
    <Text as="span" fontWeight="bold">
      {formatDate(date, "HH:mm")}
    </Text>{" "}
    h
  </>
);

export const BulkPaymentStatusStepper = ({
  bulkPayment,
}: {
  bulkPayment: BulkPaymentSummary;
}) => {
  const { steps, activeStep } = useMemo(() => {
    const steps: {
      title: string;
      description: ReactNode;
      icon?: IconType;
    }[] = [
      {
        title: "Creado",
        description: (
          <>
            <FormattedDate date={bulkPayment.createdAt} /> por{" "}
            <Text as="span" color="brand.500" fontWeight="bold">
              {bulkPayment.operatedBy.name}
            </Text>
          </>
        ),
        icon: FiCheck,
      },
    ];

    switch (bulkPayment.status) {
      case BulkPaymentStatus.Created:
        steps.push({
          title: "Borrador",
          description: "Aún no se ha enviado para su aprobación",
          icon: FiEdit3,
        });
        break;
      case BulkPaymentStatus.Cancelled:
        steps.push({
          title: "Cancelado",
          description: null,
          icon: FiX,
        });
        break;
      case BulkPaymentStatus.PendingApproval:
        steps.push({
          title: "Esperando aprobación",
          description: "Se necesita la firma de un aprobador",
          icon: FiClock,
        });
        break;
      case BulkPaymentStatus.Rejected:
        steps.push({
          title: "Rechazado",
          description: bulkPayment.reviewedAt ? (
            <FormattedDate date={bulkPayment.reviewedAt} />
          ) : null,
          icon: MdOutlineFrontHand,
        });
        break;
      case BulkPaymentStatus.Scheduled:
      case BulkPaymentStatus.InProcess:
      case BulkPaymentStatus.Finished:
        steps.push({
          title: "Aprobado",
          description: bulkPayment.reviewedAt ? (
            <FormattedDate date={bulkPayment.reviewedAt} />
          ) : null,
          icon: FiCheck,
        });
        break;
    }

    switch (bulkPayment.status) {
      case BulkPaymentStatus.Created:
      case BulkPaymentStatus.Cancelled:
      case BulkPaymentStatus.Rejected:
        break;
      case BulkPaymentStatus.PendingApproval:
      case BulkPaymentStatus.Scheduled:
        steps.push({
          title: "A ejecutar",
          description:
            "Una vez aprobado el pago se ejecutará en la fecha indicada",
          icon: FiClock,
        });
        break;
      case BulkPaymentStatus.InProcess:
        steps.push({
          title: "En ejecución",
          description: "El pago se está ejecutando",
          icon: FiSettings,
        });
        break;
      case BulkPaymentStatus.Finished: {
        const description = bulkPayment.executedAt ? (
          <FormattedDate date={bulkPayment.executedAt} />
        ) : null;
        const errorCount = bulkPayment.paymentsMetadata?.failed?.count ?? 0;
        if (errorCount > 0) {
          steps.push({
            title: `Procesado con ${errorCount} error${
              errorCount! > 1 ? "es" : ""
            }`,
            description,
            icon: FiX,
          });
        } else {
          steps.push({
            title: "Procesado",
            description,
            icon: FiCheck,
          });
        }
        break;
      }
    }

    return {
      steps,
      activeStep: [BulkPaymentStatus.Finished].includes(bulkPayment.status)
        ? 3
        : [
            BulkPaymentStatus.Scheduled,
            BulkPaymentStatus.InProcess,
            BulkPaymentStatus.Cancelled,
            BulkPaymentStatus.Rejected,
          ].includes(bulkPayment.status)
        ? 2
        : 1,
    };
  }, [
    bulkPayment.status,
    bulkPayment.createdAt,
    bulkPayment.reviewedAt,
    bulkPayment.executedAt,
    bulkPayment.operatedBy.name,
    bulkPayment.paymentsMetadata,
  ]);

  const hasErrors = useMemo(
    () => (bulkPayment.paymentsMetadata?.failed?.count ?? 0) > 0,
    [bulkPayment.paymentsMetadata?.failed]
  );

  return (
    <Stepper
      index={activeStep}
      orientation="vertical"
      gap={0}
      colorScheme={bulkPaymentStatusColorScheme(bulkPayment.status, hasErrors)}
    >
      {steps.map((step, index) => (
        <Step key={index}>
          <StepIndicator mr={1}>
            <StepStatus complete={step.icon} active={step.icon} />
          </StepIndicator>

          <Box
            flexShrink={0}
            minH={12}
            mt={step.description ? 0 : 1}
            lineHeight="short"
          >
            <StepTitle>
              <Text as="span" fontWeight="semibold">
                {step.title}
              </Text>
            </StepTitle>
            <StepDescription>
              <Text as="span" color="muted">
                {step.description}
              </Text>
            </StepDescription>
          </Box>

          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  );
};
