import { ChakraProvider } from "@chakra-ui/react";
import dayjs from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";
import utc from "dayjs/plugin/utc";
import React from "react";
import ReactDOM from "react-dom/client";

import { CompaniesApp } from "app";
import { theme } from "app.theme";

import "global.scss";

// Dayjs configuration
import "dayjs/locale/es";
dayjs.locale("es");
dayjs.extend(utc);
dayjs.extend(dayOfYear);

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <ChakraProvider
      theme={theme}
      toastOptions={{ defaultOptions: { position: "top", isClosable: true } }}
    >
      <CompaniesApp />
    </ChakraProvider>
  </React.StrictMode>
);
