import { gql } from "urql";

export const OnboardingUsers = gql`
  fragment OnboardingUsers on CompanyOnboardingPublicSummary {
    users {
      id
      role {
        id
        name
        description
      }
      name
      lastName
      taxId
      email
      canAcceptTermsAndConditions
      powerOfLaw {
        id
        publicURL
      }
    }
  }
`;

export const OnboardingDocs = gql`
  fragment OnboardingDocs on CompanyOnboardingPublicSummary {
    docs {
      id
      fileName
      typeBySociety {
        documentTypeId
        documentName
        documentDescription
        mandatory
      }
      attachment {
        publicURL
      }
      skipExplanation
    }
  }
`;

export const OnboardingAccountingBalance = gql`
  fragment OnboardingAccountingBalance on AccountingInformation {
    balances {
      netWorthInCents
      revenueInCents
      fiscalYearEndDate
    }
  }
`;

export const CompanySocietyDetailsFields = gql`
  fragment CompanySocietyDetailsFields on SocietyDetails {
    registrationNumber
    registrationDescription
    shareCapital
    structure {
      members {
        __typename
        ... on SocietyStructureNodePerson {
          parentCompanyId
          country {
            id
            description
          }
          memberType {
            id
            description
          }
          personDocumentType {
            id
            description
          }
          documentNumber
          firstName
          lastName
          sharesWithDecimals
        }
        ... on SocietyStructureNodeCompany {
          id
          parentCompanyId
          name
          taxId
          sharesWithDecimals
        }
      }
    }
  }
`;

export const OnboardingSummary = gql`fragment OnboardingSummary on CompanyOnboardingPublicSummary {
  phoneNumber
  email
  status {
    status
    message
  }
  comments {
    comment
  }
  companyDescription {
    taxId
    fantasyName
    logo {
      publicURL
    }
    societyType {
      id
      name
      description
    }
    legalName
    constitutionDay
    isReportingParty
    address {
      street
      number
      locality
      provinceISOCode
      zipCode
      floor
      apartment
    }
    activityDescription
    ageInBusiness
    hasOtherBanks
    taxCondition {
      id
      bankCoreId
      description
    }
    employeesQuantity
    activity {
      id
      name
      description
    }
    referenceNumber
  }
  accountingInformation {
    ...OnboardingAccountingBalance
  }
  societyDetails {
    ...CompanySocietyDetailsFields
  }
  userComment
  ...OnboardingUsers
  ...OnboardingDocs
  ${OnboardingUsers}
  ${OnboardingDocs}
  ${OnboardingAccountingBalance}
  ${CompanySocietyDetailsFields}
}`;
