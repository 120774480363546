import { Button, IconButton } from "@chakra-ui/button";
import { useBreakpointValue } from "@chakra-ui/media-query";
import { Tooltip } from "@chakra-ui/tooltip";
import { ForwardedRef, forwardRef } from "react";

import { ComponentPropsOmitting, PropsWithRest } from "types/react-utils";

export const ResponsiveButton = forwardRef(
  (
    {
      breakpoint,
      label,
      tooltipLabel = label,
      icon,
      iconPosition = "left",
      rounded,
      isRound,
      ...rest
    }: PropsWithRest<
      {
        breakpoint: string;
        label: string;
        tooltipLabel?: string | null;
        iconPosition?: "left" | "right" | "none";
      },
      ComponentPropsOmitting<typeof IconButton, "aria-label">
    >,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const isFullButton = useBreakpointValue({
      base: false,
      [breakpoint]: true,
    });

    if (isFullButton) {
      return (
        <Button
          ref={ref}
          rounded={isRound ? "full" : rounded}
          leftIcon={iconPosition === "left" ? icon : undefined}
          rightIcon={iconPosition === "right" ? icon : undefined}
          {...rest}
        >
          {label}
        </Button>
      );
    } else {
      return (
        <Tooltip label={tooltipLabel}>
          <IconButton
            ref={ref}
            aria-label={label}
            isRound={isRound}
            rounded={rounded}
            icon={icon}
            {...rest}
          />
        </Tooltip>
      );
    }
  }
);
