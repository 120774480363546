import { Client, gql } from "urql";

import { Contact, ContactFields } from "./bulk-payments.api";

import { AmountWithCurrency } from "types/api-global.types";

export type Provider = {
  id: string;
  contact: Contact;
  note: string | null;
};

export type ProvidersPage = {
  providers: Provider[];
  cursor: string | null;
};

export type ProviderInput = {
  name: string;
  taxId: string;
  note?: string;
} & ({ cbu: string } | { alias: string });

export type ProviderEditInput = {
  name: string;
};

export type ProvidersQuery =
  | { name: string }
  | { taxId: string }
  | { cbu: string };

export type ContactQuery =
  | { cbu: string }
  | {
      alias: string;
    };

export type NetworkContact = {
  alias: string;
  cbu: string;
  holders: AccountHolder[];
};

export type AccountHolder = {
  fullName: string;
  taxId: string;
};

export const ProviderFields = gql`
  fragment ProviderFields on Provider {
    id
    contact {
      ...ContactFields
    }
    note
  }
  ${ContactFields}
`;

export const createProvidersBulkPayment = async (client: Client) => {
  return client.mutation<{
    createEmptyBulkPaymentToProviders: { id: string };
  }>(
    gql`
      mutation CreateProvidersBulkPayment {
        createEmptyBulkPaymentToProviders {
          id
        }
      }
    `,
    {}
  );
};

export const fetchProviders = async (
  client: Client,
  pageSize: number,
  cursor: string | null,
  query: ProvidersQuery | null = null
) => {
  return client.query<
    { searchProviderInAgenda: ProvidersPage },
    {
      cursor: string | null;
      pageSize: number;
      name?: string;
      taxId?: string;
      cbu?: string;
    }
  >(
    gql`
      query FetchProvidersPage(
        $cursor: String
        $pageSize: Int
        $name: String
        $taxId: String
        $cbu: String
      ) {
        searchProviderInAgenda(
          in: {
            pagination: { cursor: $cursor, pageSize: $pageSize }
            name: $name
            taxId: $taxId
            cbu: $cbu
          }
        ) {
          providers {
            ...ProviderFields
          }
          cursor
        }
      }
      ${ProviderFields}
    `,
    {
      cursor,
      pageSize,
      ...query,
    }
  );
};

export const lookupProviderContact = async (
  client: Client,
  query: ContactQuery
) =>
  client.query<
    {
      lookupProviderContact:
        | {
            contact: Contact;
            networkContact: null;
          }
        | {
            contact: null;
            networkContact: NetworkContact | null;
          };
    },
    {
      cbu?: string;
      alias?: string;
    }
  >(
    gql`
      query LookupProviderContact($cbu: String, $alias: String) {
        lookupProviderContact(cbu: $cbu, alias: $alias) {
          contact {
            ...ContactFields
          }
          networkContact {
            alias
            cbu
            holders {
              fullName
              taxId
            }
          }
        }
      }
      ${ContactFields}
    `,
    {
      ...query,
    }
  );

export const addProvider = async (client: Client, provider: ProviderInput) => {
  return client.mutation<
    { addProvider: Provider },
    { provider: ProviderInput }
  >(
    gql`
      mutation AddProvider($provider: ProviderInput!) {
        addProvider(in: $provider) {
          ...ProviderFields
        }
      }
      ${ProviderFields}
    `,
    {
      provider,
    }
  );
};

export const deleteProvider = async (client: Client, providerId: string) => {
  return client.mutation<{ deleteProvider: null }, { providerId: string }>(
    gql`
      mutation DeleteProvider($providerId: ID!) {
        deleteProvider(providerId: $providerId)
      }
    `,
    { providerId }
  );
};

export const editProvider = async (
  client: Client,
  providerId: string,
  data: ProviderEditInput
) => {
  return client.mutation<
    { editProvider: null },
    { providerId: string; data: ProviderEditInput }
  >(
    gql`
      mutation EditProvider($providerId: ID!, $data: ProviderEditInput!) {
        editProvider(providerId: $providerId, in: $data)
      }
    `,
    { providerId, data }
  );
};

export const addPaymentToProvider = async (
  client: Client,
  bulkPaymentId: string,
  contactId: string,
  paymentData: {
    amount: AmountWithCurrency;
    description: string;
  }
) => {
  return client.mutation<
    { addPaymentToProvider: { id: string } },
    {
      bulkPaymentId: string;
      contactId: string;
      amount: AmountWithCurrency;
      description: string;
    }
  >(
    gql`
      mutation AddPaymentToProvider(
        $bulkPaymentId: ID!
        $contactId: ID!
        $amount: AmountWithCurrencyInput!
        $description: String!
      ) {
        addPaymentToProvider(
          in: {
            bulkPaymentId: $bulkPaymentId
            contactId: $contactId
            amount: $amount
            description: $description
          }
        ) {
          id
        }
      }
    `,
    {
      bulkPaymentId,
      contactId,
      ...paymentData,
    }
  );
};

export const updatePayment = async (
  client: Client,
  paymentId: string,
  bulkPaymentId: string,
  paymentData: {
    amount: AmountWithCurrency;
    description?: string;
  }
) => {
  return client.mutation<
    { updatePayment: null },
    {
      paymentId: string;
      bulkPaymentId: string;
      amount: AmountWithCurrency;
      description?: string;
    }
  >(
    gql`
      mutation UpdatePayment(
        $paymentId: ID!
        $bulkPaymentId: ID!
        $amount: AmountWithCurrencyInput!
        $description: String
      ) {
        updatePayment(
          in: {
            paymentId: $paymentId
            bulkPaymentId: $bulkPaymentId
            amount: $amount
            description: $description
          }
        )
      }
    `,
    {
      paymentId,
      bulkPaymentId,
      ...paymentData,
    }
  );
};

export const deletePayment = async (
  client: Client,
  paymentId: string,
  bulkPaymentId: string
) => {
  return client.mutation<
    { deletePayment: null },
    { paymentId: string; bulkPaymentId: string }
  >(
    gql`
      mutation DeletePayment($paymentId: ID!, $bulkPaymentId: ID!) {
        deletePayment(
          in: { paymentId: $paymentId, bulkPaymentId: $bulkPaymentId }
        )
      }
    `,
    {
      paymentId,
      bulkPaymentId,
    }
  );
};

export const finishBulkPayment = async (
  client: Client,
  bulkPaymentId: string,
  scheduledFor: string | null,
  context?: { noErrorToast?: boolean }
) => {
  return client.mutation<
    { finishBulkPayment: null },
    {
      in: { bulkPaymentId: string; scheduledFor: string | null };
    }
  >(
    gql`
      mutation FinishBulkPayment($in: FinishBulkPaymentInput!) {
        finishBulkPayment(in: $in)
      }
    `,
    {
      in: {
        bulkPaymentId,
        scheduledFor,
      },
    },
    context
  );
};
