import { Card, CardBody, Center, Flex, Stack } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

import dashboardBg from "assets/backgrounds/dashboard.png";
import { ReactComponent as LogoSvg } from "assets/logos/logo-brubank.svg";

export const LoginLayout = () => {
  return (
    <Center
      bgAttachment="fixed"
      bgImage={dashboardBg}
      bgPos="center"
      bgSize="200% 200%"
      minH="100svh"
    >
      <Card mx={3} rounded="2xl" shadow="xl">
        <CardBody p={12}>
          <Stack align="center" maxW="full" spacing={12} w={80}>
            <Flex alignSelf="center" fill="brand.500">
              <LogoSvg width="12rem" />
            </Flex>
            <Outlet />
          </Stack>
        </CardBody>
      </Card>
    </Center>
  );
};
