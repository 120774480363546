import { RouteObject } from "react-router-dom";

import { Login } from "./login";
import { RequestLogin } from "./request-login";

import { LoginLayout } from "modules/dashboard/components/login-layout";

export const loginRoutes: RouteObject[] = [
  {
    path: "login",
    element: <LoginLayout />,
    children: [
      {
        index: true,
        element: <RequestLogin />,
      },
      {
        path: ":token",
        element: <Login />,
      },
    ],
  },
];
