import { dashboardRoutes } from "./routes/dashboard.routes";

import type { AppModule } from "modules/modules.types";

export const DashboardModule: AppModule = {
  routes: dashboardRoutes,
  cacheKeys: {
    AmountWithCurrency: () => null,
    Payroll: () => "singleton",
    EmployeesPage: () => null,
    ProvidersPage: () => null,
    PaymentsPage: () => null,
    PaymentsMetadata: () => null,
    BulkPaymentsPage: () => null,
    LookupContactResponse: () => null,
    NetworkContact: () => null,
    Holder: () => null,
  },
};
