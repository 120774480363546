import { Divider, HStack, Heading } from "@chakra-ui/react";

import { ActionButton, ActionButtonProps } from "./action-button";

import { useOnboarding } from "modules/onboarding/onboarding.context";

export type SectionHeaderProps = {
  title: string;
  description?: string;
  button?: ActionButtonProps;
};

export const SectionHeader = ({ title, button }: SectionHeaderProps) => {
  const {
    state: { isAccepted },
  } = useOnboarding();

  return (
    <HStack
      align="center"
      justify="space-between"
      spacing={{ base: 4, md: 10 }}
    >
      <Heading flexShrink={0} size="md" paddingTop={3} maxW="fit-content">
        {title}
      </Heading>
      <Divider />
      {button && !isAccepted && <ActionButton {...button} />}
    </HStack>
  );
};
