import { Icon } from "@chakra-ui/icon";
import { Flex, Text, Stack, Center } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Spinner } from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import { useDropzone } from "react-dropzone";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { FiUpload } from "react-icons/fi";
import { RiCheckboxCircleFill, RiErrorWarningFill } from "react-icons/ri";

import { DocumentTypeBySocietyType } from "modules/onboarding/onboarding.types";

export const UploadDocumentModal = ({
  documentType,
  isOpen,
  onClose,
  onUpload,
}: {
  documentType: DocumentTypeBySocietyType | null;
  isOpen: boolean;
  onClose: () => void;
  onUpload: (id: number, file: File) => Promise<boolean>;
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const onAccepted = async (files: File[]) => {
    if (documentType) {
      setIsUploading(true);
      const result = await onUpload(documentType.documentTypeId, files[0]);
      setIsUploading(false);
      if (result) {
        onClose();
      }
    }
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "application/msword": [".doc"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
        "application/pdf": [".pdf"],
        "image/png": [".png"],
        "image/jpeg": [".jpg"],
      },
      multiple: false,
      maxFiles: 1,
      onDropAccepted: onAccepted,
    });

  if (!documentType) {
    return null;
  }

  let content: ReactNode;
  if (isUploading) {
    content = (
      <Center>
        <Spinner color="brand.500" size="xl" thickness="5px" />
      </Center>
    );
  } else if (isDragAccept) {
    content = (
      <Stack align="center" spacing={2}>
        <Icon as={RiCheckboxCircleFill} boxSize={10} color="green.500" />
        <Text
          color="green.500"
          fontSize="lg"
          fontWeight="semibold"
          textAlign="center"
        >
          El archivo arrastrado es compatible
        </Text>
      </Stack>
    );
  } else if (isDragReject) {
    content = (
      <Stack align="center" spacing={2}>
        <Icon as={RiErrorWarningFill} boxSize={10} color="red.500" />
        <Text
          color="red.500"
          fontSize="lg"
          fontWeight="semibold"
          textAlign="center"
        >
          El archivo arrastrado no es compatible
        </Text>
      </Stack>
    );
  } else {
    content = (
      <Stack align="center" spacing={2} textAlign="center">
        <Icon as={FiUpload} boxSize={10} />
        <Text color="gray.500" fontSize="xs" fontWeight="semibold">
          Archivos compatibles: DOC, DOCX, XLS, XLSX, JPG, PNG, o PDF
        </Text>
        <Text
          fontSize="xl"
          fontWeight="semibold"
          lineHeight={1.2}
          maxW="40ch"
          textAlign="center"
        >
          {documentType.documentName}
        </Text>

        <Text
          _dark={{ color: "brand.300" }}
          color="brand.500"
          fontSize="sm"
          fontWeight="semibold"
          mt={5}
        >
          Adjuntar archivo
        </Text>
      </Stack>
    );
  }

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent mx={2} overflow="hidden">
        <ModalCloseButton />
        <Flex direction={{ base: "column", md: "row" }} h="full">
          <Flex className="content" direction="column" flexGrow={1}>
            <ModalHeader fontSize="2xl" mb={0} px={8}>
              Adjuntar documentación
            </ModalHeader>
            <ModalBody pb={8} px={8}>
              <Flex
                direction="column"
                {...getRootProps({
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "360px",
                  cursor: "pointer",
                  bg: "gray.100",
                  _dark: {
                    bg: "gray.800",
                    borderColor: isDragAccept
                      ? "green.400"
                      : isDragReject
                      ? "red.400"
                      : "gray.600",
                  },
                  borderWidth: 2,
                  borderColor: isDragAccept
                    ? "green.400"
                    : isDragReject
                    ? "red.400"
                    : "gray.300",
                  rounded: "md",
                  borderStyle: "dashed",
                  outline: "none",
                  transition: "border .2s ease-in-out",
                  px: 2,
                })}
              >
                <input {...getInputProps()} hidden />

                {content}
              </Flex>
            </ModalBody>
          </Flex>

          <Stack
            _dark={{ bg: "gray.800" }}
            align="center"
            bg="gray.100"
            flexShrink={0}
            justify="center"
            p={8}
            spacing={{ base: 2, md: 4 }}
            textAlign="center"
            width={{ base: "full", md: "xs" }}
          >
            <Icon as={BsFillQuestionCircleFill} boxSize={10} color="gray.400" />
            <Text fontSize="lg" fontWeight="bold" lineHeight={1.2}>
              ¿Qué es este documento?
            </Text>
            <Text color="gray.500" fontSize="sm">
              {documentType.documentDescription}
            </Text>
          </Stack>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
