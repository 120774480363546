import { Heading, Stack } from "@chakra-ui/layout";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useMemo } from "react";

import { EmptyState } from "components/empty-state";
import { useOnboarding } from "modules/onboarding/onboarding.context";
import { formatDate } from "utils/date.utils";
import { formatMoney } from "utils/money.utils";

export const AccountingInformation = () => {
  const {
    data: { onboardingSummary },
  } = useOnboarding();

  const balances = useMemo(() => {
    return onboardingSummary?.accountingInformation.balances ?? [];
  }, [onboardingSummary]);

  return (
    <Stack direction="column" spacing={5}>
      <Stack spacing={4}>
        <Heading as="h3" fontSize="md">
          Balances
        </Heading>

        {balances.length > 0 ? (
          <TableContainer>
            <Table>
              <Thead>
                <Th>Patrimonio neto</Th>
                <Th>Ventas</Th>
                <Th>Cierre de ejercicio</Th>
              </Thead>
              <Tbody>
                {balances.map((balance, index) => (
                  <Tr key={index}>
                    <Td>{formatMoney(balance.netWorthInCents)}</Td>
                    <Td>{formatMoney(balance.revenueInCents)}</Td>
                    <Td>{formatDate(balance.fiscalYearEndDate)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <EmptyState>No se registró información de balances.</EmptyState>
        )}
      </Stack>
    </Stack>
  );
};
