const AVAILABLE_TAGS = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
];

export const mapSourceTags = (
  sourceTags: URLSearchParams
): Record<string, string> => {
  const tags = {};
  for (const tag of AVAILABLE_TAGS) {
    const value = sourceTags.get(tag);
    if (value) {
      tags[tag] = value;
    }
  }
  return tags;
};
