import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  PropsOf,
} from "@chakra-ui/react";
import {
  ReactElement,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

import { PropsWithRest } from "types/react-utils";

export const CollapsibleInput = forwardRef<
  HTMLInputElement,
  PropsWithRest<
    {
      "aria-label": string;
      icon: ReactElement;
      colorScheme: PropsOf<typeof IconButton>["colorScheme"];
    },
    typeof Input
  >
>(
  (
    {
      w,
      value,
      "aria-label": ariaLabel,
      icon,
      colorScheme,
      isDisabled,
      ...rest
    },
    forwardedRef
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const inputRef = useRef<HTMLInputElement | null>(null);

    const onFocus = useCallback(() => {
      setIsFocused(true);
      inputRef?.current?.focus();
    }, []);

    const onBlur = useCallback(() => {
      setIsFocused(false);
    }, []);

    const isInputVisible = useMemo(
      () => isFocused || (value !== undefined && value !== ""),
      [isFocused, value]
    );

    return (
      <InputGroup
        onBlur={onBlur}
        onFocus={onFocus}
        transition="width 0.2s ease"
        w={isInputVisible ? w : 10}
      >
        <InputLeftElement>
          <IconButton
            aria-label={ariaLabel}
            colorScheme={colorScheme}
            icon={icon}
            isDisabled={isDisabled}
            pointerEvents={isInputVisible ? "none" : "auto"}
            rounded="full"
            tabIndex={isInputVisible ? -1 : 0}
            variant="ghost"
          />
        </InputLeftElement>
        <Input
          ref={(el) => {
            if (typeof forwardedRef === "function") {
              forwardedRef(inputRef.current);
            } else if (forwardedRef) {
              forwardedRef.current = el;
            }
            inputRef.current = el;
          }}
          isDisabled={isDisabled}
          opacity={isInputVisible ? 1 : 0}
          pr={isInputVisible ? 4 : 0}
          rounded="full"
          transition="opacity 0.2s ease"
          w="full"
          {...rest}
        />
      </InputGroup>
    );
  }
);
