import { useBoolean } from "@chakra-ui/react";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useContextSafe } from "hooks/use-context-safe.hook";
import { useBulkPaymentsPayroll } from "modules/dashboard/routes/bulk-payments/bulk-payments-payroll/bulk-payments-payroll.context";

const BulkPaymentsPayrollEditorContext = createContext<{
  selectedEmployees: {
    ids: string[];
    onToggleSelection: (employeeId: string) => void;
    onToggleAllSelection: () => void;
    isSelected: (employeeId: string) => boolean;
    areAllSelected: boolean;
  };
  addEmployeeForm: {
    isOpenedByUser: boolean;
    onToggle: () => void;
    onOpen: () => void;
    isVisible: boolean;
  };
} | null>(null);

export const useBulkPaymentsPayrollEditor = () =>
  useContextSafe(BulkPaymentsPayrollEditorContext);

export const BulkPaymentsPayrollEditorProvider = ({
  children,
}: PropsWithChildren) => {
  const {
    data: { employees, employeeCount },
  } = useBulkPaymentsPayroll();

  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<string[]>([]);

  const onToggleEmployeeSelection = (employeeId: string) => {
    setSelectedEmployeeIds((prev) =>
      prev.includes(employeeId)
        ? prev.filter((id) => id !== employeeId)
        : [...prev, employeeId]
    );
  };

  const onToggleAllEmployeeSelection = useCallback(() => {
    setSelectedEmployeeIds((prev) =>
      prev.length === employees.length
        ? []
        : employees.map((employee) => employee.id)
    );
  }, [employees]);

  const isEmployeeSelected = useCallback(
    (employeeId: string) => selectedEmployeeIds.includes(employeeId),
    [selectedEmployeeIds]
  );

  const areAllEmployeesSelected = useMemo(
    () => employeeCount > 0 && selectedEmployeeIds.length === employeeCount,
    [employeeCount, selectedEmployeeIds]
  );

  const [
    isAddEmployeeFormOpenedByUser,
    { toggle: onToggleAddEmployeeForm, on: onOpenAddEmployeeForm },
  ] = useBoolean(false);
  const isAddEmployeeFormVisible = useMemo(
    () => isAddEmployeeFormOpenedByUser || employeeCount === 0,
    [employeeCount, isAddEmployeeFormOpenedByUser]
  );

  useEffect(() => {
    setSelectedEmployeeIds((prev) =>
      prev.filter((id) => employees.some((employee) => employee.id === id))
    );
  }, [employees]);

  return (
    <BulkPaymentsPayrollEditorContext.Provider
      value={{
        selectedEmployees: {
          ids: selectedEmployeeIds,
          onToggleSelection: onToggleEmployeeSelection,
          onToggleAllSelection: onToggleAllEmployeeSelection,
          isSelected: isEmployeeSelected,
          areAllSelected: areAllEmployeesSelected,
        },
        addEmployeeForm: {
          isOpenedByUser: isAddEmployeeFormOpenedByUser,
          onToggle: onToggleAddEmployeeForm,
          onOpen: onOpenAddEmployeeForm,
          isVisible: isAddEmployeeFormVisible,
        },
      }}
    >
      {children}
    </BulkPaymentsPayrollEditorContext.Provider>
  );
};
