import { Button, Heading, Spinner, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { Link, Navigate, useParams } from "react-router-dom";

import { useDashboard } from "modules/dashboard/dashboard.context";

export const Login = () => {
  const { token } = useParams();
  let ignore = false;

  const {
    state: { isLoggingInExternalOperator, isAuthenticated },
    actions: { onLoginExternalOperator },
  } = useDashboard();

  useEffect(() => {
    if (token && !ignore) {
      onLoginExternalOperator(token);
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (!token) {
    throw new Error("No token provided");
  }

  if (isAuthenticated) {
    return <Navigate replace to="/" />;
  } else if (isLoggingInExternalOperator) {
    return (
      <>
        <Spinner boxSize={10} color="brand.500" thickness="4px" />
        <Heading color="gray.700" mb={4} size="md">
          Iniciando sesión...
        </Heading>
      </>
    );
  } else {
    return (
      <>
        <Text textAlign="center">
          <Heading color="gray.700" mb={4} size="md">
            Este enlace ya expiró
          </Heading>
          Por favor, generá un nuevo enlace de ingreso y volvé a intentar.
        </Text>
        <Button as={Link} size="lg" to="/login" w="full">
          Generar nuevo enlace
        </Button>
      </>
    );
  }
};
