import { Client, gql } from "urql";

import { AmountWithCurrency } from "types/api-global.types";

export enum ContactType {
  Employee = "employee",
  Provider = "provider",
}

export type Contact = {
  id: string;
  taxId: string;
  name: string;
  cbu: string;
  type: ContactType;
};

export type PaymentSum = {
  count: number;
  amount: AmountWithCurrency;
};

export type PaymentsMetadata = {
  total: PaymentSum;
  successful: PaymentSum | null;
  failed: PaymentSum | null;
};

export enum BulkPaymentType {
  Salaries = "payroll",
  Providers = "provider",
}

export enum BulkPaymentStatus {
  Created = "created",
  PendingApproval = "pending_approval",
  Scheduled = "scheduled",
  InProcess = "in_process",
  Finished = "finished",
  Rejected = "rejected",
  Cancelled = "canceled",
}

export enum PaymentStatus {
  Pending = "pending",
  Sent = "sent",
  Successful = "successful",
  Failed = "failed",
  Cancelled = "canceled",
}

export type WebOperator = {
  id: string;
  name: string;
};

export type BulkPaymentSummary = {
  id: string;
  paymentType: BulkPaymentType;
  operatedBy: WebOperator;
  status: BulkPaymentStatus;
  createdAt: string;
  reviewedAt: string | null;
  executedAt: string | null;
  paymentsMetadata: PaymentsMetadata | null;
};

export type BulkPaymentsPage = {
  bulkPayments: BulkPaymentSummary[];
  cursor: string | null;
  totalCount: number;
};

export type BulkPaymentOptions = {
  scheduledFor: string | null;
};

export type Payment = {
  id: string;
  to: {
    id: string;
    taxId: string;
    name: string;
    cbu: string;
    type: string;
  };
  toCBU: string;
  status: PaymentStatus;
  amount: AmountWithCurrency;
  description: string;
};

export type PaymentsPage = {
  payments: Payment[];
  cursor: string | null;
};

export type BulkPayment = BulkPaymentSummary & {
  payments: PaymentsPage;
};

export const ContactFields = gql`
  fragment ContactFields on Contact {
    id
    taxId
    name
    cbu
    type
  }
`;

export const PaymentsSumFields = gql`
  fragment PaymentsSumFields on PaymentSum {
    amount {
      amountInCents
      currency
    }
    count
  }
`;

export const BulkPaymentSummaryFields = gql`
  fragment BulkPaymentSummaryFields on BulkPayment {
    id
    paymentType
    operatedBy {
      id
      name
    }
    createdAt
    reviewedAt
    executedAt
    status
    paymentsMetadata {
      total {
        ...PaymentsSumFields
      }
      successful {
        ...PaymentsSumFields
      }
      failed {
        ...PaymentsSumFields
      }
    }
  }
  ${PaymentsSumFields}
`;

export const PaymentFields = gql`
  fragment PaymentFields on Payment {
    id
    to {
      id
      taxId
      name
      cbu
      type
    }
    toCBU
    status
    amount {
      amountInCents
      currency
    }
    description
  }
`;

export const BulkPaymentFields = gql`
  fragment BulkPaymentFields on BulkPayment {
    ...BulkPaymentSummaryFields
    payments(in: { pagination: { pageSize: $pageSize, cursor: $cursor } }) {
      payments {
        ...PaymentFields
      }
      cursor
    }
  }
  ${BulkPaymentSummaryFields}
  ${PaymentFields}
`;

/**
 * Fetches a page of bulk payment without their payments.
 */
export const fetchBulkPayments = async (
  client: Client,
  pageSize: number,
  cursor: string | null,
  filters?: {
    status?: BulkPaymentStatus;
    type?: BulkPaymentType;
  }
) => {
  return client.query<
    {
      bulkPayments: BulkPaymentsPage;
    },
    {
      cursor: string | null;
      pageSize: number;
      status?: BulkPaymentStatus;
      type?: BulkPaymentType;
    }
  >(
    gql`
      query FetchBulkPaymentsPage(
        $cursor: String
        $pageSize: Int
        $status: String
        $type: String
      ) {
        bulkPayments(
          in: {
            pagination: { cursor: $cursor, pageSize: $pageSize }
            status: $status
            type: $type
          }
        ) {
          bulkPayments {
            ...BulkPaymentSummaryFields
          }
          cursor
          totalCount
        }
      }
      ${BulkPaymentSummaryFields}
    `,
    {
      cursor,
      pageSize,
      status: filters?.status,
      type: filters?.type,
    }
  );
};

/**
 * Fetches a bulk payment without its payments.
 */
export const fetchBulkPaymentSummary = async (
  client: Client,
  bulkPaymentId: string
) => {
  return client.query<
    { bulkPayment: BulkPaymentSummary },
    { bulkPaymentId: string }
  >(
    gql`
      query FetchBulkPayment($bulkPaymentId: ID!) {
        bulkPayment(id: $bulkPaymentId) {
          ...BulkPaymentSummaryFields
        }
      }
      ${BulkPaymentSummaryFields}
    `,
    { bulkPaymentId: bulkPaymentId },
    { requestPolicy: "cache-first" }
  );
};

/**
 * Fetches a bulk payment with its payments.
 */
export const fetchBulkPayment = async (
  client: Client,
  id: string,
  pageSize: number,
  cursor: string | null
) => {
  return client.query<
    {
      bulkPayment: BulkPayment | null;
    },
    {
      id: string;
      pageSize: number;
      cursor: string | null;
    }
  >(
    gql`
      query FetchBulkPayment($id: ID!, $pageSize: Int, $cursor: String) {
        bulkPayment(id: $id) {
          ...BulkPaymentFields
        }
      }
      ${BulkPaymentFields}
    `,
    {
      id,
      pageSize,
      cursor,
    }
  );
};

/**
 * Fetches a page of payments for a bulk payment.
 */
export const fetchBulkPaymentPaymentsPage = async (
  client: Client,
  id: string,
  pageSize: number,
  cursor: string | null
) => {
  return client.query<
    {
      bulkPayment: {
        payments: PaymentsPage;
      };
    },
    {
      id: string;
      pageSize: number;
      cursor: string | null;
    }
  >(
    gql`
      query FetchBulkPaymentPage($id: ID!, $pageSize: Int, $cursor: String) {
        bulkPayment(id: $id) {
          payments(
            in: { pagination: { pageSize: $pageSize, cursor: $cursor } }
          ) {
            payments {
              ...PaymentFields
            }
            cursor
          }
        }
      }
      ${PaymentFields}
    `,
    {
      id,
      pageSize,
      cursor,
    }
  );
};

/**
 * Cancels a bulk payment.
 */
export const cancelBulkPayment = async (
  client: Client,
  bulkPaymentId: string
) => {
  return client.mutation<
    { cancelBulkPayment: null },
    { bulkPaymentId: string }
  >(
    gql`
      mutation CancelBulkPayment($bulkPaymentId: ID!) {
        cancelBulkPayment(bulkPaymentId: $bulkPaymentId)
      }
    `,
    { bulkPaymentId }
  );
};

/**
 * Deletes a bulk payment.
 */
export const deleteBulkPayment = async (
  client: Client,
  bulkPaymentId: string
) => {
  return client.mutation<
    { deleteBulkPayment: null },
    { bulkPaymentId: string }
  >(
    gql`
      mutation DeleteBulkPayment($bulkPaymentId: ID!) {
        deleteBulkPayment(bulkPaymentId: $bulkPaymentId)
      }
    `,
    { bulkPaymentId }
  );
};
