import { ReactIntegration, ReactRouterVersion } from "@grafana/faro-react";
import { getWebInstrumentations, initializeFaro } from "@grafana/faro-web-sdk";
import { TracingInstrumentation } from "@grafana/faro-web-tracing";
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

// eslint-disable-next-line no-restricted-imports
import packageJson from "../package.json";

// eslint-disable-next-line brubank-frontend-companies/no-restricted-imports-clone
import { config } from "config";

export const faro = initializeFaro({
  url: config.faroUrl,
  apiKey: config.faroApiKey,
  app: {
    name: config.faroAppName,
    version: packageJson.version,
    environment: config.faroEnvironment,
  },
  instrumentations: [
    ...getWebInstrumentations({ captureConsole: true }),
    new ReactIntegration({
      router: {
        version: ReactRouterVersion.V6,
        dependencies: {
          createRoutesFromChildren,
          matchRoutes,
          Routes,
          useLocation,
          useNavigationType,
        },
      },
    }),
    ...(config.faroTracingEnabled
      ? [
          new TracingInstrumentation({
            instrumentationOptions: {
              propagateTraceHeaderCorsUrls: [config.baseUrl],
            },
          }),
        ]
      : []),
  ],
});
