import { Input } from "@chakra-ui/input";
import { Flex, Stack, HStack } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  FormControl,
  FormErrorMessage,
  UnorderedList,
  ListItem,
  AlertIcon,
} from "@chakra-ui/react";
import { Select } from "@chakra-ui/select";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { MemberLegalPowerDropzone } from "./member-legal-power-dropzone";

import { CuitInput } from "components/cuit-input";
import { OnboardingButton } from "modules/onboarding/components/onboarding-button";
import { OnboardingHeader } from "modules/onboarding/components/onboarding-header";
import { OnboardingLabel } from "modules/onboarding/components/onboarding-label";
import {
  CompanyOnboardingUser,
  ItemWithNameAndDescription,
} from "modules/onboarding/onboarding.types";
import { isValidNaturalPersonTaxId } from "utils/tax-id.utils";

export type MemberFormFields = {
  roleId: string;
  name: string;
  lastName: string;
  taxId: string;
  email: string;
  canAcceptTermsAndConditions: boolean;
  powerDocument: File | null;
};

function getContent({
  isAddingSelf,
  isEditing,
}: {
  isAddingSelf: boolean;
  isEditing: boolean;
}) {
  const allowedActionsTexts = [
    "Hacer transferencias.",
    `${isAddingSelf ? "Adherirte" : "Adherirse"} y pagar servicios.`,
    "Pagar sueldos y eliminar a personas usuarias.",
    "Retirar dinero y pedir créditos.",
  ];
  const header = {
    title: isEditing
      ? "Editá un persona con acceso"
      : isAddingSelf
      ? "Agregate para tener acceso"
      : "Agregá una persona para que tenga acceso",
    subtitle: isAddingSelf
      ? "Vas a poder acceder a la información de la cuenta empresa y tendrás habilitadas todas sus funcionalidades."
      : "Va a poder acceder a la información de la cuenta empresa y tendrá habilitadas todas sus funcionalidades.",
  };

  const role = {
    title: `${
      isAddingSelf ? "Vas" : "Esta persona va"
    } a poder usar la cuenta empresa para:`,
    requiredMessage: isAddingSelf
      ? "Elegí tu rol."
      : `Elegí el rol de la persona que estás ${
          isEditing ? "editando" : "agregando"
        }.`,
    labelMessage: "Rol dentro de cuenta empresa",
  };

  const firstName = {
    label: isAddingSelf ? "Tu nombre" : "Nombre",
    placeholder: isAddingSelf ? "Tu nombre" : "Nombre",
    requiredMessage: isAddingSelf
      ? "Ingresá tu nombre."
      : "Ingresá el nombre de la persona.",
  };

  const lastName = {
    label: isAddingSelf ? "Tu apellido" : "Apellido",
    placeholder: isAddingSelf ? "Tu apellido" : "Apellido",
    requiredMessage: isAddingSelf
      ? "Ingresá tu apellido."
      : "Ingresá el apellido de la persona.",
  };

  const taxId = {
    label: "CUIT - CUIL",
    requiredMessage: isAddingSelf
      ? "Ingresá tu CUIT - CUIL."
      : "Ingresá el CUIT - CUIL de la persona.",
    errorMessage: "Verificá que el CUIT - CUIL sea el correcto y reintentá.",
  };

  const footer = {
    message: isAddingSelf
      ? "Debés estar en el Estatuto de la empresa para que podamos aprobarte como representante legal."
      : "Esta persona debe estar en el Estatuto de la empresa para que podamos aprobarla como representante legal.",
    confirmButton: isEditing
      ? "Confirmar"
      : isAddingSelf
      ? "Agregarme"
      : "Agregar",
  };

  const legalRepresentative = {
    title: isAddingSelf
      ? "¿Sos representante o tenés poder legal?"
      : "¿Representante o con poder legal?",
    message: isAddingSelf
      ? "Soy representante legal"
      : "Es representante legal",
  };

  const powerOfLaw = {
    message: isAddingSelf ? "Tengo poder legal" : "Tiene poder legal",
    documentTitle: isAddingSelf
      ? "Necesitamos que adjuntes tu poder legal."
      : "Necesitamos que adjuntes su poder legal.",
    documentRequiredMessage: "Adjuntá el poder faltante.",
  };

  const email = {
    title: "Correo electrónico",
    requiredMessage: isAddingSelf
      ? "Ingresá tu correo electrónico."
      : "Ingresá el correo electrónico de la persona.",
    errorMessage: "Verificá el correo electrónico y reintentá.",
  };

  return {
    allowedActionsTexts,
    header,
    role,
    firstName,
    lastName,
    taxId,
    footer,
    legalRepresentative,
    powerOfLaw,
    email,
  };
}

export const MemberModal = ({
  data,
  isOpen,
  onClose,
  onCreateMember,
  onUpdateMember,
  roleOptions,
  isAddingSelf = false,
  existsLegalRepresentative = false,
}: {
  data: CompanyOnboardingUser | null;
  isOpen: boolean;
  onClose: () => void;
  onCreateMember: (data: MemberFormFields) => Promise<boolean>;
  onUpdateMember: (data: MemberFormFields) => Promise<boolean>;
  roleOptions: ItemWithNameAndDescription[];
  isAddingSelf?: boolean;
  existsLegalRepresentative?: boolean;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isEditing = useMemo(() => {
    return data !== null;
  }, [data]);

  const {
    allowedActionsTexts,
    header,
    role,
    firstName,
    lastName,
    taxId,
    footer,
    legalRepresentative,
    powerOfLaw,
    email,
  } = getContent({ isAddingSelf, isEditing });

  const [showPowerDocumentFields, setShowPowerDocumentFields] = useState(
    existsLegalRepresentative
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset: resetForm,
    watch,
    setValue,
  } = useForm<MemberFormFields>({
    mode: "onTouched",
  });

  const watchRoleId = watch("roleId");

  const allowedActions = useMemo(() => {
    switch (watchRoleId) {
      case "1":
        return allowedActionsTexts;
      default:
        return undefined;
    }
  }, [watchRoleId, allowedActionsTexts]);

  const reset = useCallback(() => {
    resetForm();
    setShowPowerDocumentFields(false);
  }, [resetForm]);

  const onSubmit: SubmitHandler<MemberFormFields> = async (data) => {
    setIsSubmitting(true);
    const successful = isEditing
      ? await onUpdateMember(data)
      : await onCreateMember(data);
    setIsSubmitting(false);
    if (successful) {
      reset();
      onClose();
    }
  };

  useEffect(() => {
    resetForm({
      roleId:
        data?.role.id.toString() ??
        (roleOptions.length === 1 ? roleOptions[0]?.id.toString() : ""),
      name: data?.name ?? "",
      lastName: data?.lastName ?? "",
      taxId: data?.taxId ?? "",
      email: data?.email ?? "",
      canAcceptTermsAndConditions:
        data?.canAcceptTermsAndConditions ?? !existsLegalRepresentative,
    });
    setShowPowerDocumentFields(!!data?.powerOfLaw || existsLegalRepresentative);
  }, [data, resetForm, roleOptions, existsLegalRepresentative]);

  useEffect(() => {
    if (!showPowerDocumentFields) {
      setValue("powerDocument", null);
    }
  }, [showPowerDocumentFields, setValue]);

  return (
    <Modal
      isOpen={isOpen}
      size="2xl"
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent mx={2} my={10}>
        <ModalCloseButton />
        <ModalBody p={8}>
          <OnboardingHeader
            mb={8}
            subtitle={header.subtitle}
            title={header.title}
          />
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Flex align="center" direction="column" maxW="md" mx="auto">
              <Stack spacing={4} w="full">
                {roleOptions.length !== 1 && (
                  <FormControl
                    isInvalid={errors?.roleId !== undefined}
                    isRequired
                  >
                    <OnboardingLabel>{role.labelMessage}</OnboardingLabel>
                    <Select
                      isDisabled={isSubmitting}
                      placeholder="Elegir"
                      size="lg"
                      {...register("roleId", {
                        required: role.requiredMessage,
                      })}
                    >
                      {roleOptions.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {errors.roleId?.message?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                )}

                {watchRoleId && (
                  <Alert
                    alignItems="start"
                    colorScheme="orange"
                    flexDirection="column"
                    px={6}
                  >
                    <AlertTitle mb={1}>{role.title}</AlertTitle>
                    <AlertDescription>
                      <UnorderedList lineHeight={1.4}>
                        {allowedActions?.map((action) => (
                          <ListItem key={action}>{action}</ListItem>
                        ))}
                      </UnorderedList>
                    </AlertDescription>
                  </Alert>
                )}

                <FormControl
                  isInvalid={
                    errors?.name !== undefined || errors?.lastName !== undefined
                  }
                >
                  <HStack align="start">
                    <FormControl
                      isInvalid={errors?.name !== undefined}
                      isRequired
                    >
                      <OnboardingLabel>{firstName.label}</OnboardingLabel>
                      <Input
                        isDisabled={isSubmitting}
                        placeholder={firstName.placeholder}
                        size="lg"
                        {...register("name", {
                          required: firstName.requiredMessage,
                        })}
                      />
                    </FormControl>

                    <FormControl
                      isInvalid={errors?.lastName !== undefined}
                      isRequired
                    >
                      <OnboardingLabel>{lastName.label}</OnboardingLabel>
                      <Input
                        isDisabled={isSubmitting}
                        placeholder={lastName.placeholder}
                        size="lg"
                        {...register("lastName", {
                          required: lastName.requiredMessage,
                        })}
                      />
                    </FormControl>
                  </HStack>

                  <FormErrorMessage>
                    <UnorderedList listStyleType="none" ml={0} pl={0}>
                      <ListItem>{errors.name?.message}</ListItem>
                      <ListItem>{errors.lastName?.message}</ListItem>
                    </UnorderedList>
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors?.taxId !== undefined} isRequired>
                  <OnboardingLabel>{taxId.label}</OnboardingLabel>
                  <Flex w="full">
                    <Controller
                      control={control}
                      name="taxId"
                      render={({ field: { onChange, ...rest } }) => (
                        <Input
                          as={CuitInput}
                          isDisabled={isSubmitting}
                          size="lg"
                          {...rest}
                          onValueChange={onChange}
                          placeholder="00-00000000-0"
                        />
                      )}
                      rules={{
                        required: taxId.requiredMessage,
                        validate: (value) =>
                          isValidNaturalPersonTaxId(value) ||
                          taxId.errorMessage,
                      }}
                    />
                  </Flex>
                  <FormErrorMessage>
                    {errors.taxId?.message?.toString()}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors?.email !== undefined} isRequired>
                  <OnboardingLabel>{email.title}</OnboardingLabel>
                  <Input
                    isDisabled={isSubmitting}
                    placeholder="correo@empresa.com"
                    size="lg"
                    {...register("email", {
                      required: email.requiredMessage,
                      pattern: {
                        value:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        message: email.errorMessage,
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.email?.message?.toString()}
                  </FormErrorMessage>
                </FormControl>

                <FormControl>
                  <OnboardingLabel>{legalRepresentative.title}</OnboardingLabel>
                  <Select
                    isDisabled={isSubmitting}
                    size="lg"
                    onChange={(ev) => {
                      const newValue = ev.target.value;
                      setShowPowerDocumentFields(
                        newValue === "has-power-of-law"
                      );
                      setValue(
                        "canAcceptTermsAndConditions",
                        newValue === "is-legal-representative"
                      );
                    }}
                    value={
                      showPowerDocumentFields
                        ? "has-power-of-law"
                        : "is-legal-representative"
                    }
                  >
                    <option
                      value="is-legal-representative"
                      disabled={existsLegalRepresentative}
                    >
                      {legalRepresentative.message}
                    </option>
                    <option value="has-power-of-law">
                      {powerOfLaw.message}
                    </option>
                  </Select>
                </FormControl>

                {showPowerDocumentFields ? (
                  <>
                    <Alert fontSize="sm" px={5} status="info">
                      <AlertIcon />
                      <AlertTitle lineHeight={1.5}>
                        {powerOfLaw.documentTitle}
                      </AlertTitle>
                    </Alert>

                    <Controller
                      control={control}
                      name="powerDocument"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormControl isInvalid={error !== undefined}>
                          <MemberLegalPowerDropzone
                            isDisabled={isSubmitting}
                            onChange={(value) => onChange(value)}
                            value={value}
                          />
                          <FormErrorMessage>{error?.message}</FormErrorMessage>
                        </FormControl>
                      )}
                      rules={{
                        required: powerOfLaw.documentRequiredMessage,
                      }}
                    />
                  </>
                ) : (
                  <Alert fontSize="sm" px={5} status="info">
                    <AlertIcon />
                    <AlertTitle lineHeight={1.5}>{footer.message}</AlertTitle>
                  </Alert>
                )}
              </Stack>

              <OnboardingButton isLoading={isSubmitting} mt={12} type="submit">
                {footer.confirmButton}
              </OnboardingButton>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
