import { Icon } from "@chakra-ui/icon";
import { Text, Flex } from "@chakra-ui/layout";
import { FaTelegramPlane } from "react-icons/fa";
import { MdVerified } from "react-icons/md";
import { Navigate } from "react-router-dom";

import { OnboardingHeader } from "modules/onboarding/components/onboarding-header";
import { OnboardingPage } from "modules/onboarding/components/onboarding-page";
import { useOnboarding } from "modules/onboarding/onboarding.context";
import {
  OnboardingStep,
  getOnboardingPath,
} from "modules/onboarding/routes/onboarding.routes";

export const InformationSent = () => {
  const {
    data: { onboardingSummary },
  } = useOnboarding();

  if (!onboardingSummary?.companyDescription.referenceNumber) {
    <Navigate to={getOnboardingPath(OnboardingStep.Summary)} />;
  }

  return (
    <OnboardingPage>
      <Flex align="center" direction="column">
        <Flex align="flex-end" mb={5}>
          <Icon as={FaTelegramPlane} boxSize={24} color="gray.800" />
          <Icon
            as={MdVerified}
            boxSize={12}
            color="green.400"
            mb={-1}
            ml={-1}
          />
        </Flex>
        <Text
          color="gray.500"
          fontSize="lg"
          fontWeight="semibold"
          mb={5}
          textAlign="center"
        >
          Trámite: #{onboardingSummary?.companyDescription.referenceNumber}
        </Text>

        <OnboardingHeader
          subtitle="Ahora, vamos a validar tu información. En aproximadamente 48 hs hábiles vas a recibir un correo electrónico contándote el estado del proceso."
          title="Enviaste la información con éxito"
        />
      </Flex>
    </OnboardingPage>
  );
};
