import { Stack } from "@chakra-ui/react";

import { AccountingInformation } from "./accounting-information";
import { SectionHeader } from "./section-header";
import { SocietyDetails } from "./society-details";
import { SocietyStructure } from "./society-structure";

export const BackofficeInformation = () => {
  return (
    <Stack direction="column" spacing={5}>
      <SectionHeader title="Información cargada por Brubank" />
      <SocietyDetails />
      <SocietyStructure />
      <AccountingInformation />
    </Stack>
  );
};
