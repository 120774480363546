import { ReactComponent as ArsIcon } from "assets/illustrations/ars_icon.svg";
import { ReactComponent as UsdIcon } from "assets/illustrations/usd_icon.svg";
import { CompanyAccountBalance } from "modules/dashboard/dashboard.api";

export enum CompanyAccountBalanceType {
  Checking = "checking",
  Yield = "yield",
}

export const COMPANY_ACCOUNT_BALANCE_TYPE_LABELS: {
  [key in CompanyAccountBalanceType]?: string;
} = {
  [CompanyAccountBalanceType.Checking]: "Cuenta corriente",
  [CompanyAccountBalanceType.Yield]: "Cuenta remunerada",
};

export function mapCompanyAccountBalances(
  companyAccountBalances: CompanyAccountBalance[]
) {
  return companyAccountBalances.map((cab) => ({
    ...cab,
    type: COMPANY_ACCOUNT_BALANCE_TYPE_LABELS[cab.type] ?? "Cuenta",
    icon: cab.balance.currency === "ARS" ? ArsIcon : UsdIcon,
  }));
}
