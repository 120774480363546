import { RouteObject, matchPath } from "react-router-dom";

import { Step1, Step2 } from "./business-data";
import { Documents } from "./documents";
import { EmailSent } from "./email-sent";
import { EmailVerified } from "./email-verified";
import { InformationSent } from "./information-sent";
import { OnboardingLogin } from "./login";
import { PhoneAdd } from "./phone-add";
import { PhoneVerification } from "./phone-verification";
import { PhoneVerified } from "./phone-verified";
import { Start } from "./start";
import { Summary } from "./summary";
import { TeamLanding, TeamList } from "./team";

import { OnboardingIndex } from "modules/onboarding/onboarding.index";
import { OnboardingWrapper } from "modules/onboarding/onboarding.wrapper";

export enum OnboardingStep {
  Start = "start",
  Login = "login",
  EmailSent = "email-sent",
  EmailVerified = "email-verified",
  PhoneAdd = "phone-add",
  PhoneVerification = "phone-verification",
  PhoneVerified = "phone-verified",
  Step1 = "step-1",
  Step2 = "step-2",
  Documents = "documents",
  // Checkpoint = "checkpoint",
  TeamLanding = "team-landing",
  TeamList = "team-list",
  Summary = "summary",
  InformationSent = "information-sent",
}

const onboardingBasePath = "/onboarding";

const onboardingStepPaths: Record<OnboardingStep, string> = {
  [OnboardingStep.Start]: "inicio",
  [OnboardingStep.Login]: "login",
  [OnboardingStep.EmailSent]: "correo-enviado",
  [OnboardingStep.EmailVerified]: "correo-verificado/:token",
  [OnboardingStep.PhoneAdd]: "celular",
  [OnboardingStep.PhoneVerification]: "celular-sms",
  [OnboardingStep.PhoneVerified]: "celular-verificado",
  [OnboardingStep.Step1]: "empresa-step-1",
  [OnboardingStep.Step2]: "empresa-step-2",
  [OnboardingStep.Documents]: "documentacion",
  // [OnboardingStep.Checkpoint]: "checkpoint",
  [OnboardingStep.TeamLanding]: "alta-equipo",
  [OnboardingStep.TeamList]: "lista-equipo",
  [OnboardingStep.Summary]: "resumen",
  [OnboardingStep.InformationSent]: "informacion-enviada",
};

export const getOnboardingPath = (step?: OnboardingStep) => {
  return step
    ? `${onboardingBasePath}/${onboardingStepPaths[step]}`
    : onboardingBasePath;
};

export const pathNeedsAuthentication = (path: string) => {
  const match = path.match(/\/onboarding(.*)/);
  if (match) {
    const stepPath = match[1];
    return (
      stepPath !== "" &&
      ![
        onboardingStepPaths[OnboardingStep.Start],
        onboardingStepPaths[OnboardingStep.EmailSent],
        onboardingStepPaths[OnboardingStep.EmailVerified],
      ].some((path) => matchPath(path, stepPath) !== null)
    );
  } else {
    return false;
  }
};

export const onboardingRoutes: RouteObject[] = [
  {
    path: onboardingBasePath,
    element: <OnboardingWrapper />,
    children: [
      {
        index: true,
        element: <OnboardingIndex />,
      },
      {
        children: [
          {
            path: onboardingStepPaths[OnboardingStep.Start],
            element: <Start />,
          },
          {
            path: onboardingStepPaths[OnboardingStep.Login],
            element: <OnboardingLogin />,
          },
          {
            path: onboardingStepPaths[OnboardingStep.EmailSent],
            element: <EmailSent />,
          },
          {
            path: onboardingStepPaths[OnboardingStep.EmailVerified],
            element: <EmailVerified />,
          },
          {
            path: onboardingStepPaths[OnboardingStep.PhoneAdd],
            element: <PhoneAdd />,
          },
          {
            path: onboardingStepPaths[OnboardingStep.PhoneVerification],
            element: <PhoneVerification />,
          },
          {
            path: onboardingStepPaths[OnboardingStep.PhoneVerified],
            element: <PhoneVerified />,
          },
          {
            path: onboardingStepPaths[OnboardingStep.Step1],
            element: <Step1 />,
          },
          {
            path: onboardingStepPaths[OnboardingStep.Step2],
            element: <Step2 />,
          },
          {
            path: onboardingStepPaths[OnboardingStep.Documents],
            element: <Documents />,
          },
          // {
          //   path: onboardingStepPaths[OnboardingStep.Checkpoint],
          //   element: <Checkpoint />,
          // },
          {
            path: onboardingStepPaths[OnboardingStep.TeamLanding],
            element: <TeamLanding />,
          },
          {
            path: onboardingStepPaths[OnboardingStep.TeamList],
            element: <TeamList />,
          },
          {
            path: onboardingStepPaths[OnboardingStep.Summary],
            element: <Summary />,
          },
          {
            path: onboardingStepPaths[OnboardingStep.InformationSent],
            element: <InformationSent />,
          },
        ],
      },
    ],
  },
];
