import { ConfigSource, ShapeOf, extractConfig } from "./extract-config";

export type AppConfig = {
  isProduction: boolean;
  baseUrl: string;
  recaptchaSiteKey?: string;
  faroUrl?: string;
  faroApiKey?: string;
  faroAppName: string;
  faroEnvironment: string;
  faroTracingEnabled: boolean;
};

const configShape: ShapeOf<AppConfig> = {
  isProduction: {
    from: "NODE_ENV",
    parser: (value) => value === "production",
  },
  baseUrl: { from: "BASE_URL", required: true },
  recaptchaSiteKey: { from: "RECAPTCHA_SITE_KEY" },
  faroUrl: { from: "FARO_URL", required: false },
  faroApiKey: { from: "FARO_API_KEY", required: false },
  faroAppName: {
    from: "FARO_APP_NAME",
    required: false,
    defaultValue: "frontend-companies",
  },
  faroEnvironment: {
    from: "FARO_ENVIRONMENT",
    required: false,
    defaultValue: "unknown",
  },
  faroTracingEnabled: {
    from: "FARO_TRACING_ENABLED",
    required: false,
    parser: (v) => v === "true",
    defaultValue: false,
  },
};

// These sources are read in order.
// Additional sources can be added to quickly change configurations
// whitout having to restart the dev server.
const configSources: ConfigSource[] = [
  // Read config from environment variables:
  {
    source: process.env,
    keyTransform: (key) => (key !== "NODE_ENV" ? `REACT_APP_${key}` : key),
  },
  // Read config from window.SERVER_DATA (for deployed apps):
  {
    source:
      window.SERVER_DATA && window.SERVER_DATA !== "__SERVER_DATA__"
        ? JSON.parse(window.SERVER_DATA)
        : {},
  },
];

/**
 * DO NOT use this config directly, unless you are very sure of what you are doing.
 * From inside React components, use the `useConfig` hook instead.
 */
export const config = extractConfig(configShape, configSources);
