import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { ComponentProps, PropsWithChildren } from "react";

export const EmptyState = ({
  children,
  ...rest
}: PropsWithChildren<ComponentProps<typeof Alert>>) => {
  return (
    <Alert status="info" {...rest}>
      <AlertIcon />
      <AlertDescription>{children}</AlertDescription>
    </Alert>
  );
};
