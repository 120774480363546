import {
  Avatar,
  Badge,
  Center,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Link,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { FiEdit, FiFileText } from "react-icons/fi";
import { LuFileSignature } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

import { ActionButtonProps } from "./action-button";
import { SectionHeader } from "./section-header";

import { CompanyOnboardingUser } from "modules/onboarding/onboarding.types";
import {
  OnboardingStep,
  getOnboardingPath,
} from "modules/onboarding/routes/onboarding.routes";
import { formatTaxId } from "utils/tax-id.utils";

export const Team = ({
  data,
  isDisabled,
  errors,
  showErrors,
}: {
  data?: CompanyOnboardingUser[];
  isDisabled: boolean;
  errors: string[];
  showErrors: boolean;
}) => {
  const navigate = useNavigate();

  const hasUsers = data && data.length > 0;

  const handleClick = () => {
    navigate(getOnboardingPath(OnboardingStep.TeamList), {
      state: { fromSummary: true },
    });
  };

  const button: ActionButtonProps = {
    buttonIcon: <FiEdit />,
    buttonLabel: "Agregar persona",
    isDisabled: isDisabled,
    onButtonClick: handleClick,
  };

  return (
    <Stack direction="column" spacing={5}>
      <SectionHeader title="Personas" button={button} />

      {hasUsers ? (
        <Stack spacing={5}>
          {data.map(
            ({
              id,
              name,
              lastName,
              taxId,
              canAcceptTermsAndConditions,
              powerOfLaw,
              email,
              role,
            }) => (
              <HStack key={id} spacing={{ base: 3, sm: 5 }}>
                <Avatar />
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  justify="space-between"
                  minW={0}
                  spacing={{ base: 0, sm: 5 }}
                  w="full"
                >
                  <HStack
                    justify="space-between"
                    spacing={{ base: 3, sm: 5 }}
                    w={{ base: "full", sm: "auto" }}
                  >
                    <Stack spacing={0.5}>
                      <Heading
                        fontSize="md"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {name} {lastName}
                      </Heading>
                      <Text
                        color="gray.400"
                        fontSize="md"
                        fontWeight="semibold"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {formatTaxId(taxId)}
                      </Text>
                    </Stack>

                    <HStack>
                      {canAcceptTermsAndConditions && (
                        <Tooltip label="Responsable firmante" placement="top">
                          <Flex>
                            <Icon
                              as={LuFileSignature}
                              color="gray.500"
                              fontSize="xl"
                            />
                          </Flex>
                        </Tooltip>
                      )}
                      {powerOfLaw && (
                        <Tooltip label="Ver poder legal" placement="top">
                          <IconButton
                            aria-label="Ver poder legal"
                            as={Link}
                            color="gray.500"
                            colorScheme="gray"
                            fontSize="xl"
                            href={powerOfLaw.publicURL}
                            icon={<FiFileText />}
                            isExternal
                            isRound
                            variant="ghost"
                          />
                        </Tooltip>
                      )}
                    </HStack>
                  </HStack>

                  <HStack
                    spacing={{ base: 3, sm: 5 }}
                    w={{ base: "full", sm: "auto" }}
                  >
                    <Stack align="end" minW={0} spacing={0.5} w="full">
                      <Text
                        color="gray.500"
                        fontWeight="semibold"
                        isTruncated
                        size="sm"
                        w="full"
                        whiteSpace="nowrap"
                      >
                        {email}
                      </Text>
                      <Badge
                        colorScheme="green"
                        px={2}
                        py={0.5}
                        rounded="xl"
                        textTransform="none"
                      >
                        {role?.description}
                      </Badge>
                    </Stack>
                  </HStack>
                </Stack>
              </HStack>
            )
          )}
        </Stack>
      ) : (
        <Center>
          <Text color="gray.600" fontWeight="bold">
            Acá van a estar las personas con acceso a la cuenta que agregues.
          </Text>
        </Center>
      )}

      {showErrors && errors.length > 0 && (
        <Stack spacing={2}>
          {errors.map((error, index) => (
            <Text key={index} color="red.500" fontSize="sm" textAlign="center">
              {error}
            </Text>
          ))}
        </Stack>
      )}
    </Stack>
  );
};
