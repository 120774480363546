export const formatTaxId = (
  value?: string | number,
  options?: { withDashes?: boolean }
): string => {
  const valueAsString = value?.toString() ?? "";
  const regex = /^\s*(\d{0,2})(?:\s*-?\s*(\d{0,8})(?:\s*-?\s*(\d))?)?/;
  const match = valueAsString.match(regex);
  if (match) {
    const [, p1, p2, p3, p4, p5] = match;
    const cuitParts = [p1, p2, p3, p4, p5].filter((part) => part);
    return cuitParts.join(options?.withDashes === false ? "" : "-");
  } else {
    return valueAsString;
  }
};

export const isValidTaxId = (
  value?: string | number,
  options?: {
    allowedPrefixes?: string[];
    disallowedPrefixes?: string[];
  }
): boolean => {
  const valueAsString = value?.toString() ?? "";
  const regex = /^(\d{2})\s*-?\s*(\d{8})\s*-?\s*(\d)$/;
  const match = valueAsString.match(regex);
  if (match) {
    const [, p1, p2, p3] = match;
    if (options?.allowedPrefixes && !options.allowedPrefixes.includes(p1!)) {
      return false;
    }
    if (
      options?.disallowedPrefixes &&
      options.disallowedPrefixes.includes(p1!)
    ) {
      return false;
    }
    const base = (p1! + p2!)
      .split("")
      .map((y) => parseInt(y, 10))
      .reverse();
    const verif = parseInt(p3!, 10);
    return (
      verif ===
      (11 -
        (base.map((y, i) => y * ((i % 6) + 2)).reduce((s, y) => s + y) % 11)) %
        11
    );
  }
  return false;
};

export const isValidCompanyTaxId = (value: string): boolean =>
  isValidTaxId(value, { allowedPrefixes: ["30", "33", "34"] });

export const isValidNaturalPersonTaxId = (value: string): boolean =>
  isValidTaxId(value, { disallowedPrefixes: ["30", "33", "34"] });
