import { useState } from "react";

export const useSet = <T>(initialItems: T[] = []) => {
  const [set, setSet] = useState<Set<T>>(new Set(initialItems));

  return {
    set,
    add: (value: T) => {
      setSet(new Set(set).add(value));
    },
    delete: (value: T) => {
      const newSet = new Set(set);
      newSet.delete(value);
      setSet(newSet);
    },
    has: (value: T) => {
      return set.has(value);
    },
    reset: () => {
      setSet(new Set());
    },
  } as const;
};
