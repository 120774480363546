import { useDisclosure } from "@chakra-ui/react";
import { PropsWithChildren, createContext, useCallback, useState } from "react";

import { useContextSafe } from "hooks/use-context-safe.hook";
import { Contact } from "modules/dashboard/routes/bulk-payments/bulk-payments.api";

const BulkPaymentProvidersEditionEditorContext = createContext<{
  addPaymentToProviderDrawer: {
    isOpen: boolean;
    onOpen: (contact?: Contact) => void;
    onClose: () => void;
    selectedContact: Contact | null;
  };
} | null>(null);

export const useBulkPaymentProvidersEditionEditor = () =>
  useContextSafe(BulkPaymentProvidersEditionEditorContext);

export const BulkPaymentProvidersEditionEditorProvider = ({
  children,
}: PropsWithChildren) => {
  const addPaymentToProviderDrawer = useDisclosure();
  const [
    addPaymentToProviderDrawerSelectedContact,
    setAddPaymentToProviderDrawerSelectedContact,
  ] = useState<Contact | null>(null);

  const onAddPaymentToProviderDrawerOpen = useCallback(
    (contact?: Contact) => {
      setAddPaymentToProviderDrawerSelectedContact(contact ?? null);
      addPaymentToProviderDrawer.onOpen();
    },
    [addPaymentToProviderDrawer]
  );

  const onAddPaymentToProviderDrawerClose = useCallback(() => {
    setAddPaymentToProviderDrawerSelectedContact(null);
    addPaymentToProviderDrawer.onClose();
  }, [addPaymentToProviderDrawer]);

  return (
    <BulkPaymentProvidersEditionEditorContext.Provider
      value={{
        addPaymentToProviderDrawer: {
          isOpen: addPaymentToProviderDrawer.isOpen,
          onOpen: onAddPaymentToProviderDrawerOpen,
          onClose: onAddPaymentToProviderDrawerClose,
          selectedContact: addPaymentToProviderDrawerSelectedContact,
        },
      }}
    >
      {children}
    </BulkPaymentProvidersEditionEditorContext.Provider>
  );
};
