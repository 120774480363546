import { Button, HStack } from "@chakra-ui/react";
import { useCallback } from "react";
import { FiArrowLeft, FiCheck } from "react-icons/fi";

import { useBulkPaymentsPayroll } from "modules/dashboard/routes/bulk-payments/bulk-payments-payroll/bulk-payments-payroll.context";

export const BulkPaymentsPayrollConfirmationActionBar = ({
  onPrevious,
  onNext,
}: {
  onPrevious: () => void;
  onNext: () => void;
}) => {
  const {
    actions: { onConfirmBulkPayment },
    state: { isConfirmingBulkPayment },
  } = useBulkPaymentsPayroll();

  const onConfirm = useCallback(async () => {
    await onConfirmBulkPayment();
    onNext();
  }, [onNext, onConfirmBulkPayment]);

  return (
    <HStack h={14} spacing={8} justify="end">
      <Button
        colorScheme="blackAlpha"
        leftIcon={<FiArrowLeft />}
        onClick={onPrevious}
        rounded="full"
        size="lg"
        variant="outline"
      >
        Volver a la nómina
      </Button>

      <Button
        isLoading={isConfirmingBulkPayment}
        leftIcon={<FiCheck />}
        onClick={onConfirm}
        rounded="full"
        size="lg"
      >
        Confirmar y enviar
      </Button>
    </HStack>
  );
};
