import { Button, HStack, Spacer, useBreakpointValue } from "@chakra-ui/react";
import { useCallback } from "react";
import { FiArrowRight, FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { ResponsiveButton } from "components/responsive-button";
import { ActionBarStat } from "modules/dashboard/components/action-bar-stat";
import { useBulkPaymentDetail } from "modules/dashboard/routes/bulk-payments/bulk-payment-detail/bulk-payment-detail.context";
import { PropsWithRest } from "types/react-utils";
import { formatAmountWithCurrency } from "utils/money.utils";

export const BulkPaymentProvidersEditionEditorActionBar = ({
  onNext,
  afterDeletePath,
  ...rest
}: PropsWithRest<
  {
    onNext: () => void;
    afterDeletePath: string;
  },
  typeof HStack
>) => {
  const navigate = useNavigate();

  const showStatistics = useBreakpointValue({ base: false, md: true });

  const {
    data: { paymentCount, totalAmount },
    state: { isDeletingBulkPayment },
    actions: { onDeleteBulkPayment },
  } = useBulkPaymentDetail();

  const onDelete = useCallback(async () => {
    const wasSuccessful = await onDeleteBulkPayment();
    if (wasSuccessful) {
      navigate(afterDeletePath);
    }
  }, [onDeleteBulkPayment, navigate, afterDeletePath]);

  return (
    <HStack spacing={16} h={14} {...rest}>
      {showStatistics && paymentCount > 0 && (
        <>
          <ActionBarStat title="Pagos" value={paymentCount.toString()} />
          <ActionBarStat
            title="Total a pagar"
            value={formatAmountWithCurrency(totalAmount)}
            valueColor="brand.500"
          />
        </>
      )}

      <Spacer />

      <HStack spacing={8}>
        <ResponsiveButton
          breakpoint="lg"
          onClick={onDelete}
          isLoading={isDeletingBulkPayment}
          icon={<FiTrash2 />}
          label="Eliminar borrador"
          rounded="full"
          size="lg"
          colorScheme="red"
          variant="outline"
        />

        <Button
          isDisabled={paymentCount === 0}
          onClick={onNext}
          rightIcon={<FiArrowRight />}
          rounded="full"
          size="lg"
        >
          Continuar
        </Button>
      </HStack>
    </HStack>
  );
};
