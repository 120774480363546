import { useBreakpointValue } from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";

import { BulkPaymentProvidersEditionStepper } from "./bulk-payment-providers-edition-stepper";
import { BulkPaymentProvidersEditionProvider } from "./bulk-payment-providers-edition.context";
import {
  BulkPaymentProvidersEditionConfirmation,
  BulkPaymentProvidersEditionConfirmationActionBar,
} from "./payment-confirmation";
import {
  BulkPaymentProvidersEditionEditor,
  BulkPaymentProvidersEditionEditorActionBar,
} from "./payment-editor";
import { BulkPaymentProvidersEditionResult } from "./payment-result";

import { DashboardSection } from "modules/dashboard/components/dashboard-section";

export const BulkPaymentProvidersEdition = ({
  backButtonPath = "/pagos/proveedores",
}: {
  backButtonPath: string;
}) => {
  const [currentStep, setCurrentStep] = useState(0);

  const onNext = useCallback(
    () => setCurrentStep((prev) => (prev < 2 ? prev + 1 : prev)),
    []
  );
  const onPrevious = useCallback(
    () => setCurrentStep((prev) => (prev > 0 ? prev - 1 : prev)),
    []
  );

  const showStepper = useBreakpointValue({ base: false, lg: true });

  const sectionBody = useMemo(() => {
    switch (currentStep) {
      case 0:
        return <BulkPaymentProvidersEditionEditor />;
      case 1:
        return <BulkPaymentProvidersEditionConfirmation />;
      case 2:
        return <BulkPaymentProvidersEditionResult onPrevious={onPrevious} />;
    }
  }, [currentStep, onPrevious]);

  const sectionActionBar = useMemo(() => {
    switch (currentStep) {
      case 0:
        return (
          <BulkPaymentProvidersEditionEditorActionBar
            onNext={onNext}
            afterDeletePath={backButtonPath}
          />
        );
      case 1:
        return (
          <BulkPaymentProvidersEditionConfirmationActionBar
            onNext={onNext}
            onPrevious={onPrevious}
          />
        );
      case 2:
        return null;
    }
  }, [currentStep, onNext, backButtonPath, onPrevious]);

  return (
    <BulkPaymentProvidersEditionProvider>
      <DashboardSection
        actionBarContent={sectionActionBar}
        backButton={backButtonPath}
        title="Pago a proveedores"
        headerControls={
          showStepper && (
            <BulkPaymentProvidersEditionStepper
              activeStep={currentStep}
              flexShrink={0}
            />
          )
        }
      >
        {sectionBody}
      </DashboardSection>
    </BulkPaymentProvidersEditionProvider>
  );
};
