export const BUSINESS_AGE_OPTIONS = [
  { id: "1", name: "De 0 a 4 años" },
  { id: "2", name: "De 4 a 10 años" },
  { id: "3", name: "Más de 10 años" },
];

export const EMPLOYEES_QUANTITY_OPTIONS = [
  { id: "1", name: "De 1 a 5" },
  { id: "2", name: "De 5 a 20" },
  { id: "3", name: "De 20 a 100" },
  { id: "4", name: "De 100 a 1000" },
  { id: "5", name: "Más de 1000" },
];

export const ONBOARDING_STATUS = {
  APPROVED_BY_SIGNER: "approved_by_signer",
  IN_PROGRESS: "in_progress",
  IN_REVIEW: "in_review",
  REQUIRES_INFORMATION: "requires_information",
  SUCCEEDED: "succeeded",
  REJECTED: "rejected",
};

export const ONBOARDING_STATUS_LABEL = {
  APPROVED_BY_SIGNER: "Aprobada por firmante", // approved_by_signer
  IN_PROGRESS: "Pendiente de envío", // in_progress
  IN_REVIEW: "En revisión", // in_review
  REQUIRES_INFORMATION: "Requiere información", // requires_information
  SUCCEEDED: "Aceptada", // succeeded
  REJECTED: "Rechazada", // rejected
};
