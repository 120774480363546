import { Button, HStack, Spacer, useBreakpointValue } from "@chakra-ui/react";
import { FiArrowRight } from "react-icons/fi";

import { ActionBarStat } from "modules/dashboard/components/action-bar-stat";
import { useBulkPaymentsPayroll } from "modules/dashboard/routes/bulk-payments/bulk-payments-payroll/bulk-payments-payroll.context";
import { PropsWithRest } from "types/react-utils";
import { formatAmountWithCurrency } from "utils/money.utils";

export const BulkPaymentsPayrollEditorActionBar = ({
  onNext,
  ...rest
}: PropsWithRest<
  {
    onNext: () => void;
  },
  typeof HStack
>) => {
  const {
    data: { employeeCount, totalAmount },
  } = useBulkPaymentsPayroll();

  const showStatistics = useBreakpointValue({ base: false, md: true });

  return (
    <HStack spacing={16} {...rest} h={14}>
      {showStatistics && employeeCount > 0 && (
        <>
          <ActionBarStat title="Personas" value={employeeCount.toString()} />
          <ActionBarStat
            title="Total en sueldos a pagar"
            value={formatAmountWithCurrency(totalAmount)}
            valueColor="brand.500"
          />
        </>
      )}

      <Spacer />

      <Button
        isDisabled={employeeCount === 0}
        onClick={onNext}
        rightIcon={<FiArrowRight />}
        rounded="full"
        size="lg"
      >
        Continuar
      </Button>
    </HStack>
  );
};
