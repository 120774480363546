import { Card, CardHeader, CardBody } from "@chakra-ui/card";
import {
  Center,
  HStack,
  Heading,
  Icon,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef } from "react";
import { FiPlus, FiSearch } from "react-icons/fi";

import { AddProviderDrawer } from "./add-provider-drawer";
import { ProvidersListBody } from "./providers-list-body";
import { ProvidersListSkeleton } from "./providers-list-skeleton";

import { ResponsiveButton } from "components/responsive-button";
import { CollapsibleInput } from "modules/dashboard/components/collapsible-input";
import { useBulkPaymentProvidersEdition } from "modules/dashboard/routes/bulk-payments/bulk-payment-providers-edition/bulk-payment-providers-edition.context";

export const ProvidersList = () => {
  const {
    data: { providers, providersQuery },
    actions: { onChangeProvidersQuery },
    state: { isFetchingProvidersFirstPage },
  } = useBulkPaymentProvidersEdition();

  const {
    isOpen: isAddProviderOpen,
    onOpen: onAddProviderOpen,
    onClose: onAddProviderClose,
  } = useDisclosure();

  const addProviderBtnRef = useRef<HTMLButtonElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Card rounded="xl" shadow="lg">
        <CardHeader pb={0} pt={6} px={8}>
          <HStack align="center" spacing={4}>
            <Heading as="h2" fontSize="2xl" my={1.5}>
              Agenda de proveedores
            </Heading>
            <Spacer />
            <ResponsiveButton
              ref={addProviderBtnRef}
              breakpoint="md"
              isDisabled={isFetchingProvidersFirstPage}
              icon={<FiPlus />}
              label="Agendar proveedor"
              onClick={onAddProviderOpen}
              rounded="full"
            />

            <CollapsibleInput
              ref={searchInputRef}
              aria-label="Buscar proveedor"
              colorScheme="blackAlpha"
              icon={<Icon as={FiSearch} boxSize={5} />}
              isDisabled={isFetchingProvidersFirstPage}
              placeholder="Buscar por nombre, CUIT o CBU/CVU"
              type="search"
              value={providersQuery}
              w="36ch"
              onBlur={(event) => {
                onChangeProvidersQuery(event.currentTarget.value);
              }}
              onInput={(event) => {
                if (
                  event.currentTarget.value === "" &&
                  !(
                    "inputType" in event.nativeEvent &&
                    event.nativeEvent.inputType === "deleteContentBackward"
                  )
                ) {
                  searchInputRef.current?.blur();
                }
              }}
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  searchInputRef.current?.blur();
                }
              }}
            />
          </HStack>
        </CardHeader>
        <CardBody p={6}>
          {isFetchingProvidersFirstPage ? (
            <ProvidersListSkeleton />
          ) : providers.length > 0 ? (
            <ProvidersListBody />
          ) : (
            <Center color="gray.500" py={20}>
              <Text>Todavía no agendaste a ningún proveedor.</Text>
            </Center>
          )}
        </CardBody>
      </Card>

      <AddProviderDrawer
        finalFocusRef={addProviderBtnRef}
        isOpen={isAddProviderOpen}
        onClose={onAddProviderClose}
      />
    </>
  );
};
