import { ComponentType, PropsWithChildren, createContext } from "react";

// eslint-disable-next-line brubank-frontend-companies/no-restricted-imports-clone
import { AppConfig, config } from "config";
import { useContextSafe } from "hooks/use-context-safe.hook";

const ConfigContext = createContext<AppConfig | null>(null);
ConfigContext.displayName = "ConfigContext";

export const useConfig = () => useContextSafe(ConfigContext);

interface WithConfigProps {
  config: AppConfig;
}

export const withConfig =
  <T extends WithConfigProps>(Component: ComponentType<T>) =>
  (props: Omit<T, keyof WithConfigProps>) => {
    const config = useConfig();

    return <Component {...(props as T)} config={config} />;
  };

export const ConfigProvider = ({ children }: PropsWithChildren) => {
  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};
