import { Context, useContext } from "react";

export const useContextSafe = <T>(context: Context<T | null>): T => {
  const value = useContext(context);
  if (value === null) {
    throw new Error(
      `Trying to use ${context.displayName} outside of its provider`
    );
  }
  return value;
};
