import { IconButton, Button } from "@chakra-ui/button";
import { Center, Checkbox, Icon } from "@chakra-ui/react";
import { Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/table";
import { useMemo, useRef, useState } from "react";
import { FaSort } from "react-icons/fa";
import { FiChevronsDown } from "react-icons/fi";

import { EmployeesTableRow } from "./employees-table-row";

import { useBulkPaymentsPayroll } from "modules/dashboard/routes/bulk-payments/bulk-payments-payroll/bulk-payments-payroll.context";
import { useBulkPaymentsPayrollEditor } from "modules/dashboard/routes/bulk-payments/bulk-payments-payroll/payroll-editor/payroll-editor.context";
import { PropsWithRest } from "types/react-utils";

const SortTableButton = ({
  onClick,
  ...rest
}: PropsWithRest<
  {
    onClick?: () => void;
  },
  typeof IconButton
>) => {
  return (
    <IconButton
      {...rest}
      _hover={{ opacity: 1 }}
      aria-label="Ordenar"
      h={4}
      icon={<FaSort />}
      minW={0}
      onClick={onClick}
      opacity={0.5}
      size="sm"
      transition="opacity 0.2s"
      variant="unstyled"
      verticalAlign={-3}
      w="auto"
    />
  );
};

type SortKey = "fullName" | "taxId" | "cbu" | "salary";

export const EmployeesTable = ({
  ...rest
}: PropsWithRest<{}, typeof TableContainer>) => {
  const {
    data: { employees },
    actions: { onFetchEmployeesNextPage },
    state: { isFetching, areMorePagesAvailable },
  } = useBulkPaymentsPayroll();

  const {
    selectedEmployees: {
      ids: selectedEmployeeIds,
      isSelected,
      onToggleSelection,
      areAllSelected: areAllEmployeesSelected,
      onToggleAllSelection: onToggleAllEmployeesSelection,
    },
  } = useBulkPaymentsPayrollEditor();

  const [sortBy, setSortBy] = useState<null | SortKey>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const sortedEmployees = useMemo(() => {
    if (!sortBy) {
      return employees;
    }
    const sorted = [...employees];
    switch (sortBy) {
      case "fullName":
        sorted.sort((a, b) =>
          `${a.firstName} ${a.lastName}`.localeCompare(
            `${b.firstName} ${b.lastName}`
          )
        );
        break;
      case "taxId":
        sorted.sort((a, b) => a.contact.taxId.localeCompare(b.contact.taxId));
        break;
      case "cbu":
        sorted.sort((a, b) => a.contact.cbu.localeCompare(b.contact.cbu));
        break;
      case "salary":
        sorted.sort((a, b) => a.salary.amountInCents - b.salary.amountInCents);
        break;
    }
    if (sortDirection === "desc") {
      sorted.reverse();
    }
    return sorted;
  }, [employees, sortBy, sortDirection]);

  const onSortBy = (key: SortKey) => {
    if (sortBy === key) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortBy(key);
      setSortDirection("asc");
    }
  };

  const rowSalaryInputRefs = useRef<Record<string, HTMLInputElement>>({});

  const onFocusPreviousRow = (employeeId: string) => {
    const previousEmployeeIndex =
      sortedEmployees.findIndex(({ id }) => id === employeeId) - 1;

    if (previousEmployeeIndex >= 0) {
      const previousEmployeeId = sortedEmployees[previousEmployeeIndex].id;
      const previousEmployeeInput =
        rowSalaryInputRefs.current[previousEmployeeId];
      previousEmployeeInput.focus();
      setTimeout(() => previousEmployeeInput.select());
    } else {
      rowSalaryInputRefs.current[employeeId].blur();
    }
  };

  const onFocusNextRow = (employeeId: string) => {
    const nextEmployeeIndex =
      sortedEmployees.findIndex(({ id }) => id === employeeId) + 1;

    if (nextEmployeeIndex < employees.length) {
      const nextEmployeeId = sortedEmployees[nextEmployeeIndex].id;
      const nextEmployeeInput = rowSalaryInputRefs.current[nextEmployeeId];
      nextEmployeeInput.focus();
      setTimeout(() => nextEmployeeInput.select());
    } else {
      rowSalaryInputRefs.current[employeeId].blur();
    }
  };

  return (
    <TableContainer {...rest} overflowX="auto" px={2}>
      <Table size="lg">
        <Thead>
          <Tr>
            <Th
              cursor="pointer"
              onClick={onToggleAllEmployeesSelection}
              p={0}
              pl={4}
              w={0}
            >
              <Checkbox
                borderColor="blackAlpha.300"
                isChecked={areAllEmployeesSelected}
                onChange={onToggleAllEmployeesSelection}
                verticalAlign="middle"
                isIndeterminate={
                  !areAllEmployeesSelected && selectedEmployeeIds.length > 0
                }
              />
            </Th>
            <Th>
              Persona empleada{" "}
              {!areMorePagesAvailable && (
                <SortTableButton onClick={() => onSortBy("fullName")} />
              )}
            </Th>
            <Th>
              CUIT{" "}
              {!areMorePagesAvailable && (
                <SortTableButton onClick={() => onSortBy("taxId")} />
              )}
            </Th>
            <Th>
              CBU{" "}
              {!areMorePagesAvailable && (
                <SortTableButton onClick={() => onSortBy("cbu")} />
              )}
            </Th>
            <Th isNumeric>
              Pago Neto{" "}
              {!areMorePagesAvailable && (
                <SortTableButton onClick={() => onSortBy("salary")} />
              )}
            </Th>
            <Th p={0} w={0}></Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedEmployees.map((employee) => (
            <EmployeesTableRow
              key={employee.id}
              ref={(ref) => {
                if (ref) {
                  rowSalaryInputRefs.current[employee.id] = ref;
                }
              }}
              employee={employee}
              isSelected={isSelected(employee.id)}
              onFocusNextRow={() => onFocusNextRow(employee.id)}
              onFocusPreviousRow={() => onFocusPreviousRow(employee.id)}
              onToggleSelection={() => onToggleSelection(employee.id)}
            />
          ))}
        </Tbody>
      </Table>
      {areMorePagesAvailable && (
        <Center mt={3}>
          <Button
            color="gray.500"
            colorScheme="gray"
            isLoading={isFetching}
            leftIcon={<Icon as={FiChevronsDown} />}
            onClick={onFetchEmployeesNextPage}
            rounded="full"
            variant="ghost"
          >
            Cargar más
          </Button>
        </Center>
      )}
    </TableContainer>
  );
};
