import { Box, Skeleton } from "@chakra-ui/react";

import { BulkPaymentConfirmationBox } from "./bulk-payment-confirmation-box";

export const BulkPaymentConfirmationStatBox = ({
  title,
  value,
  isLoading,
}: {
  title: string;
  value: string | number;
  isLoading?: boolean;
}) => {
  const valueWidth = Math.floor(Math.random() * 5) + 3;
  const titleWidth = Math.floor(Math.random() * 8) + 6;

  return (
    <BulkPaymentConfirmationBox>
      <Box fontSize="3xl" fontWeight="bold">
        {!isLoading ? (
          value
        ) : (
          <Skeleton height={10} mb={3} rounded="lg" width={`${valueWidth}ch`} />
        )}
      </Box>
      <Box color="gray.500" fontSize="lg" fontWeight="semibold">
        {!isLoading ? (
          title
        ) : (
          <Skeleton height={5} rounded="md" width={`${titleWidth}ch`} />
        )}
      </Box>
    </BulkPaymentConfirmationBox>
  );
};
