import {
  Box,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Input,
  Link as ChakraLink,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Stack,
  Text,
  useBreakpointValue,
  Card,
  CardBody,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FiHelpCircle } from "react-icons/fi";
import { Link } from "react-router-dom";

import { CuitInput } from "components/cuit-input";
import { Logo } from "components/logo";
import { useRecaptcha, withRecaptcha } from "contexts/recaptcha.context";
import { OnboardingButton } from "modules/onboarding/components/onboarding-button";
import { OnboardingHeader } from "modules/onboarding/components/onboarding-header";
import { OnboardingLabel } from "modules/onboarding/components/onboarding-label";
import { useOnboarding } from "modules/onboarding/onboarding.context";
import { isValidCompanyTaxId, isValidTaxId } from "utils/tax-id.utils";

type OnboardingFormFields = {
  email: string;
  taxId: string;
};

export const Start = withRecaptcha(() => {
  const {
    state: { isCreatingCompanyOnboarding },
    actions: { onCreateCompanyOnboarding },
  } = useOnboarding();
  const { getRecaptchaToken } = useRecaptcha();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<OnboardingFormFields>({ mode: "onTouched" });

  const onSubmit: SubmitHandler<OnboardingFormFields> = useCallback(
    async (data) => {
      const { email, taxId } = data;
      onCreateCompanyOnboarding(email, taxId, getRecaptchaToken);
    },
    [onCreateCompanyOnboarding, getRecaptchaToken]
  );

  const requiredInformation = [
    {
      title: "Documentación de la empresa.",
      description:
        "Dependiendo del tipo societario de la empresa (SA, SRL, SAU o SAS), vamos a necesitar que adjuntes documentación correspondiente, tal como Estatutos y Constancias de Inscripción.",
    },
    {
      title: "Datos de las personas que tendrán acceso a la cuenta.",
      description:
        "Vamos a necesitar el nombre, apellido, correo electrónico y CUIT/CUIL de todas las personas que tengan acceso a la cuenta.",
    },
    {
      title: "Poderes legales.",
      description:
        "Vamos a necesitar que adjuntes el poder legal de las personas que son representantes legales o que van a tener el poder de autorizar la creación de la cuenta.",
    },
  ];

  const isNarrowScreen = useBreakpointValue({ base: true, lg: false });

  return (
    <Flex
      align="stretch"
      flexDir={{ base: "column", lg: "row" }}
      minH={{ lg: "100vh" }}
    >
      <Center
        flexBasis={0}
        flexGrow={{ base: undefined, lg: 5 }}
        px={{ base: 10, lg: 12 }}
      >
        <Center minH={{ base: "100svh", lg: "auto" }} py={12}>
          <Stack align="center" spacing={12}>
            {isNarrowScreen && <Logo fill="brand.500" h="2.7em" w="auto" />}

            <Card rounded="2xl" shadow={{ base: "none", lg: "xl" }}>
              <CardBody px={{ base: 0, lg: 16 }} py={{ base: 0, lg: 8 }}>
                <Stack
                  align="center"
                  as="form"
                  direction="column"
                  maxW="md"
                  onSubmit={handleSubmit(onSubmit)}
                  spacing={12}
                >
                  <OnboardingHeader
                    subtitle="Como primer paso, vamos a vincular tu correo con tu cuenta y luego, vamos a pedirte que lo valides."
                    title="Abrí tu cuenta empresa"
                  />

                  <Stack maxW="sm" spacing={4} w="full">
                    <FormControl
                      isDisabled={isCreatingCompanyOnboarding}
                      isInvalid={errors?.email !== undefined}
                    >
                      <OnboardingLabel>Correo electrónico</OnboardingLabel>
                      <Input
                        placeholder="correo@ejemplo.com"
                        size="lg"
                        {...register("email", {
                          required: "Ingresá tu correo electrónico.",
                          pattern: {
                            value:
                              /^[a-zA-Z0-9-_.+]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                            message:
                              "Verificá el correo electrónico y reintentá.",
                          },
                          maxLength: {
                            value: 128,
                            message:
                              "Ingresá un correo con menos de máximo 128 caracteres.",
                          },
                        })}
                      />
                      {errors.email && (
                        <FormErrorMessage>
                          {errors?.email?.message?.toString()}
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      isDisabled={isCreatingCompanyOnboarding}
                      isInvalid={errors?.taxId !== undefined}
                    >
                      <OnboardingLabel>CUIT de empresa</OnboardingLabel>
                      <Controller
                        control={control}
                        name="taxId"
                        render={({ field: { onChange, ...rest } }) => (
                          <Input
                            as={CuitInput}
                            size="lg"
                            {...rest}
                            onValueChange={onChange}
                            placeholder="30-00000000-00"
                          />
                        )}
                        rules={{
                          required: "Ingresá el CUIT de la empresa.	",
                          validate: (value) => {
                            if (!isValidTaxId(value)) {
                              return "Verificá que el CUIT sea el correcto y reintentá.";
                            } else if (!isValidCompanyTaxId(value)) {
                              return "El número de CUIT debe comenzar en 30, 33 o 34.";
                            }
                          },
                        }}
                      />
                      <FormErrorMessage>
                        {errors?.taxId?.message?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>

                  <OnboardingButton
                    isLoading={isCreatingCompanyOnboarding}
                    maxW="sm"
                    type="submit"
                    w="full"
                  >
                    Empezar
                  </OnboardingButton>
                </Stack>
              </CardBody>
            </Card>

            <Text
              color="gray.500"
              fontSize="lg"
              fontWeight="bold"
              textAlign="center"
            >
              ¿Ya tenés una cuenta empresa?{" "}
              <ChakraLink as={Link} color="brand.500" to="/">
                Ingresá acá
              </ChakraLink>
              .
            </Text>
          </Stack>
        </Center>
      </Center>

      <Center
        bgGradient="linear(to-b, brand.400, brand.500)"
        color="white"
        flexBasis={0}
        flexDirection="column"
        flexGrow={{ base: undefined, lg: 4 }}
        lineHeight={1.25}
        px={{ base: 6, lg: 12 }}
        py={{ base: 20, lg: 12 }}
      >
        <Logo fill="white" h="2.8em" mb="6" w="auto" />

        <Text fontSize="3xl" fontWeight="semibold" mb="12" textAlign="center">
          Te damos la bienvenida a <br />
          Brubank Empresas
        </Text>

        <Stack align="center" maxW="50ch" spacing={12} w="full">
          <Box
            backdropFilter="auto"
            backdropSaturate="2"
            bg="whiteAlpha.300"
            px={6}
            py={7}
            rounded="xl"
            shadow="lg"
            transitionDuration="2s"
            w="full"
          >
            <Text fontSize="2xl" fontWeight="bold" pb={6} textAlign="center">
              Tené a mano esta información
            </Text>
            <Stack spacing={5}>
              {requiredInformation.map((info, i) => (
                <Box key={i} bg="whiteAlpha.200" px={5} py={3} rounded="lg">
                  <HStack align="center" fontSize="xl" spacing={4}>
                    <Text>✅</Text>
                    <Text fontWeight="semibold" textAlign="left">
                      {info.title}
                    </Text>
                    <Spacer />
                    <Popover
                      arrowShadowColor="brand.900"
                      placement="right"
                      trigger="hover"
                    >
                      <PopoverTrigger>
                        <Box lineHeight={1}>
                          <Icon
                            as={FiHelpCircle}
                            boxSize={6}
                            color="whiteAlpha.700"
                          />
                        </Box>
                      </PopoverTrigger>
                      <PopoverContent
                        bg="brand.800"
                        borderColor="brand.900"
                        color="white"
                        fontSize="md"
                      >
                        <PopoverArrow bg="brand.800" />

                        <PopoverBody>
                          <Text fontSize="lg" fontWeight="bold" mb={1}>
                            {info.title}
                          </Text>
                          {info.description}
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </HStack>
                </Box>
              ))}
            </Stack>
          </Box>

          <Box
            backdropFilter="auto"
            backdropSaturate={1.15}
            bg="whiteAlpha.200"
            px={6}
            py={5}
            rounded="xl"
            w="full"
          >
            <HStack spacing={4}>
              <Icon as={FiHelpCircle} boxSize={6} color="white" />
              <Stack align="start" spacing={0.5}>
                <Text fontWeight="bold">¿Tenés dudas?</Text>
                <Text>
                  Consultá nuestras{" "}
                  <ChakraLink href="#">preguntas frecuentes</ChakraLink>.
                </Text>
              </Stack>
            </HStack>
          </Box>
        </Stack>
      </Center>
    </Flex>
  );
});
