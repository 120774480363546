import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useMemo } from "react";

import { SocietyPeopleTable } from "./society-members-table";

import {
  SocietyStructureNodeCompanyWithMembers,
  SocietyStructureNodePerson,
  SocietyStructureNodeType,
} from "modules/onboarding/onboarding.types";
import { formatWithDecimalScale } from "utils/number.utils";
import { formatTaxId } from "utils/tax-id.utils";

export type CompanyItemProps = {
  company: SocietyStructureNodeCompanyWithMembers;
  children?: React.ReactNode;
};

export const CompanyItem = ({ company }: CompanyItemProps) => {
  const people = company?.members.filter(
    (m) => m.__typename === SocietyStructureNodeType.Person
  );
  const childrenCompanies = company?.members?.filter(
    (m) => m.__typename === SocietyStructureNodeType.Company
  );

  const sharesWithDecimals = useMemo(
    () =>
      formatWithDecimalScale(company?.sharesWithDecimals ?? 0, 2, {
        removeTrailingZeros: true,
      }),
    [company?.sharesWithDecimals]
  );

  return (
    <AccordionItem border={0} mt={5}>
      {({ isExpanded }) => (
        <>
          <AccordionButton
            borderRadius="lg"
            {...(isExpanded && { borderBottomRadius: 0 })}
            backgroundColor="gray.100"
            _hover={{ bgColor: "gray.200" }}
          >
            <Flex
              p={{ base: 0, md: 4 }}
              fontSize={{ base: "xs", md: "md" }}
              justifyContent="space-between"
              width="100%"
            >
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: 0, md: 4 }}
              >
                <Text color="gray.800" fontWeight="bold">
                  {company?.name}
                </Text>
                <Text color="gray.800">
                  CUIT {company?.taxId && formatTaxId(company?.taxId)}
                </Text>
              </Stack>
              <HStack spacing={4}>
                <Text color="gray.800" pl={4}>
                  {sharesWithDecimals} cuotas
                </Text>
                <AccordionIcon color="gray.800" />
              </HStack>
            </Flex>
          </AccordionButton>
          <AccordionPanel
            border="1px solid"
            borderColor="gray.200"
            borderRadius="lg"
            borderTop={0}
            borderTopRadius={0}
            paddingBottom="2"
          >
            <SocietyPeopleTable
              people={people as SocietyStructureNodePerson[]}
            />
            {childrenCompanies?.map((member, index) => (
              <CompanyItem
                key={index}
                company={member as SocietyStructureNodeCompanyWithMembers}
              />
            ))}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};
