import { RouteObject } from "react-router-dom";

import { Home } from "./home";

import { DashboardLayout } from "modules/dashboard/components/dashboard-layout";

export const homeRoutes: RouteObject[] = [
  {
    path: "",
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
    ],
  },
];
