import {
  ComponentType,
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import { useConfig } from "contexts/config";
import { useContextSafe } from "hooks/use-context-safe.hook";

export type RecaptchaTokenGetter = (action: string) => Promise<string | null>;

type RecaptchaContextValue = {
  getRecaptchaToken: RecaptchaTokenGetter;
};

const RecaptchaContext = createContext<RecaptchaContextValue | null>(null);

export const useRecaptcha = () => useContextSafe(RecaptchaContext);

export const RecaptchaProvider = ({ children }: PropsWithChildren<{}>) => {
  const { recaptchaSiteKey } = useConfig();

  const recaptchaScriptUrl = useMemo(() => {
    if (recaptchaSiteKey === undefined) {
      return null;
    }
    return `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`;
  }, [recaptchaSiteKey]);

  const addRecaptchaScript = useCallback(() => {
    if (recaptchaScriptUrl === null) {
      return;
    }
    const existingRecaptchaScriptTag = document.querySelector(
      `script[src="${recaptchaScriptUrl}"]`
    );
    if (existingRecaptchaScriptTag) {
      return;
    }
    const recaptchaScriptTag = document.createElement("script");
    recaptchaScriptTag.src = recaptchaScriptUrl;
    recaptchaScriptTag.async = true;
    document.body.appendChild(recaptchaScriptTag);
  }, [recaptchaScriptUrl]);

  const removeRecaptchaScript = useCallback(() => {
    const recaptchaScriptTag = document.querySelector(
      `script[src="${recaptchaScriptUrl}"]`
    );
    if (recaptchaScriptTag) {
      recaptchaScriptTag.remove();
    }
    const recaptchaBadge = document.querySelector(".grecaptcha-badge");
    if (recaptchaBadge) {
      recaptchaBadge.remove();
    }
  }, [recaptchaScriptUrl]);

  useEffect(() => {
    addRecaptchaScript();
    return removeRecaptchaScript;
  }, [addRecaptchaScript, removeRecaptchaScript]);

  const getToken = useCallback(
    async (action: string) => {
      if (!window.grecaptcha || !window.grecaptcha.execute) {
        console.error("Recaptcha script not loaded");
        return null;
      } else {
        return await window.grecaptcha.execute(recaptchaSiteKey, { action });
      }
    },
    [recaptchaSiteKey]
  );

  return (
    <RecaptchaContext.Provider value={{ getRecaptchaToken: getToken }}>
      {children}
    </RecaptchaContext.Provider>
  );
};

export const withRecaptcha =
  <T extends {}>(Component: ComponentType<T>) =>
  (props: T) =>
    (
      <RecaptchaProvider>
        <Component {...props} />
      </RecaptchaProvider>
    );
