import { ProvidersQuery } from "modules/dashboard/routes/bulk-payments/bulk-payments-providers.api";
import { isValidCbu } from "utils/cbu.utils";
import { formatTaxId, isValidTaxId } from "utils/tax-id.utils";

export const parseProvidersQuery = (
  rawQuery: string
): ProvidersQuery | null => {
  if (rawQuery === "") {
    return null;
  } else if (isValidTaxId(rawQuery)) {
    return {
      taxId: formatTaxId(rawQuery, { withDashes: false }),
    };
  } else if (isValidCbu(rawQuery)) {
    return { cbu: rawQuery };
  } else {
    return { name: rawQuery };
  }
};
