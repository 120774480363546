import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useCallback, useMemo, useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { gql, useMutation } from "urql";

import { DocumentTypeCard } from "./document-type-card";
import { UploadDocumentModal } from "./upload-document-modal";

import { OnboardingBackButton } from "modules/onboarding/components/onboarding-back-button";
import { OnboardingButton } from "modules/onboarding/components/onboarding-button";
import { OnboardingHeader } from "modules/onboarding/components/onboarding-header";
import { OnboardingPage } from "modules/onboarding/components/onboarding-page";
import { useOnboarding } from "modules/onboarding/onboarding.context";
import { OnboardingDocs } from "modules/onboarding/onboarding.fragments";
import {
  CompanyOnboardingDoc,
  DocumentTypeBySocietyType,
} from "modules/onboarding/onboarding.types";
import { OmitDocumentModal } from "modules/onboarding/routes/documents/omit-document-modal";
import {
  OnboardingStep,
  getOnboardingPath,
} from "modules/onboarding/routes/onboarding.routes";

export const Documents = () => {
  const [selectedDocumentType, setSelectedDocumentType] =
    useState<DocumentTypeBySocietyType | null>(null);
  const {
    isOpen: isUploadDocsOpen,
    onOpen: onUploadDocsOpen,
    onClose: onUploadDocsClose,
  } = useDisclosure();
  const {
    isOpen: isOmitDocsOpen,
    onOpen: onOmitDocsOpen,
    onClose: onOmitDocsClose,
  } = useDisclosure();

  const {
    state: { isStale, isFetchingFormOptions },
    data: { onboardingSummary, isOnboardingFinished, formOptions },
    actions: { setRequiresFormReSubmission },
  } = useOnboarding();

  const documentsByType = useMemo(() => {
    return (
      onboardingSummary?.docs?.reduce((acc, doc) => {
        if (acc[doc.typeBySociety.documentTypeId]) {
          acc[doc.typeBySociety.documentTypeId].push(doc);
        } else {
          acc[doc.typeBySociety.documentTypeId] = [doc];
        }
        return acc;
      }, {} as Record<number, CompanyOnboardingDoc[]>) ?? {}
    );
  }, [onboardingSummary]);

  const [addCompanyOnboardingDocumentResult, addCompanyOnboardingDocument] =
    useMutation<
      {
        addCompanyOnboardingDocument: {
          docs: CompanyOnboardingDoc[];
        };
      },
      {
        documentTypeId: number;
        content?: File;
        skipExplanation?: string;
      }
    >(gql`
    mutation AddCompanyOnboardingDocument(
      $documentTypeId: Int!,
      $content: Upload,
      $skipExplanation: String
    ) {
      addCompanyOnboardingDocument(in: {
        documentTypeId: $documentTypeId,
        content: $content,
        skipExplanation: $skipExplanation
      }) {
        ...OnboardingDocs
      }
      ${OnboardingDocs}
    }`);

  const [
    removeCompanyOnboardingDocumentResult,
    removeCompanyOnboardingDocument,
  ] = useMutation<
    {
      removeCompanyOnboardingDocument: {
        docs: CompanyOnboardingDoc[];
      };
    },
    {
      documentId: string;
    }
  >(gql`
    mutation RemoveCompanyOnboardingDocument($documentId: String!) {
      removeCompanyOnboardingDocument(in: {
        documentId: $documentId
      }) {
        ...OnboardingDocs
      }
      ${OnboardingDocs}
    }`);

  const toast = useToast();

  const navigate = useNavigate();
  const location = useLocation();

  const requiredDocumentsQuantity = formOptions.documentTypes.length;
  const hasAllUploadedDocuments =
    documentsByType !== null &&
    Object.keys(documentsByType).length === requiredDocumentsQuantity;
  const isComingFromSummary = location.state?.fromSummary || false;
  const navigateWhere = getOnboardingPath(
    isComingFromSummary ? OnboardingStep.Summary : OnboardingStep.TeamLanding
  );

  const handleNavigateBack = () => {
    navigate(
      getOnboardingPath(
        isComingFromSummary ? OnboardingStep.Summary : OnboardingStep.Step2
      )
    );
  };

  const onUploadDocument = useCallback(
    async (id: number, file: File): Promise<boolean> => {
      const result = await addCompanyOnboardingDocument({
        content: file,
        documentTypeId: id,
      });
      if (!result.error) {
        toast({
          title: "Adjuntado con éxito.",
          status: "success",
        });
        setSelectedDocumentType(null);
        return true;
      } else {
        return false;
      }
    },
    [addCompanyOnboardingDocument, toast]
  );

  const onOmitDocument = useCallback(
    async (id: number, reason: string) => {
      const result = await addCompanyOnboardingDocument({
        documentTypeId: id,
        skipExplanation: reason,
      });
      if (!result.error) {
        toast({
          title: "La carga del documento se omitió correctamente.",
          status: "success",
        });
        setSelectedDocumentType(null);
        return true;
      } else {
        return false;
      }
    },
    [toast, addCompanyOnboardingDocument]
  );

  const onRemoveDocument = useCallback(
    async (document: CompanyOnboardingDoc) => {
      const result = await removeCompanyOnboardingDocument({
        documentId: document.id,
      });
      if (!result.error) {
        toast({
          title: document.skipExplanation
            ? "La omisión se deshizo correctamente."
            : "El archivo se eliminó correctamente.",
          status: "success",
        });
        setSelectedDocumentType(null);
      }
    },
    [removeCompanyOnboardingDocument, toast]
  );

  const onSubmit = async () => {
    navigate(navigateWhere);
  };

  useEffect(() => {
    if (isComingFromSummary) {
      setRequiresFormReSubmission(true);
    }
  }, [
    onboardingSummary?.docs,
    isComingFromSummary,
    setRequiresFormReSubmission,
  ]);

  if (isOnboardingFinished) {
    return <Navigate to={getOnboardingPath(OnboardingStep.Summary)} />;
  }

  return (
    <OnboardingPage activeStep={1} isLoading={isFetchingFormOptions}>
      <OnboardingBackButton onClick={handleNavigateBack} />

      <Flex align="center" direction="column">
        <OnboardingHeader
          mb={12}
          subtitle="Necesitamos probar la existencia de la empresa con su información contable y detalle de personas empleadas."
          title="Adjuntá documentación"
        />

        {!onboardingSummary?.companyDescription?.societyType?.id && (
          <Alert maxWidth="xl" status="warning">
            <AlertIcon />
            <AlertTitle>
              Antes de adjuntar la documentación, debés indicar el tipo
              societario de la empresa.
            </AlertTitle>
          </Alert>
        )}

        <Stack
          maxW="xl"
          opacity={isStale ? 0.5 : 1}
          pointerEvents={isStale ? "none" : "auto"}
          spacing={8}
          w="full"
        >
          {formOptions.documentTypes.map((documentType) => {
            const { documentTypeId } = documentType;

            const onAdd = () => {
              setSelectedDocumentType(documentType);
              onUploadDocsOpen();
            };

            const onOmit = () => {
              setSelectedDocumentType(documentType);
              onOmitDocsOpen();
            };

            return (
              <DocumentTypeCard
                key={documentTypeId}
                documents={documentsByType[documentTypeId]}
                onAddDocument={onAdd}
                onOmitDocument={onOmit}
                onRemoveDocument={onRemoveDocument}
                documentType={documentType}
                isDisabled={
                  addCompanyOnboardingDocumentResult.fetching ||
                  removeCompanyOnboardingDocumentResult.fetching ||
                  isStale
                }
              />
            );
          })}
        </Stack>

        <OnboardingButton
          mt={12}
          onClick={onSubmit}
          isDisabled={
            (!isComingFromSummary && !hasAllUploadedDocuments) ||
            addCompanyOnboardingDocumentResult.fetching ||
            removeCompanyOnboardingDocumentResult.fetching ||
            isStale
          }
        >
          {isComingFromSummary ? "Volver al resumen" : "Continuar"}
        </OnboardingButton>

        {!isComingFromSummary && !hasAllUploadedDocuments && (
          <Button mt={8} variant="link" onClick={onSubmit} colorScheme="gray">
            Cargar la documentación más tarde
          </Button>
        )}
      </Flex>

      <UploadDocumentModal
        documentType={selectedDocumentType}
        isOpen={isUploadDocsOpen}
        onClose={onUploadDocsClose}
        onUpload={onUploadDocument}
      />
      <OmitDocumentModal
        documentType={selectedDocumentType}
        isOpen={isOmitDocsOpen}
        onClose={onOmitDocsClose}
        onSubmit={onOmitDocument}
      />
    </OnboardingPage>
  );
};
