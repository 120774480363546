import { useCallback, useEffect, useMemo } from "react";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { gql, useMutation } from "urql";

import { OnboardingButton } from "modules/onboarding/components/onboarding-button";
import { OnboardingHeader } from "modules/onboarding/components/onboarding-header";
import { OnboardingPage } from "modules/onboarding/components/onboarding-page";
import { useOnboarding } from "modules/onboarding/onboarding.context";
import {
  OnboardingStep,
  getOnboardingPath,
} from "modules/onboarding/routes/onboarding.routes";

export const EmailVerified = () => {
  const [loginOnboardingResult, loginOnboarding] = useMutation<
    { loginOnboarding: boolean },
    { token: string }
  >(gql`
    mutation LoginOnboarding($token: String!) {
      loginOnboarding(in: { token: $token })
    }
  `);

  const {
    state: { isLoading: isContextLoading },
    data: { onboardingSummary, currentStepRoute },
    actions: { setPauseSummaryQuery },
  } = useOnboarding();

  let { token } = useParams();
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate(getOnboardingPath(OnboardingStep.PhoneAdd));
  };

  const handleNavigateBack = () => {
    navigate(getOnboardingPath(OnboardingStep.Start));
  };

  const onLogin = useCallback(async () => {
    // Avoid summary query to conflict with login mutation
    setPauseSummaryQuery(true);
    if (token) {
      const result = await loginOnboarding(
        { token },
        {
          additionalTypenames: ["CompanyOnboarding"],
        }
      );
      if (!result.error) {
        // We can resume summary query now
        setPauseSummaryQuery(false);
      }
    }
  }, [token, loginOnboarding, setPauseSummaryQuery]);

  useEffect(() => {
    onLogin();
  }, [onLogin]);

  const isLoading = useMemo(
    () => loginOnboardingResult.fetching || isContextLoading,
    [loginOnboardingResult.fetching, isContextLoading]
  );

  if (!isLoading && currentStepRoute && onboardingSummary?.phoneNumber) {
    return <Navigate replace to={currentStepRoute} />;
  }

  return (
    <OnboardingPage isLoading={isLoading || !currentStepRoute}>
      {onboardingSummary ? (
        <>
          <OnboardingHeader
            icon={MdOutlineMarkEmailUnread}
            subtitle={<>Ya podés continuar con el proceso de registro.</>}
            title="Verificamos tu cuenta con éxito"
          />

          <OnboardingButton mt={20} onClick={handleContinue}>
            Continuar
          </OnboardingButton>
        </>
      ) : (
        <>
          <OnboardingHeader
            icon={MdOutlineMarkEmailUnread}
            subtitle="El enlace que te mandamos expiró. Por favor, solicitá el reenvío del correo electrónico de validación."
            title="No pudimos verificar tu cuenta"
          />

          <OnboardingButton mt={20} onClick={handleNavigateBack}>
            Reenviar correo
          </OnboardingButton>
        </>
      )}
    </OnboardingPage>
  );
};
