import { RouteObject } from "react-router-dom";

import { bulkPaymentsRoutes } from "./bulk-payments/bulk-payments.routes";
import { homeRoutes } from "./home/home.routes";
import { loginRoutes } from "./login/login.routes";

import { DashboardAuthWall } from "modules/dashboard/components/dashboard-auth-wall";
import { DashboardWrapper } from "modules/dashboard/dashboard.wrapper";

export const dashboardRoutes: RouteObject[] = [
  {
    element: <DashboardWrapper />,
    children: [
      ...loginRoutes,
      {
        element: <DashboardAuthWall />,
        children: [...homeRoutes, ...bulkPaymentsRoutes],
      },
    ],
  },
];
