import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Select,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { useCallback, useId, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { DocumentTypeBySocietyType } from "modules/onboarding/onboarding.types";

type OmitDocumentFields = {
  reason: string;
  customReason: string;
};

export const OmitDocumentModal = ({
  documentType,
  isOpen,
  onClose: _onClose,
  onSubmit: _onSubmit,
}: {
  documentType: DocumentTypeBySocietyType | null;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (id: number, reason: string) => Promise<boolean>;
}) => {
  const reasonOptions = useMemo(
    () => ["Compañia recientemente constituida", "Surge del estatuto", "Otro"],
    []
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch,
  } = useForm<OmitDocumentFields>();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const formId = useId();

  const onClose = useCallback(() => {
    reset();
    _onClose();
  }, [reset, _onClose]);

  const onSubmit: SubmitHandler<OmitDocumentFields> = useCallback(
    async (data) => {
      if (documentType) {
        setIsSubmitting(true);
        const wasSuccessful = await _onSubmit(
          documentType.documentTypeId,
          data.reason === "Otro" ? data.customReason : data.reason
        );
        if (wasSuccessful) {
          onClose();
        }
        setIsSubmitting(false);
      }
    },
    [_onSubmit, documentType, onClose]
  );

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent mx={2} overflow="hidden">
        <ModalCloseButton />
        <ModalHeader fontSize="2xl" mb={0} px={8}>
          Omitir la carga del documento
        </ModalHeader>
        <ModalBody pb={8} px={8}>
          <Stack
            spacing={3}
            as="form"
            id={formId}
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormControl>
              <FormLabel>
                Indicá el motivo por el cual no vas a cargar este documento.
              </FormLabel>
              <Select placeholder="Elegir" {...register("reason")}>
                {reasonOptions.map((reason) => (
                  <option key={reason} value={reason}>
                    {reason}
                  </option>
                ))}
              </Select>
            </FormControl>
            {watch("reason") === "Otro" && (
              <FormControl isInvalid={errors.customReason !== undefined}>
                <FormLabel display="none">Descripción del motivo</FormLabel>
                <Textarea
                  placeholder="Escribí un motivo..."
                  {...register("customReason", {
                    required:
                      "Escribí un motivo para omitir la carga del documento.",
                    maxLength: {
                      value: 255,
                      message: "El motivo no puede superar los 255 caracteres.",
                    },
                  })}
                ></Textarea>
                <FormErrorMessage>
                  {errors.customReason?.message}
                </FormErrorMessage>
              </FormControl>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button
              colorScheme="gray"
              onClick={onClose}
              isDisabled={isSubmitting}
            >
              Cancelar
            </Button>
            <Button type="submit" form={formId} isLoading={isSubmitting}>
              Aceptar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
