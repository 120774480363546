import {
  ReactNode,
  Reducer,
  createContext,
  useCallback,
  useEffect,
} from "react";
import { CombinedError, useClient } from "urql";

import { useAlertDialog } from "contexts/alert-dialog.context";
import { useContextSafe } from "hooks/use-context-safe.hook";
import { useReducerAlt } from "hooks/use-reducer-alt.hook";
import type { EmployeeInput } from "modules/dashboard/routes/bulk-payments/bulk-payments-salaries.api";
import {
  Employee,
  addEmployee,
  createBulkPaymentFromPayroll,
  deleteEmployee,
  fetchEmployees,
  updateEmployeeSalary,
} from "modules/dashboard/routes/bulk-payments/bulk-payments-salaries.api";
import {
  BulkPaymentSummary,
  cancelBulkPayment,
  BulkPaymentOptions,
} from "modules/dashboard/routes/bulk-payments/bulk-payments.api";
import { Currency, AmountWithCurrency } from "types/api-global.types";

export type BulkPaymentsPayrollConfirmationResult = {
  bulkPayment: BulkPaymentSummary | null;
  error: CombinedError | null;
};

export const BulkPaymentsPayrollContext = createContext<{
  actions: {
    onFetchEmployeesFirstPage: () => Promise<void>;
    onFetchEmployeesNextPage: () => Promise<void>;
    onAddEmployee: (employee: EmployeeInput) => Promise<boolean>;
    onUpdateEmployeeSalary: (
      employeeId: string,
      salary: AmountWithCurrency
    ) => Promise<boolean>;
    onDeleteEmployee: (employeeId: string) => Promise<void>;
    onBulkDeleteEmployees: (employeeIds: string[]) => Promise<void>;
    onConfirmBulkPayment: () => Promise<void>;
    onCancelBulkPayment: () => Promise<boolean>;
    onUpdateScheduledFor: (
      scheduledFor: BulkPaymentOptions["scheduledFor"]
    ) => void;
  };
  data: {
    employees: Employee[];
    employeeCount: number;
    totalAmount: AmountWithCurrency;
    confirmationResult: BulkPaymentsPayrollConfirmationResult;
    scheduledFor: BulkPaymentOptions["scheduledFor"];
  };
  state: {
    isFetching: boolean;
    isFetchingFirstPage: boolean;
    areMorePagesAvailable: boolean;
    isAdding: boolean;
    isUpdatingIds: string[];
    isUpdating: (employeeId: string) => boolean;
    isConfirmingBulkPayment: boolean;
    isCancellingBulkPayment: boolean;
  };
} | null>(null);

export const useBulkPaymentsPayroll = () =>
  useContextSafe(BulkPaymentsPayrollContext);

type BulkPaymentsPayrollState = {
  employees: Employee[];
  cursor: string | null;
  metadata: {
    employeeCount: number;
    totalAmount: AmountWithCurrency;
  };
  isFetching: boolean;
  isFetchingFirstPage: boolean;
  isAdding: boolean;
  isUpdatingIds: string[];
  isConfirmingBulkPayment: boolean;
  isCancellingBulkPayment: boolean;
  confirmationResult: BulkPaymentsPayrollConfirmationResult;
  scheduledFor: BulkPaymentOptions["scheduledFor"];
};

type BulkPaymentsPayrollAction =
  | {
      type: "ADD_EMPLOYEES";
      payload: {
        employees: Employee[];
        placement?: "start" | "end" | "replace";
      };
    }
  | {
      type: "UPDATE_EMPLOYEE";
      payload: Partial<Employee> & { id: string };
    }
  | {
      type: "DELETE_EMPLOYEE";
      payload: string;
    }
  | {
      type: "SET_CURSOR";
      payload: string | null;
    }
  | {
      type: "SET_METADATA";
      payload:
        | Partial<BulkPaymentsPayrollState["metadata"]>
        | ((
            prev: BulkPaymentsPayrollState["metadata"]
          ) => Partial<BulkPaymentsPayrollState["metadata"]>);
    }
  | {
      type: "SET_IS_FETCHING";
      payload: { firstPage?: boolean; value: boolean };
    }
  | {
      type: "SET_IS_ADDING";
      payload: boolean;
    }
  | {
      type: "SET_IS_UPDATING";
      payload: { ids: string[]; value: boolean };
    }
  | {
      type: "SET_IS_CONFIRMING_PAYMENT";
      payload: boolean;
    }
  | {
      type: "SET_IS_CANCELLING_PAYMENT";
      payload: boolean;
    }
  | {
      type: "SET_CONFIRMATION_RESULT";
      payload: BulkPaymentsPayrollConfirmationResult;
    }
  | {
      type: "SET_SCHEDULED_FOR";
      payload: BulkPaymentOptions["scheduledFor"];
    };

const BulkPaymentsPayrollReducer: Reducer<
  BulkPaymentsPayrollState,
  BulkPaymentsPayrollAction
> = (state, { type, payload }): BulkPaymentsPayrollState => {
  switch (type) {
    case "ADD_EMPLOYEES":
      if (payload.placement === "replace") {
        return { ...state, employees: payload.employees };
      } else if (payload.placement === "start") {
        return {
          ...state,
          employees: [...payload.employees, ...state.employees],
        };
      } else {
        return {
          ...state,
          employees: [...state.employees, ...payload.employees],
        };
      }
    case "UPDATE_EMPLOYEE":
      return {
        ...state,
        employees: state.employees.map((employee) =>
          employee.id === payload.id ? { ...employee, ...payload } : employee
        ),
      };
    case "DELETE_EMPLOYEE":
      return {
        ...state,
        employees: state.employees.filter(
          (employee) => employee.id !== payload
        ),
      };
    case "SET_CURSOR":
      return { ...state, cursor: payload };
    case "SET_IS_FETCHING":
      return {
        ...state,
        [payload.firstPage ? "isFetchingFirstPage" : "isFetching"]:
          payload.value,
      };
    case "SET_METADATA":
      return {
        ...state,
        metadata: {
          ...state.metadata,
          ...(typeof payload === "function"
            ? payload(state.metadata)
            : payload),
        },
      };
    case "SET_IS_ADDING":
      return { ...state, isAdding: payload };
    case "SET_IS_UPDATING":
      return {
        ...state,
        isUpdatingIds: payload.value
          ? [...state.isUpdatingIds, ...payload.ids]
          : state.isUpdatingIds.filter((id) => !payload.ids.includes(id)),
      };
    case "SET_IS_CONFIRMING_PAYMENT":
      return { ...state, isConfirmingBulkPayment: payload };
    case "SET_IS_CANCELLING_PAYMENT":
      return { ...state, isCancellingBulkPayment: payload };
    case "SET_CONFIRMATION_RESULT":
      return { ...state, confirmationResult: payload };
    case "SET_SCHEDULED_FOR":
      return { ...state, scheduledFor: payload };
  }
};

export const BulkPaymentsPayrollProvider = ({
  pageSize = 50,
  children,
}: {
  pageSize?: number;
  children: ReactNode;
}) => {
  const client = useClient();
  const alertDialog = useAlertDialog();
  const [state, dispatch] = useReducerAlt(BulkPaymentsPayrollReducer, {
    employees: [],
    cursor: null,
    metadata: {
      employeeCount: 0,
      totalAmount: { amountInCents: 0, currency: Currency.ARS },
    },
    isFetching: false,
    isFetchingFirstPage: false,
    isAdding: false,
    isUpdatingIds: [],
    isConfirmingBulkPayment: false,
    isCancellingBulkPayment: false,
    confirmationResult: {
      bulkPayment: null,
      error: null,
    },
    scheduledFor: null,
  });

  /**
   * API requests
   */
  const onFetchEmployeesPage = useCallback(
    async (cursor: string | null) => {
      dispatch("SET_IS_FETCHING", { firstPage: cursor === null, value: true });
      const result = await fetchEmployees(client, pageSize, cursor);
      if (!result.error && result.data) {
        dispatch("ADD_EMPLOYEES", {
          employees: result.data.payroll.employees.employees,
          placement: cursor === null ? "replace" : "end",
        });
        dispatch("SET_METADATA", {
          employeeCount: result.data.payroll.metadata?.count ?? 0,
          totalAmount: result.data.payroll.metadata?.amount ?? {
            amountInCents: 0,
            currency: Currency.ARS,
          },
        });
        dispatch("SET_CURSOR", result.data.payroll.employees.cursor);
      }
      dispatch("SET_IS_FETCHING", { firstPage: cursor === null, value: false });
    },
    [client, dispatch, pageSize]
  );

  const onFetchEmployeesFirstPage = useCallback(async () => {
    await onFetchEmployeesPage(null);
  }, [onFetchEmployeesPage]);

  const onFetchEmployeesNextPage = useCallback(async () => {
    await onFetchEmployeesPage(state.cursor);
  }, [onFetchEmployeesPage, state.cursor]);

  const onAddEmployee = useCallback(
    async (employee: EmployeeInput) => {
      dispatch("SET_IS_ADDING", true);
      const result = await addEmployee(client, employee);
      if (!result.error && result.data) {
        dispatch("ADD_EMPLOYEES", {
          employees: [result.data.addEmployee],
          placement: "start",
        });
        dispatch("SET_METADATA", ({ employeeCount, totalAmount }) => ({
          employeeCount: employeeCount + 1,
          totalAmount: {
            ...totalAmount,
            amountInCents:
              totalAmount.amountInCents + employee.salary.amountInCents,
          },
        }));
      }
      dispatch("SET_IS_ADDING", false);
      return result.error === undefined;
    },
    [client, dispatch]
  );

  const onUpdateEmployeeSalary = useCallback(
    async (employeeId: string, salary: AmountWithCurrency) => {
      dispatch("SET_IS_UPDATING", { ids: [employeeId], value: true });
      const prevSalaryAmount =
        state.employees.find((employee) => employee.id === employeeId)?.salary
          .amountInCents ?? 0;
      const result = await updateEmployeeSalary(client, employeeId, salary);
      if (!result.error) {
        dispatch("UPDATE_EMPLOYEE", {
          id: employeeId,
          salary,
        });
        dispatch("SET_METADATA", ({ totalAmount }) => ({
          totalAmount: {
            ...totalAmount,
            amountInCents:
              totalAmount.amountInCents +
              (salary.amountInCents - prevSalaryAmount),
          },
        }));
      }
      dispatch("SET_IS_UPDATING", { ids: [employeeId], value: false });
      return result.error === undefined;
    },
    [client, dispatch, state.employees]
  );

  const onDeleteEmployee = useCallback(
    async (employeeId: string) => {
      const confirmed = await alertDialog({
        title: "¿Querés eliminar la información de esta persona?",
        message:
          "Si te arrepentís, no te preocupes. Vas a poder volver a cargar sus datos cuando quieras.",
        cancelButton: { text: "No eliminar" },
        confirmButton: { text: "Eliminar" },
      });
      if (confirmed) {
        dispatch("SET_IS_UPDATING", { ids: [employeeId], value: true });
        const salaryAmount =
          state.employees.find((employee) => employee.id === employeeId)?.salary
            .amountInCents ?? 0;
        const result = await deleteEmployee(client, employeeId);
        if (!result.error) {
          dispatch("DELETE_EMPLOYEE", employeeId);
          dispatch("SET_METADATA", ({ employeeCount, totalAmount }) => ({
            employeeCount: employeeCount - 1,
            totalAmount: {
              ...totalAmount,
              amountInCents: totalAmount.amountInCents - salaryAmount,
            },
          }));
        }
        dispatch("SET_IS_UPDATING", { ids: [employeeId], value: false });
      }
    },
    [client, dispatch, alertDialog, state.employees]
  );

  const onBulkDeleteEmployees = useCallback(
    async (employeeIds: string[]) => {
      const confirmed = await alertDialog({
        title: `¿Querés eliminar la información de ${
          employeeIds.length === 1
            ? "esta persona"
            : `estas ${employeeIds.length} personas`
        }?`,
        message:
          "Si te arrepentís, no te preocupes. Vas a poder volver a cargar sus datos cuando quieras.",
        cancelButton: { text: "No eliminar" },
        confirmButton: { text: "Eliminar" },
      });
      if (confirmed) {
        dispatch("SET_IS_UPDATING", { ids: employeeIds, value: true });
        employeeIds.forEach(async (employeeId) => {
          const salaryAmount =
            state.employees.find((employee) => employee.id === employeeId)
              ?.salary.amountInCents ?? 0;
          const result = await deleteEmployee(client, employeeId);
          if (!result.error) {
            dispatch("DELETE_EMPLOYEE", employeeId);
            dispatch("SET_METADATA", ({ employeeCount, totalAmount }) => ({
              employeeCount: employeeCount - 1,
              totalAmount: {
                ...totalAmount,
                amountInCents: totalAmount.amountInCents - salaryAmount,
              },
            }));
          }
          dispatch("SET_IS_UPDATING", { ids: [employeeId], value: false });
        });
      }
    },
    [client, dispatch, alertDialog, state.employees]
  );

  const onConfirmBulkPayment = useCallback(async () => {
    dispatch("SET_IS_CONFIRMING_PAYMENT", true);
    const result = await createBulkPaymentFromPayroll(
      client,
      { scheduledFor: state.scheduledFor },
      {
        noErrorToast: true,
      }
    );
    dispatch("SET_IS_CONFIRMING_PAYMENT", false);
    dispatch("SET_CONFIRMATION_RESULT", {
      bulkPayment: result.data?.createBulkPaymentFromPayroll ?? null,
      error: result.error ?? null,
    });
  }, [client, dispatch, state.scheduledFor]);

  const onCancelBulkPayment = useCallback(async () => {
    const { bulkPayment } = state.confirmationResult;
    if (bulkPayment) {
      const confirmed = await alertDialog({
        title: "¿Querés cancelar el pago de sueldos?",
        message: "No vas a poder deshacer esta acción.",
        cancelButton: { text: "No cancelar" },
        confirmButton: { text: "Cancelar" },
      });
      if (confirmed) {
        dispatch("SET_IS_CANCELLING_PAYMENT", true);
        const result = await cancelBulkPayment(client, bulkPayment.id);
        dispatch("SET_IS_CANCELLING_PAYMENT", false);
        if (!result.error) {
          dispatch("SET_CONFIRMATION_RESULT", {
            bulkPayment: null,
            error: null,
          });
          return true;
        }
      }
    }
    return false;
  }, [client, dispatch, alertDialog, state.confirmationResult]);

  const onUpdateScheduledFor = useCallback(
    (scheduledFor: BulkPaymentOptions["scheduledFor"]) => {
      dispatch("SET_SCHEDULED_FOR", scheduledFor);
    },
    [dispatch]
  );

  // Fetch the first page on mount
  useEffect(() => {
    onFetchEmployeesFirstPage();
  }, [onFetchEmployeesFirstPage]);

  return (
    <BulkPaymentsPayrollContext.Provider
      value={{
        actions: {
          onFetchEmployeesFirstPage,
          onFetchEmployeesNextPage,
          onAddEmployee,
          onUpdateEmployeeSalary,
          onDeleteEmployee,
          onBulkDeleteEmployees,
          onConfirmBulkPayment,
          onCancelBulkPayment,
          onUpdateScheduledFor,
        },
        data: {
          employees: state.employees,
          employeeCount: state.metadata.employeeCount,
          totalAmount: state.metadata.totalAmount,
          confirmationResult: state.confirmationResult,
          scheduledFor: state.scheduledFor,
        },
        state: {
          isFetching: state.isFetching,
          isFetchingFirstPage: state.isFetchingFirstPage,
          areMorePagesAvailable: state.cursor !== null,
          isAdding: state.isAdding,
          isUpdatingIds: state.isUpdatingIds,
          isUpdating: (employeeId: string) =>
            state.isUpdatingIds.includes(employeeId),
          isConfirmingBulkPayment: state.isConfirmingBulkPayment,
          isCancellingBulkPayment: state.isCancellingBulkPayment,
        },
      }}
    >
      {children}
    </BulkPaymentsPayrollContext.Provider>
  );
};
