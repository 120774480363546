export const applyDecimalScale = (
  number: number | string,
  decimalScale: number
): [string, string] => {
  const amountAsString = number.toString().split(".");
  const amountAsJoinedString = amountAsString.join("");
  const pointIndex = Math.max(0, amountAsString[0]!.length - decimalScale);
  const integerPart =
    amountAsJoinedString.slice(0, pointIndex) +
      (pointIndex > amountAsJoinedString.length
        ? "0".repeat(pointIndex - amountAsJoinedString.length)
        : "") || "0";
  const decimalPart = amountAsJoinedString
    .slice(pointIndex)
    .padStart(decimalScale, "0");
  return [integerPart, decimalPart];
};

export const parseNumber = (
  number: string,
  {
    decimalScale = 0,
    decimalSeparator = ",",
  }: {
    decimalScale?: number;
    decimalSeparator?: string;
  } = {}
): number => {
  const [inputIntegerPart, inputDecimalPart] = number
    .replace(new RegExp(`[^\\d${decimalSeparator}]`, "g"), "")
    .split(decimalSeparator);
  const integerPart =
    inputIntegerPart +
    (inputDecimalPart ?? "").slice(0, decimalScale).padEnd(decimalScale, "0");
  if (inputDecimalPart) {
    const decimalPart = inputDecimalPart.slice(decimalScale);
    return parseFloat(integerPart + "." + decimalPart);
  } else {
    return parseInt(integerPart, 10);
  }
};

export const formatWithDecimalScale = (
  number: number | string,
  decimalScale: number,
  { decimalSeparator = ",", removeTrailingZeros = false } = {}
): string => {
  const [integerPart, decimalPart] = applyDecimalScale(number, decimalScale);
  const formattedDecimalPart = removeTrailingZeros
    ? decimalPart.replace(/0+$/, "")
    : decimalPart;
  return formattedDecimalPart
    ? `${integerPart}${decimalSeparator}${formattedDecimalPart}`
    : integerPart;
};
