import { Button, Icon, useToast } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom";

import { BulkPaymentResult } from "modules/dashboard/components/bulk-payment-result";
import { useDashboard } from "modules/dashboard/dashboard.context";
import { useBulkPaymentsPayroll } from "modules/dashboard/routes/bulk-payments/bulk-payments-payroll/bulk-payments-payroll.context";
import { Currency } from "types/api-global.types";
import { buildErrorMessage } from "utils/errors.utils";
import { formatAmountWithCurrency } from "utils/money.utils";

export const BulkPaymentsPayrollResult = ({
  onPrevious,
}: {
  onPrevious: () => void;
}) => {
  const toast = useToast();

  const {
    data: { confirmationResult },
    actions: { onCancelBulkPayment },
    state: { isCancellingBulkPayment },
  } = useBulkPaymentsPayroll();

  const {
    data: { company },
  } = useDashboard();

  const onCancel = useCallback(async () => {
    const successful = await onCancelBulkPayment();
    if (successful) {
      toast({
        description: "El pago fue cancelado exitosamente.",
        status: "success",
        title: "Pago cancelado",
      });
      onPrevious();
    }
  }, [onCancelBulkPayment, onPrevious, toast]);

  const paymentConditions = (
    <>
      de manera <strong>inmediata</strong>
    </>
  );

  const wasSuccessful = useMemo(
    () => confirmationResult.bulkPayment !== null,
    [confirmationResult]
  );

  const errorMessage = useMemo(
    () =>
      confirmationResult?.error
        ? buildErrorMessage(confirmationResult.error)
        : "Ocurrió un error al intentar confirmar el pago. Por favor, intentá nuevamente más tarde",
    [confirmationResult]
  );

  return (
    <BulkPaymentResult
      status={wasSuccessful ? "success" : "error"}
      title={
        wasSuccessful ? "¡Estás a un paso de pagar sueldos!" : "Algo salió mal"
      }
      body={
        confirmationResult.bulkPayment ? (
          <>
            Las personas firmantes ya fueron notificados. Cuando se apruebe el
            pago, vamos a debitar{" "}
            <strong>
              {formatAmountWithCurrency(
                confirmationResult.bulkPayment.paymentsMetadata?.total
                  .amount ?? {
                  amountInCents: 0,
                  currency: Currency.ARS,
                }
              )}
            </strong>{" "}
            de la caja de ahorro de{" "}
            <strong>{company?.legalName ?? "la empresa"}</strong>{" "}
            {paymentConditions}.
          </>
        ) : (
          errorMessage
        )
      }
      actions={
        confirmationResult.bulkPayment ? (
          <>
            <Button
              as={Link}
              rounded="full"
              size="lg"
              to={`/pagos/${confirmationResult.bulkPayment.id}`}
            >
              Ir al resumen
            </Button>

            <Button
              colorScheme="blackAlpha"
              isLoading={isCancellingBulkPayment}
              onClick={onCancel}
              rounded="full"
              size="lg"
              variant="outline"
            >
              Cancelar pago
            </Button>
          </>
        ) : (
          <Button
            colorScheme="blackAlpha"
            leftIcon={<Icon as={FiArrowLeft} />}
            onClick={onPrevious}
            rounded="full"
            size="lg"
            variant="outline"
          >
            Volver
          </Button>
        )
      }
    />
  );
};
