import { Box, ChakraProps, HStack, Stack, Text } from "@chakra-ui/react";

import { PropsWithRest } from "types/react-utils";

export const LineStepper = ({
  steps,
  activeStep,
  activeStepColor,
  ...rest
}: PropsWithRest<
  {
    steps: string[];
    activeStep: number;
    activeStepColor?: ChakraProps["color"];
  },
  typeof HStack
>) => {
  return (
    <HStack {...rest} align="start" spacing={4}>
      {steps.map((step, index) => {
        return (
          <Stack key={index} w={{ base: 160, xl: 180 }}>
            <Box
              h={1}
              rounded="full"
              bg={
                index === activeStep
                  ? activeStepColor ?? "brand.500"
                  : "blackAlpha.300"
              }
            />
            <Text
              color={index === activeStep ? "black" : "blackAlpha.500"}
              fontSize="sm"
              fontWeight="bold"
            >
              {index + 1}. {step}
            </Text>
          </Stack>
        );
      })}
    </HStack>
  );
};
