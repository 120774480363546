import { Accordion, Heading, Stack } from "@chakra-ui/react";
import { useMemo } from "react";

import { CompanyItem } from "./company-item";
import { transformSourceToSocietyStructure } from "./society-structure-utils";

import { useOnboarding } from "modules/onboarding/onboarding.context";
import {
  SocietyStructureNodeType,
  SocietyStructure as SocietyStructureType,
} from "modules/onboarding/onboarding.types";

export const SocietyStructure = () => {
  const {
    data: { onboardingSummary },
  } = useOnboarding();

  const societyStructure = transformSourceToSocietyStructure(
    onboardingSummary?.societyDetails.structure ?? ({} as SocietyStructureType)
  );

  const totalShares = useMemo(() => {
    return societyStructure.reduce((acc, member) => {
      return acc + (member.sharesWithDecimals ?? 0);
    }, 0);
  }, [societyStructure]);

  return (
    <Stack>
      <Heading as="h3" fontSize="md">
        Estructura&nbsp;societaria
      </Heading>
      <Accordion allowMultiple>
        <CompanyItem
          company={{
            __typename: SocietyStructureNodeType.Company,
            id: "",
            members: societyStructure ?? [],
            name: onboardingSummary?.companyDescription.fantasyName ?? "",
            taxId: onboardingSummary?.companyDescription.taxId ?? "",
            parentCompanyId: null,
            sharesWithDecimals: totalShares ?? 0,
          }}
        />
      </Accordion>
    </Stack>
  );
};
