import { Card, CardHeader, CardBody } from "@chakra-ui/card";
import { Alert, AlertDescription, useTheme } from "@chakra-ui/react";
import chroma from "chroma-js";

import { BulkPaymentsPayrollEditorBody } from "./payroll-editor-body";
import { BulkPaymentsPayrollEditorHeader } from "./payroll-editor-header";
import { BulkPaymentsPayrollEditorProvider } from "./payroll-editor.context";

import { useBulkPaymentsPayroll } from "modules/dashboard/routes/bulk-payments/bulk-payments-payroll/bulk-payments-payroll.context";

export const BulkPaymentsPayrollEditor = () => {
  const {
    data: { employeeCount },
    state: { isFetchingFirstPage },
  } = useBulkPaymentsPayroll();

  const helpAlertBg = chroma(useTheme().colors.green[300]).alpha(0.25).hex();

  return (
    <BulkPaymentsPayrollEditorProvider>
      {!isFetchingFirstPage && employeeCount === 0 && (
        <Alert
          alignItems="start"
          bg={helpAlertBg}
          flexDirection="column"
          mb={6}
          px={8}
          py={4}
          rounded="xl"
          shadow="inner"
          status="success"
        >
          <AlertDescription>
            Agregá a personas empleadas para poder crear pagos de sueldos. Una
            vez que confirmes el pago, se le va a enviar una notificación a las
            personas firmantes para que aprueben los depósitos.
          </AlertDescription>
        </Alert>
      )}

      <Card rounded="xl" shadow="lg">
        <CardHeader pb={0} pt={6} px={8}>
          <BulkPaymentsPayrollEditorHeader />
        </CardHeader>
        <CardBody pt={6}>
          <BulkPaymentsPayrollEditorBody />
        </CardBody>
      </Card>
    </BulkPaymentsPayrollEditorProvider>
  );
};
