import { Flex, Text } from "@chakra-ui/react";

export const ActionBarStat = ({
  title,
  value,
  partialValue,
  valueColor = "gray.800",
}: {
  title: string;
  value: string;
  partialValue?: string;
  valueColor?: string;
}) => {
  return (
    <Flex direction="column" justify="center" lineHeight="shorter">
      <Text
        fontSize="xs"
        fontWeight="bold"
        letterSpacing="wide"
        mb={0}
        textTransform="uppercase"
      >
        {title}
      </Text>
      <Text color={valueColor} fontSize="3xl" fontWeight="bold" mb={0}>
        {partialValue && (
          <Text as="span" color="blackAlpha.400">
            {partialValue} de
          </Text>
        )}{" "}
        {value}
      </Text>
    </Flex>
  );
};
