import {
  LinkBox,
  Center,
  Grid,
  HStack,
  Heading,
  Icon,
  Stack,
  Text,
  LinkOverlay,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { FiEdit, FiPaperclip, FiXCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { ActionButtonProps } from "./action-button";
import { SectionHeader } from "./section-header";

import { useOnboarding } from "modules/onboarding/onboarding.context";
import { CompanyOnboardingDoc } from "modules/onboarding/onboarding.types";
import {
  OnboardingStep,
  getOnboardingPath,
} from "modules/onboarding/routes/onboarding.routes";

export const Documentation = ({
  data,
  errors,
  isDisabled,
  showErrors,
}: {
  data?: CompanyOnboardingDoc[];
  errors: string[];
  isDisabled: boolean;
  showErrors: boolean;
}) => {
  const navigate = useNavigate();
  const hasDocs = data && data.length > 0;

  const {
    data: { onboardingSummary },
  } = useOnboarding();

  const canUpload = useMemo(() => {
    return onboardingSummary?.companyDescription?.societyType?.id;
  }, [onboardingSummary]);

  const handleClick = () => {
    navigate(getOnboardingPath(OnboardingStep.Documents), {
      state: { fromSummary: true },
    });
  };

  const button: ActionButtonProps = {
    buttonIcon: <FiEdit />,
    buttonLabel: "Adjuntar documentos",
    isDisabled,
    onButtonClick: handleClick,
    buttonTooltip: !canUpload
      ? "Seleccioná el tipo societario de la empresa para poder adjuntar documentación."
      : "",
  };

  return (
    <Stack direction="column" spacing={5}>
      <SectionHeader title="Documentación" button={button} />
      {hasDocs ? (
        <Grid
          columnGap={4}
          rowGap={5}
          templateColumns={{ base: "1fr", sm: "repeat(2, 1fr)" }}
        >
          {data.map((document) => (
            <LinkBox
              key={document.id}
              _dark={{ bg: "gray.700", _hover: { bg: "gray.600" } }}
              _hover={document.attachment ? { bg: "gray.200" } : {}}
              bg="gray.100"
              px={5}
              py={4}
              rounded="xl"
              transition="background-color .2s ease-in-out"
              minW={0}
            >
              <HStack align="start" spacing={4}>
                <Icon
                  as={document.attachment ? FiPaperclip : FiXCircle}
                  boxSize={6}
                  mt={2}
                />
                <Stack align="flex-start" flexGrow={1} minW={0} spacing={1}>
                  <Heading maxW="full" size="sm" noOfLines={2}>
                    {document.typeBySociety.documentName}
                  </Heading>
                  <Text
                    color="gray.400"
                    fontSize="md"
                    fontWeight="semibold"
                    lineHeight="shorter"
                    maxW="full"
                  >
                    {document.attachment ? (
                      <LinkOverlay
                        href={document.attachment.publicURL}
                        isExternal
                        noOfLines={1}
                      >
                        {document.fileName}
                      </LinkOverlay>
                    ) : (
                      <Text wordBreak="break-word" noOfLines={2}>
                        Omitido: {document.skipExplanation}
                      </Text>
                    )}
                  </Text>
                </Stack>
              </HStack>
            </LinkBox>
          ))}
        </Grid>
      ) : (
        <Center>
          <Text color="gray.600" fontWeight="bold">
            Acá va a estar toda la documentación que adjuntes.
          </Text>
        </Center>
      )}

      {showErrors && errors.length > 0 && (
        <Stack spacing={2}>
          {errors.map((error, index) => (
            <Text key={index} color="red.500" fontSize="sm" textAlign="center">
              {error}
            </Text>
          ))}
        </Stack>
      )}
    </Stack>
  );
};
