import { Client, gql } from "urql";

import { AmountWithCurrency } from "types/api-global.types";

export type Company = {
  id: string;
  userId: string;
  legalName: string;
  fantasyName: string;
  taxId: string;
  logo: {
    publicURL: string;
  };
};

export type CompanyAccountBalance = {
  id: string;
  type: string;
  cbu: string;
  alias: string | null;
  balance: AmountWithCurrency;
};

export type Transaction = {
  id: string;
  type: string;
  time: string;
  title: string;
  amount: AmountWithCurrency;
  imageURL: string | null;
  reverseFromId: string | null;
  reverseId: string | null;
};

export const requestExternalOperatorLogin = async (
  client: Client,
  { email, taxId }: { email: string; taxId: string },
  recaptchaToken: string | null
) => {
  return client.mutation<
    { requestExternalOperatorLogin: null },
    { operatorEmail: string; companyTaxId: string }
  >(
    gql`
      mutation RequestExternalOperatorLogin(
        $operatorEmail: String!
        $companyTaxId: String!
      ) {
        requestExternalOperatorLogin(
          in: { operatorEmail: $operatorEmail, companyTaxId: $companyTaxId }
        )
      }
    `,
    {
      operatorEmail: email,
      companyTaxId: taxId,
    },
    {
      fetchOptions: {
        headers: recaptchaToken
          ? {
              "x-captcha-token": recaptchaToken,
            }
          : {},
      },
    }
  );
};

export const loginExternalOperator = async (
  client: Client,
  { token }: { token: string }
) => {
  return client.mutation<{ loginExternalOperator: null }, { token: string }>(
    gql`
      mutation LoginExternalOperator($token: String!) {
        loginExternalOperator(in: { token: $token })
      }
    `,
    {
      token,
    }
  );
};

export const logoutExternalOperator = async (client: Client) => {
  return client.mutation<{ logoutExternalOperator: null }, {}>(
    gql`
      mutation LogoutExternalOperator {
        logoutExternalOperator
      }
    `,
    {}
  );
};

export const fetchCompany = async (client: Client) =>
  client.query<{ company: Company }, {}>(
    gql`
      query Company {
        company {
          id
          userId
          legalName
          fantasyName
          taxId
          logo {
            publicURL
          }
        }
      }
    `,
    {}
  );

export const fetchCompanyAccountBalances = async (client: Client) =>
  client.query<{ companyAccountBalances: CompanyAccountBalance[] }, {}>(
    gql`
      query CompanyAccountBalances {
        companyAccountBalances {
          id
          type
          cbu
          alias
          balance {
            amountInCents
            currency
          }
        }
      }
    `,
    {}
  );

export const fetchTransactions = async (client: Client, accountId, cursor) =>
  client.query<
    {
      transactions: { transactions: Transaction[]; nextCursor: string | null };
    },
    { accountId: string; cursor: string | null }
  >(
    gql`
      query Transactions($accountId: String!, $cursor: String) {
        transactions(
          in: { accountId: $accountId, cursor: $cursor, pageSize: 20 }
        ) {
          nextCursor
          transactions {
            id
            type
            time
            title
            amount {
              amountInCents
              currency
            }
            imageURL
            reverseFromId
            reverseId
          }
        }
      }
    `,
    { accountId, cursor }
  );
