import { Client, gql } from "urql";

import {
  BulkPaymentSummary,
  BulkPaymentSummaryFields,
  Contact,
  ContactFields,
  BulkPaymentOptions,
} from "./bulk-payments.api";

import { AmountWithCurrency } from "types/api-global.types";

export type Employee = {
  id: string;
  firstName: string;
  lastName: string;
  salary: AmountWithCurrency;
  contact: Contact;
};

export type EmployeesPage = {
  employees: Employee[];
  cursor: string | null;
};

export type EmployeeInput = {
  firstName: string;
  lastName: string;
  salary: AmountWithCurrency;
  taxId: string;
} & ({ cbu: string } | { alias: string });

export const EmployeeFields = gql`
  fragment EmployeeFields on Employee {
    id
    firstName
    lastName
    salary {
      amountInCents
      currency
    }
    contact {
      ...ContactFields
    }
  }
  ${ContactFields}
`;

export const fetchEmployees = async (
  client: Client,
  pageSize: number,
  cursor: string | null
) => {
  return client.query<
    {
      payroll: {
        employees: EmployeesPage;
        metadata: {
          amount: AmountWithCurrency;
          count: number;
        } | null;
      };
    },
    { cursor: string | null; pageSize: number }
  >(
    gql`
      query FetchEmployeesPage($cursor: String, $pageSize: Int) {
        payroll {
          employees(
            in: { pagination: { cursor: $cursor, pageSize: $pageSize } }
          ) {
            employees {
              ...EmployeeFields
            }
            cursor
          }
          metadata {
            amount {
              amountInCents
              currency
            }
            count
          }
        }
      }
      ${EmployeeFields}
    `,
    {
      cursor,
      pageSize,
    }
  );
};

export const addEmployee = async (client: Client, employee: EmployeeInput) => {
  return client.mutation<
    { addEmployee: Employee },
    { employee: EmployeeInput }
  >(
    gql`
      mutation AddEmployee($employee: EmployeeInput!) {
        addEmployee(in: $employee) {
          ...EmployeeFields
        }
      }
      ${EmployeeFields}
    `,
    {
      employee,
    }
  );
};

export const updateEmployeeSalary = async (
  client: Client,
  employeeId: string,
  salary: AmountWithCurrency
) => {
  return client.mutation<
    { updateEmployeeSalary: null },
    { employeeId: string; salary: AmountWithCurrency }
  >(
    gql`
      mutation UpdateEmployeeSalary(
        $employeeId: ID!
        $salary: AmountWithCurrencyInput!
      ) {
        updateEmployeeSalary(employeeId: $employeeId, in: $salary)
      }
    `,
    {
      employeeId,
      salary,
    }
  );
};

export const deleteEmployee = async (client: Client, employeeId: string) => {
  return client.mutation<{ deleteEmployee: null }, { employeeId: string }>(
    gql`
      mutation DeleteEmployee($employeeId: ID!) {
        deleteEmployee(employeeId: $employeeId)
      }
    `,
    { employeeId }
  );
};

/**
 * Confirms a salaries bulk payment.
 */
export const createBulkPaymentFromPayroll = async (
  client: Client,
  input: BulkPaymentOptions,
  context?: { noErrorToast?: boolean }
) => {
  return client.mutation<
    {
      createBulkPaymentFromPayroll: BulkPaymentSummary;
    },
    { input: BulkPaymentOptions }
  >(
    gql`
      mutation CreateBulkPaymentFromPayroll(
        $input: CreateBulkPaymentFromPayrollInput!
      ) {
        createBulkPaymentFromPayroll(in: $input) {
          ...BulkPaymentSummaryFields
        }
      }
      ${BulkPaymentSummaryFields}
    `,
    {
      input,
    },
    context
  );
};
