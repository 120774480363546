import {
  Input,
  Select,
  Flex,
  Stack,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { gql, useMutation } from "urql";

import { AutocompleteInput } from "components/autocomplete-input";
import { BusinessAvatar } from "components/business-avatar";
import { RadioSwitch } from "components/radio-switch";
import { OnboardingBackButton } from "modules/onboarding/components/onboarding-back-button";
import { OnboardingButton } from "modules/onboarding/components/onboarding-button";
import { OnboardingFieldHelp } from "modules/onboarding/components/onboarding-field-help";
import { OnboardingHeader } from "modules/onboarding/components/onboarding-header";
import { OnboardingLabel } from "modules/onboarding/components/onboarding-label";
import { OnboardingPage } from "modules/onboarding/components/onboarding-page";
import {
  BUSINESS_AGE_OPTIONS,
  EMPLOYEES_QUANTITY_OPTIONS,
} from "modules/onboarding/onboarding.constants";
import { useOnboarding } from "modules/onboarding/onboarding.context";
import { OnboardingSummary } from "modules/onboarding/onboarding.fragments";
import {
  CompanyDescription,
  CompanyOnboarding,
} from "modules/onboarding/onboarding.types";
import {
  OnboardingStep,
  getOnboardingPath,
} from "modules/onboarding/routes/onboarding.routes";

type Step2FormFields = {
  activityDescription: string;
  activityId: number;
  ageInBusiness: string;
  usesOtherBanks: "true" | "false";
  employeesQuantity: string;
};

const usesOtherBanksOptions = [
  { label: "Sí", value: "true" },
  { label: "No", value: "false" },
];

export const Step2 = () => {
  const {
    state: { isFetchingFormOptions, isSavingData },
    data: { onboardingSummary, isOnboardingFinished, formOptions },
    actions: { setIsSavingData, setRequiresFormReSubmission },
  } = useOnboarding();

  const [, updateCompanyOnboardingActivityDetails] = useMutation<
    {
      updateCompanyOnboardingDetails: {
        companyOnboarding: CompanyOnboarding;
      };
    },
    Pick<
      CompanyDescription,
      "activityDescription" | "ageInBusiness" | "employeesQuantity"
    > & {
      usesOtherBanks: boolean;
      activityId: number;
    }
  >(gql`
    mutation UpdateCompanyOnboardingActivityDetails(
      $activityDescription: String!
      $ageInBusiness: String!
      $usesOtherBanks: Boolean!
      $employeesQuantity: String!
      $activityId: ID!
    ) {
      updateCompanyOnboardingActivityDetails(
        in: {
          activityDescription: $activityDescription
          ageInBusiness: $ageInBusiness
          usesOtherBanks: $usesOtherBanks
          employeesQuantity: $employeesQuantity
          activityId: $activityId
        }
      ) {
        ...OnboardingSummary
      }
    }
    ${OnboardingSummary}
  `);

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm<Step2FormFields>({
    mode: "onTouched",
  });

  const navigate = useNavigate();
  const location = useLocation();
  const isComingFromSummary = location.state?.fromSummary || false;
  const navigateWhere = getOnboardingPath(
    isComingFromSummary ? OnboardingStep.Summary : OnboardingStep.Documents
  );

  const onSubmit: SubmitHandler<Step2FormFields> = async (data) => {
    if (isDirty) {
      setRequiresFormReSubmission(true);
    }
    setIsSavingData(true);
    const onboardingActivityDetails = {
      activityDescription: data.activityDescription,
      ageInBusiness: data.ageInBusiness,
      usesOtherBanks: data.usesOtherBanks === "false" ? false : true,
      employeesQuantity: data.employeesQuantity,
      activityId: data.activityId,
    };
    const result = await updateCompanyOnboardingActivityDetails(
      onboardingActivityDetails
    );
    if (!result.error) {
      navigate(navigateWhere);
    }
    setIsSavingData(false);
  };

  const handleNavigateBack = () => {
    navigate(getOnboardingPath(OnboardingStep.Step1), {
      state: location.state,
    });
  };

  useEffect(() => {
    reset({
      activityDescription:
        onboardingSummary?.companyDescription?.activityDescription,
      activityId: onboardingSummary?.companyDescription?.activity?.id,
      ageInBusiness: onboardingSummary?.companyDescription?.ageInBusiness
        ? BUSINESS_AGE_OPTIONS.find(
            ({ name }) =>
              name === onboardingSummary.companyDescription.ageInBusiness
          )?.name
        : "",
      usesOtherBanks: onboardingSummary?.companyDescription?.hasOtherBanks
        ? "true"
        : "false",
      employeesQuantity: onboardingSummary?.companyDescription
        ?.employeesQuantity
        ? EMPLOYEES_QUANTITY_OPTIONS.find(
            ({ name }) =>
              name === onboardingSummary.companyDescription.employeesQuantity
          )?.name
        : "",
    });
  }, [reset, onboardingSummary]);

  if (isOnboardingFinished) {
    return <Navigate to={getOnboardingPath(OnboardingStep.Summary)} />;
  }

  return (
    <OnboardingPage activeStep={0} isLoading={isFetchingFormOptions}>
      <OnboardingBackButton onClick={handleNavigateBack} />

      <OnboardingHeader mb={12} title="Completá los datos de la empresa" />

      <BusinessAvatar
        size="2xl"
        cuit={onboardingSummary?.companyDescription?.taxId}
        mb={8}
        name={onboardingSummary?.companyDescription?.fantasyName}
        photo={onboardingSummary?.companyDescription?.logo?.publicURL}
      />

      <Flex
        align="center"
        as="form"
        direction="column"
        maxW="sm"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        w="full"
      >
        <Stack spacing={4} w="full">
          <FormControl
            isInvalid={errors?.activityDescription !== undefined}
            isRequired
          >
            <OnboardingLabel>Rubro de la empresa</OnboardingLabel>
            <OnboardingFieldHelp text="Es a lo que se dedica la empresa. Por ejemplo: entretenimiento, gastronomía, servicios de diseño, etc.">
              <Input
                isDisabled={isSavingData}
                placeholder="Por ejemplo: entretenimiento, finanzas"
                size="lg"
                {...register("activityDescription", {
                  required: "Ingresá el rubro de la empresa.",
                })}
              />
            </OnboardingFieldHelp>
            {errors?.activityDescription && (
              <FormErrorMessage>
                {errors.activityDescription.message?.toString()}
              </FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={errors?.activityId !== undefined} isRequired>
            <OnboardingLabel>Actividad principal</OnboardingLabel>
            <OnboardingFieldHelp text="Es la actividad principal, tal cual aparece en la inscripción de AFIP.">
              <Flex w="full">
                <Controller
                  control={control}
                  name="activityId"
                  render={({ field: { value, onChange } }) => (
                    <AutocompleteInput
                      isDisabled={isSavingData}
                      onChangeValue={(value) => onChange(value)}
                      optionLabel={(option) => option.name}
                      optionValue={(option) => option.id}
                      options={formOptions.activityTypes ?? []}
                      placeholder="Buscar actividad..."
                      size="lg"
                      sortOptions={(o1, o2) => o1.name.localeCompare(o2.name)}
                      value={value}
                      w="full"
                    />
                  )}
                  rules={{
                    required: "Elegí la actividad principal de la empresa.",
                  }}
                />
              </Flex>
            </OnboardingFieldHelp>
            {errors?.activityId && (
              <FormErrorMessage>
                {errors.activityId.message?.toString()}
              </FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            isInvalid={errors?.ageInBusiness !== undefined}
            isRequired
          >
            <OnboardingLabel>Antigüedad en rubro</OnboardingLabel>
            <Flex>
              <Select
                isDisabled={isSavingData}
                placeholder="Elegir"
                size="lg"
                {...register("ageInBusiness", {
                  required: "Elegí la antigüedad dentro del rubro.",
                })}
              >
                {BUSINESS_AGE_OPTIONS.map(({ id, name }) => (
                  <option key={id} value={name}>
                    {name}
                  </option>
                ))}
              </Select>
            </Flex>
            {errors?.ageInBusiness && (
              <FormErrorMessage>
                {errors.ageInBusiness.message?.toString()}
              </FormErrorMessage>
            )}
          </FormControl>

          <FormControl>
            <OnboardingLabel>
              ¿La empresa opera con otros bancos?
            </OnboardingLabel>
            <OnboardingFieldHelp text="Significa si la empresa ya está dada de alta en otra entidad bancaria.">
              <Flex w="full">
                <Controller
                  control={control}
                  name="usesOtherBanks"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <RadioSwitch
                        isDisabled={isSavingData}
                        name="usesOtherBanks"
                        onChange={onChange}
                        options={usesOtherBanksOptions}
                        value={value}
                      />
                    );
                  }}
                />
              </Flex>
            </OnboardingFieldHelp>
          </FormControl>

          <FormControl
            isInvalid={errors?.employeesQuantity !== undefined}
            isRequired
          >
            <OnboardingLabel>Cantidad de personas empleadas</OnboardingLabel>
            <Flex>
              <Select
                placeholder="Elegir"
                size="lg"
                {...register("employeesQuantity", {
                  required: "Elegí la cantidad de personas empleadas.",
                })}
              >
                {EMPLOYEES_QUANTITY_OPTIONS.map(({ id, name }) => (
                  <option key={id} value={name}>
                    {name}
                  </option>
                ))}
              </Select>
            </Flex>
            {errors?.employeesQuantity && (
              <FormErrorMessage>
                {errors.employeesQuantity.message?.toString()}
              </FormErrorMessage>
            )}
          </FormControl>
        </Stack>

        <OnboardingButton isLoading={isSavingData} mt={12} type="submit">
          {isComingFromSummary ? "Volver al resumen" : "Continuar"}
        </OnboardingButton>
      </Flex>
    </OnboardingPage>
  );
};
