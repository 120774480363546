import {
  PropsWithChildren,
  Reducer,
  createContext,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import { useClient } from "urql";

import {
  Company,
  CompanyAccountBalance,
  Transaction,
  fetchCompany,
  fetchCompanyAccountBalances,
  loginExternalOperator,
  logoutExternalOperator,
  requestExternalOperatorLogin,
} from "./dashboard.api";

import { RecaptchaTokenGetter } from "contexts/recaptcha.context";
import { useContextSafe } from "hooks/use-context-safe.hook";
import { useReducerAlt } from "hooks/use-reducer-alt.hook";
import { formatTaxId } from "utils/tax-id.utils";

type DashboardContextValue = {
  state: {
    isLoading: boolean;
    isAuthenticated: boolean;
    isRequestingExternalOperatorLogin: boolean;
    isLoggingInExternalOperator: boolean;
    isLoggingOutExternalOperator: boolean;
  };
  data: {
    company: Company | null;
    companyAccountBalances: CompanyAccountBalance[];
  };
  actions: {
    onRequestExternalOperatorLogin: (
      operatorEmail: string,
      companyTaxId: string,
      getRecaptchaToken: RecaptchaTokenGetter
    ) => Promise<boolean>;
    onLoginExternalOperator: (token: string) => void;
    onLogoutExternalOperator: () => void;
    refetchCompanyAccountBalancesData: () => void;
  };
};

const DashboardContext = createContext<DashboardContextValue | null>(null);

export const useDashboard = () => useContextSafe(DashboardContext);

type DashboardState = {
  companyData: Company | null;
  companyAccountBalances: CompanyAccountBalance[];
  transactions: Transaction[];
  isFetching: boolean;
  isRequestingExternalOperatorLogin: boolean;
  isLoggingInExternalOperator: boolean;
  isLoggingOutExternalOperator: boolean;
};

type DashboardAction =
  | {
      type: "SET_COMPANY_DATA";
      payload: Company | null;
    }
  | {
      type: "SET_IS_FETCHING";
      payload: boolean;
    }
  | {
      type: "SET_IS_REQUESTING_EXTERNAL_OPERATOR_LOGIN";
      payload: boolean;
    }
  | {
      type: "SET_IS_LOGGING_IN_EXTERNAL_OPERATOR";
      payload: boolean;
    }
  | {
      type: "SET_IS_LOGGING_OUT_EXTERNAL_OPERATOR";
      payload: boolean;
    }
  | {
      type: "SET_COMPANY_ACCOUNT_BALANCES";
      payload: CompanyAccountBalance[];
    }
  | {
      type: "SET_TRANSACTIONS";
      payload: Transaction[];
    };

const dashboardReducer: Reducer<DashboardState, DashboardAction> = (
  state,
  { type, payload }
): DashboardState => {
  switch (type) {
    case "SET_COMPANY_DATA":
      return {
        ...state,
        companyData: payload,
      };
    case "SET_IS_FETCHING":
      return {
        ...state,
        isFetching: payload,
      };
    case "SET_IS_REQUESTING_EXTERNAL_OPERATOR_LOGIN":
      return {
        ...state,
        isRequestingExternalOperatorLogin: payload,
      };
    case "SET_IS_LOGGING_IN_EXTERNAL_OPERATOR":
      return {
        ...state,
        isLoggingInExternalOperator: payload,
      };
    case "SET_IS_LOGGING_OUT_EXTERNAL_OPERATOR":
      return {
        ...state,
        isLoggingOutExternalOperator: payload,
      };
    case "SET_COMPANY_ACCOUNT_BALANCES":
      return {
        ...state,
        companyAccountBalances: payload,
      };
    case "SET_TRANSACTIONS":
      return {
        ...state,
        transactions: payload,
      };
  }
};

export const DashboardProvider = ({
  isAuthenticated,
  setIsAuthenticated,
  children,
}: PropsWithChildren<{
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
}>) => {
  const client = useClient();
  const navigate = useNavigate();

  const [state, dispatch] = useReducerAlt(dashboardReducer, {
    companyData: null,
    companyAccountBalances: [],
    transactions: [],
    isFetching: true,
    isRequestingExternalOperatorLogin: false,
    isLoggingInExternalOperator: false,
    isLoggingOutExternalOperator: false,
  });

  /**
   * API requests
   */
  const onFetchCompanyData = useCallback(async () => {
    dispatch("SET_IS_FETCHING", true);
    const result = await fetchCompany(client);
    if (!result.error && result.data?.company) {
      dispatch("SET_COMPANY_DATA", result.data.company);
    }
    dispatch("SET_IS_FETCHING", false);
  }, [client, dispatch]);

  const onRequestExternalOperatorLogin = useCallback(
    async (
      email: string,
      taxId: string,
      getRecaptchaToken: RecaptchaTokenGetter
    ) => {
      dispatch("SET_IS_REQUESTING_EXTERNAL_OPERATOR_LOGIN", true);
      const recaptchaToken = await getRecaptchaToken("externalOperatorLogin");
      const result = await requestExternalOperatorLogin(
        client,
        {
          email,
          taxId: formatTaxId(taxId, { withDashes: false }),
        },
        recaptchaToken
      );
      dispatch("SET_IS_REQUESTING_EXTERNAL_OPERATOR_LOGIN", false);
      return !result.error;
    },
    [client, dispatch]
  );

  const onLoginExternalOperator = useCallback(
    async (token: string) => {
      dispatch("SET_IS_LOGGING_IN_EXTERNAL_OPERATOR", true);
      const result = await loginExternalOperator(client, { token });
      if (!result.error) {
        setIsAuthenticated(true);
      }
      dispatch("SET_IS_LOGGING_IN_EXTERNAL_OPERATOR", false);
    },
    [client, dispatch, setIsAuthenticated]
  );

  const onLogoutExternalOperator = useCallback(async () => {
    dispatch("SET_IS_LOGGING_OUT_EXTERNAL_OPERATOR", true);
    const result = await logoutExternalOperator(client);
    if (!result.error) {
      dispatch("SET_COMPANY_DATA", null);
      setIsAuthenticated(false);
    }
    dispatch("SET_IS_LOGGING_OUT_EXTERNAL_OPERATOR", false);
  }, [client, dispatch, setIsAuthenticated]);

  const onFetchCompanyAccountBalancesData = useCallback(async () => {
    dispatch("SET_IS_FETCHING", true);
    const result = await fetchCompanyAccountBalances(client);
    if (!result.error && result.data?.companyAccountBalances) {
      dispatch(
        "SET_COMPANY_ACCOUNT_BALANCES",
        result.data.companyAccountBalances
      );
    }
    dispatch("SET_IS_FETCHING", false);
  }, [client, dispatch]);

  const refetchCompanyAccountBalancesData = useCallback(async () => {
    const result = await fetchCompanyAccountBalances(client);
    if (!result.error && result.data?.companyAccountBalances) {
      dispatch(
        "SET_COMPANY_ACCOUNT_BALANCES",
        result.data.companyAccountBalances
      );
    }
  }, [client, dispatch]);

  /**
   * Effects
   */
  useEffect(() => {
    if (isAuthenticated) {
      onFetchCompanyData();
      onFetchCompanyAccountBalancesData();
    }
  }, [
    isAuthenticated,
    onFetchCompanyData,
    navigate,
    onFetchCompanyAccountBalancesData,
  ]);

  const value: DashboardContextValue = useMemo(
    () => ({
      state: {
        isAuthenticated,
        isLoading: state.isFetching,
        isRequestingExternalOperatorLogin:
          state.isRequestingExternalOperatorLogin,
        isLoggingInExternalOperator: state.isLoggingInExternalOperator,
        isLoggingOutExternalOperator: state.isLoggingOutExternalOperator,
      },
      data: {
        company: state.companyData,
        companyAccountBalances: state.companyAccountBalances,
      },
      actions: {
        onRequestExternalOperatorLogin,
        onLoginExternalOperator,
        onLogoutExternalOperator,
        refetchCompanyAccountBalancesData,
      },
    }),
    [
      state,
      isAuthenticated,
      onRequestExternalOperatorLogin,
      onLoginExternalOperator,
      onLogoutExternalOperator,
      refetchCompanyAccountBalancesData,
    ]
  );

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};
