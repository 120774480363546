import {
  Button,
  Center,
  Icon,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { FiChevronsDown } from "react-icons/fi";

export function BulkPaymentConfirmationTable<ColumnKey extends string>({
  columns,
  rows,
  isFetchingFirstPage,
  isFetchingAdditionalPage,
  areMorePagesAvailable,
  onFetchNextPage,
}: {
  columns: { [key in ColumnKey]?: { title: ReactNode; isNumeric: boolean } };
  rows: Array<Record<ColumnKey, ReactNode> & { id: string }>;
  isFetchingFirstPage: boolean;
  isFetchingAdditionalPage: boolean;
  areMorePagesAvailable: boolean;
  onFetchNextPage: () => Promise<void>;
}) {
  const skeletonRowIndices = Array.from({ length: 8 }, (_, i) => i);

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            {(
              Object.entries(columns) as Array<
                [ColumnKey, { title: string; isNumeric: boolean }]
              >
            ).map(([key, { title, isNumeric }]) => (
              <Th key={key} isNumeric={isNumeric}>
                {title}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {!isFetchingFirstPage
            ? rows.map((row) => (
                <Tr key={row.id}>
                  {(
                    Object.entries(columns) as Array<
                      [ColumnKey, { title: string; isNumeric: boolean }]
                    >
                  ).map(([key, { isNumeric }]) => (
                    <Td key={key} isNumeric={isNumeric}>
                      {row[key]}
                    </Td>
                  ))}
                </Tr>
              ))
            : skeletonRowIndices.map((index) => (
                <Tr key={index}>
                  {Object.keys(columns).map((key) => (
                    <Td key={key}>
                      <Skeleton height={5} rounded="md" width="full" />
                    </Td>
                  ))}
                </Tr>
              ))}
        </Tbody>
      </Table>
      {areMorePagesAvailable && (
        <Center mt={1}>
          <Button
            color="gray.500"
            colorScheme="gray"
            isLoading={isFetchingAdditionalPage}
            leftIcon={<Icon as={FiChevronsDown} />}
            onClick={onFetchNextPage}
            rounded="full"
            variant="ghost"
          >
            Cargar más
          </Button>
        </Center>
      )}
    </TableContainer>
  );
}
