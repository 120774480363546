import { Alert, AlertDescription, AlertIcon, Flex } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

import { RetryCountdown } from "components/retry-countdown";
import { useRecaptcha, withRecaptcha } from "contexts/recaptcha.context";
import { OnboardingBackButton } from "modules/onboarding/components/onboarding-back-button";
import { OnboardingHeader } from "modules/onboarding/components/onboarding-header";
import { OnboardingPage } from "modules/onboarding/components/onboarding-page";
import { useOnboarding } from "modules/onboarding/onboarding.context";
import {
  OnboardingStep,
  getOnboardingPath,
} from "modules/onboarding/routes/onboarding.routes";
import { obfuscateEmail } from "utils/email.utils";

export const EmailSent = withRecaptcha(() => {
  const {
    data: { lastCreatedOnboarding },
    actions: { onCreateCompanyOnboarding },
  } = useOnboarding();
  const { getRecaptchaToken } = useRecaptcha();

  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const handleNavigateBack = () => {
    navigate(getOnboardingPath(OnboardingStep.Start));
  };

  const obfuscatedEmail = obfuscateEmail(state.email);

  const onResendEmail = useCallback(async () => {
    if (!lastCreatedOnboarding) {
      return;
    }
    setIsLoading(true);
    const { email, taxId } = lastCreatedOnboarding;
    await onCreateCompanyOnboarding(email, taxId, getRecaptchaToken);
    setIsLoading(false);
  }, [lastCreatedOnboarding, onCreateCompanyOnboarding, getRecaptchaToken]);

  return (
    <OnboardingPage>
      <OnboardingBackButton onClick={handleNavigateBack} />

      <OnboardingHeader
        icon={MdOutlineMarkEmailUnread}
        mb={16}
        title="Ingresá a tu correo electrónico"
        subtitle={
          <>
            Te enviamos un correo a <strong>{obfuscatedEmail}</strong> para que
            valides tu identidad.
          </>
        }
      />

      <Flex>
        <Alert
          color="blue.500"
          fontSize="sm"
          fontWeight="semibold"
          rounded="lg"
          status="info"
        >
          <AlertIcon />
          <AlertDescription>
            Si no lo encontrás, revisá la carpeta de spam o correo no deseado.
          </AlertDescription>
        </Alert>
      </Flex>

      <RetryCountdown retry={onResendEmail} isLoading={isLoading} />
    </OnboardingPage>
  );
});
