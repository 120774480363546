export type ConfigShapeEntry<T = string> = {
  from: string;
  parser?: (value: string) => T;
  required?: boolean;
  defaultValue?: T;
  validator?: (value: T) => boolean;
};

export type ConfigSource = {
  source: Record<string, string | undefined>;
  keyTransform?: (key: string) => string;
};

export type ShapeOf<C extends Record<string, any>> = {
  [K in keyof C]: ConfigShapeEntry<C[K]>;
};

export const extractConfig = <C extends Record<string, any>>(
  shape: ShapeOf<C>,
  sources: ConfigSource[]
): C => {
  const config = {};

  const definedSources = sources.filter(({ source }) => source);

  for (const [
    key,
    { from, parser, required, defaultValue, validator },
  ] of Object.entries(shape)) {
    for (const { source, keyTransform } of definedSources) {
      const envKey = keyTransform ? keyTransform(from) : from;
      const value = source[envKey];

      if (value !== undefined) {
        if (parser) {
          config[key] = parser(value);
        } else {
          config[key] = value;
        }
        break;
      }
    }

    if (config[key] === undefined && defaultValue !== undefined) {
      config[key] = defaultValue;
    }

    if (config[key] === undefined && required) {
      throw Error(`Missing required config value: ${key}`);
    }

    if (config[key] !== undefined && validator) {
      const isValid = validator(config[key]);

      if (!isValid) {
        throw Error(`Invalid config value for ${key}: ${config[key]}`);
      }
    }
  }

  return config as C;
};
