import { Skeleton, Stack, Wrap } from "@chakra-ui/react";
import { useMemo } from "react";

import { PayrollDateScheduler } from "./payroll-date-scheduler";

import { FeatureFlagKey, useFeatureFlags } from "contexts/feature-flags";
import {
  BulkPaymentConfirmationBox,
  BulkPaymentConfirmationCard,
  BulkPaymentConfirmationStatBox,
  BulkPaymentConfirmationTable,
} from "modules/dashboard/components/bulk-payment-confirmation";
import { useBulkPaymentsPayroll } from "modules/dashboard/routes/bulk-payments/bulk-payments-payroll/bulk-payments-payroll.context";
import { formatAmountWithCurrency } from "utils/money.utils";
import { formatTaxId } from "utils/tax-id.utils";

export const BulkPaymentsPayrollConfirmation = () => {
  const { isFeatureEnabled } = useFeatureFlags();

  const {
    data: { employees, employeeCount, totalAmount },
    state: { areMorePagesAvailable, isFetching, isFetchingFirstPage },
    actions: { onFetchEmployeesNextPage },
  } = useBulkPaymentsPayroll();

  const stats = useMemo(
    () => [
      {
        title: "Personas",
        value: employeeCount,
      },
      {
        title: "Total en sueldos a pagar",
        value: formatAmountWithCurrency(totalAmount),
      },
      { title: "Fecha de pago", value: "Hoy" },
      // { title: "Nuevos", value: "?" },
      // { title: "Removidos", value: "?" },
      // { title: "Mes pasado", value: "+?%" },
    ],
    [employeeCount, totalAmount]
  );

  return (
    <Stack spacing={8}>
      <BulkPaymentConfirmationCard title="Resumen">
        <Wrap overflow="visible" spacing={4}>
          {stats.map((stat, index) => (
            <BulkPaymentConfirmationStatBox
              {...stat}
              key={index}
              isLoading={isFetchingFirstPage}
            />
          ))}
        </Wrap>
      </BulkPaymentConfirmationCard>

      {isFeatureEnabled(FeatureFlagKey.BulkPaymentScheduling) && (
        <BulkPaymentConfirmationCard title="Fecha de pago">
          <BulkPaymentConfirmationBox>
            <PayrollDateScheduler />
          </BulkPaymentConfirmationBox>
        </BulkPaymentConfirmationCard>
      )}

      <BulkPaymentConfirmationCard
        title={
          !isFetchingFirstPage ? (
            `${employeeCount} ${
              employeeCount === 1 ? "sueldo" : "sueldos"
            } a pagar`
          ) : (
            <Skeleton h={7} rounded="md" w="90%" />
          )
        }
      >
        <BulkPaymentConfirmationBox>
          <BulkPaymentConfirmationTable
            areMorePagesAvailable={areMorePagesAvailable}
            isFetchingAdditionalPage={isFetching}
            isFetchingFirstPage={isFetchingFirstPage}
            onFetchNextPage={onFetchEmployeesNextPage}
            columns={{
              fullName: { title: "Persona empleada", isNumeric: false },
              taxId: { title: "CUIT", isNumeric: false },
              cbu: { title: "CBU", isNumeric: false },
              salary: { title: "Pago neto", isNumeric: true },
            }}
            rows={employees.map((employee) => ({
              id: employee.id,
              fullName: `${employee.firstName} ${employee.lastName}`,
              taxId: formatTaxId(employee.contact.taxId),
              cbu: employee.contact.cbu,
              salary: formatAmountWithCurrency(employee.salary),
            }))}
          />
        </BulkPaymentConfirmationBox>
      </BulkPaymentConfirmationCard>
    </Stack>
  );
};
