import { Box, Flex } from "@chakra-ui/layout";
import { Outlet } from "react-router";

import dashboardBg from "assets/backgrounds/dashboard.png";
import { DashboardSidebar } from "modules/dashboard/components/dashboard-sidebar";

export const DashboardLayout = () => {
  return (
    <Flex bg="gray.100" direction="column" minH="100vh">
      <Flex flexGrow={1}>
        <DashboardSidebar
          flexGrow={0}
          flexShrink={0}
          h="100vh"
          position="sticky"
          shadow="md"
          top={0}
          zIndex={101}
        />
        <Box
          bgAttachment="fixed"
          bgImage={dashboardBg}
          bgPos="center"
          bgSize="200% 200%"
          flexGrow={1}
          minW={0}
          minH="100vh"
        >
          <Outlet />
        </Box>
      </Flex>
    </Flex>
  );
};
