import { useCallback, useMemo, useState } from "react";

/**
 * `useInfinitePagination` abstracts the handling of a paginated list where:
 * - each page comes with a cursor pointing to the next page,
 * - the user can see the contents of all loaded pages, and can request that
 * additional pages are loaded.
 *
 * @param fetchPage A function that fetches a page of data given a cursor.
 * It should return the data and the cursor pointing to the next page,
 * or `null` if an error occurred.
 * You may want to use `useCallback` to memoize this function.
 * @param initialLoadingState Whether the loading state should be set to `true`
 * initially.
 * @returns An object containing the paginated data, loading states, and
 * functions to load the first page and subsequent pages.
 */
export const useInfinitePagination = <
  Data,
  Cursor = string,
  InitialCursor = null
>(
  fetchPage: (
    cursor: Cursor | InitialCursor
  ) => Promise<{ data: Data[]; cursor: Cursor | null } | null>,
  {
    initialCursor,
    initialLoadingState = true,
  }: {
    initialCursor: InitialCursor;
    initialLoadingState?: boolean;
  }
) => {
  const [isLoadingFirstPage, setIsLoadingFirstPage] =
    useState(initialLoadingState);
  const [isLoadingAdditionalPage, setIsLoadingAdditionalPage] = useState(false);
  const [data, setData] = useState<Data[]>([]);
  const [cursor, setCursor] = useState<Cursor | null>(null);

  const areMorePagesAvailable = useMemo(() => cursor !== null, [cursor]);

  const onLoadFirstPage = useCallback(async () => {
    setIsLoadingFirstPage(true);
    const result = await fetchPage(initialCursor);
    if (result) {
      const { data: newData, cursor: newCursor } = result;
      setData(newData);
      setCursor(newCursor);
    }
    setIsLoadingFirstPage(false);
  }, [fetchPage, initialCursor]);

  const onLoadNextPage = useCallback(async () => {
    if (cursor === null) {
      return;
    }
    setIsLoadingAdditionalPage(true);
    const result = await fetchPage(cursor);
    if (result) {
      const { data: newData, cursor: newCursor } = result;
      setData((prevData) => [...prevData, ...newData]);
      setCursor(newCursor);
    }
    setIsLoadingAdditionalPage(false);
  }, [fetchPage, cursor]);

  return {
    data,
    isLoadingFirstPage,
    isLoadingAdditionalPage,
    areMorePagesAvailable,
    onLoadFirstPage,
    onLoadNextPage,
  };
};
