import { ChakraProps, Flex, LinkBox, LinkOverlay } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

import { ReactComponent as LogoSvg } from "assets/logos/logo-brubank.svg";
import { PropsWithRest } from "types/react-utils";

export const Logo = ({
  fill = "brand.500",
  w = "150px",
  h = "auto",
  ...rest
}: PropsWithRest<
  {
    fill?: ChakraProps["fill"];
    w?: number | string;
    h?: number | string;
  },
  typeof LinkBox
>) => {
  return (
    <LinkBox {...rest} className="logo">
      <LinkOverlay
        as={NavLink}
        data-group="logo"
        to="/"
        _focusVisible={{
          outline: "none",
        }}
      >
        <Flex display="flex" fill={fill}>
          <LogoSvg height={h} width={w} />
        </Flex>
      </LinkOverlay>
    </LinkBox>
  );
};
