import { List, ListItem } from "@chakra-ui/react";
import { print } from "graphql/language/printer";
import { CombinedError, Operation } from "urql";

import { faro } from "faro";

export const buildErrorMessage = (error: CombinedError) => {
  if (error.networkError) {
    return "No pudimos conectarnos con el servidor. Por favor, intentá de nuevo más tarde.";
  } else if (error.graphQLErrors.length > 1) {
    const errorMessages = Array.from(
      new Set(error.graphQLErrors.map((graphQLError) => graphQLError.message))
    );
    return (
      <List>
        {errorMessages.map((errorMessage, index) => (
          <ListItem key={index}>{errorMessage}</ListItem>
        ))}
      </List>
    );
  } else if (error.graphQLErrors.length === 1) {
    return error.graphQLErrors[0].message;
  } else {
    return "Ocurrió un error. Por favor, intentá de nuevo más tarde.";
  }
};

export const pushGqlErrorToFaro = (
  error: CombinedError,
  operation?: Operation
) => {
  faro.api.pushError(error, {
    type: error.networkError ? "network" : "graphql",
    context: {
      operation: operation
        ? JSON.stringify({
            ...operation,
            query: print(operation.query),
          })
        : "unknown",
      status: error.response?.status?.toString() ?? "unknown",
      graphQLErrors: JSON.stringify(error.graphQLErrors),
      networkError: error.networkError
        ? JSON.stringify({
            name: error.networkError.name,
            message: error.networkError.message,
            stack: error.networkError.stack,
          })
        : "none",
    },
  });
};
