import { Button, HStack, Spacer } from "@chakra-ui/react";
import { useCallback } from "react";
import { FiArrowLeft, FiCheck } from "react-icons/fi";

import { useBulkPaymentDetail } from "modules/dashboard/routes/bulk-payments/bulk-payment-detail/bulk-payment-detail.context";

export const BulkPaymentProvidersEditionConfirmationActionBar = ({
  onPrevious,
  onNext,
}: {
  onPrevious: () => void;
  onNext: () => void;
}) => {
  const {
    actions: { onConfirmBulkPayment },
    state: { isConfirmingBulkPayment },
  } = useBulkPaymentDetail();

  const onConfirm = useCallback(async () => {
    await onConfirmBulkPayment();
    onNext();
  }, [onNext, onConfirmBulkPayment]);

  return (
    <HStack h={14} spacing={8}>
      <Button
        colorScheme="blackAlpha"
        leftIcon={<FiArrowLeft />}
        onClick={onPrevious}
        rounded="full"
        size="lg"
        variant="outline"
      >
        Volver al listado
      </Button>

      <Spacer />

      <Button
        isLoading={isConfirmingBulkPayment}
        leftIcon={<FiCheck />}
        onClick={onConfirm}
        rounded="full"
        size="lg"
      >
        Confirmar y enviar
      </Button>
    </HStack>
  );
};
