import { GiSmartphone } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

import { OnboardingButton } from "modules/onboarding/components/onboarding-button";
import { OnboardingHeader } from "modules/onboarding/components/onboarding-header";
import { OnboardingPage } from "modules/onboarding/components/onboarding-page";
import {
  OnboardingStep,
  getOnboardingPath,
} from "modules/onboarding/routes/onboarding.routes";

export const PhoneVerified = () => {
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate(getOnboardingPath(OnboardingStep.Step1));
  };

  return (
    <OnboardingPage>
      <OnboardingHeader
        icon={GiSmartphone}
        mb={16}
        subtitle="Ya podés continuar con el proceso de registro."
        title="Verificamos tu número de celular con éxito"
      />
      <OnboardingButton onClick={handleContinue}>Continuar</OnboardingButton>
    </OnboardingPage>
  );
};
