import {
  Box,
  Flex,
  HStack,
  UseRadioProps,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { KeyboardEvent, PropsWithChildren } from "react";

import { PropsWithRest } from "types/react-utils";

const CustomRadio = ({
  children,
  ...radioProps
}: PropsWithChildren<UseRadioProps>) => {
  const { getInputProps, getRadioProps } = useRadio(radioProps);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box
      as="label"
      flexGrow={1}
      _focusVisible={{
        shadow: "outline",
        outline: "none",
      }}
      _notLast={{
        "> div": { borderRightWidth: 0 },
      }}
    >
      <input {...input} tabIndex={-1} />
      <Box
        borderColor="gray.200"
        _dark={{
          borderColor: "gray.700",
          _hover: {
            bg: "gray.600",
          },
          _checked: {
            bg: "brand.500",
          },
        }}
        _hover={{
          bg: "gray.100",
        }}
        {...checkbox}
        borderWidth={2}
        cursor="pointer"
        px={5}
        py={3}
        rounded="xl"
        textAlign="center"
        transition="all 0.2s"
        _checked={{
          color: "white",
          bg: "brand.500",
          fontWeight: "semibold",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

const { node } = PropTypes;

CustomRadio.propTypes = {
  children: node.isRequired,
};

export const RadioSwitch = ({
  value,
  defaultValue,
  name,
  isDisabled,
  onChange,
  options,
  ...rest
}: PropsWithRest<
  {
    value?: string;
    defaultValue?: string;
    name: string;
    isDisabled?: boolean;
    onChange?: (value: string) => void;
    options: { label: string; value: string }[];
  },
  typeof HStack
>) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    value,
    onChange,
    isDisabled,
  });

  const onKeyDown = (ev: KeyboardEvent) => {
    if (ev.key === "ArrowLeft") {
      ev.preventDefault();
      const index = options.findIndex(({ value: optionValue }) => {
        return optionValue === value;
      });
      const nextIndex = index === 0 ? options.length - 1 : index - 1;
      onChange?.(options[nextIndex].value);
    } else if (
      ev.key === "ArrowRight" ||
      ev.key === " " ||
      ev.key === "Enter"
    ) {
      ev.preventDefault();
      const index = options.findIndex(({ value: optionValue }) => {
        return optionValue === value;
      });
      const nextIndex = index === options.length - 1 ? 0 : index + 1;
      onChange?.(options[nextIndex].value);
    }
  };

  return (
    <Flex
      _dark={{ bg: "gray.700" }}
      bg="gray.200"
      flexWrap="wrap"
      onKeyDown={onKeyDown}
      opacity={isDisabled ? 0.5 : 1}
      rounded="xl"
      tabIndex={0}
      width="full"
      _focusVisible={{
        shadow: "outline",
        outline: "none",
      }}
      {...rest}
      {...getRootProps()}
    >
      {options.map(({ label, value: optionValue }, index) => (
        <CustomRadio key={index} {...getRadioProps({ value: optionValue })}>
          {label}
        </CustomRadio>
      ))}
    </Flex>
  );
};
