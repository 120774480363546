import { useCallback } from "react";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import { CombinedError, Operation } from "urql";

import { DashboardProvider } from "./dashboard.context";

import { ApiProvider } from "contexts/api.context";
import { useGraphQLErrorToast } from "hooks/use-graphql-error-toast.hook";
import { useLocalStorage } from "hooks/use-local-storage.hook";
import { pushGqlErrorToFaro } from "utils/errors.utils";
import { ErrorCode, getErrorCodes } from "utils/graphql.utils";

export const DashboardWrapper = () => {
  // We keep the authentication state outside the DashboardProvider because
  // the ApiProvider needs access to handle unauthorized errors.
  const [isAuthenticated, setIsAuthenticated] = useLocalStorage(
    "dashboard:isAuthenticated",
    false
  );
  const errorToast = useGraphQLErrorToast();

  const onError = useCallback(
    (error: CombinedError, operation: Operation) => {
      pushGqlErrorToFaro(error, operation);
      if (operation.context.noErrorToast !== true) {
        const errorCodes = getErrorCodes(error);
        if (
          errorCodes.includes(ErrorCode.Unauthorized) ||
          errorCodes.includes(ErrorCode.SessionExpired)
        ) {
          setIsAuthenticated(false);
        } else {
          errorToast(error);
        }
      }
    },
    [setIsAuthenticated, errorToast]
  );

  return (
    <ApiProvider map={{ onError }}>
      <DashboardProvider
        isAuthenticated={isAuthenticated}
        setIsAuthenticated={setIsAuthenticated}
      >
        <Helmet
          titleTemplate="%s · Brubank Empresas"
          defaultTitle="Brubank Empresas"
        />
        <Outlet />
      </DashboardProvider>
    </ApiProvider>
  );
};
