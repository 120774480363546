import { Navigate, Outlet } from "react-router-dom";

import { useDashboard } from "modules/dashboard/dashboard.context";

export const DashboardAuthWall = () => {
  const {
    state: { isAuthenticated },
  } = useDashboard();
  if (!isAuthenticated) {
    return <Navigate replace to="/login" />;
  } else {
    return <Outlet />;
  }
};
