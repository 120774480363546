import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  Text,
  Checkbox,
  Alert,
  AlertIcon,
  Divider,
  UnorderedList,
  ListItem,
  AlertTitle,
  AlertDescription,
  Stack,
  FormControl,
  FormErrorMessage,
  Box,
  HStack,
} from "@chakra-ui/react";
import { useEffect, useId, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useNavigation } from "react-router-dom";
import { gql, useMutation } from "urql";

import { BackofficeInformation } from "./backoffice-information";
import { Business } from "./business";
import { Comment } from "./comment";
import { Documentation } from "./documentation";
import { Team } from "./team";

import { BusinessAvatar } from "components/business-avatar";
import { OnboardingButton } from "modules/onboarding/components/onboarding-button";
import { OnboardingPage } from "modules/onboarding/components/onboarding-page";
import {
  ONBOARDING_STATUS,
  ONBOARDING_STATUS_LABEL,
} from "modules/onboarding/onboarding.constants";
import { useOnboarding } from "modules/onboarding/onboarding.context";
import {
  OnboardingStep,
  getOnboardingPath,
} from "modules/onboarding/routes/onboarding.routes";
import { formatTaxId } from "utils/tax-id.utils";

type SummaryForm = {
  truthcheck: boolean;
  comment: string;
};

export const Summary = () => {
  const [hasTriedToSubmit, setTriedToSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    state: { isLoading, isStale, requiresFormReSubmission, isAccepted },
    data: { onboardingSummary, formOptions, userComment },
    actions: { setRequiresFormReSubmission, setUserComment },
  } = useOnboarding();

  const [, finishCompanyOnboarding] = useMutation<{
    finishCompanyOnboarding: null;
  }>(gql`
    mutation FinishCompanyOnboarding {
      finishCompanyOnboarding
    }
  `);

  const [, updateOnBoardingUserComment] = useMutation<
    {},
    { input: { comment: string } }
  >(gql`
    mutation UpdateOnboardingUserComment($input: UpdateUserCommentInput!) {
      updateOnboardingUserComment(in: $input)
    }
  `);

  const {
    formState: { errors: formErrors },
    handleSubmit,
    register,
    getValues,
    setValue,
    watch,
  } = useForm<SummaryForm>({
    mode: "onChange",
  });

  useEffect(() => {
    if (userComment) {
      setValue("comment", userComment);
    }
  }, [userComment, setValue]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "comment") {
        setUserComment(value.comment || "");
      }
    });
    return () => subscription.unsubscribe();
  }, [setUserComment, watch]);

  const summaryFormId = useId();

  const navigate = useNavigate();
  const navigation = useNavigation();

  const onboardingStatus = useMemo(
    () => onboardingSummary?.status?.status,
    [onboardingSummary?.status?.status]
  );
  const hasComments = useMemo(
    () =>
      onboardingSummary !== null &&
      onboardingSummary?.comments &&
      onboardingSummary?.comments.length > 0,
    [onboardingSummary]
  );
  const isDisabled: boolean = useMemo(
    () =>
      isSubmitting ||
      (onboardingStatus !== undefined &&
        onboardingStatus !== ONBOARDING_STATUS.IN_PROGRESS &&
        onboardingStatus !== ONBOARDING_STATUS.REQUIRES_INFORMATION),
    [isSubmitting, onboardingStatus]
  );

  const requiresMoreInformationStatus = useMemo(
    () => onboardingStatus === ONBOARDING_STATUS.REQUIRES_INFORMATION,
    [onboardingStatus]
  );

  const isOnboardingAccepted: boolean = useMemo(
    () =>
      onboardingStatus !== undefined &&
      onboardingStatus === ONBOARDING_STATUS.SUCCEEDED,
    [onboardingStatus]
  );

  useEffect(() => {
    const handleBeforeUnload = (ev) => {
      if (requiresFormReSubmission && requiresMoreInformationStatus) {
        ev.preventDefault();
        return "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [
    navigation,
    onboardingStatus,
    requiresMoreInformationStatus,
    requiresFormReSubmission,
  ]);

  const otherErrors = useMemo(() => {
    const errors: {
      users: string[];
      docs: string[];
    } = {
      users: [],
      docs: [],
    };
    if (!onboardingSummary?.users || onboardingSummary.users.length === 0) {
      errors.users.push(
        "Debés agregar al menos a una persona con acceso a la cuenta."
      );
    }

    const loadedDocIds =
      onboardingSummary?.docs?.map((doc) => doc.typeBySociety.documentTypeId) ||
      [];
    const missingDocs = formOptions.documentTypes.filter(
      (doc) => !loadedDocIds.includes(doc.documentTypeId)
    );

    if (!onboardingSummary?.docs || missingDocs.length > 0) {
      const errorMessage =
        missingDocs.length === 1
          ? `Debés cargar "${missingDocs[0].documentName}" para poder enviar toda la documentación necesaria.`
          : "Debés cargar la documentación faltante para poder terminar el proceso de apertura.";
      errors.docs.push(errorMessage);
    }

    return errors;
  }, [
    onboardingSummary?.users,
    formOptions.documentTypes,
    onboardingSummary?.docs,
  ]);

  const onSubmit: SubmitHandler<SummaryForm> = async () => {
    if (Object.values(otherErrors).some((errors) => errors.length > 0)) {
      return;
    }
    setIsSubmitting(true);

    const comment = getValues("comment");

    const updateCommentResult = await updateOnBoardingUserComment({
      input: { comment },
    });
    const finishOnboardingResult = await finishCompanyOnboarding();

    if (!finishOnboardingResult.error && !updateCommentResult.error) {
      setRequiresFormReSubmission(false);
      if (!updateCommentResult.error) {
        navigate(getOnboardingPath(OnboardingStep.InformationSent));
      }
      setIsSubmitting(false);
    }
  };

  return (
    <OnboardingPage
      activeStep={3}
      _dark={{
        bgGradient: !isLoading
          ? "linear(to-b, brand.800, transparent)"
          : undefined,
      }}
      actionBarContent={
        isLoading ? null : (
          <HStack justify="center">
            <OnboardingButton
              form={summaryFormId}
              isDisabled={isDisabled}
              isLoading={isSubmitting}
              type="submit"
            >
              Enviar documentación
            </OnboardingButton>
          </HStack>
        )
      }
      bgGradient={
        !isLoading ? "linear(to-b, brand.200, transparent)" : undefined
      }
    >
      <Box
        maxW="800px"
        opacity={isStale ? 0.5 : 1}
        position="relative"
        w="full"
      >
        <Card
          _dark={{ bg: "gray.800" }}
          bottom={-2.5}
          left={3}
          opacity={0.5}
          position="absolute"
          right={3}
          rounded="2xl"
          shadow="lg"
          top={0}
        />
        <Card _dark={{ bg: "gray.800" }} rounded="2xl" shadow="lg">
          <CardHeader pb={4} pt={{ base: 4, md: 10 }} px={{ base: 6, md: 12 }}>
            <Stack spacing={6}>
              <Heading as="h1">Resumen</Heading>

              <Stack
                align={{ base: "start", sm: "center" }}
                direction={{ base: "column", sm: "row" }}
                justify="space-between"
                spacing={6}
              >
                <HStack spacing={6}>
                  <BusinessAvatar
                    flexShrink={0}
                    name={onboardingSummary?.companyDescription?.fantasyName}
                    showNameAndCuit={false}
                    size="lg"
                    photo={
                      onboardingSummary?.companyDescription?.logo?.publicURL
                    }
                  />
                  <Box>
                    <Heading as="h2" fontSize={{ base: "2xl", sm: "3xl" }}>
                      {onboardingSummary?.companyDescription?.fantasyName}
                    </Heading>
                    <Text color="gray.500" fontSize="lg" fontWeight="semibold">
                      {onboardingSummary?.companyDescription?.taxId
                        ? formatTaxId(
                            onboardingSummary?.companyDescription?.taxId
                          )
                        : ""}
                    </Text>
                  </Box>
                </HStack>

                <Alert px={5} rounded="lg" status="info" w="initial">
                  <AlertIcon mr={4} />
                  <AlertDescription>
                    Status:{" "}
                    <Text as="strong">
                      {onboardingStatus
                        ? ONBOARDING_STATUS_LABEL[
                            onboardingStatus.toUpperCase()
                          ]
                        : "Sin información"}
                    </Text>
                  </AlertDescription>
                </Alert>
              </Stack>
            </Stack>
          </CardHeader>

          <CardBody px={{ base: 6, md: 12 }} py={{ base: 4, md: 10 }}>
            <Stack spacing={{ base: 8, md: 12 }}>
              {hasComments && (
                <Flex direction="column">
                  <HStack mb={4} spacing={{ base: 4, sm: 10 }}>
                    <Heading as="h2" size="md">
                      Pendientes
                    </Heading>
                    <Divider />
                  </HStack>
                  <Alert
                    alignItems="start"
                    flexDir="column"
                    status="warning"
                    gap={2}
                  >
                    <AlertTitle>Notas del agente</AlertTitle>
                    <AlertDescription>
                      <UnorderedList>
                        {onboardingSummary?.comments?.map((comment, index) => {
                          return (
                            <ListItem key={index} fontWeight="semibold">
                              {comment.comment}
                            </ListItem>
                          );
                        })}
                      </UnorderedList>
                    </AlertDescription>
                  </Alert>
                </Flex>
              )}
              {requiresMoreInformationStatus && (
                <Alert status="warning">
                  <AlertIcon />
                  <AlertTitle>¡Importante!</AlertTitle>
                  <AlertDescription>
                    Recordá volver a enviar la documentación al terminar de
                    actualizarla.
                  </AlertDescription>
                </Alert>
              )}
              <Business
                data={onboardingSummary?.companyDescription}
                isDisabled={isDisabled}
              />
              <Documentation
                data={onboardingSummary?.docs ?? []}
                errors={otherErrors.docs}
                isDisabled={isDisabled}
                showErrors={hasTriedToSubmit}
              />
              <Team
                data={onboardingSummary?.users ?? []}
                errors={otherErrors.users}
                isDisabled={isDisabled}
                showErrors={hasTriedToSubmit}
              />
              {isOnboardingAccepted && <BackofficeInformation />}
              <Comment
                {...register("comment", {
                  maxLength: {
                    value: 1024,
                    message:
                      "El comentario no puede superar los 1024 caracteres.",
                  },
                })}
              />
            </Stack>
          </CardBody>

          <form
            id={summaryFormId}
            onSubmit={(ev) => {
              setTriedToSubmit(true);
              handleSubmit(onSubmit)(ev);
            }}
          >
            <Divider
              color="blackAlpha.200"
              _dark={{
                color: "whiteAlpha.300",
              }}
            />

            {!isAccepted && (
              <CardFooter
                as={Flex}
                direction="column"
                px={{ base: 6, md: 12 }}
                py={{ base: 4, md: 10 }}
              >
                <Stack align="center" spacing={5}>
                  <FormControl
                    fontSize="sm"
                    isInvalid={formErrors?.truthcheck !== undefined}
                    maxW="55ch"
                  >
                    <Checkbox
                      color="gray.500"
                      defaultChecked={isDisabled}
                      fontSize="sm"
                      isDisabled={isDisabled}
                      isInvalid={formErrors?.truthcheck !== undefined}
                      {...register("truthcheck", {
                        required:
                          "Para finalizar, debés aceptar la declaración.",
                      })}
                    >
                      <Text fontSize="sm" ml={2}>
                        Declaro bajo juramento que los datos consignados en la
                        presente declaración jurada son verídicos y me hago
                        responsable respecto de la veracidad de los mismos.
                      </Text>
                    </Checkbox>
                    <FormErrorMessage justifyContent="center">
                      {formErrors.truthcheck?.message?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
              </CardFooter>
            )}
          </form>
        </Card>
      </Box>
    </OnboardingPage>
  );
};
