import { Card, CardHeader, CardBody } from "@chakra-ui/card";
import {
  Center,
  HStack,
  Heading,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { AddPaymentToProviderDrawer } from "./add-payment-to-provider-drawer";
import { PaymentsListBody } from "./payments-list-body";
import { PaymentsListSkeleton } from "./payments-list-skeleton";

import { useBulkPaymentDetail } from "modules/dashboard/routes/bulk-payments/bulk-payment-detail/bulk-payment-detail.context";
import { ProviderSearch } from "modules/dashboard/routes/bulk-payments/bulk-payment-providers-edition/payment-editor/payments-list/provider-search";
import { ProviderSearchTrigger } from "modules/dashboard/routes/bulk-payments/bulk-payment-providers-edition/payment-editor/payments-list/provider-search-trigger";

export const PaymentsList = () => {
  const {
    data: { payments },
    state: { isFetchingFirstPage: isFetchingPaymentsFirstPage },
  } = useBulkPaymentDetail();

  const {
    isOpen: isProviderSearchOpen,
    onOpen: openProviderSearch,
    onClose: closeProviderSearch,
  } = useDisclosure();

  return (
    <>
      <Card rounded="xl" w="full" shadow="lg">
        <CardHeader pb={0} pt={6} px={8}>
          <HStack align="center" spacing={4}>
            <Heading as="h2" fontSize="2xl" my={1.5} flexShrink={0}>
              Ficha de pago
            </Heading>
            <Spacer />
            <ProviderSearchTrigger
              onOpen={openProviderSearch}
              isDisabled={isFetchingPaymentsFirstPage}
            />
          </HStack>
        </CardHeader>
        <CardBody pt={6}>
          {/* {!isFetchingPaymentsFirstPage && (
              <AddPaymentToProviderForm mx={3} />
            )} */}
          {isFetchingPaymentsFirstPage ? (
            <PaymentsListSkeleton mx={2} />
          ) : payments.length > 0 ? (
            <PaymentsListBody />
          ) : (
            <Center color="gray.500" py={20}>
              <Text>Todavía no creaste ningún pago a proveedor.</Text>
            </Center>
          )}
        </CardBody>
      </Card>

      <AddPaymentToProviderDrawer />
      <ProviderSearch
        isOpen={isProviderSearchOpen}
        onClose={closeProviderSearch}
      />
    </>
  );
};
