import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { FiCheckCircle, FiFileText, FiTrash2 } from "react-icons/fi";
import { LuFileSignature } from "react-icons/lu";

import { CompanyOnboardingUser } from "modules/onboarding/onboarding.types";
import { PropsWithRest } from "types/react-utils";

export const TeamMember = ({
  user: { name, lastName, powerOfLaw, role, canAcceptTermsAndConditions },
  onEdit: _onEdit,
  onRemove,
  isDisabled,
  ...rest
}: PropsWithRest<
  {
    user: CompanyOnboardingUser;
    onEdit: () => void;
    onRemove: () => Promise<void>;
    isDisabled: boolean;
  },
  typeof Box
>) => {
  const [isRemoving, setIsRemoving] = useState(false);

  const onRemoveUser = async () => {
    setIsRemoving(true);
    await onRemove();
    setIsRemoving(false);
  };

  return (
    <Box borderWidth={1} px={6} py={5} rounded="xl" shadow="sm" {...rest}>
      <HStack spacing={{ base: 3, sm: 5 }} w="full">
        <Icon as={FiCheckCircle} boxSize={6} color="green.400" />
        <Stack alignItems="start" spacing={1}>
          <Text fontSize="lg" fontWeight="semibold" lineHeight="1.2">
            {name} {lastName}
          </Text>

          <Badge
            colorScheme="green"
            px={2}
            py={0.5}
            rounded="xl"
            textTransform="none"
          >
            {role?.description}
          </Badge>
        </Stack>

        <Spacer />

        <HStack>
          {canAcceptTermsAndConditions && (
            <Tooltip label="Responsable firmante" placement="top">
              <Flex>
                <Icon as={LuFileSignature} color="gray.500" fontSize="xl" />
              </Flex>
            </Tooltip>
          )}
          {powerOfLaw && (
            <Tooltip label="Ver poder legal" placement="top">
              <IconButton
                aria-label="Ver poder legal"
                as={Link}
                color="gray.500"
                colorScheme="gray"
                fontSize="xl"
                href={powerOfLaw.publicURL}
                icon={<FiFileText />}
                isExternal
                isRound
                variant="ghost"
              />
            </Tooltip>
          )}
        </HStack>

        {/* <HStack>
          <IconButton
            aria-label="Editar"
            icon={<FiEdit />}
            isDisabled={isDisabled}
            onClick={_onEdit}
            rounded="full"
            variant="subtle"
          />
          <IconButton
            aria-label="Eliminar"
            colorScheme="red"
            icon={<FiTrash2 />}
            isDisabled={isDisabled}
            isLoading={isRemoving}
            onClick={onRemoveUser}
            rounded="full"
            variant="subtle"
          />
        </HStack> */}

        {useBreakpointValue({
          base: (
            <IconButton
              aria-label="Eliminar"
              colorScheme="red"
              flexShrink={0}
              icon={<FiTrash2 />}
              isDisabled={isDisabled}
              isLoading={isRemoving}
              onClick={onRemoveUser}
              rounded="full"
              variant="subtle"
            />
          ),
          sm: (
            <Button
              colorScheme="red"
              flexShrink={0}
              isDisabled={isDisabled}
              isLoading={isRemoving}
              leftIcon={<FiTrash2 />}
              onClick={onRemoveUser}
              rounded="full"
              variant="subtle"
            >
              Eliminar
            </Button>
          ),
        })}
      </HStack>
    </Box>
  );
};
