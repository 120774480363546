import { Button, ButtonProps, Text, VStack } from "@chakra-ui/react";
import { useState, useEffect, useCallback } from "react";
import { useBeforeUnload } from "react-router-dom";

import { useLocalStorage } from "hooks/use-local-storage.hook";

export const RetryCountdown = ({
  retry,
  isLoading,
  defaultCountdown = 180,
  type = "long",
  buttonProps,
}: {
  retry: () => void;
  isLoading: boolean;
  defaultCountdown?: number;
  type?: "inline" | "long";
  buttonProps?: ButtonProps;
}) => {
  const [savedCountdown, setSavedCountdown] = useLocalStorage(
    "retryCountdown",
    defaultCountdown
  );
  const [countdown, setCountdown] = useState(savedCountdown);

  useEffect(() => {
    const timer =
      countdown > 0
        ? setInterval(() => setCountdown(countdown - 1), 1000)
        : undefined;
    return () => clearInterval(timer);
  }, [countdown]);

  const handleOnClick = () => {
    retry();
    setCountdown(defaultCountdown);
  };

  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;
  const formattedCountdown = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  const isInline = type === "inline";
  const message = isInline
    ? formattedCountdown
    : `Si no lo recibiste, reenvialo en ${formattedCountdown}`;

  useBeforeUnload(
    useCallback(() => {
      setSavedCountdown(countdown);
    }, [countdown, setSavedCountdown])
  );

  return (
    <VStack w="100%" mt={4}>
      {!isInline && <Text color="gray.500">{message}</Text>}
      <Button
        onClick={handleOnClick}
        isDisabled={countdown > 0}
        isLoading={isLoading}
        {...buttonProps}
      >
        {isInline && countdown > 0 ? message : "Reenviar"}
      </Button>
    </VStack>
  );
};
