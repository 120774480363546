import { AmountWithCurrency } from "types/api-global.types";
import { applyDecimalScale } from "utils/number.utils";

export const formatMoney = (
  amount: number | string,
  {
    currency = "ARS",
    inputDecimalScale = 0,
    decimalScale = 2,
  }: Partial<{
    currency: "ARS" | "USD" | null;
    inputDecimalScale: number;
    decimalScale: number;
  }> = {}
) => {
  let amountToFormat = typeof amount === "string" ? parseFloat(amount) : amount;
  if (inputDecimalScale !== 0) {
    const [integerPart, decimalPart] = applyDecimalScale(
      amountToFormat,
      inputDecimalScale
    );
    amountToFormat = parseFloat(
      integerPart + (decimalPart ? `.${decimalPart}` : "")
    );
  }
  const numberFormat =
    currency !== null
      ? new Intl.NumberFormat("es-AR", {
          style: "currency",
          currency,
          minimumFractionDigits: decimalScale,
          maximumFractionDigits: decimalScale,
        })
      : new Intl.NumberFormat("es-AR", {
          style: "decimal",
          minimumFractionDigits: decimalScale,
          maximumFractionDigits: decimalScale,
        });
  return numberFormat.format(amountToFormat);
};

export const parseMoney = (amount: string) => {
  const [int, frac] = amount.split(",");
  return (
    parseInt(int.replace(/[^0-9]/g, "")) +
    (frac ? parseInt(frac.replace(/[^0-9]/g, "")) / 100 : 0)
  );
};

export const formatAmountWithCurrency = (
  amountWithCurrency: AmountWithCurrency
) => {
  const { amountInCents, currency } = amountWithCurrency;
  return formatMoney(amountInCents, { currency, inputDecimalScale: 2 });
};
