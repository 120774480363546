/* eslint-disable no-redeclare */

import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * `useSearchParamsState` allows to keep state in sync with URL search params.
 */
export function useSearchParamsState<
  T extends Record<string, string | string[] | null>
>(config: {
  [K in keyof T]: T[K] extends string[] | null
    ? { initialValue: T[K]; isArray: true }
    : { initialValue: T[K]; isArray?: false };
}): readonly [T, Dispatch<SetStateAction<T>>] {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentValue = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(config).map(([key, { initialValue, isArray }]) => {
          if (isArray) {
            return [
              key,
              searchParams
                .getAll(key)
                .map((value) => decodeURIComponent(value)) as T[typeof key],
            ];
          } else {
            const value = searchParams.get(key);
            if (value === null) {
              return [key, initialValue];
            } else {
              return [key, decodeURIComponent(value) as T[typeof key]];
            }
          }
        })
      ) as T,
    [config, searchParams]
  );

  const setValue = useCallback(
    (value: SetStateAction<T>) => {
      const newValue =
        typeof value === "function" ? value(currentValue) : value;

      setSearchParams((prev) => {
        const newSearchParams = new URLSearchParams(prev);
        Object.entries(newValue).forEach(([key, innerValue]) => {
          const isArray = Array.isArray(innerValue);
          if (isArray) {
            newSearchParams.delete(key);
            if (innerValue !== null) {
              (innerValue as string[]).forEach((item) => {
                newSearchParams.append(key, encodeURIComponent(item));
              });
            }
          } else {
            if (innerValue === null) {
              newSearchParams.delete(key);
            } else {
              newSearchParams.set(
                key,
                encodeURIComponent(innerValue as string)
              );
            }
          }
        });
        return newSearchParams;
      });
    },
    [currentValue, setSearchParams]
  );

  return [currentValue, setValue] as const;
}
