import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import {
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  List,
  ListItem,
  Spinner,
  HStack,
  Text,
  Spacer,
  useToast,
  ModalHeader,
  ModalCloseButton,
  Grid,
  Card,
  CardBody,
  Button,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { FiChevronRight, FiSearch } from "react-icons/fi";
import { useClient } from "urql";

import { useAutocompleteInput } from "hooks/use-autocomplete-input";
import { useBulkPaymentProvidersEdition } from "modules/dashboard/routes/bulk-payments/bulk-payment-providers-edition/bulk-payment-providers-edition.context";
import { parseProvidersQuery } from "modules/dashboard/routes/bulk-payments/bulk-payment-providers-edition/bulk-payment-providers-edition.utils";
import { useBulkPaymentProvidersEditionEditor } from "modules/dashboard/routes/bulk-payments/bulk-payment-providers-edition/payment-editor/payment-editor.context";
import {
  NetworkContact,
  Provider,
  fetchProviders,
  lookupProviderContact,
} from "modules/dashboard/routes/bulk-payments/bulk-payments-providers.api";
import { isValidCbu, isValidCbuAlias } from "utils/cbu.utils";
import { formatTaxId } from "utils/tax-id.utils";

type ProviderSearchResult =
  | {
      type: "provider";
      provider: Provider;
    }
  | {
      type: "lookup";
      lookupType: "cbu" | "alias";
      text: string;
      query: string;
    };

export const ProviderSearch = ({
  isOpen,
  onClose: _onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const client = useClient();

  const {
    actions: { onAddProvider: _onAddProvider },
  } = useBulkPaymentProvidersEdition();

  const {
    addPaymentToProviderDrawer: { onOpen: onAddPaymentToProviderDrawerOpen },
  } = useBulkPaymentProvidersEditionEditor();

  const toast = useToast();

  const [mode, setMode] = useState<
    "searching" | "looking-up-contact" | "adding-provider" | "choosing-holder"
  >("searching");
  const [foundNetworkContact, setFoundNetworkContact] =
    useState<NetworkContact | null>(null);

  const onClose = useCallback(() => {
    setMode("searching");
    setFoundNetworkContact(null);
    _onClose();
  }, [_onClose]);

  const onAddProvider = useCallback(
    async (name: string, taxId: string, cbu: string) => {
      setMode("adding-provider");
      const addedProvider = await _onAddProvider({
        name: name.trim(),
        taxId,
        cbu,
      });
      if (addedProvider) {
        onAddPaymentToProviderDrawerOpen(addedProvider.contact);
      }
      onClose();
    },
    [_onAddProvider, onAddPaymentToProviderDrawerOpen, onClose]
  );

  const {
    isSearching,
    areOptionsShown,
    inputProps,
    listboxProps,
    options,
    reset,
  } = useAutocompleteInput<ProviderSearchResult>({
    search: useCallback(
      async (query) => {
        const trimmedQuery = query.trim();
        const parsedQuery =
          trimmedQuery.length > 2 ? parseProvidersQuery(trimmedQuery) : null;
        let results: ProviderSearchResult[] = [];
        if (parsedQuery !== null) {
          const result = await fetchProviders(client, 5, null, parsedQuery);
          if (!result.error && result.data) {
            results = result.data.searchProviderInAgenda.providers.map(
              (provider) => ({ type: "provider" as const, provider })
            );
          }
        }
        if (isValidCbuAlias(trimmedQuery)) {
          results.push({
            type: "lookup" as const,
            lookupType: "alias",
            text: "Validar el alias para agregar el proveedor",
            query: trimmedQuery,
          });
        } else if (
          isValidCbu(trimmedQuery) &&
          !(
            parsedQuery &&
            "cbu" in parsedQuery &&
            results.some(
              (result) =>
                result.type === "provider" &&
                result.provider.contact.cbu === parsedQuery.cbu
            )
          )
        ) {
          results.push({
            type: "lookup" as const,
            lookupType: "cbu",
            text: "Validar CBU/CVU para agregar el proveedor",
            query: trimmedQuery,
          });
        }
        return results;
      },
      [client]
    ),
    onSelectOption: useCallback(
      async (result, _method, { reset }) => {
        if (result.type === "provider") {
          onAddPaymentToProviderDrawerOpen(result.provider.contact);
          reset();
          onClose();
        } else {
          setMode("looking-up-contact");
          const lookupResult = await lookupProviderContact(
            client,
            result.lookupType === "alias"
              ? { alias: result.query }
              : { cbu: result.query }
          );
          if (!lookupResult.error && lookupResult.data) {
            const { contact, networkContact } =
              lookupResult.data.lookupProviderContact;
            if (contact) {
              onAddPaymentToProviderDrawerOpen(contact);
            } else if (networkContact) {
              setFoundNetworkContact(networkContact);
              if (networkContact.holders.length === 1) {
                onAddProvider(
                  networkContact.holders[0].fullName,
                  networkContact.holders[0].taxId,
                  networkContact.cbu
                );
                return;
              } else {
                setMode("choosing-holder");
                return;
              }
            } else {
              toast({
                status: "warning",
                title: "No hay resultados",
                description: `No encontramos ninguna cuenta con el ${
                  result.lookupType === "alias" ? "alias" : "CBU/CVU"
                } buscado.`,
              });
            }
          }
          reset();
          onClose();
        }
      },
      [onAddPaymentToProviderDrawerOpen, onClose, client, toast, onAddProvider]
    ),
    hideOptions: (query) => query === null || query.length < 3,
    hideOptionsOnBlur: false,
    debounceBy: (query) => (query.length > 0 ? 300 : 0),
  });

  return (
    <Modal
      isOpen={isOpen}
      size="2xl"
      scrollBehavior="inside"
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent rounded="xl" mt="15vh" mx={3}>
        {mode === "searching" && (
          <>
            <InputGroup size="xl">
              <InputLeftElement>
                <Icon as={FiSearch} />
              </InputLeftElement>
              <Input
                type="search"
                placeholder="Agregar por CBU/CVU, Alias o proveedor agendado..."
                rounded="xl"
                roundedBottom={areOptionsShown ? 0 : undefined}
                {...inputProps}
              />
            </InputGroup>
            {areOptionsShown ? (
              <ModalBody roundedBottom="xl" px={4} pb={4} pt={4}>
                <Stack spacing={4}>
                  {isSearching ? (
                    <HStack color="gray.500" px={4} py={2} spacing={4}>
                      <Spinner size="sm" />
                      <Heading fontSize="lg" fontWeight="medium">
                        Buscando...
                      </Heading>
                    </HStack>
                  ) : options.length > 0 ? (
                    <List {...listboxProps} spacing={0}>
                      {options.map(
                        (
                          {
                            option: result,
                            optionProps: resultProps,
                            isFocused: isSelected,
                          },
                          i
                        ) => (
                          <ListItem
                            key={i}
                            pl={5}
                            pr={3}
                            py={3}
                            fontSize="lg"
                            bg={isSelected ? "brand.50" : undefined}
                            rounded="md"
                            cursor="pointer"
                            transition="background-color 0.2s"
                            color={
                              result.type === "lookup" ? "brand.500" : undefined
                            }
                            {...resultProps}
                          >
                            <HStack align="baseline" spacing={4}>
                              {result.type === "provider" ? (
                                <>
                                  <Text
                                    as="span"
                                    fontWeight="semibold"
                                    flexShrink={0}
                                    maxW="90%"
                                    isTruncated
                                  >
                                    {result.provider.contact.name}
                                  </Text>
                                  <Text
                                    as="span"
                                    color="gray.500"
                                    fontSize="sm"
                                    isTruncated
                                  >
                                    CBU {result.provider.contact.cbu}
                                  </Text>
                                </>
                              ) : (
                                <Text fontWeight="semibold">{result.text}</Text>
                              )}
                              <Spacer />
                              <Icon
                                alignSelf="center"
                                as={FiChevronRight}
                                color="gray.500"
                                boxSize={6}
                              />
                            </HStack>
                          </ListItem>
                        )
                      )}
                    </List>
                  ) : (
                    <Heading
                      fontSize="lg"
                      fontWeight="medium"
                      color="gray.500"
                      px={4}
                      py={2}
                    >
                      No se encontraron resultados
                    </Heading>
                  )}
                </Stack>
              </ModalBody>
            ) : null}
          </>
        )}
        {mode === "choosing-holder" && (
          <>
            <ModalCloseButton />
            <ModalHeader textAlign="center" p={6}>
              <Heading as="h2" size="lg">
                Cuenta bancaria con más de un titular
              </Heading>
            </ModalHeader>
            <ModalBody px={6} pb={6}>
              <Grid
                gap={6}
                templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
              >
                {foundNetworkContact?.holders.map((holder) => (
                  <Card
                    key={holder.taxId}
                    variant="outline"
                    as={LinkBox}
                    transition="box-shadow 0.2s"
                    _hover={{ shadow: "lg" }}
                  >
                    <CardBody py={8}>
                      <Stack align="center" spacing={6}>
                        <Stack align="center" spacing={2}>
                          <Heading as="h3" size="md">
                            {holder.fullName || "?"}
                          </Heading>
                          <Text color="gray.500" fontSize="sm">
                            CUIT{" "}
                            {holder.taxId ? formatTaxId(holder.taxId) : "?"}
                          </Text>
                        </Stack>
                        <LinkOverlay
                          as={Button}
                          rounded="full"
                          onClick={() =>
                            onAddProvider(
                              holder.fullName,
                              holder.taxId,
                              foundNetworkContact.cbu
                            )
                          }
                        >
                          Agendar
                        </LinkOverlay>
                      </Stack>
                    </CardBody>
                  </Card>
                ))}
              </Grid>
            </ModalBody>
          </>
        )}
        {mode === "looking-up-contact" && (
          <ModalBody px={6} py={24} color="gray.500">
            <Stack align="center">
              <HStack spacing={6} align="center">
                <Spinner thickness="2px" />
                <Heading as="h2" size="md" fontWeight="semibold">
                  Estamos validando los datos...
                </Heading>
              </HStack>
            </Stack>
          </ModalBody>
        )}
        {mode === "adding-provider" && (
          <ModalBody px={6} py={24} color="gray.500">
            <Stack align="center">
              <HStack spacing={6} align="center">
                <Spinner thickness="2px" />
                <Heading as="h2" size="md" fontWeight="semibold">
                  Estamos agendando el proveedor...
                </Heading>
              </HStack>
            </Stack>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};
