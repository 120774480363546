import { Stack } from "@chakra-ui/react";

import { BulkPaymentProvidersEditionEditorProvider } from "./payment-editor.context";
import { PaymentsList } from "./payments-list";

import { ProvidersList } from "modules/dashboard/routes/bulk-payments/bulk-payment-providers-edition/payment-editor/providers-list";

export const BulkPaymentProvidersEditionEditor = () => {
  return (
    <BulkPaymentProvidersEditionEditorProvider>
      <Stack spacing={10} w="full">
        <PaymentsList />
        <ProvidersList />
      </Stack>
    </BulkPaymentProvidersEditionEditorProvider>
  );
};
