import { PropsOf } from "@chakra-ui/react";

import { LineStepper } from "components/line-stepper";

const steps = ["Datos de la empresa", "Documentación", "Personas", "Resumen"];

export const OnboardingStepper = ({
  activeStep,
  ...rest
}: Omit<PropsOf<typeof LineStepper>, "steps">) => {
  return (
    <LineStepper
      activeStep={activeStep}
      alignSelf="end"
      display={{ base: "none", md: "flex" }}
      maxW="full"
      mb={12}
      pl="100px"
      steps={steps}
      {...rest}
    />
  );
};
