import { Skeleton } from "@chakra-ui/react";
import {
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
} from "@chakra-ui/table";

import { PropsWithRest } from "types/react-utils";

export const EmployeesTableSkeleton = ({
  rowCount = 8,
  ...rest
}: PropsWithRest<{
  rowCount?: number;
}>) => {
  const rowIndices = Array.from({ length: rowCount }, (_, i) => i);

  return (
    <TableContainer {...rest} overflowX="auto" px={2}>
      <Table size="lg">
        <Thead>
          <Tr>
            <Th px={4} w={0}></Th>
            <Th w="25%">Persona empleada</Th>
            <Th w="25%">CUIT</Th>
            <Th w="25%">CBU</Th>
            <Th isNumeric w="25%">
              Pago neto
            </Th>
            <Th px={4} w={0}></Th>
          </Tr>
        </Thead>
        <Tbody>
          {rowIndices.map((index) => (
            <Tr key={index}>
              <Td px={4} w={0}></Td>
              <Td>
                <Skeleton height={6} rounded="md" width="full" />
              </Td>
              <Td>
                <Skeleton height={6} rounded="md" width="full" />
              </Td>
              <Td>
                <Skeleton height={6} rounded="md" width="full" />
              </Td>
              <Td isNumeric>
                <Skeleton height={6} rounded="md" width="full" />
              </Td>
              <Td px={4} w={0}></Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
