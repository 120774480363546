import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  Stack,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useEffect, useMemo } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { gql, useMutation } from "urql";

import { UploadAvatar } from "./upload-avatar";

import { RadioSwitch } from "components/radio-switch";
import { OnboardingBackButton } from "modules/onboarding/components/onboarding-back-button";
import { OnboardingButton } from "modules/onboarding/components/onboarding-button";
import { OnboardingFieldHelp } from "modules/onboarding/components/onboarding-field-help";
import { OnboardingHeader } from "modules/onboarding/components/onboarding-header";
import { OnboardingLabel } from "modules/onboarding/components/onboarding-label";
import { OnboardingPage } from "modules/onboarding/components/onboarding-page";
import { useOnboarding } from "modules/onboarding/onboarding.context";
import { OnboardingSummary } from "modules/onboarding/onboarding.fragments";
import {
  CompanyAddress,
  CompanyDescription,
  CompanyOnboarding,
} from "modules/onboarding/onboarding.types";
import {
  OnboardingStep,
  getOnboardingPath,
} from "modules/onboarding/routes/onboarding.routes";
import { isValidTaxId } from "utils/tax-id.utils";

type Step1FormFields = {
  legalName: string;
  fantasyName: string;
  societyTypeId: string;
  taxConditionId: string;
  constitutionDay: string;
  isReportingParty: string;
  street: string;
  number: string;
  floor: string;
  apartment: string;
  zipCode: string;
  provinceISOCode: string;
  locality: string;
};

const isReportingPartyOptions = [
  { label: "Sí", value: "true" },
  { label: "No", value: "false" },
];

export const provinceOptions = [
  { id: "C", label: "CABA" },
  { id: "B", label: "Prov. de Bs As" },
  { id: "A", label: "Salta" },
  { id: "D", label: "San Luis" },
  { id: "E", label: "Entre Ríos" },
  { id: "F", label: "La Rioja" },
  { id: "G", label: "Santiago del Estero" },
  { id: "H", label: "Chaco" },
  { id: "J", label: "San Juan" },
  { id: "K", label: "Catamarca" },
  { id: "L", label: "La Pampa" },
  { id: "M", label: "Mendoza" },
  { id: "N", label: "Misiones" },
  { id: "P", label: "Formosa" },
  { id: "Q", label: "Neuquén" },
  { id: "R", label: "Río Negro" },
  { id: "S", label: "Santa Fe" },
  { id: "T", label: "Tucumán" },
  { id: "U", label: "Chubut" },
  { id: "V", label: "Tierra del Fuego" },
  { id: "W", label: "Corrientes" },
  { id: "X", label: "Córdoba" },
  { id: "Y", label: "Jujuy" },
  { id: "Z", label: "Santa Cruz" },
];

export const Step1 = () => {
  const {
    state: { isFetchingFormOptions, isSavingData },
    data: { onboardingSummary, isOnboardingFinished, formOptions },
    actions: { setIsSavingData, setRequiresFormReSubmission },
  } = useOnboarding();

  const hasUploadedDocs = useMemo(
    () =>
      onboardingSummary?.docs !== null &&
      onboardingSummary?.docs !== undefined &&
      onboardingSummary.docs.length > 0,
    [onboardingSummary]
  );

  const [, updateCompanyOnboardingDetails] = useMutation<
    {
      updateCompanyOnboardingDetails: {
        companyOnboarding: CompanyOnboarding;
      };
    },
    Pick<
      CompanyDescription,
      "fantasyName" | "legalName" | "constitutionDay" | "isReportingParty"
    > & {
      address: Partial<CompanyAddress>;
      societyTypeId: number;
      taxConditionId: number;
    }
  >(gql`
    mutation UpdateCompanyOnboardingDetails(
      $legalName: String!,
      $fantasyName: String!,
      $societyTypeId: Int!,
      $taxConditionId: Int!,
      $constitutionDay: String!,
      $isReportingParty: Boolean!,
      $address: CompanyAddressInput!
    ) {
    updateCompanyOnboardingDetails(in: {
      legalName: $legalName,
      fantasyName: $fantasyName,
      societyTypeId: $societyTypeId,
      taxConditionId: $taxConditionId,
      constitutionDay: $constitutionDay,
      isReportingParty: $isReportingParty,
      address: $address
    }) {
      ...OnboardingSummary
    }
    ${OnboardingSummary}
  }`);

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm<Step1FormFields>({
    mode: "onTouched",
  });

  const navigate = useNavigate();
  const location = useLocation();
  const isComingFromSummary = location.state?.fromSummary || false;

  const handleNavigateBack = () => {
    if (isComingFromSummary) {
      navigate(getOnboardingPath(OnboardingStep.Summary));
    }
  };

  const onSubmit: SubmitHandler<Step1FormFields> = async (data) => {
    if (isDirty) {
      setRequiresFormReSubmission(true);
    }
    setIsSavingData(true);
    const onboardingDetails = {
      fantasyName: data.fantasyName,
      societyTypeId: parseInt(data.societyTypeId, 10),
      legalName: data.legalName,
      constitutionDay: data.constitutionDay,
      taxConditionId: parseInt(data.taxConditionId, 10),
      isReportingParty: data.isReportingParty === "true",
      address: {
        street: data.street,
        number: data.number,
        locality: data.locality,
        provinceISOCode: data.provinceISOCode,
        zipCode: data.zipCode,
        floor: data.floor || undefined,
        apartment: data.apartment || undefined,
      },
    };
    const result = await updateCompanyOnboardingDetails(onboardingDetails);
    if (!result.error) {
      navigate(getOnboardingPath(OnboardingStep.Step2), {
        state: location.state,
      });
    }
    setIsSavingData(false);
  };

  useEffect(() => {
    reset(
      {
        legalName: onboardingSummary?.companyDescription.legalName,
        fantasyName: onboardingSummary?.companyDescription.fantasyName,
        societyTypeId:
          onboardingSummary?.companyDescription.societyType?.id.toString(),
        taxConditionId: onboardingSummary?.companyDescription.taxCondition?.id,
        constitutionDay:
          onboardingSummary?.companyDescription.constitutionDay ?? undefined,
        isReportingParty: onboardingSummary?.companyDescription.isReportingParty
          ? "true"
          : "false",
        street: onboardingSummary?.companyDescription.address?.street,
        number: onboardingSummary?.companyDescription.address?.number,
        floor: onboardingSummary?.companyDescription.address?.floor,
        apartment: onboardingSummary?.companyDescription.address?.apartment,
        zipCode: onboardingSummary?.companyDescription.address?.zipCode,
        provinceISOCode:
          onboardingSummary?.companyDescription.address?.provinceISOCode,
        locality: onboardingSummary?.companyDescription.address?.locality,
      },
      { keepDirtyValues: true }
    );
  }, [reset, onboardingSummary]);

  if (isOnboardingFinished) {
    return <Navigate to={getOnboardingPath(OnboardingStep.Summary)} />;
  }

  return (
    <OnboardingPage activeStep={0} isLoading={isFetchingFormOptions}>
      {isComingFromSummary && (
        <OnboardingBackButton onClick={handleNavigateBack} />
      )}

      <OnboardingHeader
        mb={12}
        subtitle="Esta información va a usarse para dar de alta a la empresa en Brubank y vas a poder editarla."
        title="Completá los datos de la empresa"
      />

      <UploadAvatar
        logo={onboardingSummary?.companyDescription.logo?.publicURL}
        maxW="md"
        mb={12}
      />

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Flex align="center" direction="column" maxW="sm" w="full">
          <Stack spacing={4} w="full">
            <FormControl
              isInvalid={errors.fantasyName !== undefined}
              isRequired
            >
              <OnboardingLabel>Nombre fantasía</OnboardingLabel>

              <OnboardingFieldHelp text="Es el nombre comercial por el cual se conoce a tu empresa.">
                <Input
                  isDisabled={isSavingData}
                  placeholder="Completá el nombre de la empresa"
                  size="lg"
                  {...register("fantasyName", {
                    required: "Ingresá el nombre fantasía de la empresa.",
                    maxLength: {
                      value: 60,
                      message:
                        "Ingresá un nombre fantasía de máximo 60 caracteres.",
                    },
                  })}
                />
              </OnboardingFieldHelp>

              <FormErrorMessage>
                {errors.fantasyName?.message?.toString()}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={errors.societyTypeId !== undefined}
              isRequired
            >
              <OnboardingLabel>Tipo societario</OnboardingLabel>
              <OnboardingFieldHelp
                text={
                  hasUploadedDocs
                    ? "Si querés editar el tipo societario, debés eliminar la documentación adjuntada."
                    : ""
                }
              >
                <Select
                  isDisabled={isSavingData || hasUploadedDocs}
                  placeholder="Elegir"
                  size="lg"
                  {...register("societyTypeId", {
                    required: "Elegí el tipo societario de la empresa.",
                  })}
                >
                  {formOptions.societyTypes?.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Select>
              </OnboardingFieldHelp>
              <FormErrorMessage>
                {errors.societyTypeId?.message?.toString()}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.legalName !== undefined} isRequired>
              <OnboardingLabel>Razón social</OnboardingLabel>

              <OnboardingFieldHelp text="Es el nombre con el cual se encuentra inscripto ante el Registro Público de Comercio de la jurisdicción de la empresa.">
                <Input
                  isDisabled={isSavingData}
                  placeholder="Completá la razón social de la empresa"
                  size="lg"
                  {...register("legalName", {
                    required: "Ingresá la razón social.",
                    minLength: {
                      value: 4,
                      message:
                        "Ingresá al menos 4 caracteres alfabéticos. Ejemplos de razón social: SA o SRL.",
                    },
                    maxLength: {
                      value: 70,
                      message:
                        "Ingresá una razón social de máximo 70 caracteres.",
                    },
                    validate: (value) => {
                      if (isValidTaxId(value)) {
                        return "La razón social tiene al menos 4 caracteres alfabéticos. Por ejemplo: SA o SRL.";
                      }
                      return true;
                    },
                  })}
                />
              </OnboardingFieldHelp>
              <FormErrorMessage>
                {errors.legalName?.message?.toString()}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={errors.constitutionDay !== undefined}
              isRequired
            >
              <OnboardingLabel>Fecha de constitución</OnboardingLabel>
              <OnboardingFieldHelp text="Es la fecha que figura en el acta de constitución de la empresa. La podés encontrar dentro de su Estatuto.">
                <Input
                  isDisabled={isSavingData}
                  max={dayjs().format("YYYY-MM-DD")}
                  size="lg"
                  type="date"
                  {...register("constitutionDay", {
                    required: "Ingresá la fecha de constitución.",
                    validate: (value) => {
                      const date = dayjs(value);
                      if (date.isBefore("1800-01-01")) {
                        return "Ingresá una fecha a partir del año 1800.";
                      }
                      if (date.isAfter(dayjs())) {
                        return "Ingresá una fecha anterior al día de hoy.";
                      }
                      return true;
                    },
                  })}
                />
              </OnboardingFieldHelp>
              <FormErrorMessage>
                {errors.constitutionDay?.message?.toString()}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={errors.taxConditionId !== undefined}
              isRequired
            >
              <OnboardingLabel>Condición impositiva</OnboardingLabel>
              <OnboardingFieldHelp text="Es la situación impositiva de la empresa frente a la AFIP.">
                <Select
                  isDisabled={isSavingData}
                  placeholder="Elegir"
                  size="lg"
                  {...register("taxConditionId", {
                    required: "Elegí la condición impositiva.",
                  })}
                >
                  {formOptions.taxConditions?.map(({ id, description }) => (
                    <option key={id} value={id}>
                      {description}
                    </option>
                  ))}
                </Select>
              </OnboardingFieldHelp>
              <FormErrorMessage>
                {errors.taxConditionId?.message?.toString()}
              </FormErrorMessage>
            </FormControl>

            <FormControl>
              <OnboardingLabel>¿Es sujeto obligado?</OnboardingLabel>
              <OnboardingFieldHelp text="Son las personas humanas y jurídicas señaladas en el artículo 20 de la Ley N° 25.246 y modificatorias. Como tales deben conocer a cada persona clienta y reportar operaciones sospechosas de LA/FT a la UIF.">
                <Flex w="full">
                  <Controller
                    control={control}
                    name="isReportingParty"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <RadioSwitch
                          isDisabled={isSavingData}
                          name="isReportingParty"
                          onChange={onChange}
                          options={isReportingPartyOptions}
                          value={value}
                        />
                      );
                    }}
                  />
                </Flex>
              </OnboardingFieldHelp>
            </FormControl>

            <FormControl
              isInvalid={
                errors.street !== undefined ||
                errors.number !== undefined ||
                errors.floor !== undefined ||
                errors.apartment !== undefined ||
                errors.zipCode !== undefined ||
                errors.locality !== undefined
              }
            >
              <OnboardingLabel mb={3}>Domicilio legal</OnboardingLabel>

              <OnboardingFieldHelp text="Es el domicilio físico de la empresa.">
                <Stack spacing={3.5}>
                  <HStack align="center" spacing={3} w="full">
                    <FormControl flex={14} isRequired variant="floating">
                      <Input
                        isDisabled={isSavingData}
                        isInvalid={errors.street !== undefined}
                        placeholder=" "
                        {...register("street", {
                          required: "Ingresá una calle.",
                          maxLength: {
                            value: 30,
                            message:
                              "Ingresá una calle de máximo 30 caracteres.",
                          },
                        })}
                      />
                      <FormLabel>Calle</FormLabel>
                    </FormControl>

                    <FormControl flex={6} isRequired variant="floating">
                      <Input
                        isDisabled={isSavingData}
                        isInvalid={errors.number !== undefined}
                        placeholder=" "
                        {...register("number", {
                          required: "Ingresá el número de la dirección.",
                          maxLength: {
                            value: 30,
                            message:
                              "Ingresá un número de máximo 30 caracteres.",
                          },
                        })}
                      />
                      <FormLabel>Nº</FormLabel>
                    </FormControl>
                  </HStack>

                  <HStack align="center" spacing={3} w="full">
                    <FormControl flex={2} variant="floating">
                      <Input
                        isDisabled={isSavingData}
                        isInvalid={errors.floor !== undefined}
                        placeholder=" "
                        {...register("floor", {
                          maxLength: {
                            value: 30,
                            message:
                              "Ingresá un número de piso de máximo 30 caracteres.",
                          },
                        })}
                      />
                      <FormLabel>Piso</FormLabel>
                    </FormControl>

                    <FormControl flex={2} variant="floating">
                      <Input
                        isDisabled={isSavingData}
                        isInvalid={errors.apartment !== undefined}
                        placeholder=" "
                        {...register("apartment", {
                          maxLength: {
                            value: 30,
                            message:
                              "Ingresá un número de departamento de máximo 30 caracteres.",
                          },
                        })}
                      />
                      <FormLabel>Depto</FormLabel>
                    </FormControl>

                    <FormControl flex={3} isRequired variant="floating">
                      <Input
                        isDisabled={isSavingData}
                        isInvalid={errors.zipCode !== undefined}
                        placeholder=" "
                        {...register("zipCode", {
                          required: "Ingresá el código postal.",
                          maxLength: {
                            value: 30,
                            message:
                              "Ingresá un código postal de máximo 30 caracteres.",
                          },
                        })}
                      />
                      <FormLabel>CP</FormLabel>
                    </FormControl>
                  </HStack>

                  <HStack align="center" spacing={3} w="full">
                    <FormControl flex={2} isRequired variant="floating">
                      <Select
                        isDisabled={isSavingData}
                        isInvalid={errors.provinceISOCode !== undefined}
                        {...register("provinceISOCode")}
                      >
                        {provinceOptions.map(
                          ({ id: provId, label: provName }) => (
                            <option key={provId} value={provId}>
                              {provName}
                            </option>
                          )
                        )}
                      </Select>
                      <FormLabel>Provincia</FormLabel>
                    </FormControl>

                    <FormControl flex={2} isRequired variant="floating">
                      <Input
                        isDisabled={isSavingData}
                        isInvalid={errors.locality !== undefined}
                        {...register("locality", {
                          required: "Ingresá la localidad.",
                          maxLength: {
                            value: 30,
                            message:
                              "Ingresá una localidad de máximo 30 caracteres.",
                          },
                        })}
                        placeholder=" "
                      />
                      <FormLabel>Localidad</FormLabel>
                    </FormControl>
                  </HStack>
                </Stack>
              </OnboardingFieldHelp>

              <FormErrorMessage>
                <UnorderedList listStyleType="none" ml={0} pl={0}>
                  <ListItem>{errors.street?.message}</ListItem>
                  <ListItem>{errors.number?.message}</ListItem>
                  <ListItem>{errors.floor?.message}</ListItem>
                  <ListItem>{errors.apartment?.message}</ListItem>
                  <ListItem>{errors.zipCode?.message}</ListItem>
                  <ListItem>{errors.locality?.message}</ListItem>
                </UnorderedList>
              </FormErrorMessage>
            </FormControl>
          </Stack>

          <OnboardingButton isLoading={isSavingData} mt={12} type="submit">
            Continuar
          </OnboardingButton>
        </Flex>
      </form>
    </OnboardingPage>
  );
};
