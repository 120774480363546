import { HStack, Stack, Text } from "@chakra-ui/react";

import { useOnboarding } from "modules/onboarding/onboarding.context";
import { formatMoney } from "utils/money.utils";

export const SocietyDetails = () => {
  const {
    data: { onboardingSummary },
  } = useOnboarding();

  return (
    <Stack direction="column" spacing={5}>
      <HStack>
        <Text fontWeight="semibold">Numero de registro:</Text>
        <Text>
          {onboardingSummary?.societyDetails.registrationNumber || "-"}
        </Text>
      </HStack>
      <HStack>
        <Text fontWeight="semibold">Descripción del registro:</Text>
        <Text>
          {onboardingSummary?.societyDetails.registrationDescription || "-"}
        </Text>
      </HStack>
      <HStack>
        <Text fontWeight="semibold">Capital social:</Text>
        <Text>
          {formatMoney(onboardingSummary?.societyDetails?.shareCapital || 0, {
            inputDecimalScale: 0,
            decimalScale: 0,
          })}
        </Text>
      </HStack>
    </Stack>
  );
};
