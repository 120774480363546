import { IconButton, Button } from "@chakra-ui/button";
import {
  Center,
  Icon,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Tooltip,
  Heading,
  Text,
  Stack,
  Spacer,
  useDisclosure,
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  Input,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  FiChevronsDown,
  FiDollarSign,
  FiEdit,
  FiMoreVertical,
  FiSave,
  FiTrash2,
} from "react-icons/fi";

import { ResponsiveButton } from "components/responsive-button";
import { useBulkPaymentProvidersEdition } from "modules/dashboard/routes/bulk-payments/bulk-payment-providers-edition/bulk-payment-providers-edition.context";
import { useBulkPaymentProvidersEditionEditor } from "modules/dashboard/routes/bulk-payments/bulk-payment-providers-edition/payment-editor/payment-editor.context";
import { Provider } from "modules/dashboard/routes/bulk-payments/bulk-payments-providers.api";
import { PropsWithRest } from "types/react-utils";
import { formatTaxId } from "utils/tax-id.utils";

export const ProvidersListBody = ({
  ...rest
}: PropsWithRest<{}, typeof Stack>) => {
  const {
    addPaymentToProviderDrawer: { onOpen: onAddPaymentToProviderDrawerOpen },
  } = useBulkPaymentProvidersEditionEditor();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [providerName, setProviderName] = useState("");
  const [selectedProvider, setProvider] = useState<Provider | null>(null);

  const {
    data: { providers },
    actions: { onFetchProvidersNextPage, onDeleteProvider, onEditProvider },
    state: {
      isFetchingProviders,
      areMoreProvidersPagesAvailable,
      isUpdatingProvider,
    },
  } = useBulkPaymentProvidersEdition();

  return (
    <>
      <Stack spacing={0} {...rest}>
        {providers.map((provider) => (
          <HStack
            key={provider.id}
            _notLast={{ borderBottomWidth: 1 }}
            px={{ base: 4, md: 6 }}
            py={{ base: 2, md: 4 }}
          >
            <Stack spacing={1}>
              <Heading as="h3" fontSize="lg" fontWeight="semibold">
                {provider.contact.name}
              </Heading>
              <Text
                noOfLines={1}
                color="gray.500"
                fontSize={{ base: "sm", md: "md" }}
                css={{
                  fontVariantNumeric: "tabular-nums",
                }}
              >
                CUIT {formatTaxId(provider.contact.taxId)}
                <Text as="span" userSelect="none" mx={2}>
                  ·
                </Text>
                CBU {provider.contact.cbu}
              </Text>
            </Stack>
            <Spacer />

            <HStack spacing={2}>
              <Menu>
                <ResponsiveButton
                  breakpoint="md"
                  rounded="full"
                  variant="subtle"
                  icon={<Icon as={FiDollarSign} />}
                  label="Crear pago"
                  iconPosition="none"
                  onClick={() =>
                    onAddPaymentToProviderDrawerOpen(provider.contact)
                  }
                />

                <Tooltip label="Acciones">
                  <MenuButton
                    aria-label="Acciones"
                    as={IconButton}
                    icon={<FiMoreVertical />}
                    isLoading={isUpdatingProvider(provider.id)}
                    rounded="full"
                    variant="ghost"
                  />
                </Tooltip>
                <Portal>
                  <MenuList>
                    <MenuItem
                      icon={<FiTrash2 />}
                      onClick={() => onDeleteProvider(provider.id)}
                    >
                      Eliminar
                    </MenuItem>
                    <MenuItem
                      icon={<FiEdit />}
                      onClick={() => {
                        setProviderName(provider.contact.name);
                        setProvider(provider);
                        onOpen();
                      }}
                    >
                      Editar
                    </MenuItem>
                  </MenuList>
                </Portal>
              </Menu>
              <Drawer size="md" isOpen={isOpen} onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton />
                  <DrawerHeader>
                    Edición de proveedor {selectedProvider?.contact.name}
                  </DrawerHeader>
                  <DrawerBody>
                    <Text as="label" fontWeight="bold">
                      Nombre
                      <Input
                        mt={1}
                        value={providerName}
                        placeholder="Nombre"
                        onChange={(e) => setProviderName(e.target.value)}
                      />
                    </Text>
                  </DrawerBody>
                  <DrawerFooter>
                    <Button
                      leftIcon={<FiSave />}
                      onClick={() => {
                        if (selectedProvider) {
                          onEditProvider(selectedProvider.id, {
                            name: providerName,
                          });
                          onClose();
                        }
                      }}
                    >
                      Guardar
                    </Button>
                  </DrawerFooter>
                </DrawerContent>
              </Drawer>
            </HStack>
          </HStack>
        ))}
      </Stack>
      {areMoreProvidersPagesAvailable && (
        <Center mt={3}>
          <Button
            color="gray.500"
            colorScheme="gray"
            isLoading={isFetchingProviders}
            leftIcon={<Icon as={FiChevronsDown} />}
            onClick={onFetchProvidersNextPage}
            rounded="full"
            variant="ghost"
          >
            Cargar más
          </Button>
        </Center>
      )}
    </>
  );
};
