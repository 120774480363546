import {
  ReactNode,
  Reducer,
  createContext,
  useCallback,
  useEffect,
} from "react";
import { CombinedError, useClient } from "urql";

import { useAlertDialog } from "contexts/alert-dialog.context";
import { useContextSafe } from "hooks/use-context-safe.hook";
import { useReducerAlt } from "hooks/use-reducer-alt.hook";
import {
  addPaymentToProvider,
  deletePayment,
  finishBulkPayment,
  updatePayment,
} from "modules/dashboard/routes/bulk-payments/bulk-payments-providers.api";
import {
  fetchBulkPayment,
  fetchBulkPaymentPaymentsPage,
  cancelBulkPayment,
  BulkPaymentType,
  Payment,
  BulkPaymentSummary,
  PaymentStatus,
  deleteBulkPayment,
  Contact,
  BulkPaymentOptions,
} from "modules/dashboard/routes/bulk-payments/bulk-payments.api";
import { Currency, AmountWithCurrency } from "types/api-global.types";

export type BulkPaymentProvidersEditionConfirmationResult = {
  successful: boolean | null;
  error: CombinedError | null;
};

export const BulkPaymentDetailContext = createContext<{
  actions: {
    onFetchFirstPage: () => Promise<void>;
    onFetchNextPage: () => Promise<void>;
    onAddPaymentToProvider: (
      contact: Contact,
      paymentData: {
        amount: AmountWithCurrency;
        description: string;
      }
    ) => Promise<boolean>;
    onUpdatePayment: (
      paymentId: string,
      paymentData: {
        amount: AmountWithCurrency;
        description?: string;
      }
    ) => Promise<boolean>;
    onDeletePayment: (paymentId: string) => Promise<void>;
    onConfirmBulkPayment: () => Promise<void>;
    onCancelBulkPayment: () => Promise<boolean>;
    onDeleteBulkPayment: () => Promise<boolean>;
    onUpdateScheduledFor: (
      scheduledFor: BulkPaymentOptions["scheduledFor"]
    ) => void;
  };
  data: {
    bulkPaymentId: string;
    bulkPayment: BulkPaymentSummary | null;
    payments: Payment[];
    paymentCount: number;
    totalAmount: AmountWithCurrency;
    confirmationResult: BulkPaymentProvidersEditionConfirmationResult;
    scheduledFor: BulkPaymentOptions["scheduledFor"];
  };
  state: {
    isFetchingFirstPage: boolean;
    isFetchingAdditionalPage: boolean;
    areMorePagesAvailable: boolean;
    isAddingPayment: boolean;
    isUpdatingPaymentIds: string[];
    isUpdatingPayment: (paymentId: string) => boolean;
    isConfirmingBulkPayment: boolean;
    isCancellingBulkPayment: boolean;
    isDeletingBulkPayment: boolean;
  };
} | null>(null);

export const useBulkPaymentDetail = () =>
  useContextSafe(BulkPaymentDetailContext);

type BulkPaymentDetailState = {
  bulkPayment: BulkPaymentSummary | null;
  payments: Payment[];
  cursor: string | null;
  metadata: {
    paymentCount: number;
    totalAmount: AmountWithCurrency;
  };
  isFetching: boolean;
  isFetchingFirstPage: boolean;
  isAdding: boolean;
  isUpdatingIds: string[];
  isConfirmingBulkPayment: boolean;
  isCancellingBulkPayment: boolean;
  isDeletingBulkPayment: boolean;
  confirmationResult: BulkPaymentProvidersEditionConfirmationResult;
  scheduledFor: BulkPaymentOptions["scheduledFor"];
};

type BulkPaymentDetailAction =
  | {
      type: "SET_BULK_PAYMENT";
      payload: BulkPaymentSummary | null;
    }
  | {
      type: "ADD_PAYMENTS";
      payload: {
        payments: Payment[];
        placement?: "start" | "end" | "replace";
      };
    }
  | {
      type: "UPDATE_PAYMENT";
      payload: Partial<Payment> & { id: string };
    }
  | {
      type: "DELETE_PAYMENT";
      payload: string;
    }
  | {
      type: "SET_CURSOR";
      payload: string | null;
    }
  | {
      type: "SET_METADATA";
      payload:
        | Partial<BulkPaymentDetailState["metadata"]>
        | ((
            prev: BulkPaymentDetailState["metadata"]
          ) => Partial<BulkPaymentDetailState["metadata"]>);
    }
  | {
      type: "SET_IS_FETCHING";
      payload: { firstPage?: boolean; value: boolean };
    }
  | {
      type: "SET_IS_ADDING";
      payload: boolean;
    }
  | {
      type: "SET_IS_UPDATING";
      payload: { ids: string[]; value: boolean };
    }
  | {
      type: "SET_IS_CONFIRMING_PAYMENT";
      payload: boolean;
    }
  | {
      type: "SET_IS_CANCELLING_PAYMENT";
      payload: boolean;
    }
  | {
      type: "SET_IS_DELETING_PAYMENT";
      payload: boolean;
    }
  | {
      type: "SET_CONFIRMATION_RESULT";
      payload: BulkPaymentProvidersEditionConfirmationResult;
    }
  | {
      type: "RESET_CONFIRMATION_RESULT";
      payload: null;
    }
  | {
      type: "SET_SCHEDULED_FOR";
      payload: BulkPaymentOptions["scheduledFor"];
    };

const BulkPaymentDetailReducer: Reducer<
  BulkPaymentDetailState,
  BulkPaymentDetailAction
> = (state, { type, payload }): BulkPaymentDetailState => {
  switch (type) {
    case "SET_BULK_PAYMENT":
      return { ...state, bulkPayment: payload };
    case "ADD_PAYMENTS":
      if (payload.placement === "replace") {
        return { ...state, payments: payload.payments };
      } else if (payload.placement === "start") {
        return {
          ...state,
          payments: [...payload.payments, ...state.payments],
        };
      } else {
        return {
          ...state,
          payments: [...state.payments, ...payload.payments],
        };
      }
    case "UPDATE_PAYMENT":
      return {
        ...state,
        payments: state.payments.map((payment) =>
          payment.id === payload.id ? { ...payment, ...payload } : payment
        ),
      };
    case "DELETE_PAYMENT":
      return {
        ...state,
        payments: state.payments.filter((payment) => payment.id !== payload),
      };
    case "SET_CURSOR":
      return { ...state, cursor: payload };
    case "SET_IS_FETCHING":
      return {
        ...state,
        [payload.firstPage ? "isFetchingFirstPage" : "isFetching"]:
          payload.value,
      };
    case "SET_METADATA":
      return {
        ...state,
        metadata: {
          ...state.metadata,
          ...(typeof payload === "function"
            ? payload(state.metadata)
            : payload),
        },
      };
    case "SET_IS_ADDING":
      return { ...state, isAdding: payload };
    case "SET_IS_UPDATING":
      return {
        ...state,
        isUpdatingIds: payload.value
          ? [...state.isUpdatingIds, ...payload.ids]
          : state.isUpdatingIds.filter((id) => !payload.ids.includes(id)),
      };
    case "SET_IS_CONFIRMING_PAYMENT":
      return { ...state, isConfirmingBulkPayment: payload };
    case "SET_IS_CANCELLING_PAYMENT":
      return { ...state, isCancellingBulkPayment: payload };
    case "SET_IS_DELETING_PAYMENT":
      return { ...state, isDeletingBulkPayment: payload };
    case "SET_CONFIRMATION_RESULT":
      return { ...state, confirmationResult: payload };
    case "RESET_CONFIRMATION_RESULT":
      return {
        ...state,
        confirmationResult: {
          successful: null,
          error: null,
        },
      };
    case "SET_SCHEDULED_FOR":
      return { ...state, scheduledFor: payload };
  }
};

export const BulkPaymentDetailProvider = ({
  bulkPaymentId,
  pageSize = 50,
  children,
}: {
  bulkPaymentId: string;
  pageSize?: number;
  children: ReactNode;
}) => {
  const client = useClient();
  const alertDialog = useAlertDialog();

  const [state, dispatch] = useReducerAlt(BulkPaymentDetailReducer, {
    bulkPayment: null,
    payments: [],
    cursor: null,
    metadata: {
      paymentCount: 0,
      totalAmount: {
        amountInCents: 0,
        currency: Currency.ARS,
      },
    },
    isFetching: false,
    isFetchingFirstPage: false,
    isAdding: false,
    isUpdatingIds: [],
    isConfirmingBulkPayment: false,
    isCancellingBulkPayment: false,
    isDeletingBulkPayment: false,
    confirmationResult: {
      successful: null,
      error: null,
    },
    scheduledFor: null,
  });

  /**
   * API requests
   */
  const onFetchFirstPage = useCallback(async () => {
    dispatch("SET_IS_FETCHING", { firstPage: true, value: true });
    const result = await fetchBulkPayment(
      client,
      bulkPaymentId,
      pageSize,
      null
    );
    if (!result.error && result.data) {
      dispatch("SET_BULK_PAYMENT", result.data.bulkPayment);
      if (result.data.bulkPayment) {
        dispatch("ADD_PAYMENTS", {
          payments: result.data.bulkPayment.payments.payments,
          placement: "replace",
        });
        dispatch("SET_METADATA", {
          paymentCount:
            result.data.bulkPayment.paymentsMetadata?.total.count ?? 0,
          totalAmount: result.data.bulkPayment.paymentsMetadata?.total
            .amount ?? {
            amountInCents: 0,
            currency: Currency.ARS,
          },
        });
        dispatch("SET_CURSOR", result.data.bulkPayment.payments.cursor);
      }
    }
    dispatch("SET_IS_FETCHING", { firstPage: true, value: false });
  }, [client, dispatch, pageSize, bulkPaymentId]);

  const onFetchNextPage = useCallback(async () => {
    dispatch("SET_IS_FETCHING", { firstPage: false, value: true });
    const result = await fetchBulkPaymentPaymentsPage(
      client,
      bulkPaymentId,
      pageSize,
      state.cursor
    );
    if (!result.error && result.data) {
      dispatch("ADD_PAYMENTS", {
        payments: result.data.bulkPayment.payments.payments,
        placement: "end",
      });
      dispatch("SET_CURSOR", result.data.bulkPayment.payments.cursor);
    }
    dispatch("SET_IS_FETCHING", { firstPage: false, value: false });
  }, [client, dispatch, pageSize, state.cursor, bulkPaymentId]);

  const onAddPaymentToProvider = useCallback(
    async (
      contact: Contact,
      paymentData: {
        amount: AmountWithCurrency;
        description: string;
      }
    ) => {
      dispatch("SET_IS_ADDING", true);
      const result = await addPaymentToProvider(
        client,
        bulkPaymentId,
        contact.id,
        paymentData
      );
      if (!result.error && result.data) {
        dispatch("ADD_PAYMENTS", {
          payments: [
            {
              id: result.data.addPaymentToProvider.id,
              to: contact,
              toCBU: contact.cbu,
              status: PaymentStatus.Pending,
              amount: paymentData.amount,
              description: paymentData.description,
            },
          ],
          placement: "start",
        });
        dispatch("SET_METADATA", ({ paymentCount, totalAmount }) => ({
          paymentCount: paymentCount + 1,
          totalAmount: {
            ...totalAmount,
            amountInCents:
              totalAmount.amountInCents + paymentData.amount.amountInCents,
          },
        }));
      }
      dispatch("SET_IS_ADDING", false);
      return result.error === undefined;
    },
    [client, dispatch, bulkPaymentId]
  );

  const onUpdatePayment = useCallback(
    async (
      paymentId: string,
      paymentData: {
        amount: AmountWithCurrency;
        description?: string;
      }
    ) => {
      dispatch("SET_IS_UPDATING", { ids: [paymentId], value: true });
      const prevPaymentAmount =
        state.payments.find((payment) => payment.id === paymentId)?.amount
          .amountInCents ?? 0;
      const result = await updatePayment(
        client,
        paymentId,
        bulkPaymentId,
        paymentData
      );
      if (!result.error && result.data) {
        dispatch("UPDATE_PAYMENT", {
          id: paymentId,
          ...paymentData,
        });
        dispatch("SET_METADATA", ({ totalAmount }) => ({
          totalAmount: {
            ...totalAmount,
            amountInCents:
              totalAmount.amountInCents +
              (paymentData.amount.amountInCents - prevPaymentAmount),
          },
        }));
      }
      dispatch("SET_IS_UPDATING", { ids: [paymentId], value: false });
      return result.error === undefined;
    },
    [client, dispatch, bulkPaymentId, state.payments]
  );

  const onDeletePayment = useCallback(
    async (paymentId: string) => {
      const confirmed = await alertDialog({
        title: "¿Querés eliminar este pago?",
        message:
          "Si te arrepentís, no te preocupes. Vas a poder volver a crear el pago cuando quieras.",
        cancelButton: { text: "No eliminar" },
        confirmButton: { text: "Eliminar" },
      });
      if (confirmed) {
        dispatch("SET_IS_UPDATING", { ids: [paymentId], value: true });
        const paymentAmount =
          state.payments.find((payment) => payment.id === paymentId)?.amount
            .amountInCents ?? 0;
        const result = await deletePayment(client, paymentId, bulkPaymentId);
        if (!result.error) {
          dispatch("DELETE_PAYMENT", paymentId);
          dispatch("SET_METADATA", ({ paymentCount, totalAmount }) => ({
            paymentCount: paymentCount - 1,
            totalAmount: {
              ...totalAmount,
              amountInCents: totalAmount.amountInCents - paymentAmount,
            },
          }));
        }
        dispatch("SET_IS_UPDATING", { ids: [paymentId], value: false });
      }
    },
    [alertDialog, client, dispatch, bulkPaymentId, state.payments]
  );

  const onConfirmBulkPayment = useCallback(async () => {
    dispatch("SET_IS_CONFIRMING_PAYMENT", true);
    const result = await finishBulkPayment(
      client,
      bulkPaymentId,
      state.scheduledFor,
      {
        noErrorToast: true,
      }
    );
    dispatch("SET_IS_CONFIRMING_PAYMENT", false);
    dispatch("SET_CONFIRMATION_RESULT", {
      successful: !result.error,
      error: result.error ?? null,
    });
  }, [client, dispatch, bulkPaymentId, state.scheduledFor]);

  const onCancelBulkPayment = useCallback(async () => {
    const confirmed = await alertDialog({
      title: `¿Querés cancelar el pago${
        state.bulkPayment
          ? state.bulkPayment.paymentType === BulkPaymentType.Providers
            ? " a proveedores"
            : " de sueldos"
          : ""
      }?`,
      message: "No vas a poder deshacer esta acción.",
      cancelButton: { text: "No cancelar" },
      confirmButton: { text: "Cancelar" },
    });
    if (confirmed) {
      dispatch("SET_IS_CANCELLING_PAYMENT", true);
      const result = await cancelBulkPayment(client, bulkPaymentId);
      dispatch("SET_IS_CANCELLING_PAYMENT", false);
      if (!result.error) {
        dispatch("RESET_CONFIRMATION_RESULT", null);
        return true;
      }
    }
    return false;
  }, [alertDialog, client, dispatch, bulkPaymentId, state.bulkPayment]);

  const onDeleteBulkPayment = useCallback(async () => {
    const confirmed = await alertDialog({
      title: "¿Querés eliminar este borrador de pago?",
      message: "No vas a poder deshacer esta acción.",
      cancelButton: { text: "No eliminar" },
      confirmButton: { text: "Eliminar" },
    });
    if (confirmed) {
      dispatch("SET_IS_DELETING_PAYMENT", true);
      const result = await deleteBulkPayment(client, bulkPaymentId);
      dispatch("SET_IS_DELETING_PAYMENT", false);
      if (!result.error) {
        dispatch("RESET_CONFIRMATION_RESULT", null);
        return true;
      }
    }
    return false;
  }, [alertDialog, client, dispatch, bulkPaymentId]);

  const onUpdateScheduledFor = useCallback(
    (scheduledFor: BulkPaymentOptions["scheduledFor"]) => {
      dispatch("SET_SCHEDULED_FOR", scheduledFor);
    },
    [dispatch]
  );

  useEffect(() => {
    onFetchFirstPage();
  }, [onFetchFirstPage]);

  return (
    <BulkPaymentDetailContext.Provider
      value={{
        actions: {
          onFetchFirstPage,
          onFetchNextPage,
          onAddPaymentToProvider,
          onUpdatePayment,
          onDeletePayment,
          onConfirmBulkPayment,
          onCancelBulkPayment,
          onDeleteBulkPayment,
          onUpdateScheduledFor,
        },
        data: {
          bulkPaymentId,
          bulkPayment: state.bulkPayment,
          payments: state.payments,
          paymentCount: state.metadata.paymentCount,
          totalAmount: state.metadata.totalAmount,
          confirmationResult: state.confirmationResult,
          scheduledFor: state.scheduledFor,
        },
        state: {
          isFetchingFirstPage: state.isFetchingFirstPage,
          isFetchingAdditionalPage: state.isFetching,
          areMorePagesAvailable: state.cursor !== null,
          isAddingPayment: state.isAdding,
          isUpdatingPaymentIds: state.isUpdatingIds,
          isUpdatingPayment: (paymentId: string) =>
            state.isUpdatingIds.includes(paymentId),
          isConfirmingBulkPayment: state.isConfirmingBulkPayment,
          isCancellingBulkPayment: state.isCancellingBulkPayment,
          isDeletingBulkPayment: state.isDeletingBulkPayment,
        },
      }}
    >
      {children}
    </BulkPaymentDetailContext.Provider>
  );
};
