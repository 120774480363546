import { Box, Flex, HStack, Spacer, Heading } from "@chakra-ui/layout";
import { Button, Icon } from "@chakra-ui/react";
import { PropsWithChildren, ReactNode } from "react";
import { FiArrowLeft } from "react-icons/fi";
import { Link, To } from "react-router-dom";

import { PropsWithRest } from "types/react-utils";

const DashboardActionBar = ({
  children,
  ...rest
}: PropsWithRest<PropsWithChildren<{}>>) => {
  return (
    <Box
      backdropFilter="auto"
      bg="whiteAlpha.200"
      {...rest}
      backdropBlur="lg"
      borderColor="blackAlpha.100"
      borderTopWidth={1}
      px={10}
      py={6}
      zIndex={100}
      shadow="lg"
    >
      {children}
    </Box>
  );
};

export const DashboardSection = ({
  title,
  headerContent,
  headerControls,
  actionBarContent,
  backButton,
  isBackButtonDisabled,
  children,
  ...rest
}: PropsWithRest<
  PropsWithChildren<{
    title?: string;
    headerContent?: ReactNode;
    headerControls?: ReactNode;
    actionBarContent?: ReactNode;
    backButton?: To;
    isBackButtonDisabled?: boolean;
  }>
>) => {
  return (
    <Flex direction="column" {...rest} minH="100vh">
      <Flex direction="column" flexGrow={1} p={10}>
        <HStack mb={10} spacing={6}>
          {backButton && (
            <Button
              aria-label="Atrás"
              as={Link}
              bg="white"
              borderColor="gray.200"
              borderWidth={1}
              color="gray.500"
              colorScheme="gray"
              h={8}
              isDisabled={isBackButtonDisabled}
              rounded="full"
              size="md"
              to={backButton}
              variant="solid"
            >
              <Icon as={FiArrowLeft} boxSize={5} />
            </Button>
          )}
          {headerContent ? (
            <Box>{headerContent}</Box>
          ) : title ? (
            <Box>
              <Heading
                as="h1"
                fontSize="2xl"
                fontWeight="bold"
                lineHeight="shorter"
              >
                {title}
              </Heading>
            </Box>
          ) : null}
          {headerControls && (
            <>
              <Spacer />
              {headerControls}
            </>
          )}
        </HStack>
        <Flex direction="column" flexGrow={1}>
          {children}
        </Flex>
      </Flex>

      {actionBarContent && (
        <DashboardActionBar bottom={0} flexShrink={0} position="sticky">
          {actionBarContent}
        </DashboardActionBar>
      )}
    </Flex>
  );
};
