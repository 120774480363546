import { Flex, HStack, Icon, IconButton, Stack, Text } from "@chakra-ui/react";
import { ReactNode } from "react";
import { useDropzone } from "react-dropzone";
import { FiTrash2, FiUpload } from "react-icons/fi";
import { LuFileCheck } from "react-icons/lu";
import { RiCheckboxCircleFill, RiErrorWarningFill } from "react-icons/ri";

export const MemberLegalPowerDropzone = ({
  value,
  isDisabled = false,
  onChange,
}: {
  value: File | null;
  isDisabled?: boolean;
  onChange: (file: File | null) => void;
}) => {
  const onRemove = (event) => {
    event.stopPropagation();

    onChange(null);
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "application/msword": [".doc"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
        "application/pdf": [".pdf"],
        "image/png": [".png"],
        "image/jpeg": [".jpg"],
      },
      multiple: false,
      maxFiles: 1,
      onDropAccepted: (data) => {
        onChange(data[0]);
      },
      disabled: isDisabled,
    });

  let content: ReactNode;

  if (isDragAccept) {
    content = (
      <Stack align="center" spacing={2}>
        <Icon as={RiCheckboxCircleFill} boxSize={10} color="green.500" />
        <Text
          color="green.500"
          fontSize="lg"
          fontWeight="semibold"
          textAlign="center"
        >
          El archivo arrastrado es compatible
        </Text>
      </Stack>
    );
  } else if (isDragReject) {
    content = (
      <Stack align="center" spacing={2}>
        <Icon as={RiErrorWarningFill} boxSize={10} color="red.500" />
        <Text
          color="red.500"
          fontSize="lg"
          fontWeight="semibold"
          textAlign="center"
        >
          El archivo arrastrado no es compatible
        </Text>
      </Stack>
    );
  } else if (value) {
    content = (
      <Flex align="center" maxW="full">
        <HStack align="center" alignItems="stretch" maxW="full">
          <HStack
            align="center"
            bgColor="gray.200"
            color="gray.600"
            fontSize="sm"
            fontWeight="semibold"
            minW={0}
            px={3}
            rounded="full"
            _dark={{
              bgColor: "gray.700",
              color: "gray.400",
            }}
          >
            <Icon as={LuFileCheck} />
            <Text isTruncated>{value.name} </Text>
          </HStack>
          <IconButton
            aria-label="Borrar documento"
            colorScheme="red"
            icon={<FiTrash2 />}
            isRound
            onClick={onRemove}
            size="sm"
          />
        </HStack>
      </Flex>
    );
  } else {
    content = (
      <Stack align="center" spacing={2} textAlign="center">
        <Icon as={FiUpload} boxSize={8} />
        <Text color="gray.500" fontSize="xs" fontWeight="semibold">
          Archivos compatibles: DOC, DOCX, XLS, XLSX, JPG, PNG, o PDF
        </Text>
        <Text
          fontSize="lg"
          fontWeight="semibold"
          lineHeight={1.2}
          maxW="250px"
          textAlign="center"
        >
          Adjuntar poder
        </Text>
      </Stack>
    );
  }

  return (
    <Flex
      direction="column"
      {...getRootProps({
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "150px",
        py: 6,
        px: 4,
        cursor: "pointer",
        backgroundColor: "gray.100",
        _dark: {
          backgroundColor: "gray.800",
          borderColor: isDragAccept
            ? "green.400"
            : isDragReject
            ? "red.400"
            : "gray.600",
        },
        borderWidth: 2,
        borderColor: isDragAccept
          ? "green.400"
          : isDragReject
          ? "red.400"
          : "gray.300",
        rounded: "md",
        borderStyle: "dashed",
        outline: "none",
        transition: "border .2s ease-in-out",
      })}
    >
      <input data-testid="dropzone-input" {...getInputProps()} />
      {content}
    </Flex>
  );
};
