import { Flex, Grid, Stack, Text } from "@chakra-ui/react";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { ActionButtonProps } from "./action-button";
import { SectionHeader } from "./section-header";

import { CompanyDescription } from "modules/onboarding/onboarding.types";
import {
  OnboardingStep,
  getOnboardingPath,
} from "modules/onboarding/routes/onboarding.routes";
import { formatDate } from "utils/date.utils";
import { formatTaxId } from "utils/tax-id.utils";

export const Business = ({
  data,
  isDisabled,
}: {
  data?: CompanyDescription;
  isDisabled: boolean;
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(getOnboardingPath(OnboardingStep.Step1), {
      state: { fromSummary: true },
    });
  };

  const dataFields = [
    {
      label: "Nombre fantasía",
      value: data?.fantasyName,
    },
    {
      label: "CUIT",
      value: data?.taxId ? formatTaxId(data?.taxId) : undefined,
    },
    {
      label: "Tipo societario",
      value: data?.societyType?.name,
    },
    {
      label: "Razón social",
      value: data?.legalName,
    },
    {
      label: "Fecha de constitución",
      value: data?.constitutionDay
        ? formatDate(data?.constitutionDay)
        : undefined,
    },
    {
      label: "Actividad que realiza",
      value: data?.activity?.name,
    },
    {
      label: "Antigüedad en rubro",
      value: data?.ageInBusiness,
    },
    {
      label: "Operación otros bancos",
      value: data?.hasOtherBanks ? "Sí" : "No",
    },
    {
      label: "Cantidad de personas empleadas",
      value: data?.employeesQuantity,
    },
  ];

  const button: ActionButtonProps = {
    buttonIcon: <FiEdit />,
    buttonLabel: "Editar datos",
    isDisabled,
    onButtonClick: handleClick,
  };

  return (
    <Stack direction="column" spacing={5}>
      <SectionHeader title="Datos de la empresa" button={button} />
      <Grid
        columnGap={4}
        rowGap={{ base: 2, sm: 5 }}
        templateColumns={{
          base: "1fr",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
        }}
      >
        {dataFields.map((item, index) => (
          <Flex key={index} className="antiquity" direction="column">
            <Text color="gray.400" fontSize="md" fontWeight="semibold">
              {item.label}
            </Text>
            <Text fontSize="lg" fontWeight="semibold">
              {item.value || "-"}
            </Text>
          </Flex>
        ))}
      </Grid>
    </Stack>
  );
};
