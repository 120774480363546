import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { useCallback, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useRecaptcha, withRecaptcha } from "contexts/recaptcha.context";
import { useOnboarding } from "modules/onboarding/onboarding.context";
import {
  OnboardingStep,
  getOnboardingPath,
} from "modules/onboarding/routes/onboarding.routes";

export const SessionExpiredDialog = withRecaptcha(
  ({ isOpen = true, onClose }: { isOpen?: boolean; onClose: () => void }) => {
    const {
      state: { isCreatingCompanyOnboarding },
      data: { lastCreatedOnboarding },
      actions: { onCreateCompanyOnboarding },
    } = useOnboarding();

    const goToStartButtonRef = useRef(null);

    const navigate = useNavigate();

    const { getRecaptchaToken } = useRecaptcha();

    const onGoToStart = useCallback(() => {
      navigate(getOnboardingPath(OnboardingStep.Start));
      onClose();
    }, [navigate, onClose]);

    const onResendEmail = useCallback(async () => {
      if (!lastCreatedOnboarding) {
        return;
      }
      const { email, taxId } = lastCreatedOnboarding;
      const successful = await onCreateCompanyOnboarding(
        email,
        taxId,
        getRecaptchaToken
      );
      if (successful) {
        onClose();
      } else {
        onGoToStart();
      }
    }, [
      lastCreatedOnboarding,
      onCreateCompanyOnboarding,
      onClose,
      onGoToStart,
      getRecaptchaToken,
    ]);

    const bodyText = useMemo(() => {
      if (lastCreatedOnboarding !== null) {
        return "No te preocupes, no vas a tener que cargar tu información de nuevo ya que la guardamos. Para retomar el proceso, reenviate el correo electrónico de ingreso.";
      } else {
        return "No te preocupes, no vas a tener que cargar tu información de nuevo ya que la guardamos. Para retomar el proceso, ingresá de nuevo con tu correo electrónico y CUIT de empresa.";
      }
    }, [lastCreatedOnboarding]);

    return (
      <AlertDialog
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isCentered
        isOpen={isOpen}
        leastDestructiveRef={goToStartButtonRef}
        onClose={onClose}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Tu sesión finalizó</AlertDialogHeader>
          <AlertDialogBody>{bodyText}</AlertDialogBody>
          <AlertDialogFooter gap={4} justifyContent="center">
            {lastCreatedOnboarding !== null ? (
              <>
                <Button
                  ref={goToStartButtonRef}
                  onClick={onGoToStart}
                  variant="subtle"
                >
                  Cerrar
                </Button>

                <Button
                  isLoading={isCreatingCompanyOnboarding}
                  onClick={onResendEmail}
                >
                  Reenviar correo
                </Button>
              </>
            ) : (
              <Button ref={goToStartButtonRef} onClick={onGoToStart}>
                Ingresar de nuevo
              </Button>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  }
);
