import { Icon } from "@chakra-ui/icon";
import { Box, Center, Flex, Stack, Spacer } from "@chakra-ui/layout";
import { IconButton, Tooltip } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { BiDollarCircle, BiHome } from "react-icons/bi";
import { FiPower } from "react-icons/fi";
import { NavLink } from "react-router-dom";

import { CompanyAvatar } from "modules/dashboard/components/company-avatar";
import { useDashboard } from "modules/dashboard/dashboard.context";
import { PropsWithRest } from "types/react-utils";

const sections = [
  {
    path: "/",
    icon: BiHome,
    title: "Home",
  },
  {
    path: "/pagos/sueldos",
    icon: BiDollarCircle,
    title: "Pago de sueldos",
  },
];

export const DashboardSidebar = ({
  ...rest
}: PropsWithRest<PropsWithChildren<{}>>) => {
  const {
    data: { company },
    actions: { onLogoutExternalOperator },
    state: { isLoggingOutExternalOperator },
  } = useDashboard();

  return (
    <Box bg="white" w={20} {...rest} px={4} py={6}>
      <Stack align="center" h="full" spacing={8}>
        <Center h={12} overflow="hidden" rounded="lg" w={12}>
          <CompanyAvatar size={12} src={company?.logo.publicURL} />
        </Center>

        <Stack spacing={4}>
          {sections.map(({ path, icon }, index) => {
            return (
              <NavLink key={index} to={path}>
                {({ isActive }) => (
                  <Flex
                    _hover={{ bg: "brand.100", color: "brand.600" }}
                    color={isActive ? "brand.500" : "gray.500"}
                    p={2}
                    rounded="full"
                    transition="all 0.2s"
                  >
                    <Icon as={icon} boxSize={5} />
                  </Flex>
                )}
              </NavLink>
            );
          })}
        </Stack>

        <Spacer />

        <Tooltip label="Cerrar sesión" placement="right">
          <IconButton
            aria-label="Cerrar sesión"
            colorScheme="gray"
            icon={<Icon as={FiPower} boxSize={6} />}
            isLoading={isLoggingOutExternalOperator}
            onClick={onLogoutExternalOperator}
            rounded="full"
            size="lg"
          />
        </Tooltip>
      </Stack>
    </Box>
  );
};
