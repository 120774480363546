export const isValidCbu = (value?: string | number): boolean => {
  const valueAsString = value?.toString() ?? "";
  const regex = /^(\d{7})(\d)(\d{13})(\d)$/;
  const match = valueAsString.match(regex);
  if (match) {
    const [, p1, v1, p2, v2] = match;
    const multipliers = [3, 1, 7, 9];
    const getSum = (digits: string) =>
      digits
        .split("")
        .reverse()
        .map((y, i) => parseInt(y, 10) * multipliers[i % 4])
        .reduce((s, y) => s + y, 0);
    const validate = (digits: string, validator: string) =>
      parseInt(validator, 10) === (10 - (getSum(digits) % 10)) % 10;
    return validate(p1, v1) && validate(p2, v2);
  }
  return false;
};

export const isValidCbuAlias = (value?: string | number): boolean =>
  /^[0-9a-zA-Z.-]{6,20}$/.test(value?.toString() ?? "");
