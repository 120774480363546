import { Reducer, useCallback, useReducer } from "react";

type ActionType<
  A extends { type: string; payload: any },
  T extends A["type"]
> = Extract<A, { type: T }>;

/**
 * `useReducerAlt` is a wrapper around `useReducer` that provides a more
 * convenient way to dispatch actions. Every action must have a `type` (which
 * must be a string) and a `payload` (which can be anything).
 */
export const useReducerAlt = <S, A extends { type: string; payload: any }>(
  reducer: Reducer<S, A>,
  initialState: S
) => {
  const [state, _dispatch] = useReducer(reducer, initialState);

  // Sintax sugar to make dispatch less verbose
  const dispatch = useCallback(
    <T extends A["type"]>(action: T, payload: ActionType<A, T>["payload"]) =>
      _dispatch({ type: action, payload } as ActionType<A, T>),
    [_dispatch]
  );

  return [state, dispatch] as const;
};
