import {
  Box,
  Button,
  Center,
  Grid,
  HStack,
  Icon,
  Skeleton,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FiChevronsDown, FiFile } from "react-icons/fi";
import { useClient } from "urql";

import { BulkPaymentsHistoryItemSkeleton } from "./bulk-payments-history-item-skeleton";
import { BulkPaymentsHistoryNewSalaries } from "./bulk-payments-history-new-salaries";

import { useAlertDialog } from "contexts/alert-dialog.context";
import { useInfinitePagination } from "hooks/pagination/use-infinite-pagination.hook";
import { useSearchParamsState } from "hooks/use-search-params-state.hook";
import { useSet } from "hooks/use-set";
import { DashboardSection } from "modules/dashboard/components/dashboard-section";
import { BulkPaymentsHistoryDraftItem } from "modules/dashboard/routes/bulk-payments/bulk-payments-history/bulk-payments-history-draft-item";
import { BulkPaymentsHistoryItem } from "modules/dashboard/routes/bulk-payments/bulk-payments-history/bulk-payments-history-item";
import { BulkPaymentsHistoryNewProviders } from "modules/dashboard/routes/bulk-payments/bulk-payments-history/bulk-payments-history-new-providers";
import {
  BulkPaymentStatus,
  BulkPaymentType,
  deleteBulkPayment,
  fetchBulkPayments,
} from "modules/dashboard/routes/bulk-payments/bulk-payments.api";
import { bulkPaymentStatusLabel } from "modules/dashboard/routes/bulk-payments/bulk-payments.utils";

export const BulkPaymentsHistory = ({
  type,
  pageSize = 12,
}: {
  type: BulkPaymentType;
  pageSize?: number;
}) => {
  const client = useClient();

  const alertDialog = useAlertDialog();

  const statusFilters: Array<{
    label: string;
    value: BulkPaymentStatus | null;
  }> = useMemo(
    () => [
      { label: "Todos", value: null },
      ...Object.values(BulkPaymentStatus)
        .filter(
          (status) =>
            type === BulkPaymentType.Providers ||
            status !== BulkPaymentStatus.Created
        )
        .map((status) => ({
          label: bulkPaymentStatusLabel(status, true),
          value: status,
        })),
    ],
    [type]
  );
  const [currentFilters, setCurrentFilters] = useSearchParamsState<{
    status: BulkPaymentStatus | null;
  }>({ status: { initialValue: null } });

  const showNewPaymentButton = useMemo(
    () =>
      currentFilters.status === null ||
      currentFilters.status === BulkPaymentStatus.Created,
    [currentFilters.status]
  );

  const [bulkPaymentCount, setBulkPaymentCount] = useState<number>(0);
  const deletingBulkPaymentIds = useSet<string>();

  const {
    data: bulkPayments,
    isLoadingFirstPage,
    isLoadingAdditionalPage,
    onLoadFirstPage,
    onLoadNextPage,
    areMorePagesAvailable,
  } = useInfinitePagination(
    useCallback(
      async (cursor: string | null) => {
        const result = await fetchBulkPayments(
          client,
          showNewPaymentButton ? pageSize - 1 : pageSize,
          cursor,
          { type, status: currentFilters.status ?? undefined }
        );
        if (!result.error && result.data) {
          setBulkPaymentCount(result.data.bulkPayments.totalCount);
          return {
            data: result.data.bulkPayments.bulkPayments,
            cursor: result.data.bulkPayments.cursor,
          };
        } else {
          return null;
        }
      },
      [client, showNewPaymentButton, pageSize, type, currentFilters.status]
    ),
    {
      initialCursor: null,
    }
  );

  const onDeleteBulkPayment = useCallback(
    async (bulkPaymentId: string) => {
      const confirmed = await alertDialog({
        title: "¿Querés eliminar este borrador de pago?",
        message: "No vas a poder deshacer esta acción.",
        cancelButton: { text: "No eliminar" },
        confirmButton: { text: "Eliminar" },
      });
      if (confirmed) {
        deletingBulkPaymentIds.add(bulkPaymentId);
        const result = await deleteBulkPayment(client, bulkPaymentId);
        if (!result.error) {
          onLoadFirstPage();
        }
        deletingBulkPaymentIds.delete(bulkPaymentId);
      }
    },
    [alertDialog, client, onLoadFirstPage, deletingBulkPaymentIds]
  );

  // Fetch the first page on mount
  useEffect(() => {
    onLoadFirstPage();
  }, [onLoadFirstPage]);

  const bulkPaymentCountText = useMemo(() => {
    let typeLabel: string;
    switch (type) {
      case BulkPaymentType.Salaries:
        typeLabel = "de sueldos";
        break;
      case BulkPaymentType.Providers:
        typeLabel = "a proveedores";
        break;
    }
    return `${bulkPaymentCount} ${
      bulkPaymentCount === 1 ? "pago" : "pagos"
    } ${typeLabel}`;
  }, [type, bulkPaymentCount]);

  return (
    <DashboardSection
      backButton="/"
      title={
        type === BulkPaymentType.Salaries
          ? "Pago de sueldos"
          : "Pago a proveedores"
      }
    >
      <Box color="blackAlpha.600" fontSize="xl" fontWeight="semibold" mb={4}>
        <Skeleton
          display="inline-block"
          isLoaded={!isLoadingFirstPage}
          rounded="md"
        >
          {bulkPaymentCountText}
        </Skeleton>
      </Box>

      <Wrap mb={6} spacing={3}>
        {statusFilters.map((filter) => (
          <WrapItem
            key={filter.label}
            role="radiogroup"
            aria-label="Filtros por estado"
          >
            <Button
              rounded="full"
              size="sm"
              textTransform="uppercase"
              role="radio"
              aria-checked={filter.value === currentFilters.status}
              bg={filter.value !== currentFilters.status ? "white" : undefined}
              borderWidth={1}
              onClick={() =>
                setCurrentFilters((prev) => ({ ...prev, status: filter.value }))
              }
              color={
                filter.value !== currentFilters.status ? "gray.600" : undefined
              }
              _hover={
                filter.value !== currentFilters.status
                  ? {
                      bg: "gray.50",
                    }
                  : {}
              }
            >
              {filter.label}
            </Button>
          </WrapItem>
        ))}
      </Wrap>

      <Grid gap={8} templateColumns="repeat(auto-fill, minmax(20rem, 1fr))">
        {!isLoadingFirstPage ? (
          <>
            {showNewPaymentButton ? (
              type === BulkPaymentType.Salaries ? (
                <BulkPaymentsHistoryNewSalaries />
              ) : (
                <BulkPaymentsHistoryNewProviders />
              )
            ) : bulkPaymentCount === 0 ? (
              <Center
                gridColumn="1 / -1"
                minH={44}
                bg="whiteAlpha.500"
                rounded="lg"
              >
                <HStack
                  borderWidth={1}
                  bgColor="whiteAlpha.700"
                  rounded="full"
                  fontSize="lg"
                  color="blackAlpha.600"
                  fontWeight="medium"
                  px={6}
                  py={3}
                  spacing={3}
                >
                  <Icon as={FiFile} />
                  <Text>
                    No hay pagos que coincidan con el fitro seleccionado.
                  </Text>
                </HStack>
              </Center>
            ) : null}

            {bulkPayments.map((bulkPayment) =>
              bulkPayment.status === BulkPaymentStatus.Created ? (
                <BulkPaymentsHistoryDraftItem
                  key={bulkPayment.id}
                  bulkPayment={bulkPayment}
                  onDelete={() => onDeleteBulkPayment(bulkPayment.id)}
                  isDeleting={deletingBulkPaymentIds.has(bulkPayment.id)}
                />
              ) : (
                <BulkPaymentsHistoryItem
                  key={bulkPayment.id}
                  bulkPayment={bulkPayment}
                />
              )
            )}
          </>
        ) : (
          new Array(pageSize)
            .fill(null)
            .map((_, index) => <BulkPaymentsHistoryItemSkeleton key={index} />)
        )}
      </Grid>
      {!isLoadingFirstPage && areMorePagesAvailable && (
        <Center mt={8}>
          <Button
            color="blackAlpha.600"
            colorScheme="blackAlpha"
            isLoading={isLoadingAdditionalPage}
            leftIcon={<Icon as={FiChevronsDown} />}
            onClick={onLoadNextPage}
            rounded="full"
            variant="ghost"
          >
            Cargar más
          </Button>
        </Center>
      )}
    </DashboardSection>
  );
};
