import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/table";

import { EmptyState } from "components/empty-state";
import { SocietyStructureNodePerson } from "modules/onboarding/onboarding.types";
import { formatWithDecimalScale } from "utils/number.utils";

export const SocietyPeopleTable = ({
  people,
}: {
  people: SocietyStructureNodePerson[];
}) => {
  if (people.length === 0) {
    return <EmptyState>No hay socios registrados.</EmptyState>;
  }

  return (
    <TableContainer fontSize={{ base: "xs", md: "md" }}>
      <Table variant="unstyled">
        <Thead fontSize={{ base: "xs", md: "md" }}>
          <Tr>
            <Th>Persona</Th>
            <Th>Documento</Th>
            <Th>Tipo miembro</Th>
            <Th>País</Th>
            <Th>Cuotas</Th>
          </Tr>
        </Thead>
        <Tbody>
          {people.map((person, index) => {
            if (person === null) {
              return null;
            }
            return (
              <Tr key={index}>
                <Td>
                  {person.firstName} {person.lastName}
                </Td>
                <Td>
                  {person.personDocumentType.description}{" "}
                  {person.documentNumber}
                </Td>
                <Td>{person.memberType.description}</Td>
                <Td>{person.country.description}</Td>
                <Td>
                  {formatWithDecimalScale(person.sharesWithDecimals ?? 0, 2, {
                    removeTrailingZeros: true,
                  })}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
