import {
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Td,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import {
  KeyboardEvent,
  RefObject,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import CurrencyInput from "react-currency-input-field";
import { FiMoreVertical, FiTrash2 } from "react-icons/fi";

import { useBulkPaymentDetail } from "modules/dashboard/routes/bulk-payments/bulk-payment-detail/bulk-payment-detail.context";
import { Payment } from "modules/dashboard/routes/bulk-payments/bulk-payments.api";
import { PropsWithRest } from "types/react-utils";
import { applyDecimalScale, parseNumber } from "utils/number.utils";
import { formatTaxId } from "utils/tax-id.utils";

export const PaymentsListRow = forwardRef(
  (
    {
      payment,
      onFocusPreviousRow = () => {},
      onFocusNextRow = () => {},
      ...rest
    }: PropsWithRest<
      {
        payment: Payment;
        onFocusPreviousRow?: () => void;
        onFocusNextRow?: () => void;
      },
      typeof Tr
    >,
    amountInputRef: RefObject<HTMLInputElement>
  ) => {
    const {
      actions: { onUpdatePayment, onDeletePayment },
      state: { isUpdatingPayment: isUpdatingById },
    } = useBulkPaymentDetail();

    const isUpdating = useMemo(
      () => isUpdatingById(payment.id),
      [isUpdatingById, payment.id]
    );

    const [localAmount, setLocalAmount] = useState("");
    const [shouldSubmit, setShouldSubmit] = useState(false);

    const reset = useCallback(() => {
      setLocalAmount(
        applyDecimalScale(payment.amount.amountInCents, 2).join(",")
      );
    }, [payment]);

    const onAmountInputKeyDown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        switch (event.key) {
          case "ArrowUp":
            onFocusPreviousRow();
            break;
          case "ArrowDown":
          case "Enter":
            onFocusNextRow();
            break;
          case "Escape":
            reset();
            break;
        }
      },
      [onFocusNextRow, onFocusPreviousRow, reset]
    );

    const onSubmitChanges = useCallback(async () => {
      const amountInCents = parseNumber(localAmount, { decimalScale: 2 });
      if (amountInCents !== payment.amount.amountInCents) {
        const successful = await onUpdatePayment(payment.id, {
          amount: {
            amountInCents,
            currency: payment.amount.currency,
          },
        });
        if (!successful) {
          reset();
        }
      }
    }, [localAmount, onUpdatePayment, payment, reset]);

    useEffect(() => {
      reset();
    }, [reset]);

    useEffect(() => {
      if (shouldSubmit) {
        onSubmitChanges();
        setShouldSubmit(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldSubmit]);

    return (
      <Tr
        {...rest}
        data-employee-id={payment.id}
        data-group="payments-table-row"
        position="relative"
      >
        <Td>{payment.to.name}</Td>
        <Td
          css={{
            fontVariantNumeric: "tabular-nums",
          }}
        >
          {formatTaxId(payment.to.taxId)}
        </Td>
        <Td
          css={{
            fontVariantNumeric: "tabular-nums",
          }}
        >
          {payment.to.cbu}
        </Td>
        <Td>{payment.description || "-"}</Td>
        <Td
          isNumeric
          px={4}
          flexShrink={0}
          position="sticky"
          right={14}
          zIndex={1}
          bg="gray.50"
          _before={{
            content: '""',
            position: "absolute",
            top: 0,
            right: 0,
            bottom: "-1px",
            left: 0,
            shadow: "md",
          }}
        >
          <Input
            ref={amountInputRef}
            allowNegativeValue={false}
            as={CurrencyInput}
            decimalScale={2}
            decimalsLimit={2}
            fontWeight="semibold"
            intlConfig={{ locale: "es-AR", currency: payment.amount.currency }}
            isDisabled={isUpdating}
            onBlur={() => setShouldSubmit(true)}
            onKeyDown={onAmountInputKeyDown}
            placeholder="$ 0,00"
            rounded="full"
            textAlign="right"
            value={localAmount}
            w="full"
            minW="20ch"
            css={{
              fontVariantNumeric: "tabular-nums",
            }}
            onValueChange={(value) => {
              setLocalAmount(value);
            }}
          />
        </Td>
        <Td py={0} px={2} position="sticky" right={0} bg="white">
          <HStack spacing={2}>
            <Menu>
              <Tooltip label="Acciones">
                <MenuButton
                  aria-label="Acciones"
                  as={IconButton}
                  icon={<FiMoreVertical />}
                  isLoading={isUpdating}
                  rounded="full"
                  variant="ghost"
                />
              </Tooltip>
              <Portal>
                <MenuList>
                  <MenuItem
                    icon={<FiTrash2 />}
                    onClick={() => onDeletePayment(payment.id)}
                  >
                    Eliminar
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          </HStack>
        </Td>
      </Tr>
    );
  }
);
