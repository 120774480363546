import {
  Box,
  Card,
  CardBody,
  HStack,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
} from "@chakra-ui/react";

export const BulkPaymentsHistoryItemSkeleton = () => {
  return (
    <Card h={44} lineHeight="short" rounded="xl" shadow="md">
      <CardBody p={6}>
        <Stack align="start" h="full" justify="center">
          <Stack align="start" spacing={1} w="full">
            <HStack
              fontSize="lg"
              fontWeight="semibold"
              justify="space-between"
              w="full"
            >
              <Skeleton rounded="md">Total</Skeleton>
              <Skeleton rounded="md">?? personas</Skeleton>
            </HStack>
            <Skeleton rounded="md">
              <Text fontSize="2xl" fontWeight="bold">
                $ 1.000.000,00
              </Text>
            </Skeleton>
          </Stack>
          <Skeleton h={4} rounded="md" w={20} />
          <Box pt={1}>
            <SkeletonText
              noOfLines={2}
              rounded="md"
              skeletonHeight={3}
              spacing={1}
            >
              Creado el ??/??/???? a las ??:?? hs.
            </SkeletonText>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  );
};
