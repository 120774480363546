import { FormLabel } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

import { PropsWithRest } from "types/react-utils";

export const OnboardingLabel = ({
  children,
  ...rest
}: PropsWithRest<PropsWithChildren, typeof FormLabel>) => {
  return (
    <FormLabel fontWeight="bold" mb={2} {...rest}>
      {children}
    </FormLabel>
  );
};
