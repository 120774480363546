import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useClient } from "urql";

import { createProvidersBulkPayment } from "./bulk-payments-providers.api";
import { BulkPaymentStatus, BulkPaymentType } from "./bulk-payments.api";

export const useBulkPaymentProvidersCreation = () => {
  const client = useClient();
  const navigate = useNavigate();

  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const onCreateBulkPayment = useCallback(async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const result = await createProvidersBulkPayment(client);
    if (!result.error && result.data) {
      const { id: bulkPaymentId } =
        result.data.createEmptyBulkPaymentToProviders;
      navigate(`/pagos/${bulkPaymentId}`, {
        state: {
          from: location.pathname,
          bulkPayment: {
            status: BulkPaymentStatus.Created,
            paymentType: BulkPaymentType.Providers,
          },
        },
      });
    }
    setIsLoading(false);
  }, [client, navigate, isLoading, location.pathname]);

  return {
    onCreateBulkPayment,
    isLoading,
  };
};
