import dayjs from "dayjs";

import { BulkPaymentStatus, PaymentStatus } from "./bulk-payments.api";

import { firstLetterToUpperCase } from "utils/string.utils";

export const bulkPaymentStatusColorScheme = (
  status: BulkPaymentStatus,
  hasErrors = false
) => {
  switch (status) {
    case BulkPaymentStatus.Created:
      return "blue";
    case BulkPaymentStatus.PendingApproval:
      return "yellow";
    case BulkPaymentStatus.Scheduled:
      return "yellow";
    case BulkPaymentStatus.InProcess:
      return "orange";
    case BulkPaymentStatus.Rejected:
      return "red";
    case BulkPaymentStatus.Cancelled:
      return "gray";
    case BulkPaymentStatus.Finished:
      return hasErrors ? "red" : "green";
  }
};

export const bulkPaymentStatusLabel = (
  status: BulkPaymentStatus,
  plural = false,
  hasErrors = false,
  hasSuccesses = false
) => {
  switch (status) {
    case BulkPaymentStatus.Created:
      return plural ? "Borradores" : "Borrador";
    case BulkPaymentStatus.PendingApproval:
      return "Esperando aprobación";
    case BulkPaymentStatus.Scheduled:
      return plural ? "Programados" : "Programado";
    case BulkPaymentStatus.InProcess:
      return "En proceso";
    case BulkPaymentStatus.Finished: {
      if (hasErrors) {
        return "Procesado con errores";
      }
      if (hasSuccesses) {
        return "Exitoso";
      }
      return plural ? "Procesados" : "Procesado";
    }
    case BulkPaymentStatus.Rejected:
      return plural ? "Rechazados" : "Rechazado";
    case BulkPaymentStatus.Cancelled:
      return plural ? "Cancelados" : "Cancelado";
    default:
      return status;
  }
};

export const paymentStatusColorScheme = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.Pending:
      return "yellow";
    case PaymentStatus.Sent:
      return "blue";
    case PaymentStatus.Successful:
      return "green";
    case PaymentStatus.Failed:
      return "red";
    case PaymentStatus.Cancelled:
      return "gray";
  }
};

export const paymentStatusLabel = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.Pending:
      return "Pendiente";
    case PaymentStatus.Sent:
      return "Procesando";
    case PaymentStatus.Successful:
      return "Exitoso";
    case PaymentStatus.Failed:
      return "Falló";
    case PaymentStatus.Cancelled:
      return "Cancelado";
  }
};

export function isImmediatePayment(date: string | null) {
  // If the date is null or empty, we consider it as an instant payment because the user didn't select a date
  return date === "" || date === null || dayjs().isSame(date);
}

export function getHumanReadableDateTextsForScheduledPayments(
  dateStringReceived: string | null
): {
  title: string;
  subtitle: string;
  editDateButton: string;
  todayButton: string;
} {
  const dateObjReceived = dayjs.utc(dateStringReceived);

  const isImmediate = isImmediatePayment(dateStringReceived);

  if (isImmediate) {
    return {
      title: "Hoy",
      subtitle:
        "El pago se va a realizar hoy, una vez que la persona firmante lo apruebe.",
      editDateButton: "Cambiar fecha",
      todayButton: "Hacer pago hoy",
    };
  } else {
    const today = dayjs.utc().startOf("day");
    const date = dateObjReceived.date();
    const day = firstLetterToUpperCase(dateObjReceived.format("dddd"));
    const month = dateObjReceived.format("MMMM");
    const daysApart = dateObjReceived.diff(today, "day");

    return {
      title: `${day} ${date} de ${month}`,
      subtitle: `El pago se va a realizar en ${daysApart} día${
        daysApart > 1 ? "s" : ""
      }`,
      editDateButton: "Cambiar fecha",
      todayButton: "Hacer pago hoy",
    };
  }
}
