import { Skeleton, Stack, Wrap } from "@chakra-ui/react";
import { useMemo } from "react";

import { PaymentDateScheduler } from "./payment-date-scheduler";

import { useFeatureFlags, FeatureFlagKey } from "contexts/feature-flags";
import {
  BulkPaymentConfirmationBox,
  BulkPaymentConfirmationCard,
  BulkPaymentConfirmationStatBox,
  BulkPaymentConfirmationTable,
} from "modules/dashboard/components/bulk-payment-confirmation";
import { useBulkPaymentDetail } from "modules/dashboard/routes/bulk-payments/bulk-payment-detail/bulk-payment-detail.context";
import { formatAmountWithCurrency } from "utils/money.utils";
import { formatTaxId } from "utils/tax-id.utils";

export const BulkPaymentProvidersEditionConfirmation = () => {
  const { isFeatureEnabled } = useFeatureFlags();

  const {
    data: { payments, paymentCount, totalAmount },
    state: {
      areMorePagesAvailable,
      isFetchingAdditionalPage,
      isFetchingFirstPage,
    },
    actions: { onFetchNextPage },
  } = useBulkPaymentDetail();

  const stats = useMemo(
    () => [
      {
        title: "Pagos",
        value: paymentCount,
      },
      {
        title: "Total a pagar",
        value: formatAmountWithCurrency(totalAmount),
      },
      { title: "Fecha de pago", value: "Hoy" },
      // { title: "Nuevos", value: "?" },
      // { title: "Removidos", value: "?" },
      // { title: "Mes pasado", value: "+?%" },
    ],
    [paymentCount, totalAmount]
  );

  return (
    <Stack spacing={8}>
      <BulkPaymentConfirmationCard title="Resumen">
        <Wrap overflow="visible" spacing={4}>
          {stats.map((stat, index) => (
            <BulkPaymentConfirmationStatBox
              {...stat}
              key={index}
              isLoading={isFetchingFirstPage}
            />
          ))}
        </Wrap>
      </BulkPaymentConfirmationCard>

      {isFeatureEnabled(FeatureFlagKey.BulkPaymentScheduling) && (
        <BulkPaymentConfirmationCard title="Fecha de pago">
          <BulkPaymentConfirmationBox>
            <PaymentDateScheduler />
          </BulkPaymentConfirmationBox>
        </BulkPaymentConfirmationCard>
      )}

      <BulkPaymentConfirmationCard
        title={
          !isFetchingFirstPage ? (
            `${paymentCount} ${
              paymentCount === 1 ? "pago" : "pagos"
            } a realizar`
          ) : (
            <Skeleton h={7} rounded="md" w="90%" />
          )
        }
      >
        <BulkPaymentConfirmationBox>
          <BulkPaymentConfirmationTable
            areMorePagesAvailable={areMorePagesAvailable}
            isFetchingAdditionalPage={isFetchingAdditionalPage}
            isFetchingFirstPage={isFetchingFirstPage}
            onFetchNextPage={onFetchNextPage}
            columns={{
              name: { title: "Proveedor", isNumeric: false },
              taxId: { title: "CUIT", isNumeric: false },
              cbu: { title: "CBU", isNumeric: false },
              amount: { title: "Pago neto", isNumeric: true },
            }}
            rows={payments.map((payment) => ({
              id: payment.id,
              name: payment.to.name,
              taxId: formatTaxId(payment.to.taxId),
              cbu: payment.to.cbu,
              amount: formatAmountWithCurrency(payment.amount),
            }))}
          />
        </BulkPaymentConfirmationBox>
      </BulkPaymentConfirmationCard>
    </Stack>
  );
};
