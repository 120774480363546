import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import { ErrorBoundary } from "./components/error-boundary";

import { appModules } from "modules";

export const AppRoutes = () => {
  const appRoutes: RouteObject[] = [
    {
      path: "/",
      errorElement: <ErrorBoundary />,
      children: appModules.map((module) => module.routes).flat(),
    },
  ];
  const appRouter = createBrowserRouter(appRoutes);

  return <RouterProvider router={appRouter} />;
};
