import { useDisclosure } from "@chakra-ui/hooks";
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { FiUserPlus } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate, useLocation } from "react-router";
import { Navigate } from "react-router-dom";
import { gql, useMutation } from "urql";

import { MemberFormFields, MemberModal } from "./member-modal";
import { TeamMember } from "./team-member";

import { OnboardingBackButton } from "modules/onboarding/components/onboarding-back-button";
import { OnboardingButton } from "modules/onboarding/components/onboarding-button";
import { OnboardingHeader } from "modules/onboarding/components/onboarding-header";
import { OnboardingPage } from "modules/onboarding/components/onboarding-page";
import { useOnboarding } from "modules/onboarding/onboarding.context";
import { OnboardingUsers } from "modules/onboarding/onboarding.fragments";
import { CompanyOnboardingUser } from "modules/onboarding/onboarding.types";
import {
  OnboardingStep,
  getOnboardingPath,
} from "modules/onboarding/routes/onboarding.routes";
import { formatTaxId } from "utils/tax-id.utils";

export const TeamList = () => {
  const [selectedUser, setSelectedUser] =
    useState<CompanyOnboardingUser | null>(null);
  const [showAddSelfPrompt, setShowAddSelfPrompt] = useState(true);
  const [hasTriedToContinue, setHasTriedToContinue] = useState(false);

  const {
    isOpen: isMemberModalOpen,
    onOpen: onOpenMemberModal,
    onClose: onCloseMemberModal,
  } = useDisclosure();

  const {
    state: { isStale, isFetchingFormOptions },
    data: { onboardingSummary, isOnboardingFinished, formOptions },
    actions: { setRequiresFormReSubmission },
  } = useOnboarding();

  const existsLegalRepresentative = onboardingSummary?.users?.some(
    ({ canAcceptTermsAndConditions }) => canAcceptTermsAndConditions
  );

  const [, addCompanyUser] = useMutation<
    {
      addCompanyUser: {
        users: CompanyOnboardingUser[];
      };
    },
    {
      roleId: number;
      name: string;
      lastName: string;
      taxId: string;
      email: string;
      canAcceptTermsAndConditions: boolean;
      powerDocument?: File;
    }
  >(gql`
      mutation AddCompanyUser(
        $roleId: Int!,
        $name: String!,
        $lastName: String!,
        $taxId: String!,
        $email: String!,
        $canAcceptTermsAndConditions: Boolean!, 
        $powerDocument: Upload
      ) {
        addCompanyUser(in: {
          roleId: $roleId,
          name: $name,
          lastName: $lastName,
          taxId: $taxId,
          email: $email,
          canAcceptTermsAndConditions: $canAcceptTermsAndConditions,
          powerDocument: $powerDocument
        }) {
          ...OnboardingUsers
        }
        ${OnboardingUsers}
      }
    `);

  const [, removeCompanyUser] = useMutation<
    {
      removeCompanyUser: {
        users: CompanyOnboardingUser[];
      };
    },
    {
      userId: string;
    }
  >(gql`
    mutation q($userId: String!) {
      removeCompanyUser(in: { userId: $userId }) {
        ...OnboardingUsers
      }
    }
    ${OnboardingUsers}
  `);

  const toast = useToast();

  const navigate = useNavigate();
  const location = useLocation();
  const isComingFromSummary = location.state?.fromSummary || false;

  const handleNavigateBack = () => {
    navigate(
      getOnboardingPath(
        isComingFromSummary
          ? OnboardingStep.Summary
          : OnboardingStep.TeamLanding
      )
    );
  };

  const handleContinue = () => {
    setHasTriedToContinue(true);
    if (!isComingFromSummary && !canContinue) {
      return;
    }
    navigate(getOnboardingPath(OnboardingStep.Summary));
  };

  const canContinue = useMemo(() => {
    return onboardingSummary?.users && onboardingSummary.users.length > 0;
  }, [onboardingSummary?.users]);

  const onCreateMember = async (user: MemberFormFields) => {
    const result = await addCompanyUser({
      ...user,
      taxId: formatTaxId(user.taxId, { withDashes: false }),
      roleId: parseInt(user.roleId),
      powerDocument: user.powerDocument ?? undefined,
    });
    if (!result.error) {
      toast({
        title: "La persona se agregó correctamente.",
        status: "success",
      });
      return true;
    } else {
      return false;
    }
  };

  const onEditMember = (user: CompanyOnboardingUser) => {
    setSelectedUser(user);
    onOpenMemberModal();
  };

  const onUpdateMember = async (_data: MemberFormFields) => {
    // Mutation not implemented
    return false;
  };

  const onRemoveMember = async (userId: string) => {
    const result = await removeCompanyUser({ userId });
    if (!result.error) {
      toast({
        title: "La persona se eliminó correctamente.",
        status: "success",
      });
    }
  };

  useEffect(() => {
    if (onboardingSummary?.users?.length) {
      setShowAddSelfPrompt(false);
    }
  }, [onboardingSummary?.users]);

  useEffect(() => {
    if (isComingFromSummary) {
      setRequiresFormReSubmission(true);
    }
  }, [isComingFromSummary, setRequiresFormReSubmission]);

  if (isOnboardingFinished) {
    return <Navigate to={getOnboardingPath(OnboardingStep.Summary)} />;
  }

  return (
    <OnboardingPage activeStep={2} isLoading={isFetchingFormOptions}>
      <OnboardingBackButton onClick={handleNavigateBack} />
      <OnboardingHeader
        mb={12}
        subtitle="Van a poder acceder a la información de la cuenta empresa y tendrán habilitadas todas sus funcionalidades."
        title="Personas con acceso a la cuenta"
      />

      <Stack
        alignItems="center"
        maxW="lg"
        opacity={isStale ? 0.5 : 1}
        pointerEvents={isStale ? "none" : "auto"}
        spacing={8}
        w="full"
      >
        {(onboardingSummary?.users ?? []).map((user) => (
          <TeamMember
            key={user.id}
            isDisabled={isStale}
            onEdit={() => onEditMember(user)}
            onRemove={() => onRemoveMember(user.id)}
            user={user}
            width="full"
          />
        ))}

        {showAddSelfPrompt ? (
          <Flex
            align="center"
            borderWidth={1}
            direction="column"
            maxW="sm"
            p={8}
            rounded="2xl"
            shadow="sm"
            textAlign="center"
          >
            <Icon as={FiUserPlus} boxSize={14} color="brand.500" mb={5} />
            <Text fontSize="xl" fontWeight="bold" mb={1}>
              ¿Querés agregarte para tener acceso a la cuenta?
            </Text>
            <Text color="gray.500" fontSize="md" fontWeight="semibold" mb={5}>
              Ingresá tus datos para acceder a la cuenta empresa.
            </Text>
            <HStack spacing={5}>
              <Button
                onClick={() => setShowAddSelfPrompt(false)}
                rounded="full"
                variant="subtle"
              >
                Por ahora no
              </Button>
              <Button onClick={onOpenMemberModal} rounded="full">
                Agregarme
              </Button>
            </HStack>
          </Flex>
        ) : (
          <>
            <Box
              as={Button}
              borderWidth={1}
              color="inherit"
              isDisabled={isStale}
              onClick={onOpenMemberModal}
              p={6}
              rounded="xl"
              shadow="sm"
              transition="all 0.2s"
              variant="link"
              w="full"
              _disabled={{
                opacity: 1,
              }}
              _hover={{
                shadow: "md",
                textDecoration: "none",
              }}
            >
              <HStack
                w="full"
                spacing={{
                  base: 3,
                  sm: 5,
                }}
              >
                <Icon as={FiUserPlus} boxSize={6} color="brand.500" />
                <Text
                  flexGrow={1}
                  fontSize="lg"
                  fontWeight="semibold"
                  textAlign="left"
                >
                  Agregar nueva persona
                </Text>
                <Icon as={IoIosArrowForward} boxSize={6} color="gray.600" />
              </HStack>
            </Box>
            {(hasTriedToContinue || isComingFromSummary) && !canContinue && (
              <Text
                color="red.500"
                fontSize="sm"
                maxW="lg"
                mt={10}
                textAlign="center"
              >
                Debés agregar al menos a una persona con acceso a la cuenta.
              </Text>
            )}

            <OnboardingButton
              disabled={isStale}
              mt={12}
              onClick={handleContinue}
            >
              {isComingFromSummary ? "Ir al resumen" : "Continuar"}
            </OnboardingButton>
          </>
        )}
      </Stack>

      <MemberModal
        data={selectedUser}
        isAddingSelf={showAddSelfPrompt}
        isOpen={isMemberModalOpen}
        onCreateMember={onCreateMember}
        onUpdateMember={onUpdateMember}
        roleOptions={formOptions.roles}
        existsLegalRepresentative={existsLegalRepresentative}
        onClose={() => {
          setSelectedUser(null);
          onCloseMemberModal();
        }}
      />
    </OnboardingPage>
  );
};
