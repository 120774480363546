import { tableAnatomy } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  theme as defaultTheme,
  defineStyle,
  extendTheme,
  withDefaultColorScheme,
} from "@chakra-ui/react";
import { StyleFunctionProps, mode } from "@chakra-ui/theme-tools";
import chroma from "chroma-js";

const { definePartsStyle: defineTableStyle } = createMultiStyleConfigHelpers(
  tableAnatomy.keys
);

const brandColor = "#614ad9";
const brandColors = {
  50: chroma(brandColor).mix("white", 0.9, "hsl").hex(),
  100: chroma(brandColor).mix("white", 0.8, "hsl").hex(),
  200: chroma(brandColor).mix("white", 0.6, "hsl").hex(),
  300: chroma(brandColor).mix("white", 0.4, "hsl").hex(),
  400: chroma(brandColor).mix("white", 0.2, "hsl").hex(),
  500: brandColor,
  600: chroma(brandColor).mix("black", 0.2, "hsi").hex(),
  700: chroma(brandColor).mix("black", 0.4, "hsi").hex(),
  800: chroma(brandColor).mix("black", 0.6, "hsi").hex(),
  900: chroma(brandColor).mix("black", 0.8, "hsi").hex(),
};

const focusShadow = (
  color: string | number | chroma.Color,
  opacity: number,
  width = "3px"
) => `0 0 0 ${width} ${chroma(color).alpha(opacity).hex()}`;

const focusShadowBottom = (
  color: string | number | chroma.Color,
  opacity: number,
  width = "2px"
) => `0 ${width} 0 0 ${chroma(color).alpha(opacity).hex()}`;

const buttonFocusVisible = {
  _focusVisible: {
    boxShadow: focusShadow(brandColors[500], 0.4),
    _dark: {
      boxShadow: focusShadow(brandColors[300], 0.6),
    },
  },
};

const xl = defineStyle({
  fontSize: "2xl",
  px: "6",
  h: "16",
  borderRadius: "lg",
});

const classicFieldStyle = (props: StyleFunctionProps) => ({
  ...defaultTheme.components.Input.variants?.outline(props).field,
  borderWidth: "1px",
  borderColor: "blackAlpha.200",
  bg: "white",
  _focusVisible: {
    borderColor: "brand.500",
    boxShadow: focusShadow(brandColors[500], 0.2),
    _invalid: {
      borderColor: "red.500",
      boxShadow: focusShadow("red", 0.2),
    },
  },
  _dark: {
    borderColor: "whiteAlpha.200",
    bg: "gray.700",
    _focusVisible: {
      borderColor: "brand.300",
      boxShadow: focusShadow(brandColors[300], 0.4),
    },
  },
  _invalid: {
    borderColor: "red.500",
  },
});

const flushedFieldStyle = (props: StyleFunctionProps) => ({
  ...defaultTheme.components.Input.variants?.flushed(props).field,
  borderColor: "blackAlpha.200",
  _focusVisible: {
    borderColor: "brand.500",
    boxShadow: focusShadowBottom(500, 0.4),
  },
  _dark: {
    borderColor: "whiteAlpha.300",
    _focusVisible: {
      borderColor: "brand.300",
      boxShadow: focusShadowBottom(300, 0.6),
    },
  },
});

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)",
};

// https://chakra-ui.com/docs/styled-system/customize-theme
export const theme = extendTheme(
  {
    semanticTokens: {
      colors: {
        muted: {
          default: "gray.500",
        },
      },
    },
    colors: {
      brand: brandColors,
    },
    fonts: {
      heading: "SNPro, sans-serif",
      body: "SNPro, sans-serif",
    },
    shadows: {
      outline: focusShadow(brandColors[400], 0.5),
      innerBrand: `inset 0 0 32px ${chroma(brandColor).alpha(0.25).hex()}`,
    },
    components: {
      Tooltip: {
        baseStyle: {
          borderRadius: "md",
        },
      },
      Input: {
        variants: {
          classic: (props: StyleFunctionProps) => ({
            ...defaultTheme.components.Input.variants?.outline(props),
            field: classicFieldStyle(props),
          }),
          flushed: (props: StyleFunctionProps) => ({
            ...defaultTheme.components.Input.variants?.flushed(props),
            field: flushedFieldStyle(props),
          }),
        },
        sizes: {
          xl: { field: xl, addon: xl, leftElement: xl, rightElement: xl },
        },
        defaultProps: {
          variant: "classic",
        },
      },
      Textarea: {
        variants: {
          classic: classicFieldStyle,
          flushed: flushedFieldStyle,
        },
        defaultProps: {
          variant: "classic",
        },
      },
      PinInput: {
        variants: {
          classic: classicFieldStyle,
          flushed: flushedFieldStyle,
        },
        defaultProps: {
          variant: "classic",
        },
      },
      Select: {
        variants: {
          classic: (props: StyleFunctionProps) => ({
            ...defaultTheme.components.Select.variants?.outline(props),
            field: classicFieldStyle(props),
          }),
          flushed: (props: StyleFunctionProps) => ({
            ...defaultTheme.components.Select.variants?.flushed(props),
            field: flushedFieldStyle(props),
          }),
        },
        defaultProps: {
          variant: "classic",
        },
      },
      Button: {
        variants: {
          solid: buttonFocusVisible,
          outline: buttonFocusVisible,
          ghost: buttonFocusVisible,
          link: buttonFocusVisible,
          subtle: (props: StyleFunctionProps) => ({
            bg: mode(
              `${props.colorScheme}.50`,
              `${props.colorScheme}.800`
            )(props),
            color: mode(
              `${props.colorScheme}.500`,
              `${props.colorScheme}.200`
            )(props),
            _hover: {
              bg: mode(
                `${props.colorScheme}.100`,
                `${props.colorScheme}.700`
              )(props),
            },
            _active: {
              bg: mode(
                `${props.colorScheme}.200`,
                `${props.colorScheme}.600`
              )(props),
              color: mode(
                `${props.colorScheme}.600`,
                `${props.colorScheme}.100`
              )(props),
            },
            ...buttonFocusVisible,
          }),
        },
      },
      Form: {
        variants: {
          floating: {
            container: {
              _focusWithin: {
                label: {
                  ...activeLabelStyles,
                },
              },
              "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
                {
                  ...activeLabelStyles,
                },
              label: {
                top: 0,
                left: 0,
                zIndex: 2,
                position: "absolute",
                backgroundColor: "white",
                _dark: {
                  backgroundColor: "gray.700",
                },
                pointerEvents: "none",
                mx: 3,
                px: 1,
                my: 2,
                transformOrigin: "left top",
                rounded: "md",
              },
            },
          },
        },
      },
      Alert: {
        baseStyle: {
          container: {
            borderRadius: "md",
          },
        },
      },
      Table: {
        // change border colors
        variants: {
          simple: defineTableStyle((props) => ({
            th: {
              borderColor: mode(
                `${props.colorScheme}.100`,
                `${props.colorScheme}.600`
              )(props),
            },
            td: {
              borderColor: mode(
                `${props.colorScheme}.100`,
                `${props.colorScheme}.600`
              )(props),
            },
            tr: {
              _last: {
                td: {
                  borderBottomWidth: 0,
                },
              },
            },
          })),
          striped: {
            tr: {
              _last: {
                td: {
                  borderBottomWidth: 0,
                },
              },
            },
          },
        },
      },
    },
  },
  withDefaultColorScheme({ colorScheme: "brand" }),
  withDefaultColorScheme({ colorScheme: "gray", components: ["Table"] })
);
